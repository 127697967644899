import React from 'react'

import IconText from '../elements/IconText'

import { printClasses } from '../../Helpers'

type Props = {
  id: string | number,
  name: string,
  value: any,
  label: string,
  checked?: boolean,
  disabled?: boolean,
  changed?: any,
  cnames?: string,
  icons?: Array,
}

RadioInput.defaultProps = {
  checked: false,
  disabled: false,
  changed: null,
  cnames: null,
  icons: [],
}

function RadioInput(props: Props) {
  const { id, name, value, label, checked, disabled, changed, cnames, icons } =
    props

  const classes = [
    'rpm-input',
    'rpm-input--control',
    'rpm-input--radio',
    disabled && 'is-disabled',
    cnames,
  ]

  return (
    <label htmlFor={id} className={printClasses(classes)}>
      <input
        type="radio"
        id={id}
        className="rpm-input--element"
        name={name}
        value={value}
        checked={checked}
        onChange={changed}
        disabled={disabled}
      />
      {icons.length > 0 ? (
        <IconText icons={icons} text={label} />
      ) : (
        <span>{label}</span>
      )}
    </label>
  )
}

export default RadioInput
