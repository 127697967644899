import { useEffect } from 'react'

export function useDebounceEffect(val, delay, callback) {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback(val)
    }, delay)
    return () => {
      clearTimeout(timer)
    }
  }, [val, delay, callback])
}

export default { useDebounceEffect }
