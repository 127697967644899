import React from 'react'

import Markdown from '../../elements/Markdown'

type WorkshopDetailsProps = {
  workshop: Object,
}

function WorkshopDetails(props: WorkshopDetailsProps) {
  const { workshop } = props
  return (
    <>
      <div className="workshop--item--details--description">
        <h5 className="text--caps">Description</h5>
        <Markdown>{workshop.description}</Markdown>
      </div>
    </>
  )
}

export default WorkshopDetails
