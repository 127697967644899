import React, { useState } from 'react'

import UserProfileRow from './UserProfileRow'

import Button from '../elements/Button'
import IconText from '../elements/IconText'

import ButtonComments from '../molecules/ButtonComments'
import ButtonLikes from '../molecules/ButtonLikes'
import ConfirmationDialog from '../molecules/ConfirmationDialog'

import { formatRawNumber, printClasses } from '../../Helpers'

type Props = {
  users: Object,
  openUserProfile: () => mixed,
  openUserComments: () => mixed,
  toggleUserLike: () => mixed,
  refetchData: () => mixed,
  isAdmin?: boolean,
  disableComments?: boolean,
  showComments?: boolean,
  disableLikes?: boolean,
  showLikes?: boolean,
  onRemove?: (integer, Object) => mixed,
  showRemove?: boolean,
  detailsFunc?: () => mixed,
}

ListUsers.defaultProps = {
  isAdmin: false,
  disableComments: false,
  showComments: true,
  disableLikes: false,
  showLikes: true,
  onRemove: () => {},
  showRemove: false,
  detailsFunc: null,
}

function ListUsers(props: Props) {
  // State
  const {
    users,
    openUserProfile,
    openUserComments,
    toggleUserLike,
    refetchData,
    isAdmin,
    disableComments,
    showComments,
    disableLikes,
    showLikes,
    onRemove,
    showRemove,
    detailsFunc,
  } = props
  const [removeUser, setRemoveUser] = useState(false)

  // API

  // Functions
  const dismissRemoveUser = () => setRemoveUser(false)

  return (
    <>
      {users?.length > 0 &&
        users.map(member => (
          <UserProfileRow
            key={member.username}
            user={member}
            avatarSize={3}
            openProfile={() => openUserProfile(member.id)}
          >
            <div
              className={printClasses([
                'flex--wrap',
                'flex--auto-spread',
                'text--small',
                'text--caps',
              ])}
            >
              {Object.is(member.status, 'invited') ? (
                <p
                  className={printClasses([
                    'user--meta--kcl',
                    'flex--auto-gap',
                    'ish',
                  ])}
                >
                  <IconText
                    icon={{ name: 'paper-plane', type: 'far' }}
                    text="Invite Sent"
                  />
                </p>
              ) : (
                <p
                  className={printClasses([
                    'user--meta--kcl',
                    'flex--auto-gap',
                  ])}
                >
                  {detailsFunc !== null && detailsFunc(member)}
                  {showComments && (
                    <ButtonComments
                      action={() =>
                        openUserComments({
                          ...member,
                          onCommentSuccess: () => refetchData(),
                        })
                      }
                      count={member?.results?.commentCount}
                      isDisabled={disableComments}
                    />
                  )}

                  {showLikes && (
                    <ButtonLikes
                      action={() => toggleUserLike(member.id)}
                      count={member?.results?.likeCount}
                      isLikeUser={member?.results?.isLikeUser}
                      isDisabled={disableLikes}
                    />
                  )}
                </p>
              )}

              <p
                className={printClasses(['user--meta--kcl', 'flex--auto-gap'])}
              >
                {Object.is(member.status, 'member') && (
                  <span>
                    {Number(member?.results?.totals?.combined) > 0
                      ? formatRawNumber(member?.results?.totals?.combined)
                      : '---'}
                  </span>
                )}

                {isAdmin && showRemove && (
                  <Button
                    kind="icon"
                    onClick={() => setRemoveUser(member.id)}
                    disabled={member.teamAdmin}
                  >
                    <IconText
                      icon={{ type: 'fal', name: 'circle-minus', size: 'lg' }}
                      cnames={!member.teamAdmin && 'text--danger'}
                    />
                  </Button>
                )}
              </p>
            </div>
          </UserProfileRow>
        ))}

      {isAdmin && showRemove && (
        <ConfirmationDialog
          isOpen={removeUser}
          header="Remove Team Member"
          primaryText="Remove"
          primaryAction={() => {
            onRemove(removeUser, {
              onSuccess: () => {
                dismissRemoveUser()
              },
              onError: anError => {
                console.log(anError)
                dismissRemoveUser()
              },
            })
          }}
          secondaryText="Cancel"
          secondaryAction={dismissRemoveUser}
          cnames="app-comments--delete-dialog"
        >
          <p>
            <b>Are you sure you want to remove this member from your team?</b>
          </p>

          <p>This cannot be undone.</p>
        </ConfirmationDialog>
      )}
    </>
  )
}

export default ListUsers
