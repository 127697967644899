import React, { useState } from 'react'
import AssessmentQuestion from './AssessmentQuestion'

type Props = {
  onNext: Object,
  nextTab: string,
  prevTab: string,
  setActiveTab: Object,
  updateResponse: Object,
  responses: Object,
  setBackgroundImage: Object,
  questions: Object,
  hideOnboardingSkip?: Boolean,
  onExitAssessment?: Objejct,
  startingQuestion?: string,
}

Assessment.defaultProps = {
  hideOnboardingSkip: false,
  onExitAssessment: undefined,
  startingQuestion: undefined,
}

function Assessment(props: Props) {
  const {
    onNext,
    onExitAssessment,
    nextTab,
    prevTab,
    setActiveTab,
    questions,
    setBackgroundImage,
    updateResponse,
    responses,
    hideOnboardingSkip,
    startingQuestion,
  } = props

  const [curQuestion, setCurQuestion] = useState(startingQuestion || 0)
  const [questionStack, setQuestionStack] = useState([])

  const handleNext = (id, value, skipTo = undefined) => {
    // if we have a skip that isn't an index we will get -1 and thus skip to the next section
    const updatedValue = { ...responses, [id]: value }

    updateResponse(updatedValue)

    let nextQuestion =
      skipTo === undefined
        ? curQuestion + 1
        : questions.findIndex(question => question.id === skipTo)

    if (nextQuestion > questions.length - 1 || nextQuestion < 0) {
      if (nextTab) {
        setActiveTab(nextTab)
      }

      if (onNext) {
        onNext(updatedValue)
      }
    } else {
      const question = questions[nextQuestion]

      if (question.skipIf && question.skipIf.length) {
        let skipThisQuestion = false

        question.skipIf.forEach(skip => {
          if (
            responses[skip.questionId] &&
            responses[skip.questionId].includes(skip.answerId)
          ) {
            skipThisQuestion = true
          }
          nextQuestion = skipThisQuestion ? nextQuestion + 1 : nextQuestion
        })
      }

      setQuestionStack([curQuestion, ...questionStack])
      setCurQuestion(nextQuestion || nextQuestion)
    }
  }

  const handleBack = () => {
    const [lastQuestion, ...rest] = questionStack

    if (questionStack.length === 0) {
      if (prevTab) {
        setActiveTab(prevTab)
      }
      if (onExitAssessment) {
        onExitAssessment()
      }
    } else {
      setCurQuestion(lastQuestion)
      setQuestionStack(rest)
    }
  }

  const currentResponse = questionIndex => {
    if (
      questionIndex !== undefined &&
      questions[questionIndex] !== undefined &&
      responses[questions[questionIndex].id] !== false
    ) {
      return responses[questions[questionIndex].id]
    }

    return undefined
  }

  return (
    <div className="assessment">
      <AssessmentQuestion
        key={questions[curQuestion].id}
        question={questions[curQuestion]}
        currentResponse={currentResponse(curQuestion)}
        setBackgroundImage={setBackgroundImage}
        onBack={handleBack}
        onNext={handleNext}
        updateResponse={updateResponse}
        hideOnboardingSkip={hideOnboardingSkip}
      />
    </div>
  )
}

export default Assessment
