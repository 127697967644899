import React, { useState } from 'react'
import MUISlider from '@mui/material/Slider'

import Button from '../elements/Button'
import Markdown from '../elements/Markdown'
import Tooltip from '../elements/Tooltip'

import DetailsBlock from '../structures/DetailsBlock'
import marks from '../../data/perceivedEffort.json'

const defaultState = undefined

type PerceivedEffortProps = {
  value: Number,
  onChange: Number => void,
  disabled?: boolean,
}

PerceivedEffort.defaultProps = {
  disabled: false,
}

function PerceivedEffort(props: PerceivedEffortProps) {
  const { value, onChange, disabled } = props

  const [showTooltip, setShowTooltip] = useState(false)

  const dismissTooltip = () => setShowTooltip(false)
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const effortChanged = event => {
    onChange(event.target.value)
  }

  const currentSelectedEffort =
    value !== undefined && marks.find(m => value === m.value)

  return (
    <>
      <Tooltip
        isOpen={showTooltip}
        handleDismiss={dismissTooltip}
        cnames="training--tooltip--demo-video"
        message="This is a subjective measure of exercise intensity. In other words... how difficult was the activity for you?"
        hasArrow={false}
      />
      <span className="rpm-input--label">
        Perceived Exertion
        <Button
          onClick={toggleTooltip}
          kind="text"
          icon={{ name: 'circle-question', type: 'fal' }}
          label=""
        />
      </span>
      <div className="rpm-block perceived--display flex--auto-spread text--small">
        <div>
          <span className="ish">How challenging was the workout? </span>
          <strong>{currentSelectedEffort?.label || '-'}</strong>
        </div>
        {!disabled && value !== defaultState && (
          <Button
            label="Clear Entry"
            kind="link"
            onClick={() => onChange(defaultState)}
          />
        )}
      </div>
      <div className="rpm-block">
        <MUISlider
          aria-label="Perceived Effort"
          value={value || 20}
          classes={{ root: value === undefined ? 'is-empty' : '' }}
          getAriaValueText={() => 'Perceived Effort'}
          valueLabelDisplay="off"
          step={20}
          marks={marks}
          min={20}
          max={100}
          onChange={effortChanged}
        />
      </div>
      {value !== defaultState && (
        <DetailsBlock collapsible lines={0}>
          <h5>{currentSelectedEffort?.detailsTitle || ''}</h5>
          <div className="ish rpm-submit-score--perceived--details--text text--small">
            <Markdown>{currentSelectedEffort?.detailsText || ''}</Markdown>
          </div>
        </DetailsBlock>
      )}
    </>
  )
}

export default PerceivedEffort
