import React, { useEffect, useState } from 'react'

import { printClasses } from '../../Helpers'
import Spinner from '../elements/Spinner'

type Props = {
  isLoading?: boolean,
  label?: string,
  size?: number | string,
  delay?: number,
  cnames?: string,
}

LoadingData.defaultProps = {
  isLoading: false,
  label: '',
  size: 3,
  delay: 750,
  cnames: null,
}

function LoadingData(props: Props) {
  const { isLoading, label, size, delay, cnames } = props
  const [showSpinner, setShowSpinner] = useState(false)
  const loaderClasses = ['app-loader', showSpinner && 'is-loading', cnames]

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(isLoading), delay)

    return () => clearTimeout(timer)
  }, [isLoading, delay])

  return (
    <div className={printClasses(loaderClasses)}>
      {showSpinner && <Spinner size={size} label={label} />}
    </div>
  )
}

export default LoadingData
