import React from 'react'

type Props = {
  front: ReactNode,
  back: ReactNode,
  onHover?: Boolean,
  isFlipped: Boolean,
}

FlipCard.defaultProps = {
  onHover: false,
}

function FlipCard(props: Props) {
  const { front, back, onHover, isFlipped } = props

  return (
    <div className={`rpm-flipcard ${onHover ? 'rpm-flipcard--on-hover' : ''}`}>
      <div
        className={`rpm-flipcard--content ${isFlipped ? 'flip-content' : ''}`}
      >
        <div className="rpm-flipcard--content--front">{front}</div>
        <div className="rpm-flipcard--content--back">{back}</div>
      </div>
    </div>
  )
}

export default FlipCard
