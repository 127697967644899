import React from 'react'
import Button from '../elements/Button'
import Icon, { PlayIcon } from '../elements/Icon'

type HeroButtonProps = {
  button: Object,
  className: string,
  kind?: string,
}

HeroButton.defaultProps = {
  kind: 'primary',
}

function HeroButton(props: HeroButtonProps) {
  const { button, kind, className } = props

  const styles = {
    '--local-button-bg': button.bg ? `var(--${button.bg})` : undefined,
    '--local-button-fg': button.fg ? `var(--${button.fg})` : undefined,
  }

  return (
    <Button
      kind={kind}
      label={button.label}
      cnames={className}
      styles={styles}
      onClick={() => {
        if (!button.target) {
          window.location = button.url
        } else {
          window.open(button.url, button.target)
        }
      }}
    />
  )
}

type PromotionProps = {
  promotion: Object,
  showDashVideo: () => void,
}

function Promotion(props: PromotionProps) {
  const { promotion, showDashVideo } = props

  return (
    <>
      <div className="dashboard--promotion--image">
        <div
          className="promo-image"
          style={{ '--promo-image-url': `url(${promotion?.image})` }}
        />
      </div>
      <div className="rpm-hero--info dashboard--promotion--info text--caps">
        <h2 className="dashboard--promotion--title">{promotion?.title}</h2>

        {promotion?.subtitle && (
          <p className="dashboard--promotion--subtitle">
            {promotion?.subtitle}
          </p>
        )}

        {promotion?.coach && (
          <p className="dashboard--promotion--coach">
            <small>
              <Icon name="coach" type="svg" />
              {promotion?.coach}
            </small>
          </p>
        )}
      </div>

      <div className="rpm-hero--cta dashboard--promotion--button">
        <Button
          kind={promotion?.videoId ? 'text' : 'primary'}
          onClick={() => {
            if (promotion?.videoId) {
              showDashVideo(promotion?.videoId)
            } else {
              window.location = promotion?.linkTo
            }
          }}
        >
          {promotion?.videoId ? <PlayIcon /> : promotion?.cta}
        </Button>

        {['bottom-right'].map(
          promoButton =>
            promotion?.buttons &&
            promotion?.buttons[promoButton] && (
              <HeroButton
                button={promotion?.buttons[promoButton]}
                kind="accent"
                className={`at-${promoButton}`}
                key={promoButton}
              />
            ),
        )}
      </div>
    </>
  )
}

export default Promotion
