import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../elements/Button'
import IconText from '../elements/IconText'
import RowMarker from '../elements/RowMarker'

import { kitLabelWithIcon } from '../../Helpers'

type DashboardTrainingCardProps = {
  linkTo: String,
  track: String,
  program: String,
  image: String,
  coaches: String,
  headerText?: String | undefined,
  rowMarkerConfig?: Object | undefined,
  aspectRatio?: String,
  centerText?: ReactNode,
}

DashboardTrainingCard.defaultProps = {
  headerText: undefined,
  rowMarkerConfig: undefined,
  aspectRatio: '16/9',
  centerText: undefined,
}

function DashboardTrainingCard(props: DashboardTrainingCardProps) {
  const {
    track,
    program,
    coaches,
    headerText,
    rowMarkerConfig,
    image,
    linkTo,
    aspectRatio,
    centerText,
  } = props
  const history = useHistory()

  const kitObject = track && kitLabelWithIcon(track, 'sm')
  const rowIcons = kitObject?.icons ? [...kitObject?.icons] : []

  const rowLabel = kitObject?.label

  return (
    <div
      style={{
        '--bg-image': `url(${image})`,
        '--aspect-ratio': aspectRatio,
      }}
      className={`is-flex--column dashboard-training-card ${
        headerText ? 'has-header' : ''
      }`}
    >
      {headerText && (
        <div className="dashboard-training-card--header text--bold text--caps text--small">
          {headerText}
        </div>
      )}
      {rowMarkerConfig && <RowMarker config={rowMarkerConfig} />}
      <strong>
        {program} -{' '}
        <IconText
          icons={rowIcons}
          text={rowLabel}
          cnames="user--meta--kit fg--white"
        />
      </strong>
      {centerText && (
        <div className="dashboard-training-card--video-cue">{centerText}</div>
      )}
      {coaches && (
        <span>
          <IconText
            icon={{ name: 'coach', type: 'svg' }}
            text={coaches}
            cnames="text--bold"
          />
        </span>
      )}
      <div className="dashboard-training-card--button">
        <Button
          isThin
          label="Start"
          onClick={() => {
            history.push(linkTo)
          }}
        />
      </div>
    </div>
  )
}

export default DashboardTrainingCard
