import React from 'react'

import Markdown from '../../../components/elements/Markdown'

export default function WorkoutHeaderSection({
  children,
}: {
  children: ReactNode,
}) {
  return (
    <span className="is-block rpm-description training--section-header">
      <Markdown>{children}</Markdown>
    </span>
  )
}
