import React from 'react'

import IconText from '../elements/IconText'

import { printClasses, userData, cleanedLevel } from '../../Helpers'

type Props = {
  user: Object,
  headline?: Mixed,
  isMe?: Boolean,
  context?: String,
  cnames?: String,
  children?: Any,
}

UserProfileHeader.defaultProps = {
  isMe: false,
  headline: null,
  context: 'panel',
  cnames: '',
  children: null,
}

function UserProfileHeader(props: Props) {
  const { user, headline, isMe, context, cnames, children } = props

  const { name, username, locale, kit, level } = userData(user)
  const levelIcon = { type: 'svg', name: `L${cleanedLevel(level)}`, size: 'lg' }
  const rowIcons = kit?.icons ? [...kit?.icons, levelIcon] : [levelIcon]

  const classes = [
    `rpm-user--hero`,
    `in-${context}`,
    isMe && 'is-me',
    user?.profileImage && 'has-image',
    cnames,
  ]

  return (
    <header className={printClasses(classes)}>
      <div className="rpm-user--hero--details">
        {headline}
        <h3>{name}</h3>
        {username && (
          <p>
            <small className="flex--auto-gap">
              {username}

              {kit && level && <IconText icons={rowIcons} text="" />}

              {locale && (
                <IconText
                  icon={
                    user?.country && {
                      name: user.country,
                      type: 'flag',
                      fw: false,
                    }
                  }
                  text={locale}
                />
              )}
            </small>
          </p>
        )}
      </div>

      {user?.profileImage && (
        <img
          className="rpm-user--hero--image"
          src={user?.profileImage}
          alt={name}
        />
      )}

      {children}
    </header>
  )
}

export default UserProfileHeader
