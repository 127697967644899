import React from 'react'
import Icon from './Icon'
import Button from './Button'

type Props = {
  pages: Integer,
  currentPage: Integer,
  selectPage: int => void,
}

Pager.defaultProps = {}

function Pager(props: Props) {
  const { pages, currentPage, selectPage } = props

  const pageButton = (page, label) => (
    <Button
      kind="text"
      cnames={Object.is(page, currentPage) && 'is-selected'}
      onClick={() => selectPage(page)}
      disabled={page < 1 || page > pages}
      key={String(`${page}-${label}`)}
    >
      {label || page}
    </Button>
  )

  const nextPage = currentPage + 1
  const prevPage = currentPage - 1

  const surroundingPages = [
    currentPage > pages - 1 ? currentPage - 3 : undefined,
    currentPage > pages - 2 ? currentPage - 2 : undefined,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage < 3 ? currentPage + 2 : undefined,
    currentPage < 2 ? currentPage + 3 : undefined,
  ].filter(e => e && e > 1 && e < pages)

  return (
    <div className="rpm-pager">
      {pageButton(prevPage, <Icon name="chevron-left" type="fas" />)}

      {pageButton(1)}
      {surroundingPages[0] === 3 && pageButton(2)}
      {surroundingPages[0] > 3 && '...'}
      {surroundingPages.map(page => pageButton(page))}
      {surroundingPages[surroundingPages.length - 1] === pages - 2 &&
        pageButton(pages - 1)}
      {surroundingPages[surroundingPages.length - 1] < pages - 2 && '...'}
      {pageButton(pages)}

      {pageButton(nextPage, <Icon name="chevron-right" type="fas" />)}
    </div>
  )
}

export default Pager
