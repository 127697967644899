import React, { useState } from 'react'

import Icon from '../../elements/Icon'

type Props = {
  item: Object,
}

function TextDetailsResource(props: Props) {
  const { item } = props
  const { label, details } = item

  const [isOpenDetails, setIsOpenDetails] = useState(false)

  const toggleOpen = event => {
    event?.preventDefault()
    setIsOpenDetails(!isOpenDetails)
  }

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      toggleOpen()
    }
  }

  return label && details ? (
    <details open={isOpenDetails} className="resource--row is-details">
      <summary
        role="button"
        tabIndex={0}
        onClick={toggleOpen}
        onKeyDown={handleKeyDown}
      >
        <div className="resource--row--label summary-title">{label}</div>
        <div className="summary-marker">
          <Icon type="far" name="angle-right" cnames="marker" />
        </div>
      </summary>
      <div className="resource--details">{details}</div>
    </details>
  ) : (
    <div className="resource--row is-not-details">
      <p>{label || details}</p>
    </div>
  )
}

export default TextDetailsResource
