import { useContext } from 'react'
import { SocialContext } from '../../context/socialContext'
import { useToggleLike } from '../../context/atomContext'

export function useOpen10kUserComment() {
  // Context
  const socialContext = useContext(SocialContext)
  const { openComments } = socialContext

  return postData => {
    openComments({
      ...postData,
      track: 'event/challenge',
      config: { commentIdName: 'userTrainingUserId' },
    })
  }
}

export function useOpen10kTeamComment() {
  // Context
  const socialContext = useContext(SocialContext)
  const { openComments } = socialContext

  return postData => {
    openComments({
      ...postData,
      track: 'event/challenge',
      config: {
        commentIdName: 'teamId',
        apiPath: '/team/comment',
      },
    })
  }
}

export function use10kToggleUserLike() {
  const toggle10kUserLike = useToggleLike({
    track: 'event/challenge',
  })

  return (userTrainingUserId, callbacks) => {
    toggle10kUserLike.mutate({ userTrainingUserId }, callbacks)
  }
}

export function use10kToggleTeamLike() {
  const toggle10kUserLike = useToggleLike({
    track: 'event/challenge',
    apiPath: '/team/like',
  })

  return (teamId, callbacks) => {
    toggle10kUserLike.mutate({ teamId }, callbacks)
  }
}

export function jumpData(data) {
  const days = Array.from(
    { length: 30 },
    (day, index) =>
      day ?? { id: index + 1, singles: null, doubles: null, total: null },
  )

  const jumps =
    (Array.isArray(data) &&
      data.map((day, index) => ({
        ...day,
        total: day.combined || Number(day.singles) + Number(day.doubles),
        id: day.trainingId || index + 1,
      }))) ||
    []

  return days
    .map(a => jumps?.find(b => b.id === a.id) || a)
    .concat(jumps?.filter(a => !days.find(b => b.id === a.id)))
}

export default useOpen10kUserComment
