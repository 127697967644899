import React from 'react'

import MUITextField from '@mui/material/TextField'

import { useSubmitScore } from '../../context/atomContext'
import { useUpdateScore, useScore } from '../../context/trainingContext'
import { calcTimeString, getTrack } from '../../Helpers'

import ToggleSwitch from '../inputs/ToggleSwitch'
import TimeInput from '../inputs/Time'
import TextField from '../inputs/TextField'

import Alert from '../elements/Alert'

import Mentions from '../structures/Mentions'

type SubmitPartProps = {
  type: string,
  timecap: number,
  setBeatCap: Object,
  beatCap: boolean,
  setVisible: Object,
  visible: boolean,
  hasLeaderboard: Boolean,
  setReps: Object,
  reps: number,
  setWeight: Object,
  weight: number,
  setTime: Object,
  time: string,
  setTiebreak: Object,
  tiebreak: number,
  setCaption: Object,
  caption: string,
  mentions: Object,
  // setNotes: Object,
  // notes: string,
  errors: Object,
  track: String,
}

const timeExceedsTimeCap = (beatCap, timecap, time) =>
  beatCap &&
  timecap > 0 &&
  calcTimeString(time) > calcTimeString(`${timecap}:00`)

function SubmitPart(props: SubmitPartProps) {
  const {
    type,
    timecap,
    setBeatCap,
    beatCap,
    setVisible,
    visible,
    hasLeaderboard,
    setReps,
    reps,
    setWeight,
    weight,
    setTime,
    time,
    setTiebreak,
    tiebreak,
    setCaption,
    caption,
    mentions,
    // setNotes,
    // notes,
    errors,
    track,
  } = props

  const timeCapExceeded = timeExceedsTimeCap(beatCap, timecap, time)
  const mentionResults = mentions?.userSearchData || []

  const trackObj = getTrack(track)

  return (
    <section className="rpm-description rpm-dialog--section rpm-dialog--section--content">
      {Object.is(type, 'time') && (
        <>
          {timecap > 0 && (
            <ToggleSwitch
              id="beatCap"
              name="beatCap"
              label="Did you beat the time cap?"
              checked={beatCap}
              changed={value => setBeatCap(value)}
              cnames="text--italic text--700"
            />
          )}
        </>
      )}

      {Object.is(type, 'reps') && (
        <ScoreSectionBlock label="Reps">
          <TextField
            id="reps"
            name="reps"
            placeholder="0"
            type="tel"
            maxlength={4}
            value={reps}
            changed={event => setReps(event.target.value)}
            cnames="form-area--numbers"
            error={errors?.score !== undefined}
          />
        </ScoreSectionBlock>
      )}

      {Object.is(type, 'weight') && (
        <ScoreSectionBlock label="Weight">
          <TextField
            id="weight"
            name="weight"
            placeholder="0 pounds"
            type="tel"
            maxlength={4}
            value={weight}
            changed={event => setWeight(event.target.value)}
            cnames="form-area--numbers"
            error={errors?.score !== undefined}
          />
        </ScoreSectionBlock>
      )}

      {Object.is(type, 'time') && (
        <>
          {beatCap && (
            <ScoreSectionBlock
              label={timecap ? `Time (${timecap} Min Cap)` : `Time`}
            >
              <TimeInput
                id="time"
                label=""
                name="score-time"
                value={time}
                disabled={!beatCap}
                required
                changed={value => setTime(value)}
                cnames="form-area--numbers"
                error={timeCapExceeded || errors?.score !== undefined}
              />
            </ScoreSectionBlock>
          )}

          {!beatCap && (
            <ScoreSectionBlock label="Reps Completed">
              <TextField
                id="tiebreak"
                name="tiebreak"
                placeholder="0"
                type="tel"
                maxlength={4}
                value={tiebreak}
                changed={event => setTiebreak(event.target.value)}
                cnames="form-area--numbers"
                error={errors?.tiebreak !== undefined}
              />
            </ScoreSectionBlock>
          )}
        </>
      )}

      {hasLeaderboard && visible !== undefined && (
        <ToggleSwitch
          id="visible"
          name="visible"
          label={trackObj?.strings?.LEADERBOARD_SHOW || 'Show on leaderboard?'}
          checked={visible}
          changed={setVisible}
          cnames="text--italic text--700"
        />
      )}

      {/* <MUITextField
        id="workoutNotesInput"
        placeholder="Section Notes (private)"
        maxRows={4}
        multiline
        hiddenLabel
        variant="filled"
        value={notes}
        onChange={event => setNotes(event.target.value)}
        cnames="form-area--notes"
        fullWidth
        sx={{
          margin: '1rem 0rem 0rem',
          '& .MuiFilledInput-root:hover': {
            backgroundColor: 'white',
          },
          '& .MuiFilledInput-root:before': {
            borderBottom: 'none',
          },
          '& .MuiFilledInput-root:hover:before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
          },
          '& .MuiFilledInput-root.Mui-focused': {
            backgroundColor: 'white',
          },
          '.MuiInputBase-root': {
            backgroundColor: 'white',
            border: '0.07em solid var(--brand-base)',
          },
          '.Mui-focused': {
            backgroundColor: 'white',
            boxShadow: '0 0 0 1px var(--brand-base) inset',
          },
          '.Mui-focused:after': {
            borderBottomColor: 'var(--brand-base)',
          },
        }}
      /> */}

      <Mentions
        rows={mentionResults}
        search={mentions?.userToSearch}
        selectedIndex={mentions?.searchSelectedIndex}
        action={mentions?.addUserToComment}
      >
        <MUITextField
          id="captionInput"
          placeholder="Comment (public)"
          maxRows={4}
          multiline
          hiddenLabel
          variant="filled"
          value={caption}
          onKeyDown={mentions?.keyWatcher}
          onChange={event => setCaption(event.target.value)}
          cnames="form-area--comment"
          fullWidth
          sx={{
            margin: '0rem 0rem',
            '& .MuiFilledInput-root:hover': {
              backgroundColor: 'white',
            },
            '& .MuiFilledInput-root:before': {
              borderBottom: 'none',
            },
            '& .MuiFilledInput-root:hover:before': {
              borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
            },
            '& .MuiFilledInput-root.Mui-focused': {
              backgroundColor: 'white',
            },
            '.MuiInputBase-root': {
              backgroundColor: 'white',
              border: '0.07em solid var(--brand-gray-3)',
            },
            '.Mui-focused': {
              backgroundColor: 'white',
              boxShadow: '0 0 0 1px var(--brand-base) inset',
              borderColor: 'var(--brand-base)',
            },
            '.Mui-focused:after': {
              borderBottomColor: 'var(--brand-base)',
            },
          }}
        />
      </Mentions>
    </section>
  )
}

type SubmitScoreProps = {
  track?: string,
  type: string,
  timecap?: Number,
  date: string,
  hasLeaderboard?: Boolean,
  mentions: Object,
  scoreKeyPath: string,
}

SubmitScore.defaultProps = {
  track: 'atomic',
  timecap: null,
  hasLeaderboard: true,
}

function SubmitScore(props: SubmitScoreProps) {
  const { track, type, timecap, date, hasLeaderboard, mentions, scoreKeyPath } =
    props

  const updateScore = useUpdateScore()
  const { visible, beatCap, reps, time, weight, notes, tiebreak } =
    useScore(scoreKeyPath)

  const postScoreSubmission = useSubmitScore()

  return (
    <>
      {postScoreSubmission.isError && (
        <Alert type="error" message="Please correct any issues..." />
      )}

      {type.map((aType, index) => (
        <SubmitPart
          type={aType}
          timecap={timecap || 0}
          setReps={val => updateScore([...scoreKeyPath, 'reps'], val)}
          setBeatCap={val => updateScore([...scoreKeyPath, 'beatCap'], val)}
          beatCap={beatCap}
          setVisible={val => updateScore([...scoreKeyPath, 'visible'], val)}
          visible={visible}
          hasLeaderboard={hasLeaderboard}
          reps={reps || ''}
          setWeight={val => updateScore([...scoreKeyPath, 'weight'], val)}
          weight={weight || ''}
          setTime={val => updateScore([...scoreKeyPath, 'time'], val)}
          time={time || ''}
          setTiebreak={val => updateScore([...scoreKeyPath, 'tiebreak'], val)}
          tiebreak={tiebreak || ''}
          setCaption={mentions?.setMentionText}
          caption={mentions?.mentionText}
          mentions={mentions}
          setNotes={val => updateScore([...scoreKeyPath, 'notes'], val)}
          notes={notes}
          key={String(`${date}-${index}`)}
          errors={
            postScoreSubmission.isError && postScoreSubmission.error?.data
              ? postScoreSubmission.error.data
              : {}
          }
          track={track}
        />
      ))}
    </>
  )
}

function ScoreSectionBlock({
  label,
  children,
}: {
  label: ReactNode,
  children: ReactNode,
}) {
  return (
    <div className="workout-section--container">
      <div className="workout-section--header text--small workout-section--row workout-section--row--full">
        <div className="workout-section--cell workout-section--cell--full">
          {label}
        </div>
      </div>
      <div className="workout-section--row workout-section--row--full">
        <div className="workout-section--cell workout-section--cell--full">
          {children}
        </div>
      </div>
    </div>
  )
}

export default SubmitScore
