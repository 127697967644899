import React, { useState } from 'react'

import TextField from './TextField'
import Button from '../elements/Button'
import InputGroup from './InputGroup'

type Props = {
  hideText?: boolean,
  toggleHideText?: any,
  id: string | number,
  disabled?: boolean,
  name: string,
  value: any,
  label?: string,
  prefix?: string,
  placeholder?: string,
  list?: string,
  cnames?: string,
  isThin?: boolean,
  required?: boolean,
  size?: string | number,
  min?: string | number,
  max?: string | number,
  maxlength?: string | number,
  step?: string | number,
  helpMessage?: string,
  error?: boolean,
  changed?: any,
  keyup?: any,
}

Password.defaultProps = {
  hideText: null,
  toggleHideText: null,
  disabled: false,
  label: null,
  prefix: null,
  placeholder: null,
  list: null,
  cnames: null,
  isThin: false,
  required: false,
  size: null,
  min: null,
  max: null,
  maxlength: null,
  step: null,
  helpMessage: null,
  error: false,
  changed: null,
  keyup: null,
}

function Password(props: Props) {
  const {
    hideText,
    toggleHideText,
    id,
    disabled,
    name,
    value,
    label,
    prefix,
    placeholder,
    list,
    cnames,
    isThin,
    required,
    size,
    min,
    max,
    maxlength,
    step,
    helpMessage,
    error,
    changed,
    keyup,
  } = props

  const [localHideText, setLocalHideText] = useState(true)
  const localToggleHide = event => {
    event.preventDefault()
    if (toggleHideText !== null) {
      toggleHideText()
    } else {
      setLocalHideText(!localHideText)
    }
  }

  const hide = hideText !== null ? hideText : localHideText
  const eyecon = hide ? 'eye' : 'eye-slash'

  return (
    <InputGroup
      label={label}
      columns="1fr auto"
      cnames="rpm-input--password-group"
    >
      <TextField
        id={id}
        type={hide ? 'password' : 'text'}
        disabled={disabled}
        name={name}
        value={value}
        prefix={prefix}
        placeholder={placeholder}
        list={list}
        cnames={cnames}
        isThin={isThin}
        required={required}
        size={size}
        min={min}
        max={max}
        maxlength={maxlength}
        step={step}
        helpMessage={helpMessage}
        error={error}
        changed={changed}
        keyup={keyup}
      />
      <Button
        kind="icon"
        icon={{ type: 'far', name: eyecon }}
        onClick={localToggleHide}
        cnames="text--link peeker"
      />
    </InputGroup>
  )
}

export default Password
