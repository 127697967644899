import React from 'react'
import Icon from './Icon'

import { printClasses } from '../../Helpers'

type Props = {
  icon?: Object,
  icons?: Array,
  text: string,
  subtext?: string,
  cnames?: string,
  textCnames?: string,
  flipped?: Boolean,
}

IconText.defaultProps = {
  icon: {},
  icons: null,
  subtext: null,
  cnames: null,
  textCnames: null,
  flipped: false,
}

function IconText(props: Props) {
  const {
    icon: iconProps,
    icons,
    text,
    subtext,
    cnames,
    textCnames,
    flipped,
  } = props

  const {
    name,
    type,
    size,
    fw,
    cnames: iconCnames,
    textCnames: iconTextCnames,
    subTextCnames,
  } = { ...iconProps }

  const hasIcon = name && type
  const hasIcons = Array.isArray(icons) && icons.length > 0
  const hasText = text?.length || subtext

  const classes = [
    'rpm-icontext',
    (hasIcon || hasIcons) && 'has-icon',
    (hasText || hasIcons) && 'has-text',
    flipped && hasText && 'is-flipped',
    cnames,
  ]

  const iconClasses = ['rpm-icontext--icon', iconCnames]
  const textClasses = ['rpm-icontext--text', textCnames, iconTextCnames]

  const textNode = hasText && (
    <span className={printClasses(textClasses)}>
      {text}
      {subtext && (
        <span className={printClasses(['ghost', subTextCnames])}>
          {subtext}
        </span>
      )}
    </span>
  )

  return (
    <span className={printClasses(classes)}>
      {flipped && textNode}

      {hasIcon && !hasIcons && (
        <Icon
          name={name}
          type={type}
          size={size}
          fw={fw}
          cnames={printClasses(iconClasses)}
        />
      )}

      {hasIcons &&
        !hasIcon &&
        icons.map((icon, index) => (
          <Icon
            key={String(`${icon.name}-${index}`)}
            name={icon.name}
            type={icon.type}
            size={icon.size}
            fw={icon.fw}
            cnames={icon.cnames}
          />
        ))}

      {!flipped && textNode}
    </span>
  )
}

export default IconText
