import React from 'react'
import { formatRawNumber, printClasses } from '../../Helpers'
import { jumpData } from './10kHelpers'

import UserProfileRow from '../../components/structures/UserProfileRow'

import DailyJumps from './ChartDailyJumps'
import YearlyJumps from './ChartYearlyJumps'
import JumpStats from './JumpStats'

type Props = {
  communityData: Object,
}

function CommunityChallenge(props: Props) {
  const { communityData } = props

  const jumpStats = [
    {
      id: 'total',
      value:
        (communityData?.totals?.combined > 0 &&
          communityData?.totals?.combined) ||
        null,
      isBold: true,
      divish: false,
      labelish: true,
      label: 'Total Jumps',
    },
    {
      id: 'doubles',
      value:
        (communityData?.totals?.doubles > 0 &&
          communityData?.totals?.doubles) ||
        null,
      isBold: false,
      divish: true,
      labelish: false,
      label: 'Double-unders',
    },
    {
      id: 'singles',
      value:
        (communityData?.totals?.singles > 0 &&
          communityData?.totals?.singles) ||
        null,
      isBold: false,
      divish: true,
      labelish: false,
      label: 'Single-unders',
    },
  ]

  const yearlyTotals = [
    { total: communityData?.yearly[2016], label: '2016' },
    { total: communityData?.yearly[2017], label: '2017' },
    { total: communityData?.yearly[2018], label: '2018' },
    { total: communityData?.yearly[2020], label: '2020' },
    { total: communityData?.yearly[2021], label: '2021' },
    { total: communityData?.yearly[2022], label: '2022' },
    { total: communityData?.yearly[2023], label: '2023' },
  ]

  return (
    <section className="community-stuff">
      <UserProfileRow
        user={{
          username: '10kchallenge',
          name: '10kchallenge',
          profileImage: '/app/images/events/10k-icon.png',
        }}
        avatarSize={3}
        showKitLevel={false}
        cnames="avatar-10k"
      >
        <div
          className={printClasses([
            'flex--auto-spread',
            'text--small',
            'text--caps',
          ])}
        >
          <p className={printClasses(['user--meta--kcl', 'flex--auto-gap'])}>
            <span>
              Today{' '}
              <b>
                {(Number(communityData?.totals?.today) > 0 &&
                  formatRawNumber(communityData?.totals?.today)) ||
                  '---'}
              </b>
            </span>
          </p>
        </div>
      </UserProfileRow>

      <section className="section-block jump-stats">
        <div className="rpm-description">
          <h5 className="text--caps">Jump Stats</h5>
          <JumpStats data={jumpStats} abbreviate />
        </div>

        <div className="rpm-description">
          <DailyJumps data={jumpData(communityData?.daily)} abbreviate />
        </div>

        <div className="rpm-description">
          <YearlyJumps data={yearlyTotals} label="" abbreviate />
        </div>
      </section>
    </section>
  )
}

export default CommunityChallenge
