import React from 'react'
import { withRouter } from 'react-router-dom'
import AppLayout from '../components/layout/Layout'
import Legal from '../components/screens/Legal'

function TermsOfUse() {
  return (
    <AppLayout
      name="terms-of-use"
      headerConfig={{ label: 'ATOM Terms of Use' }}
      cnames="one-column-content"
    >
      <Legal />
    </AppLayout>
  )
}

export default withRouter(TermsOfUse)
