import React from 'react'
import { printClasses } from '../../Helpers'
import Icon from './Icon'

type Props = {
  message: string,
  type: string,
  icon?: Object,
  cnames?: string,
}

Alert.defaultProps = {
  icon: null,
  cnames: null,
}

const alertIcons = {
  error: {
    name: 'exclamation-circle',
    type: 'far',
    size: 'lg',
    fw: true,
  },
}

function Alert(props: Props) {
  const { message, type, icon, cnames } = props
  const classes = ['rpm-alert', `rpm-alert--${type}`, cnames]
  const alertIcon = icon || alertIcons[type]

  const clickHandler = () => {
    if (Object.is(type, 'error')) {
      document
        .querySelector('.has-error')
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <div
      className={printClasses(classes)}
      onClick={clickHandler}
      onKeyPress={clickHandler}
      role="button"
      tabIndex="0"
    >
      {alertIcon?.name && alertIcon?.type && (
        <Icon
          name={alertIcon?.name}
          type={alertIcon?.type}
          size={alertIcon?.size}
          fw={alertIcon?.fw}
          cnames="rpm-alert--icon"
        />
      )}
      {message && <span className="rpm-alert--message">{message}</span>}
    </div>
  )
}

export default Alert
