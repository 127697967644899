import React from 'react'

import Button from '../../components/elements/Button'

type SubmitBarProps = {
  openLogbook: () => void,
  toggleScoreForm: () => void,
  saveButtonLabel: String,
  saveButtonAction: () => void,
  displayConfirmDeleteScore: () => void,
  goToLeaderboard: () => void,
  showLogButtons: Boolean,
  showEditButtons: Boolean,
  cnames: String,
}

function SubmitBar(props: SubmitBarProps) {
  const {
    openLogbook,
    toggleScoreForm,
    saveButtonLabel,
    saveButtonAction,
    displayConfirmDeleteScore,
    goToLeaderboard,
    showLogButtons,
    showEditButtons,
    cnames,
  } = props
  return (
    <div className={cnames}>
      {showLogButtons && (
        <Button
          cnames="fg--brand small-icon"
          kind="icon"
          onClick={openLogbook}
          icon={{ name: 'notebook', type: 'fas' }}
        />
      )}
      {showEditButtons && (
        <Button
          kind="icon"
          cnames="fg--brand-gray-3 small-icon"
          icon={{ name: 'xmark', type: 'fal' }}
          onClick={toggleScoreForm}
        />
      )}
      <Button
        label={
          <span className="training--edit-animated--text">
            {saveButtonLabel}
          </span>
        }
        cnames="training--edit-animated"
        onClick={saveButtonAction}
      />
      {showEditButtons && (
        <Button
          kind="icon"
          cnames="small-icon--danger"
          icon={{ name: 'trash-can', type: 'fas' }}
          onClick={displayConfirmDeleteScore}
        />
      )}
      {showLogButtons && (
        <Button
          cnames="fg--brand small-icon"
          kind="icon"
          onClick={() => goToLeaderboard()}
          icon={{ name: 'users', type: 'fas' }}
        />
      )}
    </div>
  )
}

export default SubmitBar
