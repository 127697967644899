import React from 'react'

import { printClasses, formattedTime } from '../../Helpers'

import videoCuePoints from '../../data/videoCuePoints.json'

import Button from '../elements/Button'

type Props = {
  videoMetadata: Object,
  setSkipToTimeCode: () => void,
  showTrainingVideo: boolean,
  setShowTrainingVideo: boolean => void,
}

function VideoSegments(props: Props) {
  const {
    videoMetadata,
    setSkipToTimeCode,
    showTrainingVideo,
    setShowTrainingVideo,
  } = props

  const videoSegments = videoMetadata?.cue_points

  function labelForCuePoint(id) {
    const point = videoCuePoints.filter(p => p.id === id)
    if (point.length > 0) return point[0].label
    return '-'
  }

  return (
    <section className="training--segments">
      {videoSegments && (
        <div className="training--video-segments">
          <h4 className="text--caps">Skip to Section</h4>

          <ul className="rpm-data-list">
            {videoSegments.map((segment, index) => (
              <li
                key={`${segment.name}-${segment.time}`}
                className={printClasses([
                  'video-segment',
                  `video-segment--${segment.name}`,
                ])}
              >
                <Button
                  kind="text"
                  label={labelForCuePoint(segment.name)}
                  icon={{
                    name: `video-segment-${segment.name}`,
                    type: 'svg',
                  }}
                  onClick={() => {
                    setSkipToTimeCode({
                      click: Date(),
                      time: segment.time,
                    })
                    if (!showTrainingVideo) {
                      setShowTrainingVideo(true)
                    }
                  }}
                />

                <span className="ghost">{formattedTime(segment.time)}</span>

                <span className="ghost">
                  {segment.time !== null &&
                    videoSegments[index + 1] &&
                    `${Math.floor(
                      (videoSegments[index + 1].time - segment.time) / 60,
                    )} min`}

                  {segment.time !== 0 &&
                    !videoSegments[index + 1] &&
                    `${Math.floor(
                      (videoMetadata.duration / 1000 - segment.time) / 60,
                    )} min`}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  )
}

export default VideoSegments
