import React, { useState } from 'react'

import { printClasses } from '../../Helpers'
import Button from './Button'

type Props = {
  color: string,
  label?: string,
  setColor: any,
  cnames?: string,
}

CheckboxInput.defaultProps = {
  label: null,
  cnames: null,
}

function CheckboxInput(props: Props) {
  const { label, color, setColor, cnames } = props

  const colors = [
    '#b51917',
    '#e7302f',
    '#f26091',
    '#ff3b80',
    '#ac41be',
    '#7e53c5',
    '#5b69c3',
    '#3ba3f8',
    '#18b5f9',
    '#0ec6dc',
    '#19a79a',
    '#63bc66',
    '#9bce5f',
    '#d4e34a',
    '#fff048',
    '#ffcb00',
    '#fba900',
    '#ff9900',
    '#ff703a',
    '#8e6e62',
    '#7a5547',
    '#77909d',
    '#536e7b',
    '#323436',
    '',
  ]

  const [showColors, setShowColors] = useState(false)

  const classes = ['rpm-color-picker', showColors && 'is-open', cnames]

  const toggleColorPicker = () => setShowColors(!showColors)

  const pickColor = value => {
    setColor(value)
    toggleColorPicker()
  }

  return (
    <div className={printClasses(classes)}>
      {label && <span className="rpm-color-picker--label">{label}</span>}
      <Button
        kind="simple"
        cnames="rpm-color-picker--cue"
        onClick={toggleColorPicker}
        styles={{ '--button-bg': color }}
        canRipple={false}
        isOutlined={!color}
      >
        <small>&nbsp;</small>
      </Button>

      <div className="rpm-color-picker--palette">
        {colors.map(c => (
          <Button
            kind="icon"
            key={c}
            onClick={() => pickColor(c)}
            styles={{ '--bg': c }}
            cnames={printClasses([
              !c && 'is-none',
              Object.is(color, c) && 'is-selected',
            ])}
            canRipple={false}
          />
        ))}
      </div>
    </div>
  )
}

export default CheckboxInput
