import React from 'react'

import TrainingScore from '../../components/structures/TrainingScore'
import UserKitCommentLike from '../../components/structures/UserKitCommentLike'

import AtomMarkup from '../../components/elements/AtomMarkup'
import Button from '../../components/elements/Button'

type UserScoreProps = {
  training?: Object,
  toggleScoreTools: () => void,
  displayEditScoreForm: () => void,
  updateSocialData: () => void,
  socialContext: Object,
  hasUserTraining?: Boolean,
  cnames?: String,
}

UserScore.defaultProps = {
  training: undefined,
  hasUserTraining: true,
  cnames: '',
}

function UserScore(props: UserScoreProps) {
  const {
    training,
    toggleScoreTools,
    displayEditScoreForm,
    updateSocialData,
    socialContext,
    hasUserTraining,
    cnames,
  } = props
  const { openComments, toggleLike } = socialContext

  const myTrainingCaption = training?.caption
  const showMyCaption =
    myTrainingCaption || training?.commentCount > 0 || training?.likeCount > 0

  return (
    <div className={cnames}>
      {hasUserTraining && training && (
        <div className="rpm-description training--info-block training--results">
          <h4 className="text--caps flex--auto-spread">
            <span>My Score</span>
            <Button
              kind="icon"
              onClick={toggleScoreTools}
              ariaLabel="open score tools"
              icon={{ name: 'ellipsis-h', type: 'far', size: 'xl', fw: false }}
            />
          </h4>
          <TrainingScore
            data={training}
            label=""
            showCompletedLabel
            showPerceivedExertion={false}
          />
        </div>
      )}

      {hasUserTraining && training?.notes && (
        <div className="rpm-description training--info-block training--my-notes">
          <h4 className="text--caps">My Notes</h4>
          <div className="flex--auto-gap use-baseline">
            <p className="ish flex--1">{training.notes}</p>
            <Button
              kind="link"
              icon={{ name: 'edit', type: 'far' }}
              onClick={displayEditScoreForm}
              ariaLabel="edit score"
            />
          </div>
        </div>
      )}

      {showMyCaption && hasUserTraining && training && (
        <div className="rpm-description training--info-block training--my-comments">
          <h4 className="text--caps">My Comments</h4>

          {!training.completedOnly && (
            <UserKitCommentLike
              commentCount={training.commentCount.toString()}
              likeCount={training.likeCount.toString()}
              toggleLike={() =>
                toggleLike(training.id, training.track, {
                  onSuccess: updateSocialData,
                })
              }
              openComments={() =>
                openComments({
                  ...training,
                  onCommentSuccess: updateSocialData,
                })
              }
            />
          )}

          {myTrainingCaption && (
            <div className="flex--auto-gap use-baseline">
              <p className="ish flex--1">
                <AtomMarkup plainText>{myTrainingCaption}</AtomMarkup>
              </p>
              <Button
                kind="link"
                icon={{ name: 'edit', type: 'far' }}
                onClick={displayEditScoreForm}
                ariaLabel="edit score"
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default UserScore
