import React from 'react'

import AtomMarkup from '../elements/AtomMarkup'
import Avatar from '../elements/Avatar'
import Button from '../elements/Button'
import Icon from '../elements/Icon'

import ClampedText from './ClampedText'
import UserKitCommentLike from './UserKitCommentLike'

import { printClasses } from '../../Helpers'

type Props = {
  row: Object,
  openComments: Object,
  openProfile: Object,
  score: string,
  results?: string | Number,
  comments: string | Number,
  likes?: string | Number,
  toggleLike: Object,
  isMe?: boolean,
  showRank?: boolean,
  details?: Any,
}

LeaderboardRow.defaultProps = {
  likes: '—',
  results: null,
  isMe: false,
  showRank: true,
  details: null,
}

function LeaderboardRow(props: Props) {
  const {
    isMe,
    row,
    results,
    comments,
    likes,
    toggleLike,
    score,
    openComments,
    openProfile,
    showRank,
    details,
  } = props

  const classes = ['leaderboard--row', isMe && 'is-me']

  return (
    <li className={printClasses(classes)}>
      <div className="leaderboard--row--rank">
        <b>{row.rank}</b>
      </div>

      <Button kind="icon" onClick={() => openProfile(row.userId)}>
        <Avatar
          image={row.profileImage || row?.image}
          label={row.name}
          size={3}
          flag={{ name: row.country }}
        />
      </Button>

      <div className="leaderboard--row--details">
        <div className="leaderboard--row--user">
          <Button
            kind="text"
            onClick={() => openProfile(row.userId)}
            label={row.username ? `@${row.username}` : row.name}
          />

          <p className="leaderboard--row--stats flex--auto-gap">
            <span className="leaderboard--row--score">
              <Button kind="text" onClick={openComments}>
                {row.completedOnly ? (
                  <Icon name="check-circle" type="fas" fw={false} />
                ) : (
                  score
                )}
              </Button>
            </span>
          </p>
        </div>

        <UserKitCommentLike
          kit={row.kit}
          level={row.level}
          rank={row.rank}
          total={results}
          showRank={showRank}
          commentCount={comments}
          likeCount={likes}
          openComments={() => openComments()}
          toggleLike={() => toggleLike()}
          isLikeUser={row.isLikeUser}
          details={details}
          cnames="leaderboard--row--meta"
        />

        {row.caption && (
          <ClampedText
            cnames="leaderboard--row--comment ghost"
            text={<AtomMarkup plainText>{row.caption}</AtomMarkup>}
          />
        )}
      </div>
    </li>
  )
}

export default LeaderboardRow
