import React from 'react'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'

import { useSubscriptions } from '../context/atomContext'

import AppLayout from '../components/layout/Layout'

function Account() {
  const { isLoading, data } = useSubscriptions()

  const returnParam = `redirect_uri=${window?.location?.protocol}//${
    window?.location?.hostname
  }${window?.location?.port ? `:${window?.location?.port}` : ''}/app/account`

  const manageAccountUrl = `/api/authenticate/manage/account?${returnParam}`

  return (
    <AppLayout
      name="manage-account"
      headerConfig={{ label: 'Account' }}
      cnames="one-column-content manage-account"
    >
      <List disablePadding>
        <ListSubheader component="div">
          Account {data?.user?.email ? `- ${data?.user?.email}` : ''}
        </ListSubheader>
        <ListItem disablePadding>
          <ListItemButton component="a" href={manageAccountUrl}>
            <ListItemText primary="Change Email" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href={manageAccountUrl}>
            <ListItemText primary="Change Password" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href={manageAccountUrl}>
            <ListItemText primary="Change Phone Number" />
          </ListItemButton>
        </ListItem>
        <ListSubheader component="div">Subscriptions</ListSubheader>
        {!isLoading && (
          <>
            {data?.value?.map(subscription => (
              <ListItem disablePadding key={subscription.title}>
                <ListItemButton component="a" href={subscription?.url}>
                  <ListItemText
                    primary={subscription?.title}
                    secondary={subscription?.subtitle}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </AppLayout>
  )
}

export default Account
