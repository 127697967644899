import React, { useState } from 'react'
import dayjs from 'dayjs'

import { useSubmitScore } from '../../context/atomContext'

import ToggleSwitch from '../inputs/ToggleSwitch'
import Textarea from '../inputs/Textarea'
import TextField from '../inputs/TextField'

import Alert from '../elements/Alert'
import Button from '../elements/Button'
import Calendar from '../elements/Calendar'

import PerceivedEffort from '../molecules/PerceivedEffort'
import SelectByFeel from '../molecules/SelectByFeel'

import Mentions from '../structures/Mentions'

import { useMentions } from '../../hooks/TextHooks'
import { dateMe } from '../../Helpers'

type SubmitCustomScoreProps = {
  handleDismiss: Object,
  openDeleteScore: string => void,
  lastScore?: Object,
}

SubmitCustomScore.defaultProps = {
  lastScore: undefined,
}

function SubmitCustomScore(props: SubmitCustomScoreProps) {
  const { handleDismiss, lastScore, openDeleteScore } = props

  const myPerceivedEffort = !Object.is(lastScore?.perceivedEffort, 0)
    ? lastScore?.perceivedEffort
    : undefined

  const myFeltLike = !Object.is(lastScore?.feltLike, 0)
    ? lastScore?.feltLike
    : undefined

  const [haveScore, setHaveScore] = useState(
    lastScore?.completedOnly !== undefined ? !lastScore?.completedOnly : true,
  )
  const [score, setScore] = useState(lastScore?.score || '')
  const [title, setTitle] = useState(lastScore?.title || '')
  const [date, setDate] = useState(
    lastScore?.date ? dayjs(lastScore.date) : dayjs(),
  )
  const [perceivedEffort, setPerceivedEffort] = useState(myPerceivedEffort)
  const [feltLike, setFeltLike] = useState(myFeltLike)

  const mentions = useMentions(lastScore?.caption || '', 'captionInput')
  const mentionResults = mentions?.userSearchData || []

  const [notes, setNotes] = useState(lastScore?.notes || '')

  const postScoreSubmission = useSubmitScore()

  const submitScore = () => {
    const [theCaption, mentionIds] = mentions?.getPostData()

    let postData = {
      title,
      track: 'manual',
      date,
      notes,
      caption: theCaption,
      mentions: mentionIds,
      completedOnly: !haveScore,
      perceivedEffort: perceivedEffort || 0,
      feltLike: feltLike || 0,
    }

    if (haveScore) {
      const additions = {
        score,
      }
      postData = { ...postData, ...additions }
    }

    if (lastScore?.id) {
      postData = { ...postData, id: lastScore?.id }
    }

    postScoreSubmission.mutate(postData, {
      onSuccess: handleDismiss,
      onError: anError => {
        console.log(anError)
      },
    })
  }

  return (
    <>
      {postScoreSubmission.isError && (
        <Alert type="error" message="Please correct any issues..." />
      )}

      <section
        className="rpm-dialog--section rpm-dialog--section--content rpm-submit-score--custom--calendar"
        style={{ '--calendar-width': 'var(--dialog-width, 25em' }}
      >
        <Calendar
          kind="input"
          mode="day"
          date={date}
          handleDate={newDate => setDate(newDate)}
          config={{
            tools: ['previous', 'next'],
            allowWeekends: true,
            maxDate: dateMe(dayjs()),
          }}
        />
      </section>

      <section className="rpm-dialog--section rpm-submit-score--perceived">
        <PerceivedEffort
          value={perceivedEffort}
          onChange={setPerceivedEffort}
        />
      </section>

      <section className="rpm-dialog--section rpm-submit-score--feltLike">
        <SelectByFeel value={feltLike} onChange={setFeltLike} />
      </section>

      <section className="rpm-dialog--section rpm-dialog--section--content">
        <TextField
          id="title"
          name="title"
          label="Title"
          placeholder="3 Mile Jog"
          value={title}
          changed={event => setTitle(event.target.value)}
        />

        <ToggleSwitch
          id="haveScore"
          name="haveScore"
          label="Did you keep score?"
          checked={haveScore}
          changed={value => setHaveScore(value)}
          cnames="text--bold"
        />
        {haveScore && (
          <TextField
            id="score"
            name="score"
            label="Score"
            placeholder="0"
            value={score}
            changed={event => setScore(event.target.value)}
            cnames="form-area--numbers"
          />
        )}
        <Textarea
          id="notesInput"
          name="notesInput"
          label="Journal Notes (private)"
          rows={2}
          value={notes}
          changed={event => setNotes(event.target.value)}
          cnames="form-area--notes"
        />
        <Mentions
          rows={mentionResults}
          search={mentions?.userToSearch}
          selectedIndex={mentions?.searchSelectedIndex}
          action={mentions.addUserToComment}
        >
          <Textarea
            id="captionInput"
            name="captionInput"
            label="Comment (public)"
            rows={3}
            value={mentions?.mentionText}
            onKeyDown={mentions?.keyWatcher}
            changed={event => mentions?.setMentionText(event.target.value)}
            cnames="form-area--comment"
          />
        </Mentions>
      </section>

      <section className="rpm-dialog--section rpm-dialog--section--buttons">
        <p className="flex--auto-gap">
          <Button
            label="Save Your Workout"
            kind="primary"
            disabled={false}
            onClick={() => submitScore()}
          />
        </p>
        {lastScore?.id && lastScore?.track && (
          <p className="flex--auto-gap">
            <Button
              label="Delete"
              kind="danger"
              disabled={false}
              onClick={() => {
                openDeleteScore(lastScore)
                handleDismiss()
              }}
            />
          </p>
        )}
      </section>

      <footer className="rpm-dialog--section rpm-dialog--section--footer">
        <p>
          <Button
            kind="cancel"
            label="Cancel"
            onClick={() => handleDismiss()}
          />
        </p>
      </footer>
    </>
  )
}

export default SubmitCustomScore
