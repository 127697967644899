import React, { useEffect, useState, useMemo } from 'react'

import AppLayout from '../components/layout/Layout'

import Button from '../components/elements/Button'
import Dialog from '../components/elements/Dialog'
import { PlayIcon } from '../components/elements/Icon'
import Flyout from '../components/elements/Flyout'
import Spinner from '../components/elements/Spinner'

import Search from '../components/inputs/Search'
import CheckboxGroup from '../components/inputs/CheckboxGroup'

import VideoPlayer from '../components/structures/VideoPlayer'
import MediaItem from '../components/structures/MediaItem'

import { setBackgroundImage, debounce, videoFilters } from '../Helpers'
import { useVideoLibrary } from '../context/atomContext'

function VideoLibrary() {
  // State
  const [searchTerm, setSearchTerm] = useState('')
  const [params, setParamsReal] = useState({ track: 'affiliate' })
  const [debouncedParams, setDebouncedParams] = useState({ track: 'affiliate' })
  const [searchFilters, setSearchFilters] = useState([])
  const [demoVideo, setDemoVideo] = useState(undefined)

  const { isFetching, isFetchingNextPage, data, fetchNextPage, hasNextPage } =
    useVideoLibrary(debouncedParams)

  const debounceSearch = useMemo(
    () =>
      debounce(p => {
        const tempParams = { ...p }
        if (tempParams?.search?.length < 3) delete tempParams.search
        setDebouncedParams(tempParams)
      }, 500),
    [setDebouncedParams],
  )

  const setParams = p => {
    setParamsReal(p)
    debounceSearch(p)
  }

  setBackgroundImage('') // unsets any default bg image hanging around
  useEffect(() => setBackgroundImage('', 'header-bg-image'), [])

  // Context
  // API
  // Methods
  const doSearch = value => {
    setSearchTerm(value.toLowerCase())
    setParams({ ...params, search: value.toLowerCase() })
  }
  const clearSearch = () => doSearch('')
  const dismissDemoVideo = () => setDemoVideo(undefined)

  const flatTags = Object.keys(videoFilters).flatMap(key => videoFilters[key])

  const toggleFilter = (name, group) => {
    const newParams = { ...params }
    const currentGroup = newParams[group] || []
    let newGroup
    if (currentGroup.includes(name)) {
      newGroup = currentGroup.filter(item => item !== name)
    } else {
      newGroup = [...currentGroup, name]
    }
    newParams[group] = newGroup
    setParams(newParams)
  }

  const updateVideoFilters = event => {
    const filters = event.target.checked
      ? [...new Set([...searchFilters, event.target.value])]
      : [...searchFilters].filter(item => !Object.is(item, event.target.value))
    const thisTag = flatTags.find(t => t.value === event.target.value)
    toggleFilter(thisTag.value, thisTag.group)
    setSearchFilters(filters)
  }

  const filters = (
    <nav
      id="affiliatesFilters"
      className="rpm-filters affiliates--filters affiliates--library--filters"
      key="filters"
    >
      <Search
        id="search"
        placeholder="Find..."
        value={searchTerm}
        changed={doSearch}
        clear={clearSearch}
        isSearching={isFetching}
        maxlength={100}
      />

      <Flyout item={{ icon: {}, label: 'Filter By' }}>
        <CheckboxGroup
          label="Movement Type"
          name="movement"
          options={videoFilters.movements}
          selectedValue={searchFilters}
          handleChange={event => updateVideoFilters(event)}
        />

        <CheckboxGroup
          label="Body Part"
          name="body"
          options={videoFilters.bodyParts}
          selectedValue={searchFilters}
          handleChange={event => updateVideoFilters(event)}
        />

        <CheckboxGroup
          label="Equipment"
          name="equipment"
          mode="list"
          options={videoFilters.equipment}
          selectedValue={searchFilters}
          handleChange={event => updateVideoFilters(event)}
        />
      </Flyout>
    </nav>
  )

  const heels = (
    <>
      <Dialog
        isOpen={demoVideo?.id}
        handleDismiss={dismissDemoVideo}
        header={demoVideo?.name || 'Demo Video'}
        cnames="training--dialog--demo-video ui-dark"
      >
        <VideoPlayer
          videoId={demoVideo?.id}
          showWorkoutOverlay={false}
          autoPlay
          muted
        />
      </Dialog>
    </>
  )

  return (
    <AppLayout
      name="affiliates"
      headerConfig={{ label: 'Video Library' }}
      isLoading={isFetching}
      filters={filters}
      heels={heels}
      cnames="one-column-content is-full-width affiliates--library"
    >
      <article className="app-content affiliates--content">
        {data?.pages && (
          <div className="rpm-subheader">
            <span>
              <b>{data?.pages[0].value?.totalCount}</b> results
            </span>
          </div>
        )}
        <section className="app-content--inner rpm-media--grid">
          {data?.pages &&
            data?.pages?.map(
              page =>
                page?.value?.rows &&
                page?.value?.rows?.map((video, index) => (
                  <MediaItem
                    key={String(`${video.id}-${index}`)}
                    kind="video"
                    action={() => setDemoVideo(video)}
                    styles={{ '--image': `url("${video.poster}")` }}
                  >
                    <p>
                      <b>{video.name}</b>
                    </p>
                    <p>
                      <PlayIcon />
                    </p>
                  </MediaItem>
                )),
            )}
        </section>
        {((hasNextPage && !isFetchingNextPage) || isFetchingNextPage) && (
          <section
            className="app-content--inner"
            style={{ maxWidth: '52rem', margin: '0 auto' }}
          >
            {hasNextPage && !isFetchingNextPage && (
              <Button label="Load More" onClick={() => fetchNextPage()} />
            )}

            {isFetchingNextPage && (
              <div className="text--center">
                <Spinner size={3} />
              </div>
            )}
          </section>
        )}
      </article>
    </AppLayout>
  )
}

export default VideoLibrary
