import React from 'react'
import { printClasses, ripple } from '../../Helpers'
import IconText from './IconText'

type Props = {
  label?: string,
  icon?: Object,
  children?: any,
  disabled?: boolean,
  onClick?: Object,
  smartify?: string | boolean,
  kind?: string,
  size?: string,
  isThin?: boolean,
  isSkinny?: boolean,
  isOutlined?: boolean,
  cnames?: string,
  styles?: Object,
  canRipple?: boolean,
  isFlipped?: boolean,
  gtm?: string,
  ariaLabel?: string,
}

Button.defaultProps = {
  label: null,
  icon: {},
  children: null,
  disabled: false,
  onClick: null,
  smartify: false,
  kind: 'primary',
  size: null,
  isThin: false,
  isSkinny: false,
  isOutlined: false,
  cnames: null,
  styles: null,
  canRipple: true,
  isFlipped: false,
  gtm: null,
  ariaLabel: undefined,
}

function Button(props: Props) {
  const {
    label,
    icon,
    children,
    disabled,
    onClick,
    smartify,
    kind,
    size,
    isThin,
    isSkinny,
    isOutlined,
    cnames,
    styles,
    canRipple,
    isFlipped,
    gtm,
    ariaLabel,
  } = props

  const classes = [
    cnames,
    'rpm-button',
    `rpm-button--${kind}`,
    size && `rpm-button--${size}`,
    smartify && 'is-smartified',
    isThin && 'rpm-button--thin',
    isSkinny && 'rpm-button--skinny',
    isOutlined && 'rpm-button--outlined',
  ]

  const content = Object.keys(icon).length ? (
    <IconText icon={icon} text={label} flipped={isFlipped} />
  ) : (
    children || (label && <span className="rpm-button--label">{label}</span>)
  )

  const onTap =
    canRipple && !['text', 'link', 'icon', 'secret'].includes(kind)
      ? event => ripple(event)
      : () => {}

  return (
    ((onClick || !smartify) && (
      <button
        disabled={disabled}
        onClick={onClick}
        onMouseDown={event => onTap(event)}
        onTouchStart={event => onTap(event)}
        className={printClasses(classes)}
        style={styles}
        data-gtm={gtm}
        aria-label={ariaLabel}
      >
        {content}
      </button>
    )) ||
    React.createElement(
      typeof smartify === 'string' ? smartify : 'span',
      {
        className: printClasses([cnames, 'is-smartified']),
      },
      content,
    )
  )
}

export default Button
