import React from 'react'
import { glueThese } from '../../Helpers'
import Icon from '../../components/elements/Icon'

export function displayTitle(
  item,
  levels = [],
  kind = 'movement',
  disableLevels = false,
  displayFormReview = false,
  WrapperClass = null,
  allowedElements = undefined,
) {
  let title =
    item?.title && item.title.replace('lb.', 'lb').replace('in.', 'in').trim()

  let subtitle =
    item.subtitle &&
    item.subtitle.replace('lb.', 'lb').replace('in.', 'in').trim()

  if (title && title.endsWith('.')) {
    title = title.substring(0, title.length - 1)
  }

  if (subtitle && subtitle.endsWith('.')) {
    subtitle = subtitle.substring(0, subtitle.length - 1)
  }

  return (
    item && (
      <span className={`is-block training--${kind}--title`}>
        <div className="">
          {WrapperClass ? (
            <WrapperClass allowedElements={allowedElements}>
              {title}
            </WrapperClass>
          ) : (
            title
          )}
          {displayFormReview && (
            <sup>
              <Icon name="video-plus" type="far" cnames="form-icon" />
            </sup>
          )}
          {levels?.length > 0 && !disableLevels && (
            <sup>{glueThese(levels)}</sup>
          )}
        </div>
        {subtitle && (
          <small
            className={`is-block training--${kind}--subtitle text--normal`}
          >
            {WrapperClass ? (
              <WrapperClass allowedElements={allowedElements}>
                {subtitle}
              </WrapperClass>
            ) : (
              subtitle
            )}
          </small>
        )}
      </span>
    )
  )
}

export function movementOptionForLevel(movement, selectedOption) {
  const optionLevel = selectedOption.level || selectedOption
  for (let i = 0; i < movement.options.length; i += 1) {
    // Old format was just a string for level and not an object
    const option = movement.options[i]
    if (option.options !== undefined) {
      for (let j = 0; j < option.options.length; j += 1) {
        const subOption = option.options[j]
        if (subOption.id === selectedOption.id) return subOption
      }
      if (selectedOption.id === undefined) {
        return option.options.find(subOpt =>
          subOpt.levels?.includes(optionLevel),
        )
      }
    }
    if (option.id !== undefined && option.id === selectedOption.id)
      return option
    if (option.levels?.includes(optionLevel)) return option
  }
  // if we get here then we couldn't find the id in selections because the workout changed so make a best guess
  if (optionLevel && selectedOption.id !== undefined)
    for (let i = 0; i < movement.options.length; i += 1) {
      const option = movement.options[i]
      if (option.options)
        return option.options.find(subOpt =>
          subOpt.levels?.includes(optionLevel),
        )
    }
  return null
}
