import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import Button from '../../components/elements/Button'
import ProfileImageForm from '../../components/inputs/ProfileImageForm'
import TextField from '../../components/inputs/TextField'
import Textarea from '../../components/inputs/Textarea'
import Alert from '../../components/elements/Alert'

import Countries from '../../data/countries.json'

import {
  useMyAffiliate,
  useSetAffiliateProfile,
} from '../../context/atomContext'

import { setBackgroundImage } from '../../Helpers'

type AffiliateProfileProps = {
  history: Object,
}

AffiliateProfile.defaultProps = {}

function AffiliateProfile(props: AffiliateProfileProps) {
  const { history } = props

  setBackgroundImage('') // unsets any default bg image hanging around
  useEffect(() => setBackgroundImage('', 'header-bg-image'), [])

  const { data: myAffiliate, isLoading } = useMyAffiliate()
  const setAffiliateProfile = useSetAffiliateProfile()
  const formError = setAffiliateProfile.isError && setAffiliateProfile.error

  const [affiliateName, setAffiliateName] = useState('')
  const [country, setCountry] = useState('')
  const [province, setProvince] = useState('')
  const [bio, setBio] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (myAffiliate?.value?.name) setAffiliateName(myAffiliate.value.name)

    let countryObject = null

    if (myAffiliate?.value?.country)
      countryObject = Countries.find(
        listCountry => listCountry.code === myAffiliate.value.country,
      )

    if (countryObject) {
      setCountry(countryObject.name)
    }

    if (myAffiliate?.value?.province)
      if (countryObject?.provinces && countryObject.provinces.length > 0) {
        const provinceObject = countryObject.provinces.find(
          listProvince => listProvince.code === myAffiliate.value.province,
        )
        if (provinceObject) {
          setProvince(provinceObject.name)
        }
      }

    if (myAffiliate?.value?.description) setBio(myAffiliate.value.description)
  }, [myAffiliate])

  const selectedCountry =
    country && Countries.find(listCountry => listCountry.name === country)
  const validCountry = () => {
    if (country === '') return true
    if (Countries.find(listCountry => listCountry.name === country)) {
      return true
    }
    return false
  }

  const getAffiliateNameError = () => {
    if (error && affiliateName === '') return true
    if (error && affiliateName === 'Atom Affiliate') return true
    if (formError && formError?.data?.affiliateName)
      return formError.data.affiliateName
    return false
  }

  const handleSubmit = event => {
    event?.preventDefault()

    if (affiliateName === '' || affiliateName === 'Atom Affiliate') {
      setError(true)
    }
    const myCountry = Countries.find(
      listCountry => listCountry.name === country,
    )

    let myProvince = ''

    if (myCountry && myCountry.provinces.length > 0) {
      myProvince = myCountry.provinces.find(
        listProvince => listProvince.name === province,
      )
    }

    let myBio = ''
    if (bio) {
      myBio = bio
    }

    if (affiliateName) {
      setError(false)
      const postData = {
        name: affiliateName,
        country: myCountry?.code,
        province: myProvince?.code,
        description: myBio,
      }
      setAffiliateProfile.mutate(postData, {
        onSuccess: () => {
          console.log('affiliate profile saved')
          history.push('/')
        },
        onError: anError => {
          setError(true)
          console.log(anError)
        },
      })
    }
  }

  if (isLoading) return ''

  return (
    <>
      <section className="profile profile--image">
        <ProfileImageForm
          image={myAffiliate?.value?.image}
          deleteDialogContent={
            <>
              <p>
                <b>
                  Are you sure you want to delete your affiliate profile image?
                </b>
              </p>
              <p>This can&apos;t be undone.</p>
            </>
          }
          action="affiliate/image"
          cnames="affiliate-avatar"
          inputName="image"
          cacheKey={['affiliate', 'myAffiliate']}
        />
      </section>

      {error && <Alert type="error" message="Please correct any issues..." />}

      <section className="profile profile--page-1">
        <p>
          <TextField
            id="affiliateName"
            label="Affiliate Name"
            placeholder="affiliate name"
            value={affiliateName === 'Atom Affiliate' ? '' : affiliateName}
            error={getAffiliateNameError()}
            changed={e => setAffiliateName(e.target.value)}
            maxlength={20}
            required
          />
        </p>

        <p>
          <Textarea
            id="bioInput"
            name="bioInput"
            placeholder="Add a bio"
            value={bio}
            label="Bio"
            rows={6}
            changed={event => setBio(event.target.value)}
          />
        </p>

        <datalist id="countries">
          <select name="country">
            {Countries.map(listCountry => (
              <option key={listCountry.code} value={listCountry.name} />
            ))}
          </select>
        </datalist>

        <p>
          <TextField
            id="country"
            label="Country"
            placeholder="Start typing..."
            value={country}
            error={error && !validCountry()}
            changed={e => setCountry(e.target.value)}
            list="countries"
            name="country"
          />
        </p>

        {selectedCountry &&
          selectedCountry.provinces &&
          selectedCountry.provinces.length > 0 && (
            <>
              <datalist id="provinces">
                <select name="province">
                  {selectedCountry &&
                    selectedCountry.provinces &&
                    selectedCountry.provinces.length &&
                    selectedCountry.provinces.map(listProvince => (
                      <option
                        key={listProvince.code}
                        value={listProvince.name}
                      />
                    ))}
                </select>
              </datalist>

              <p>
                <TextField
                  id="province"
                  label="State/Province"
                  placeholder="Start typing..."
                  value={province}
                  error={error && province === ''}
                  changed={e => setProvince(e.target.value)}
                  list="provinces"
                  name="province"
                />
              </p>
            </>
          )}
      </section>

      <section className="profile profile--footer">
        <div className="rpm-container--button-group">
          <p>
            <Button label="Save" onClick={event => handleSubmit(event)} />
          </p>
        </div>
      </section>
    </>
  )
}

export default withRouter(AffiliateProfile)
