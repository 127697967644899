import React from 'react'

import Button from '../../elements/Button'
import Icon from '../../elements/Icon'

type Props = {
  item: Object,
  onClick: Object,
}

function VideoResource(props: Props) {
  const { item, onClick } = props
  const { label } = item

  return (
    <Button kind="text" onClick={onClick} cnames="resource--row is-video">
      <span className="resource--row--label">{label}</span>
      <Icon type="fal" name="video" cnames="resource--row--icon" />
    </Button>
  )
}

export default VideoResource
