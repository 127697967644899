import React from 'react'

import { printClasses } from '../../Helpers'

import Radio from './Radio'
import InputGroup from './InputGroup'

type Props = {
  name: string,
  options: array,
  handleChange: object,
  mode?: string,
  label?: string,
  selectedValue?: any,
  disabled?: boolean,
  columns?: string,
  cnames?: string,
  styles?: string,
}

RadioGroup.defaultProps = {
  mode: 'bar',
  label: null,
  selectedValue: null,
  disabled: false,
  columns: null,
  cnames: null,
  styles: null,
}

function RadioGroup(props: Props) {
  const {
    name,
    options,
    handleChange,
    mode,
    label,
    selectedValue,
    disabled,
    columns,
    cnames,
    styles,
  } = props

  const classes = [
    'rpm-input--group--radio',
    `is-${mode}`,
    disabled && 'is-disabled',
    cnames,
  ]

  return (
    <InputGroup
      label={label}
      cnames={printClasses(classes)}
      styles={{ ...styles, '--cols': options.length }}
      columns={columns}
    >
      {options.map(option => {
        const { id, label: optLabel, value, selected, icons } = option
        const checked = selected || Object.is(value, selectedValue)

        return (
          <Radio
            name={name}
            id={id}
            key={id}
            value={value}
            label={optLabel}
            checked={checked}
            changed={handleChange}
            disabled={disabled}
            icons={icons}
          />
        )
      })}
    </InputGroup>
  )
}

export default RadioGroup
