import React, { useState, useCallback } from 'react'
import dayjs from 'dayjs'
import confetti from 'canvas-confetti'
import 'animate.css'

import { useHistory } from 'react-router-dom'

import AppLayout from '../components/layout/Layout'

import {
  setBackgroundImage,
  dateMe,
  animateCSS,
  getCalendarIcons,
  urlForTraining,
  printClasses,
} from '../Helpers'
import {
  useProfile,
  useSubmitScore,
  useTrainingJournal,
  useTrainingCalendar,
  useDeleteScore,
} from '../context/atomContext'

import Button from '../components/elements/Button'
import ConfirmDeleteScore from '../components/molecules/ConfirmDeleteScore'
import Calendar from '../components/elements/Calendar'
import Dialog from '../components/elements/Dialog'
import IconText from '../components/elements/IconText'
import NutritionComponent from '../components/screens/Nutrition'
import SelectKit from '../components/molecules/SelectKit'
import ToggleSwitch from '../components/inputs/ToggleSwitch'
import Zipper from '../components/elements/Zipper'

import TrainingJournal from './TrainingJournal'

const defaultGoals = {
  protein: false,
  kcals: false,
  water: false,
  tags: ['persist_nutrition'],
}

const nutritionKit = {
  id: 'PERSIST_NUTRITION',
  value: 'PERSIST_NUTRITION',
  label: 'Persist Nutrition',
  marketing: 'Persist Nutrition',
  tracks: ['persist'],
  alternatives: [],
  altLabel: 'Persist Nutrition',
}

function Nutrition() {
  const { data: profile } = useProfile()
  const deleteScore = useDeleteScore()
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search)

  const [date, setDate] = useState(queryParams.get('date') || dateMe(dayjs()))
  const [calendarDate, setCalendarDate] = useState(
    queryParams.get('date') || dateMe(dayjs()),
  )
  const [goalCache, setGoalCache] = useState({})
  const [showTrainingJournal, setShowTrainingJournal] = useState(false)
  const [showEditMode, setShowEditMode] = useState(false)
  const [showConfirmDeleteScore, setShowConfirmDeleteScore] =
    useState(undefined)

  const dismissTrainingJournal = () => {
    setShowTrainingJournal(false)
  }
  const dismissConfirmDeleteScore = () => {
    setShowConfirmDeleteScore(false)
    setShowEditMode(false)
  }

  const { data: calendarData } = useTrainingCalendar(
    dateMe(calendarDate, 'yearMonth'),
  )

  const {
    data,
    isFetching,
    isLoading,
    isError: isTrainingJournalError,
  } = useTrainingJournal(null, null, null, '-created', false, 'manual', true, {
    fromSearchDate: date,
    toSearchDate: date,
  })
  const training = data?.value?.rows?.find(
    day =>
      day.date === date && day.metadata?.tags?.includes('persist_nutrition'),
  )

  const cancelEditMode = () => {
    setShowEditMode(false)
    setGoalCache(training?.metadata || {})
  }

  const doSelectDate = useCallback(
    aDate => {
      const currentUrlParams = new URLSearchParams(window.location.search)

      currentUrlParams.set('date', aDate)
      setDate(aDate)

      history.push(
        `${history.location.pathname}?${currentUrlParams.toString()}`,
      )
      setGoalCache({})
    },
    [history],
  )

  const shakeButton = () => {
    const buttonSelector = '.nutrition--edit-animated'
    animateCSS(buttonSelector, ['headShake', 'repeat-2', 'fast'])
  }

  const victory = () => {
    const buttonSelector = '.nutrition--edit-animated'
    const textSelector = '.nutrition--edit-animated--text'

    animateCSS(buttonSelector, 'bounceOut').then(() => {
      confetti({
        startVelocity: 40,
        angle: 60,
        spread: 360,
        ticks: 125,
        zIndex: 200,
        disableForReducedMotion: true,
        origin: { x: 0, y: 1 },
        particleCount: 200,
        gravity: 0.2,
        colors: ['#edae7e', '#5eb3b4'],
      })
      confetti({
        startVelocity: 40,
        angle: 120,
        spread: 360,
        ticks: 125,
        zIndex: 200,
        disableForReducedMotion: true,
        origin: { x: 1, y: 1 },
        particleCount: 200,
        gravity: 0.2,
        colors: ['#edae7e', '#5eb3b4'],
      })

      animateCSS(buttonSelector, 'tada').then(() => {
        animateCSS(textSelector, 'fadeOut').then(() => {
          animateCSS(textSelector, 'fadeIn')
        })
      })
    })
  }

  const postScoreSubmission = useSubmitScore()

  const submitScore = newGoals => {
    if (newGoals.protein && newGoals.kcals && newGoals.water) {
      victory()
    }

    let postData = {
      title: 'Persist Nutrition',
      track: 'manual',
      date,
      notes: '',
      caption: '',
      mentions: [],
      completedOnly: true,
      perceivedEffort: 0,
      feltLike: 0,
      metadata: {
        ...defaultGoals,
        ...newGoals,
      },
    }

    if (training?.id) {
      postData = { ...postData, id: training?.id }
    }

    postScoreSubmission.mutate(postData, {
      onError: anError => {
        console.log(anError)
      },
    })
  }

  const setGoals = newGoals => {
    setGoalCache(newGoals)
  }

  const allGoalsComplete = () => {
    const testGoals = { ...training?.metadata, ...goalCache }
    if (testGoals.kcals && testGoals.protein && testGoals.water) return true
    return false
  }

  const nutritionLogged = training?.metadata?.tags

  const persistNutritionSubscription =
    profile?.value?.subscriptions?.persist_nutrition

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  setBackgroundImage('') // unsets any default bg image hanging around

  const headerConfig = {
    label: 'Nutrition',
  }

  const loggedBgColor = showEditMode ? 'bg--brand-gold' : 'bg--gray'
  const filters = (
    <nav
      id="trainingFilters"
      className="rpm-filters training--filters"
      key="filters"
    >
      <Calendar
        mode="day"
        date={date}
        // eventNutritionDates={['2023-06-16']}
        // eventDates={nutritionDates()}
        // allowedDates={allowedDates()}
        eventIcons={getCalendarIcons(calendarData?.value)}
        updateEventDatesForDate={d => setCalendarDate(d)}
        handleDate={doSelectDate}
        config={{
          tools: ['previous', 'next'],
          maxDate: dateMe(dayjs()),
          allowWeekends: true,
        }}
      />
      <SelectKit
        item={nutritionKit}
        allowedKits={[nutritionKit]}
        doSelect={undefined}
      />

      <Zipper isActive={isFetching} />

      {nutritionLogged && (
        <div
          className={printClasses([
            'rpm-block training--editing-status text--center text--small text--caps text--bold grid-column-end-2 fg--light animate__animated animate__slideInDown',
            loggedBgColor,
          ])}
        >
          {showEditMode ? (
            <IconText
              icon={{ name: 'lock-open', type: 'fas' }}
              text="Editing"
            />
          ) : (
            <IconText icon={{ name: 'lock', type: 'fas' }} text="Logged" />
          )}
        </div>
      )}
    </nav>
  )

  const editLabel = nutritionLogged ? 'Edit Nutrition' : 'Log Nutrition'
  const logButtonClasses = showEditMode
    ? 'rpm-button-block is-shaded training--log-buttons training--log-buttons--full-width training--log-buttons--edit'
    : 'rpm-button-block is-shaded training--log-buttons training--log-buttons--full-width'

  const heels = (
    <>
      <div className={logButtonClasses}>
        {!showEditMode && (
          <Button
            cnames="fg--brand small-icon"
            kind="icon"
            onClick={() => setShowTrainingJournal(true)}
            icon={{ name: 'notebook', type: 'fas' }}
          />
        )}
        {showEditMode && (
          <Button
            kind="icon"
            cnames="fg--brand-gray-3 small-icon"
            icon={{ name: 'xmark', type: 'fal' }}
            onClick={cancelEditMode}
          />
        )}

        <Button
          label={
            <span className="nutrition--edit-animated--text">
              {showEditMode ? 'Save Nutrition' : editLabel}
            </span>
          }
          cnames="nutrition--edit-animated nutrition--message-button"
          onClick={() => {
            if (!showEditMode && nutritionLogged) {
              setShowEditMode(true)
            } else {
              submitScore(goalCache)
              setShowEditMode(false)
            }
          }}
        />
        {showEditMode && (
          <Button
            kind="icon"
            cnames="small-icon--danger"
            icon={{ name: 'trash-can', type: 'fas' }}
            onClick={() =>
              setShowConfirmDeleteScore({ id: training.id, track: 'manual' })
            }
          />
        )}

        {!showEditMode && (
          <Button
            cnames="fg--brand small-icon nutrition--message-button"
            kind="icon"
            onClick={() => console.log('click')}
            icon={{ name: 'message', type: 'fas' }}
          />
        )}
      </div>
      <Dialog
        isOpen={showTrainingJournal}
        handleDismiss={dismissTrainingJournal}
        header="Training Journal"
        cnames="training--dialog--demo-video ui-dark"
      >
        <div className="training--journal-embed">
          <TrainingJournal
            kind="component"
            searchTerm="Persist Nutrition"
            key="persist nutrition"
            dialogs={{
              setShowScoreTools: row => {
                if (row) history.push(urlForTraining(row))
                dismissTrainingJournal()
              },
            }}
          />
        </div>
      </Dialog>
      <ConfirmDeleteScore
        isOpen={showConfirmDeleteScore}
        action={() => {
          dismissConfirmDeleteScore()
          deleteScore.mutate({
            id: showConfirmDeleteScore.id,
            track: showConfirmDeleteScore.track,
          })
          setGoalCache({})
        }}
        dismissAction={dismissConfirmDeleteScore}
      />
    </>
  )

  return (
    <AppLayout
      name="nutrition"
      headerConfig={headerConfig}
      isLoading={false}
      isError={isTrainingJournalError}
      errorAction={() => window.location.reload()}
      cnames="nutrition one-column-content"
      filters={filters}
      heels={heels}
    >
      <div className="app-content--inner">
        {persistNutritionSubscription && (
          <>
            <NutritionComponent collapsible={false} />
            {!isLoading && (
              <>
                <div className="flex--auto-spread bg--brand-gray-6 nutrition--streak-bar">
                  <span className="text--bold">Nutrition Goals</span>
                  <span className="text--700" />
                </div>
                <div className="nutrition--item--details">
                  <ToggleSwitch
                    id="set_all"
                    name="set_all"
                    label="I met all my nutrition goals"
                    checked={allGoalsComplete()}
                    changed={val => {
                      if (!showEditMode && nutritionLogged) {
                        shakeButton()
                        return
                      }
                      if (val) {
                        setGoals({
                          ...training?.metadata,
                          ...goalCache,
                          water: true,
                          kcals: true,
                          protein: true,
                        })
                      } else {
                        setGoals({
                          ...training?.metadata,
                          ...goalCache,
                          water: false,
                          kcals: false,
                          protein: false,
                        })
                      }
                    }}
                    cnames="text--bold"
                  />

                  <ToggleSwitch
                    id="protein"
                    name="protein"
                    label="I met my protein goal"
                    checked={goalCache.protein ?? training?.metadata?.protein}
                    changed={val => {
                      if (!showEditMode && nutritionLogged) {
                        shakeButton()
                        return
                      }

                      setGoals({
                        ...training?.metadata,
                        ...goalCache,
                        protein: val,
                      })
                    }}
                    cnames={
                      allGoalsComplete()
                        ? 'nutrition--toggle-indent text--bold fg--gray'
                        : 'nutrition--toggle-indent text--bold'
                    }
                  />
                  <ToggleSwitch
                    id="kcals"
                    name="kcals"
                    label="I met my calorie goal"
                    checked={goalCache.kcals ?? training?.metadata?.kcals}
                    changed={val => {
                      if (!showEditMode && nutritionLogged) {
                        shakeButton()
                        return
                      }

                      setGoals({
                        ...training?.metadata,
                        ...goalCache,
                        kcals: val,
                      })
                    }}
                    cnames={
                      allGoalsComplete()
                        ? 'nutrition--toggle-indent text--bold fg--gray'
                        : 'nutrition--toggle-indent text--bold'
                    }
                  />

                  <ToggleSwitch
                    id="water"
                    name="water"
                    label="I met my water intake goal"
                    checked={goalCache.water ?? training?.metadata?.water}
                    changed={val => {
                      if (!showEditMode && nutritionLogged) {
                        shakeButton()
                        return
                      }

                      setGoals({
                        ...training?.metadata,
                        ...goalCache,
                        water: val,
                      })
                    }}
                    cnames={
                      allGoalsComplete()
                        ? 'nutrition--toggle-indent text--bold fg--gray'
                        : 'nutrition--toggle-indent text--bold'
                    }
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </AppLayout>
  )
}

export default Nutrition
