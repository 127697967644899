import React from 'react'
import { printClasses } from '../../Helpers'
import Button from '../elements/Button'

type Props = {
  action: Object,
  kind: String,
  cnames?: String,
  styles?: Object,
  children?: Mixed,
}

MediaItem.defaultProps = {
  cnames: null,
  styles: null,
  children: null,
}

function MediaItem(props: Props) {
  const { action, kind, cnames, styles, children } = props
  const classes = ['rpm-media--item', `rpm-media--${kind}`, cnames]

  return (
    <Button
      kind="block"
      smartify="div"
      onClick={action}
      cnames={printClasses(classes)}
      styles={styles}
    >
      <div className="rpm-media--item--info">{children}</div>
    </Button>
  )
}

export default MediaItem
