import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Button from '../elements/Button'
import RowMarker from '../elements/RowMarker'
import Icon from '../elements/Icon'

type Props = {
  linkTo?: string,
  title: string,
  lines?: Array,
  rowMarkerConfig: Object,
  cnames?: string,
  styles?: Object,
  kind?: string,
  onClick?: mixed,
  altItem?: mixed,
  details?: mixed,
}

DashboardItem.defaultProps = {
  linkTo: null,
  lines: [],
  cnames: '',
  styles: undefined,
  kind: undefined,
  onClick: undefined,
  altItem: undefined,
  details: '',
}

function DashboardItem(props: Props) {
  const {
    linkTo,
    title,
    lines,
    rowMarkerConfig,
    cnames,
    styles,
    kind,
    onClick,
    altItem,
    details,
  } = props

  const [detailsOpen, setDetailsOpen] = useState(false)

  const toggleDetails = event => {
    event?.preventDefault()
    setDetailsOpen(!detailsOpen)
  }

  const buttonOnClick = () => {
    if (onClick) onClick()
    else toggleDetails()
  }
  const detailBlock = (
    <section className="dashboard--details">{details}</section>
  )
  const detailsButton = (
    <Button
      kind="text"
      onClick={toggleDetails}
      cnames={`dashboard--details--button ${
        detailsOpen ? 'is-open' : 'is-closed'
      }`}
    >
      <h5>
        <Icon name="chevron-right" type="far" size="lg" cnames="ish" />
      </h5>
    </Button>
  )

  const inner = () => (
    <>
      <RowMarker config={rowMarkerConfig} />
      <div className="journal--row--details">
        <h4 className="text--caps">
          {title} {altItem || <div />}
        </h4>
        {lines.map((line, index) => (
          <p className="text--small" key={String(`${index}`)}>
            {line}
          </p>
        ))}
      </div>
    </>
  )

  if (kind === 'button')
    return (
      <>
        <div className={`dashboard-item ${details ? 'has-details' : ''}`}>
          <Button
            kind="text"
            onClick={buttonOnClick}
            cnames={
              cnames ||
              'rpm-block journal--row dashboard--dt dashboard--dt--atomic'
            }
            styles={styles}
            canRipple={false}
          >
            {inner()}
          </Button>
          {details && detailsButton}
        </div>
        {detailsOpen && detailBlock}
      </>
    )

  return (
    <>
      <div className={`dashboard-item ${details ? 'has-details' : ''}`}>
        <Link
          to={linkTo}
          className={
            cnames ||
            'rpm-block journal--row dashboard--dt dashboard--dt--atomic'
          }
          style={styles}
        >
          {inner()}
        </Link>
        {details && detailsButton}
      </div>
      {detailsOpen && detailBlock}
    </>
  )
}

export default DashboardItem
