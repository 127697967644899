import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import { useSetProfile, useProfile } from '../context/atomContext'

import AppLayout from '../components/layout/Layout'
import TabSet from '../components/elements/TabSet'

import Profile from './parts-for-onboarding/Profile'
import AffiliateProfile from './parts-for-affiliates/AffiliateProfile'

type EditProfileProps = {
  history: Object,
  startTab?: String,
  location: Object,
}

EditProfile.defaultProps = {
  startTab: 'user',
}

function EditProfile(props: EditProfileProps) {
  const { history, location, startTab } = props
  const postProfile = useSetProfile()
  const { data: myProfile } = useProfile()
  const [error, setError] = useState(undefined)

  const realStartTab = location?.state?.startTab || startTab

  const isAffiliateAdmin =
    myProfile?.value?.subscriptions?.affiliate &&
    myProfile?.value?.affiliate?.role === 'admin'

  const submitProfile = (newProfile, onSuccess, onError) => {
    setError(false)
    postProfile.mutate(newProfile, {
      onSuccess: () => {
        onSuccess()
        history.push('/')
      },
      onError: anError => {
        console.log(anError)
        setError(anError)
        onError()
      },
    })
  }

  return (
    <AppLayout
      name="edit-profile"
      headerConfig={{ label: 'Profile' }}
      cnames="one-column-content"
    >
      <TabSet cnames="app-content" startTab={realStartTab}>
        <div id="user" label="User">
          <Profile
            onSubmit={(newProfile, onSuccess, onError) => {
              submitProfile(newProfile, onSuccess, onError)
            }}
            formError={error || false}
          />
        </div>

        {isAffiliateAdmin && (
          <div id="affiliate" label="Affiliate">
            <AffiliateProfile />
          </div>
        )}
      </TabSet>
    </AppLayout>
  )
}

export default withRouter(EditProfile)
