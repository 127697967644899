import React, { useState } from 'react'

import { usePublicProfile } from '../../context/atomContext'
import { toImperial } from '../../Helpers'

import UserProfileHeader from '../structures/UserProfileHeader'
// import UserProfileRow from '../structures/UserProfileRow'
import Panel from '../elements/Panel'
import LoadingData from '../molecules/LoadingData'
import Checkbox from '../inputs/Checkbox'
import Button from '../elements/Button'

type Props = {
  isOpen: boolean,
  handleDismiss: Object,
  userId: Object,
  zIndex?: number,
}

PublicProfile.defaultProps = {
  zIndex: null,
}

function PublicProfile(props: Props) {
  const { isOpen, handleDismiss, userId, zIndex } = props
  const { data, isLoading, isError, refetch } = usePublicProfile(userId)
  const [useMetric, setUseMetric] = useState(false)

  if (!userId) return ''

  const showStats =
    data?.value?.age && data?.value?.height && data?.value?.weight

  return (
    <Panel
      isOpen={isOpen}
      isLoading={isLoading}
      handleDismiss={handleDismiss}
      header="Profile"
      opensFrom="right"
      cnames="app-public-profile"
      zIndex={zIndex}
    >
      {isLoading && <LoadingData isLoading={isLoading} />}
      {isError && (
        <div className="app-header--error--content rpm-container">
          <p>
            <strong>We experienced an internal issue.</strong>
          </p>

          <p>
            Please retry. If you continue to see this error, please contact RPM
            customer support.
          </p>

          <p className="text--center">
            <Button label="Retry" kind="danger" onClick={() => refetch()} />
          </p>
        </div>
      )}

      {!isLoading && !isError && (
        <div className="rpm-container app-public-profile--inner">
          <UserProfileHeader user={data?.value} context="panel" />

          {/* <UserProfileRow
            user={data?.value}
            isPublic
            showUsernameKitLevel
            showKitLevel={false}
          /> */}

          <section className="rpm-block app-public-profile--bio">
            <h4>BIO</h4>
            <p>{data?.value?.bio}</p>
          </section>

          {showStats && (
            <section className="rpm-block app-public-profile--stats">
              <h4 className="app-public-profile--stats--title">
                <span>STATS</span>
                <small>
                  <Checkbox
                    id="useMetric"
                    name="toggleMetric"
                    label="Use Metric"
                    checked={useMetric}
                    changed={() => setUseMetric(!useMetric)}
                  />
                </small>
              </h4>

              <p>
                {data?.value?.age && (
                  <span>
                    Age: <strong>{data.value.age}</strong>
                  </span>
                )}

                {data?.value?.height && (
                  <span>
                    Height:{' '}
                    <strong>
                      {useMetric
                        ? `${data.value.height}cm`
                        : `${toImperial('height', data.value.height).feet}' ${
                            toImperial('height', data.value.height).inches
                          }"`}
                    </strong>
                  </span>
                )}

                {data?.value?.weight && (
                  <span>
                    Weight:{' '}
                    <strong>
                      {useMetric
                        ? `${data.value.weight}kg`
                        : `${toImperial('weight', data.value.weight)}lb`}
                    </strong>
                  </span>
                )}
              </p>
            </section>
          )}

          {/* <section className="rpm-block app-public-profile--benchmarks">
            <h4>Benchmarks</h4>
          </section> */}
        </div>
      )}
    </Panel>
  )
}

export default PublicProfile
