import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

import dayjs from 'dayjs'

import {
  useProfile,
  useSetProfileImage,
  useDeleteProfileImage,
} from '../../context/atomContext'

import {
  toImperial,
  toMetric,
  kitForID,
  KIT_MAP,
  ATOM_KITS,
  setBackgroundImage,
  getTrackString,
  getLogoutUrl,
} from '../../Helpers'

import dataKits from '../../data/kits.json'
import dataLevels from '../../data/levels.json'
import Countries from '../../data/countries.json'

import InputGroup from '../../components/inputs/InputGroup'
import TextField from '../../components/inputs/TextField'
import Password from '../../components/inputs/Password'
import RadioGroup from '../../components/inputs/RadioGroup'
import Checkbox from '../../components/inputs/Checkbox'
import Textarea from '../../components/inputs/Textarea'
import Date from '../../components/inputs/Date'
import ProfileImageForm from '../../components/inputs/ProfileImageForm'

import Alert from '../../components/elements/Alert'
import Button from '../../components/elements/Button'

import ConfirmationDialog from '../../components/molecules/ConfirmationDialog'
import LoadingData from '../../components/molecules/LoadingData'

type Props = {
  onSubmit?: Object,
  onNext?: Object,
  nextTab?: string,
  setActiveTab?: Object,
  history: Object,
  sections?: Array,
  saveButtonLabel?: String,
  backgroundImage?: String,
  profileHeadline?: string,
  formError?: Object,
  showPassword?: boolean,
  noticeText?: String,
}

Profile.defaultProps = {
  onSubmit: () => {},
  onNext: () => {},
  nextTab: () => {},
  setActiveTab: () => {},
  sections: undefined,
  saveButtonLabel: 'Save',
  backgroundImage: '',
  profileHeadline: null,
  formError: null,
  showPassword: false,
  noticeText: undefined,
}

function Profile(props: Props) {
  const {
    onSubmit,
    onNext,
    nextTab,
    setActiveTab,
    history,
    sections,
    saveButtonLabel,
    backgroundImage,
    profileHeadline,
    formError,
    showPassword,
    noticeText,
  } = props

  // const pic1 = '/images/onboarding/bg-profile-01.jpg'

  const { data: myProfile, isLoading, isError, refetch } = useProfile()
  const setProfileImage = useSetProfileImage()
  const deleteProfileImage = useDeleteProfileImage()

  const [error, setError] = useState(false)
  const [bio, setBio] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [country, setCountry] = useState('')
  const [province, setProvince] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dob, setDob] = useState()
  const [gender, setGender] = useState('')
  const [height, setHeight] = useState('')
  const [feet, setFeet] = useState('')
  const [inches, setInches] = useState('')
  const [weight, setWeight] = useState('')
  const [useMetricHeight, setUseMetricHeight] = useState(false)
  const [useMetricWeight, setUseMetricWeight] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [showProfileImageDialog, setShowProfileImageDialog] = useState(false)
  const [activeLevel, setActiveLevel] = useState(undefined)
  const [activeKit, setActiveKit] = useState(undefined)
  const [activeTrainingGoal, setTrainingGoal] = useState(undefined)
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const subscriptions = myProfile?.value?.subscriptions
  const welcomeText = getTrackString(
    subscriptions,
    'WELCOME_TEXT',
    'Welcome to Atom',
  )
  const logoutUrl = getLogoutUrl()

  const selectKit = event => {
    setActiveKit(kitForID(event.target.value))
  }

  const selectLevel = event =>
    setActiveLevel(
      dataLevels.find(level => level.value === Number(event.target.value)) ||
        {},
    )

  const selectTrainingGoal = event =>
    setTrainingGoal(Number(event.target.value)) || 3

  const selectedCountry =
    country && Countries.find(listCountry => listCountry.name === country)

  useEffect(() => {
    if (initialized) {
      return
    }

    if (myProfile?.value?.kit)
      setActiveKit(kitForID(KIT_MAP[Number(myProfile.value.kit)]))

    if (myProfile?.value?.level)
      setActiveLevel(
        dataLevels.find(
          level =>
            level.value ===
            Math.min(4, Math.round(Number(myProfile.value.level))),
        ) || undefined,
      )

    if (myProfile?.value?.trainingDaysGoal)
      setTrainingGoal(myProfile?.value?.trainingDaysGoal)

    let countryObject = null

    if (myProfile?.value?.username) setUsername(myProfile.value.username)
    if (myProfile?.value?.firstName) setFirstName(myProfile.value.firstName)
    if (myProfile?.value?.lastName) setLastName(myProfile.value.lastName)
    if (myProfile?.value?.bio) setBio(myProfile.value.bio)

    if (myProfile?.value?.country)
      countryObject = Countries.find(
        listCountry => listCountry.code === myProfile.value.country,
      )

    if (countryObject) {
      setCountry(countryObject.name)
    }

    if (myProfile?.value?.province)
      if (countryObject?.provinces && countryObject.provinces.length > 0) {
        const provinceObject = countryObject.provinces.find(
          listProvince => listProvince.code === myProfile.value.province,
        )
        if (provinceObject) {
          setProvince(provinceObject.name)
        }
      }

    if (myProfile?.value?.dob)
      setDob(dayjs(myProfile.value.dob).format('YYYY/MM/DD'))

    if (myProfile?.value?.gender) setGender(myProfile.value.gender)

    if (myProfile?.value?.height) {
      setHeight(myProfile.value.height)
      setFeet(toImperial('height', myProfile.value.height).feet || '')
      setInches(toImperial('height', myProfile.value.height).inches || '')
    }

    if (myProfile?.value?.weight)
      setWeight(toImperial('kg', myProfile.value.weight))

    if (myProfile && myProfile.value) setInitialized(true)
  }, [initialized, myProfile])

  setBackgroundImage(backgroundImage)

  const handleDOB = (date: string) => {
    setDob(date)
  }

  const handleImperialHeight = (theFeet: number = 0, theInches: number = 0) => {
    setHeight(toMetric('height', { feet: theFeet, inches: theInches }))
  }

  const validHeight = () => height === '' || height > 15

  const validWeight = () => weight === '' || weight > 25

  const validCountry = () => {
    if (country === '') return true
    if (Countries.find(listCountry => listCountry.name === country)) {
      return true
    }
    return false
  }

  const validDOB = () => {
    const checkDate = dayjs(dob, 'YYYY/MM/D', true)

    return (
      (checkDate.format('YYYY/MM/D') === dob ||
        checkDate.format('YYYY/MM/DD') === dob) &&
      !checkDate.isAfter(dayjs())
    )
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const metricWeight = useMetricWeight ? weight : toMetric('weight', weight)
    const myCountry = Countries.find(
      listCountry => listCountry.name === country,
    )

    let myProvince = ''

    if (myCountry && myCountry.provinces.length > 0) {
      myProvince = myCountry.provinces.find(
        listProvince => listProvince.name === province,
      )
    }

    if (
      (showSection('height') && height && !validHeight()) ||
      (showSection('weight') && weight && !validWeight()) ||
      (showSection('firstName') && !firstName) ||
      (showSection('lastName') && !lastName) ||
      (showSection('username') && !username) ||
      (showSection('password') && showPassword && !password)
    ) {
      setError(true)
      return
    }

    const postData = {}

    if (showSection('dob')) postData.dob = validDOB() ? dob : ''
    if (showSection('gender')) postData.gender = gender
    if (showSection('height')) postData.height = height
    if (showSection('weight')) postData.weight = metricWeight
    if (showSection('firstName')) postData.firstName = firstName
    if (showSection('lastName')) postData.lastName = lastName
    if (showSection('country'))
      postData.country = (myCountry && myCountry.code) || ''
    if (showSection('province'))
      postData.province = (myProvince && myProvince.code) || ''
    if (showSection('username')) postData.username = username
    if (showSection('password') && showPassword) postData.password = password
    if (showSection('terms')) postData.terms = acceptedTerms

    if (showSection('trainingGoal') || showSection('easyTrainingGoal'))
      postData.trainingDaysGoal = activeTrainingGoal

    if (bio) {
      postData.bio = bio
    } else {
      postData.bio = ''
    }

    if (activeKit) {
      postData.kit = activeKit.mapId
    }

    if (activeLevel) {
      postData.level = activeLevel.value
    }

    onSubmit(postData, () => {
      setError(false)
      if (onNext) {
        onNext()
      }

      setActiveTab(nextTab)
    })
  }

  const getUsernameError = () => {
    if (error && username === '') return true
    if (formError && formError.data.username) return formError.data.username
    return false
  }

  const getFirstNameError = () => {
    if (formError && formError.data.firstName) return formError.data.firstName
    if (error && firstName === '') return true
    return false
  }

  const getLastNameError = () => {
    if (formError && formError.data.lastName) return formError.data.lastName
    if (error && lastName === '') return true
    return false
  }

  const getPasswordError = () => {
    if (error && !password) return true
    if (formError && formError.data.username) return formError.data.username
    return false
  }

  const genderOptions = [
    { id: 'male', value: 'male', label: 'Male', checked: false },
    { id: 'female', value: 'female', label: 'Female', checked: false },
    {
      id: 'unspecified',
      value: 'unspecified',
      label: 'Unspecified',
      checked: false,
    },
  ]

  const levelOptions = [
    {
      id: '4',
      value: 4,
      label: 'L4',
      checked: false,
      tooltipText:
        '**Level 4 (Beginner)**: You are totally new to strength training, don’t regularly participate in other sports or vigorous physical activity, and may have other physical limitations that make strength development challenging.',
    },
    {
      id: '3',
      value: 3,
      label: 'L3',
      checked: true,
      tooltipText:
        '**Level 3 (Novice)**: You have some limited experience with strength training, and may even be quite active, but you’re still building your foundation, and many functional movements still feel pretty new.',
    },
    {
      id: '2',
      value: 2,
      label: 'L2',
      checked: false,
      tooltipText:
        '**Level 2 (Intermediate)**: You have at least a year of consistent training experience, feel confident with almost any functional training movement, and have a solid baseline level of strength.',
    },
    {
      id: '1',
      value: 1,
      label: 'L1',
      checked: false,
      tooltipText:
        "**Level 1 (Advanced)**: You have an expert level of competency in most, if not all, the common strength movements, including the Olympic lifts, and you're usually one of the stronger/fitter athletes in any class.",
    },
  ]

  const levelLabel = (
    <>
      <span>Select Your Level</span>
      <span>(L4 = Beginner)</span>
    </>
  )

  const getLevelText = () => {
    const val = levelOptions.find(l => l.value === activeLevel?.value)
    return (
      val?.tooltipText ||
      'How would you best describe your level of experience with strength training in general?'
    )
  }

  const kitOptions = [
    {
      id: '1',
      value: 'POWER_KIT',
      label: 'Power',
      tooltipText:
        '**Power**: To perform Power you will need dumbbells, a kettlebell, a sandbag, a jump rope, a plyobox, a pull-up bar, gymnastics rings, and a barbell and bumper plates.',
    },
    {
      id: '2',
      value: 'FLY_KIT',
      label: 'Fly',
      tooltipText:
        '**Fly**: To perform Fly you will need dumbbells, a kettlebell, a sandbag, a jump rope, a plyobox, a pull-up bar, and gymnastics rings.',
    },
    {
      id: '3',
      value: 'AXIS_KIT',
      label: 'Axis',
      tooltipText:
        '**Axis**: To perform Axis you will need dumbbells, a kettlebell, a sandbag, a jump rope, and a plyobox.',
    },
  ]

  const getKitText = () => {
    const val = kitOptions.find(l => l.value === activeKit?.value)
    return val?.tooltipText || 'What is your preferred kit?'
  }

  const trainingGoalLabel = (
    <span>Select Your Target Training Days Per Week</span>
  )
  const trainingGoalOptions = [
    {
      id: 'training-3',
      value: 3,
      label: '3',
      tooltipText: 'My goal is to train 3 days per week.',
    },
    {
      id: 'training-4',
      value: 4,
      label: '4',
      tooltipText: 'My goal is to train 4 days per week.',
    },
    {
      id: 'training-5',
      value: 5,
      label: '5',
      tooltipText: 'My goal is to train 5 days per week.',
    },
  ]

  const getTrainingGoalText = () => {
    const val = trainingGoalOptions.find(l => l.value === activeTrainingGoal)
    return val?.tooltipText || 'How often do you want to train per week?'
  }

  const handleMetricHeightToggle = (event: any) => {
    setUseMetricHeight(event.target.checked)

    setHeight(toMetric('height', { feet, inches }) || '')
    setFeet(toImperial('height', height).feet || '')
    setInches(toImperial('height', height).inches || '')
  }

  const handleMetricWeightToggle = (event: any) => {
    setUseMetricWeight(event.target.checked)

    setWeight(
      event.target.checked
        ? toMetric('weight', weight) || ''
        : toImperial('weight', weight) || '',
    )
  }

  const heightLabel = (
    <>
      <span>
        Height <em>(private)</em>
      </span>
      <Checkbox
        id="useMetricHeight"
        name="toggleHeightMetric"
        label="Use Metric"
        checked={useMetricHeight}
        changed={event => handleMetricHeightToggle(event)}
      />
    </>
  )

  const weightLabel = (
    <>
      <span>
        Weight <em>(private)</em>
      </span>
      <Checkbox
        id="useMetricWeight"
        name="toggleWeightMetric"
        label="Use Metric"
        checked={useMetricWeight}
        changed={event => handleMetricWeightToggle(event)}
      />
    </>
  )

  if (isError) {
    return (
      <div className="app-header--error--content rpm-container">
        <p>
          <strong>We experienced an internal issue.</strong>
        </p>

        <p>
          Please retry. If you continue to see this error, please contact Atom
          customer support.
        </p>

        <p className="text--center">
          <Button label="Retry" kind="danger" onClick={() => refetch()} />
        </p>
      </div>
    )
  }

  const allSections = [
    'profileImage',
    'username',
    'country',
    'province',
    'bio',
    'firstName',
    'lastName',
    'dob',
    'gender',
    'height',
    'weight',
    'kit',
    'level',
    'trainingGoal',
  ]
  const mySections = sections || allSections
  const showSection = sectionName => mySections.includes(sectionName)

  const showPage1 =
    showSection('username') ||
    showSection('password') ||
    showSection('easyLevel') ||
    showSection('firstName') ||
    showSection('lastName') ||
    showSection('country') ||
    showSection('province') ||
    showSection('bio') ||
    showSection('terms')

  const showPage2 =
    showSection('dob') ||
    showSection('gender') ||
    showSection('height') ||
    showSection('height') ||
    showSection('weight') ||
    showSection('kit') ||
    showSection('level') ||
    showSection('trainingGoal')

  return (
    <>
      {showSection('profileImage') && (
        <section className="profile profile--image">
          {profileHeadline && <h5 className="text--caps">{profileHeadline}</h5>}

          <ProfileImageForm image={myProfile?.value?.profileImage} />
        </section>
      )}

      <section className="profile profile--welcome">
        <h1>{welcomeText}</h1>
      </section>

      {error && <Alert type="error" message="Please correct any issues..." />}
      {!error && noticeText && <Alert type="notice" message={noticeText} />}

      {setProfileImage.error && (
        <Alert variant="filled" severity="error">
          Unable to save profile image. Please try again.
        </Alert>
      )}

      {deleteProfileImage.error && (
        <Alert variant="filled" severity="error">
          Unable to delete profile image. Please try again.
        </Alert>
      )}

      {isLoading && <LoadingData isLoading={isLoading} />}

      {initialized && !isLoading && (
        <>
          {showPage1 && (
            <section className="profile profile--page-1">
              {showSection('firstName') && (
                <p>
                  <TextField
                    id="firstName"
                    label="First Name"
                    value={firstName}
                    error={getFirstNameError()}
                    changed={e => setFirstName(e.target.value)}
                    maxlength={20}
                  />
                </p>
              )}

              {showSection('lastName') && (
                <p>
                  <TextField
                    id="lastName"
                    label="Last Name"
                    value={lastName}
                    error={getLastNameError()}
                    changed={e => setLastName(e.target.value)}
                    maxlength={20}
                  />
                </p>
              )}

              {showSection('username') && (
                <p>
                  <TextField
                    id="username"
                    label="Username"
                    prefix="@"
                    placeholder="yourname"
                    value={username}
                    error={getUsernameError()}
                    changed={e => setUsername(e.target.value)}
                    maxlength={20}
                    required
                  />
                </p>
              )}

              {showSection('password') && showPassword && (
                <Password
                  id="password"
                  type="password"
                  label="Password"
                  placeholder=""
                  value={password}
                  error={getPasswordError()}
                  changed={e => setPassword(e.target.value)}
                  maxlength={20}
                  required
                />
              )}

              {showSection('easyLevel') && (
                <>
                  <RadioGroup
                    name="level"
                    label={levelLabel}
                    options={levelOptions}
                    handleChange={event => selectLevel(event)}
                    selectedValue={activeLevel?.value}
                  />
                  <div
                    className={`profile--level-text at-level--${
                      5 - activeLevel?.value || 0
                    }`}
                  >
                    <ReactMarkdown>{getLevelText()}</ReactMarkdown>
                  </div>
                </>
              )}

              {showSection('easyTrainingGoal') && (
                <>
                  <RadioGroup
                    name="trainingGoal"
                    label={trainingGoalLabel}
                    options={trainingGoalOptions}
                    handleChange={event => selectTrainingGoal(event)}
                    selectedValue={activeTrainingGoal}
                  />
                  <div className="profile--level-text">
                    <ReactMarkdown>{getTrainingGoalText()}</ReactMarkdown>
                  </div>
                </>
              )}

              {showSection('country') && (
                <>
                  <datalist id="countries">
                    <select name="country">
                      {Countries.map(listCountry => (
                        <option
                          key={listCountry.code}
                          value={listCountry.name}
                        />
                      ))}
                    </select>
                  </datalist>
                  <p>
                    <TextField
                      id="country"
                      label="Country"
                      placeholder="Start typing..."
                      value={country}
                      error={error && !validCountry()}
                      changed={e => setCountry(e.target.value)}
                      list="countries"
                      name="country"
                    />
                  </p>
                </>
              )}

              {showSection('province') &&
                selectedCountry &&
                selectedCountry.provinces &&
                selectedCountry.provinces.length > 0 && (
                  <>
                    <datalist id="provinces">
                      <select name="province">
                        {selectedCountry &&
                          selectedCountry.provinces &&
                          selectedCountry.provinces.length &&
                          selectedCountry.provinces.map(listProvince => (
                            <option
                              key={listProvince.code}
                              value={listProvince.name}
                            />
                          ))}
                      </select>
                    </datalist>

                    <p>
                      <TextField
                        id="province"
                        label="State/Province"
                        placeholder="Start typing..."
                        value={province}
                        error={error && province === ''}
                        changed={e => setProvince(e.target.value)}
                        list="provinces"
                        name="province"
                      />
                    </p>
                  </>
                )}

              {showSection('bio') && (
                <p>
                  <Textarea
                    id="bioInput"
                    name="bioInput"
                    placeholder="Add a bio"
                    value={bio}
                    label="Bio"
                    rows={6}
                    changed={event => setBio(event.target.value)}
                  />
                </p>
              )}

              {showSection('terms') && (
                <p className="tos-checkbox">
                  <Checkbox
                    id="terms"
                    name="terms"
                    checked={acceptedTerms}
                    changed={() => setAcceptedTerms(!acceptedTerms)}
                  >
                    <small>
                      I agree to Atom&rsquo;s{' '}
                      <a
                        href="/terms-of-service"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms&nbsp;of&nbsp;Service
                      </a>{' '}
                      and{' '}
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy&nbsp;Policy
                      </a>
                    </small>
                  </Checkbox>
                </p>
              )}
            </section>
          )}

          {showPage2 && (
            <section className="profile profile--page-2">
              {showSection('dob') && (
                <Date
                  id="dob"
                  label="Date of Birth"
                  value={dob}
                  error={error && !validDOB()}
                  changed={date => handleDOB(date)}
                />
              )}

              {showSection('gender') && (
                <RadioGroup
                  name="gender"
                  label="Gender"
                  options={genderOptions}
                  handleChange={event => setGender(event.target.value)}
                  selectedValue={gender}
                />
              )}

              {showSection('height') && useMetricHeight && (
                <InputGroup columns="1fr" label={heightLabel}>
                  <TextField
                    id="height"
                    type="number"
                    min="15"
                    max="250"
                    placeholder="Centimeters"
                    value={height || ''}
                    error={error && !validHeight()}
                    changed={event =>
                      setHeight(Number.parseFloat(event.target.value))
                    }
                  />
                </InputGroup>
              )}

              {showSection('height') && !useMetricHeight && (
                <InputGroup columns="1fr 1fr" label={heightLabel}>
                  <TextField
                    id="feet"
                    type="number"
                    placeholder="Feet"
                    min="3"
                    max="7"
                    value={feet || ''}
                    error={error && !validHeight()}
                    changed={event => {
                      setFeet(Number.parseFloat(event.target.value))
                      handleImperialHeight(event.target.value, inches)
                    }}
                  />
                  <TextField
                    id="inches"
                    type="number"
                    placeholder="Inches"
                    min="0"
                    max="11.5"
                    step="0.5"
                    value={inches || ''}
                    error={error && !validHeight()}
                    changed={event => {
                      setInches(Number.parseFloat(event.target.value))
                      handleImperialHeight(feet, event.target.value)
                    }}
                  />
                </InputGroup>
              )}

              {showSection('weight') && (
                <InputGroup columns="1fr" label={weightLabel}>
                  <TextField
                    id="weight"
                    type="number"
                    placeholder={useMetricWeight ? 'Kilograms' : 'Pounds'}
                    value={weight || ''}
                    min="0"
                    error={error && !validWeight()}
                    changed={event =>
                      setWeight(Number.parseFloat(event.target.value))
                    }
                  />
                </InputGroup>
              )}

              {showSection('kit') &&
                myProfile?.value?.subscriptions?.atomic && (
                  <>
                    <RadioGroup
                      name="kit"
                      label="Kit"
                      options={dataKits.filter(k => ATOM_KITS.includes(k.id))}
                      handleChange={event => selectKit(event)}
                      selectedValue={activeKit?.value}
                    />
                    <div
                      className={`profile--level-text at-kit--${
                        kitOptions.find(l => l.value === activeKit?.value)
                          ?.id || 0
                      }`}
                    >
                      <ReactMarkdown>{getKitText()}</ReactMarkdown>
                    </div>
                  </>
                )}

              {showSection('level') && (
                <>
                  <RadioGroup
                    name="level"
                    label={levelLabel}
                    options={levelOptions}
                    handleChange={event => selectLevel(event)}
                    selectedValue={activeLevel?.value}
                  />
                  <div
                    className={`profile--level-text at-level--${
                      5 - activeLevel?.value || 0
                    }`}
                  >
                    <ReactMarkdown>{getLevelText()}</ReactMarkdown>
                  </div>
                </>
              )}

              {showSection('trainingGoal') && (
                <>
                  <RadioGroup
                    name="trainingGoal"
                    label={trainingGoalLabel}
                    options={trainingGoalOptions}
                    handleChange={event => selectTrainingGoal(event)}
                    selectedValue={activeTrainingGoal}
                  />
                  <div
                    className={`profile--level-text at-goal--${
                      activeTrainingGoal - 2 || 0
                    }`}
                  >
                    <ReactMarkdown>{getTrainingGoalText()}</ReactMarkdown>
                  </div>
                </>
              )}
            </section>
          )}

          <section className="profile profile--footer">
            <div className="rpm-container--button-group">
              <p>
                <Button
                  label={saveButtonLabel}
                  disabled={showSection('terms') && !acceptedTerms}
                  onClick={event => handleSubmit(event)}
                />
              </p>

              {showSection('goOnboarding') && (
                <p>
                  <Button
                    kind="link"
                    label="Go to Onboarding"
                    onClick={() => history.push('/onboarding')}
                  />
                </p>
              )}
            </div>
            {showSection('skipOnboarding') && (
              <div className="rpm-container--button-group">
                <p>
                  <Button
                    onClick={() => window.open('/', '_self')}
                    kind="link"
                    cnames="rpm-onboarding--skip"
                    gtm="Atom-Onboarding-Skip-Profile-1"
                  >
                    Skip Onboarding,
                    <br /> take me to my Dashboard
                  </Button>
                </p>
              </div>
            )}{' '}
            {showSection('showLogout') && (
              <div className="profile--logout">
                <small>
                  <a href={logoutUrl} rel="noreferrer">
                    Log Out
                  </a>
                </small>
              </div>
            )}
          </section>

          {myProfile && myProfile.value && myProfile.value.profileImage && (
            <ConfirmationDialog
              isOpen={showProfileImageDialog}
              header="Remove Profile Image?"
              primaryText="Delete"
              primaryAction={() => {
                setShowProfileImageDialog(false)
                deleteProfileImage.mutate()
              }}
              secondaryText="Cancel"
              secondaryAction={() => setShowProfileImageDialog(false)}
              cnames="profile-image--delete-dialog"
            >
              <p>
                <b>Are you sure you want to delete your profile image?</b>
              </p>

              <p>This can&apos;t be undone.</p>
            </ConfirmationDialog>
          )}
        </>
      )}
    </>
  )
}

export default withRouter(Profile)
