import React, { useMemo } from 'react'

import Button from './Button'

type Props = {
  children: string,
  openProfile?: () => mixed,
  plainText?: boolean,
}

AtomMarkup.defaultProps = {
  openProfile: () => {},
  plainText: false,
}

// Helper function to get plain text but also an array of user mentions
export function extractMentions(text: String) {
  const parts = text?.split(/(::atomUser\[.*?\]\{.*?\})/)
  const mentions = []

  const string = parts?.map(part => {
    const match = part.match(/::(.+?)\[(.+?)\]\{(.+)\}/)
    if (match) {
      const [, command, label, id] = match
      switch (command) {
        case 'atomUser':
          mentions.push({ username: label, id })
          return `@${label}`
        default:
          return part
      }
    } else {
      return part
    }
  })
  return [string?.join(''), mentions]
}

function AtomMarkup(props: Props) {
  const { children, openProfile, plainText } = props

  const computeMarkup = () => {
    const parts = children?.split(/(::atomUser\[.*?\]\{.*?\})/)
    const data = parts?.map((part, index) => {
      const match = part.match(/::(.+?)\[(.+?)\]\{(.+)\}/)
      if (match) {
        const [, command, label, id] = match
        switch (command) {
          case 'atomUser':
            if (plainText) return `@${label}`
            return (
              <Button
                kind="link"
                onClick={() => openProfile(id)}
                label={`@${label}`}
                key={String(`${id}-${index}`)}
              />
            )
          default:
            return part
        }
      } else {
        return part
      }
    })

    return data || children || null
  }

  const memoizedMarkup = useMemo(computeMarkup, [
    children,
    openProfile,
    plainText,
  ])

  return memoizedMarkup
}

export default AtomMarkup
