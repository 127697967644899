import React from 'react'

import Skeleton from '@mui/material/Skeleton'

function PromotionSkeleton() {
  return (
    <>
      <div className="dashboard--promotion--image">
        <div
          className="promo-image"
          style={{ backgroundColor: 'var(--brand-gray-0)' }}
        />
      </div>
      <div className="rpm-hero--info dashboard--promotion--info text--caps">
        <h2 className="dashboard--promotion--title">
          <Skeleton
            variant="text"
            sx={{ bgcolor: 'grey.700', fontSize: '4rem' }}
          />
        </h2>

        <p className="dashboard--promotion--subtitle">
          <Skeleton
            variant="text"
            sx={{ bgcolor: 'grey.700', fontSize: '2rem' }}
          />
        </p>

        <p className="dashboard--promotion--coach">
          <small>
            <Skeleton
              variant="text"
              sx={{ bgcolor: 'grey.700', fontSize: '1rem' }}
            />
          </small>
        </p>
      </div>

      <div className="rpm-hero--cta dashboard--promotion--button">
        <Skeleton
          variant="circular"
          width={40}
          height={40}
          sx={{ bgcolor: 'grey.700' }}
        />
      </div>
    </>
  )
}

export default PromotionSkeleton
