import React, { useState } from 'react'

import Toast from '../elements/Toast'

type Props = {
  toasts: array,
  setToasts: () => {},
}

function Toasts(props: Props) {
  const { toasts, setToasts } = props
  const [silenceTimes, setSilenceTimes] = useState({})

  const removeToast = aToast => {
    if (aToast?.silenceTime) {
      const silentTill = Date.now() + aToast.silenceTime
      const newSilenceTimes = { ...silenceTimes }
      newSilenceTimes[aToast.id] = silentTill
      setSilenceTimes(newSilenceTimes)
    } else {
      const newToasts = toasts.filter(t => t.id !== aToast.id)
      setToasts(newToasts)
    }
  }

  const visibleToasts = toasts.filter(
    t =>
      t.silenceTime === undefined ||
      silenceTimes[t.id] === undefined ||
      Date.now() > silenceTimes[t.id],
  )
  const hasVisibleToasts = visibleToasts.length > 0

  return (
    hasVisibleToasts && (
      <ul className="app-toasts">
        {toasts.map((toast, index) => (
          <Toast
            key={toast.id}
            item={toast}
            isOpen={
              toast.silenceTime === undefined ||
              silenceTimes[toast.id] === undefined ||
              Date.now() > silenceTimes[toast.id]
            }
            handleDismiss={() => removeToast(toast)}
            styles={{ '--queue': index + 1 }}
          />
        ))}
      </ul>
    )
  )
}

export default Toasts
