import React from 'react'

import ConfirmationDialog from './ConfirmationDialog'

type ConfirmDeleteScoreProps = {
  isOpen: Boolean,
  action: () => void,
  dismissAction: () => void,
}
function ConfirmDeleteScore(props: ConfirmDeleteScoreProps) {
  const { isOpen, action, dismissAction } = props
  return (
    <ConfirmationDialog
      isOpen={isOpen}
      header="Delete My Workout"
      primaryText="Delete"
      primaryAction={action}
      secondaryText="Cancel"
      secondaryAction={dismissAction}
      cnames="app-comments--delete-dialog"
    >
      <p>
        <b>
          Are you sure you want to delete this workout and all associated data?
        </b>
      </p>

      <p>This can&apos;t be undone.</p>
    </ConfirmationDialog>
  )
}

export default ConfirmDeleteScore
