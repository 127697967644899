import React from 'react'

import { printClasses } from '../../Helpers'

import InputGroup from './InputGroup'
import TextField from './TextField'

type Props = {
  id: string | number,
  name: string,
  value: string,
  disabled?: boolean,
  label?: string,
  delimiter?: string,
  cnames?: string,
  required?: boolean,
  changed?: any,
  error?: boolean,
}

TimeInput.defaultProps = {
  disabled: false,
  label: null,
  delimiter: ':',
  cnames: null,
  required: false,
  changed: null,
  error: false,
}

function TimeInput(props: Props) {
  const {
    id,
    name,
    value,
    disabled,
    label,
    delimiter,
    cnames,
    required,
    changed,
    error,
  } = props

  const classes = ['rpm-input--group--time', cnames]

  const time = (value && value.split(delimiter)) || ['', '']
  const minutes = time[0]
  const seconds = parseInt(time[1], 10) ? time[1] : ''

  const handleTimeAtom = (event, atom) => {
    time[atom] = event.target.value && event.target.value.padStart(1, '0')
    const newTime = time.join(delimiter)

    return changed(newTime)
  }

  return (
    <InputGroup
      label={label}
      cnames={printClasses(classes)}
      columns="1fr 1em 1fr"
      styles={{ '--cols': 3 }}
    >
      <TextField
        id={`${id}-minutes`}
        name={name && `${name}-minutes`}
        ariaLabel="minutes"
        type="tel"
        disabled={disabled}
        maxlength={2}
        value={minutes}
        placeholder="00"
        required={required}
        changed={event => handleTimeAtom(event, 0)}
        error={error}
      />

      <b>{delimiter}</b>

      <TextField
        id={`${id}-seconds`}
        name={name && `${name}-seconds`}
        ariaLabel="seconds"
        type="tel"
        disabled={disabled}
        maxlength={3}
        value={seconds}
        placeholder="00"
        required={required}
        changed={event => handleTimeAtom(event, 1)}
        error={error}
      />
    </InputGroup>
  )
}

export default TimeInput
