import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import axios from 'axios'
import { HelmetProvider } from 'react-helmet-async'

import AtomProvider from './context/atomContext'
import SocialProvider from './context/socialContext'
import TrainingProvider from './context/trainingContext'

import AtomRouter from './AtomRouter'

import ErrorBoundary from './components/ErrorBoundry'

import './styles/base.scss'
import 'swiper/swiper-bundle.css'

// TODO, use reasonable defaults for cacheTime of data
// TODO, add a default staleTime to prevent double fetches in some cases
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 30,
    },
  },
})

axios.defaults.baseURL = process.env.REACT_APP_API_URL

export default function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <AtomProvider>
            <SocialProvider>
              <TrainingProvider>
                <AtomRouter />
              </TrainingProvider>
            </SocialProvider>
          </AtomProvider>
          <ReactQueryDevtools initialIsOpen />
        </QueryClientProvider>
      </HelmetProvider>
    </ErrorBoundary>
  )
}
