import React from 'react'
import PickList from '../elements/PickList'

type Props = {
  item: string,
  doSelect: object,
  open?: boolean,
  isDisabled?: boolean,
}

SelectLevel.defaultProps = {
  open: false,
  isDisabled: false,
}

function SelectLevel(props: Props) {
  const { item, open, doSelect, isDisabled } = props
  const defaultItem = item

  const items = [
    {
      id: 'l1',
      label: 'Level 1',
      icon: { name: 'l1', type: 'svg' },
    },
    {
      id: 'l2',
      label: 'Level 2',
      icon: { name: 'l2', type: 'svg' },
    },
    {
      id: 'l3',
      label: 'Level 3',
      icon: { name: 'l3', type: 'svg' },
    },
    {
      id: 'l4',
      label: 'Level 4',
      icon: { name: 'l4', type: 'svg' },
    },
  ]

  const placeholder = {
    label: 'Level',
  }

  const selectItem = element => doSelect(element)

  return (
    <PickList
      item={defaultItem}
      open={open}
      placeholder={placeholder}
      items={items}
      doSelect={selectItem}
      eventOnly
      isDisabled={isDisabled}
    />
  )
}

export default SelectLevel
