import React from 'react'
import PickList from '../elements/PickList'

import { trainingKits } from '../../Helpers'

type Props = {
  item: object | string,
  allowedKits?: Array,
  doSelect: object,
  open?: boolean,
  track?: string,
  isDisabled?: boolean,
  kits?: Array,
}

KitSelect.defaultProps = {
  open: false,
  allowedKits: undefined,
  track: 'atomic',
  isDisabled: false,
  kits: undefined,
}

function trainingKitGrouper(kits) {
  const newKits = []
  for (let i = 0; i < kits.length; i += 1) {
    const curKit = kits[i]
    if (curKit.subGroup) {
      const kitsInGroup = kits.filter(k => k.subGroup === curKit.subGroup)
      if (kitsInGroup.length < 2) {
        // don't sub group if we only have 1 in group
        newKits.push(curKit)
      } else {
        if (!newKits.find(k => k.groupName === curKit.subGroup)) {
          newKits.push({
            id: curKit.subGroup,
            label: curKit.groupLabel,
            groupName: curKit.subGroup,
            collapse: true,
            children: [],
          })
        }
        for (let j = 0; j < newKits.length; j += 1) {
          if (newKits[j].groupName === curKit.subGroup) {
            newKits[j].children.push(curKit)
          }
        }
      }
    } else {
      newKits.push(curKit)
    }
  }
  return newKits
}

function KitSelect(props: Props) {
  const { item, open, doSelect, track, allowedKits, isDisabled, kits } = props
  const dataKits = trainingKits(track, allowedKits, kits)
  const finalKits = trainingKitGrouper(dataKits)

  const defaultItem =
    typeof item === 'string'
      ? dataKits.find(kit => kit.id === item) || {}
      : item

  const placeholder = {
    label: 'Kit',
    icon: { name: 'ellipsis-h', type: 'far' },
  }

  const selectItem = element => doSelect(element)

  return (
    <PickList
      item={defaultItem}
      open={open}
      placeholder={placeholder}
      items={finalKits}
      doSelect={selectItem}
      isDisabled={isDisabled}
      className="rpm-select-kit"
      flipped
    />
  )
}

export default KitSelect
