import React from 'react'
import Button from './Button'

import { printClasses } from '../../Helpers'

type Props = {
  handleDismiss: Any,
  message?: String,
  isOpen?: Boolean,
  kind?: String,
  opensAt?: String,
  showDismiss?: Boolean,
  cnames?: String,
  children?: Any,
  hasArrow?: Boolean,
}

Toolitip.defaultProps = {
  message: null,
  isOpen: false,
  kind: null,
  opensAt: null,
  showDismiss: true,
  cnames: null,
  children: null,
  hasArrow: true,
}

function Toolitip(props: Props) {
  const {
    handleDismiss,
    message,
    isOpen,
    kind,
    opensAt,
    showDismiss,
    cnames,
    children,
    hasArrow,
  } = props

  const classes = [
    'rpm-tooltip',
    kind && `rpm-tooltip--${kind}`,
    opensAt && `at-${opensAt}`,
    isOpen && 'is-open',
    hasArrow && 'has-arrow',
    cnames,
  ]

  const tooltipBody = children ? (
    <div className="rpm-tooltip--content">{children}</div>
  ) : (
    <p className="rpm-tooltip--message">{message}</p>
  )

  const dismissTooltip = () => {
    document.documentElement.style.setProperty('--tooltip-y', '')
    handleDismiss()
  }

  return (
    <div className={printClasses(classes)} open={isOpen}>
      {tooltipBody}
      {showDismiss && (
        <Button
          kind="text"
          cnames="rpm-tooltip--dismiss"
          onClick={dismissTooltip}
          icon={{
            name: 'times-circle',
            type: 'fal',
            size: '2x',
            fw: false,
          }}
          canRipple={false}
        />
      )}
    </div>
  )
}

export default Toolitip
