import React from 'react'
import Icon from './Icon'

import { printClasses } from '../../Helpers'

type Props = {
  line1?: string,
  line2?: string,
  image?: string,
  icon?: Object,
  theme?: string,
  isFuture?: Boolean,
  cnames?: string,
  config?: Object,
}

RowMarker.defaultProps = {
  line1: null,
  line2: null,
  image: null,
  icon: null,
  theme: null,
  isFuture: false,
  cnames: null,
  config: [],
}

function RowMarker(props: Props) {
  const { line1, line2, image, icon, theme, isFuture, cnames, config } = props

  const line1R = line1 || config?.line1
  const line2R = line2 || config?.line2
  const imageR = image || config?.image
  const iconR = icon || config?.icon
  const themeR = theme || config?.theme
  const isFutureR = isFuture || config?.isFuture
  const cnamesR = cnames || config?.cnames

  const classes = [
    'rpm-marker',
    themeR && `rpm-marker--${themeR}`,
    imageR && 'has-image',
    isFutureR && 'is-future',
    cnamesR,
  ]

  const styles = {
    '--row-icon': imageR && `url(${imageR})`,
  }

  return (
    <div className={printClasses(classes)} style={styles}>
      {line1R && <small>{line1R}</small>}
      {line2R && <span>{line2R}</span>}
      {iconR && (
        <Icon
          name={iconR.name}
          type={iconR.type}
          size={iconR.size}
          fw={iconR.fw}
        />
      )}
    </div>
  )
}

export default RowMarker
