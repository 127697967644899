import React from 'react'

import { printClasses } from '../../Helpers'

type Props = {
  id: string | number,
  name: string,
  value: any,
  label?: string,
  placeholder?: any,
  onKeyDown?: any,
  rows?: number,
  changed?: any,
  cnames?: string,
  readOnly?: Boolean,
}

TextareaInput.defaultProps = {
  label: null,
  placeholder: null,
  onKeyDown: null,
  rows: 2,
  changed: null,
  cnames: null,
  readOnly: false,
}

function TextareaInput(props: Props) {
  const {
    id,
    name,
    value,
    label,
    placeholder,
    onKeyDown,
    rows,
    changed,
    cnames,
    readOnly,
  } = props

  const classes = [
    'rpm-input',
    'rpm-input--field',
    `rpm-input--textarea`,
    label?.length && 'has-label',
    cnames,
  ]

  return (
    <label htmlFor={id} className={printClasses(classes)}>
      {label && <span className="rpm-input--label">{label}</span>}

      <textarea
        id={id}
        className="rpm-input--element"
        name={name}
        onKeyDown={onKeyDown}
        value={value}
        rows={rows}
        placeholder={placeholder}
        onChange={changed}
        readOnly={readOnly}
      />
    </label>
  )
}

export default TextareaInput
