import React from 'react'

import { printClasses } from '../../Helpers'

type Props = {
  id: string | number,
  type?: string,
  disabled?: boolean,
  name: string,
  value: any,
  label?: string,
  prefix?: string,
  placeholder?: string,
  list?: string,
  cnames?: string,
  isThin?: boolean,
  required?: boolean,
  size?: string | number,
  min?: string | number,
  max?: string | number,
  maxlength?: string | number,
  step?: string | number,
  helpMessage?: string,
  error?: boolean,
  changed?: any,
  keyup?: any,
  children?: any,
  ariaLabel?: string,
  readOnly?: Boolean,
}

TextInput.defaultProps = {
  type: 'text',
  disabled: false,
  label: null,
  prefix: null,
  placeholder: null,
  list: null,
  cnames: null,
  isThin: false,
  required: false,
  size: null,
  min: null,
  max: null,
  maxlength: null,
  step: null,
  helpMessage: null,
  error: false,
  changed: null,
  keyup: null,
  children: null,
  ariaLabel: null,
  readOnly: false,
}

function TextInput(props: Props) {
  const {
    id,
    name,
    value,
    label,
    prefix,
    placeholder,
    list,
    type,
    disabled,
    cnames,
    isThin,
    required,
    min,
    max,
    maxlength,
    step,
    size,
    helpMessage,
    error,
    changed,
    keyup,
    children,
    ariaLabel,
    readOnly,
  } = props

  const classes = [
    'rpm-input',
    'rpm-input--field',
    `rpm-input--${['number', 'tel'].includes(type) ? 'number' : type}`,
    label?.length && 'has-label',
    error && 'has-error',
    prefix && 'has-prefix',
    isThin && 'is-thin',
    cnames,
  ]

  return (
    <label htmlFor={id} className={printClasses(classes)}>
      {label && <span className="rpm-input--label">{label}</span>}

      <span
        className={printClasses(['rpm-input--box', prefix && 'has-prefix'])}
      >
        {prefix && <span className="rpm-input--prefix ish">{prefix}</span>}

        <input
          id={id}
          className="rpm-input--element"
          type={type}
          disabled={disabled}
          name={name}
          required={required}
          size={size}
          min={min}
          max={max}
          step={step}
          maxLength={maxlength}
          value={value}
          placeholder={placeholder}
          list={list}
          onChange={changed}
          aria-label={ariaLabel}
          onKeyPress={keyup}
          readOnly={readOnly}
        />

        {children}
      </span>

      {helpMessage && (
        <small className="rpm-input--help-message">{helpMessage}</small>
      )}

      {typeof error === 'string' && (
        <small className="rpm-input--error">{error}</small>
      )}
    </label>
  )
}

export default TextInput
