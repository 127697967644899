import React, { useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import AppLayout from '../components/layout/Layout'
import Assessment from './parts-for-onboarding/Assessment'

import PersistTourSummary from '../components/screens/PersistTourSummary'

import { setBackgroundImage, getTour } from '../Helpers'
import { useAddTourResponse } from '../context/atomContext'

function Tour() {
  // State
  const [tourResponses, setTourResponses] = useState({})
  const [isCompleted, setIsCompleted] = useState(false)

  const { track } = useParams()
  const location = useLocation()
  const sendTourResponse = useAddTourResponse()
  const queryParams = new URLSearchParams(location.search)
  const history = useHistory()
  const tourId = queryParams.get('id') || track
  const previousTour = queryParams.get('previousTour')
  const trackObj = getTour(tourId)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const tourTarget = responses => {
    let destination = '/'
    if (trackObj?.target) {
      destination = trackObj?.target
    }
    if (
      trackObj?.targetQuestionId &&
      responses[trackObj?.targetQuestionId]?.length
    ) {
      const question = trackObj.questions.find(
        q => q.id === trackObj?.targetQuestionId,
      )
      const answer = responses[trackObj?.targetQuestionId][0]
      const answerDetails = question.answers.find(a => a.id === answer)
      if (answerDetails?.target) {
        destination = answerDetails.target
      }
    }
    return destination
  }

  if (!trackObj) history.push('/')

  const summaryComponents = {
    persist: PersistTourSummary,
  }

  const SummaryComponent =
    trackObj?.showSummary && summaryComponents[trackObj?.showSummary]

  const completeTour = responses => {
    sendTourResponse.mutate({
      id: tourId,
      responses,
    })
    if (trackObj?.showSummary && SummaryComponent) {
      setIsCompleted(true)
    } else {
      history.push(tourTarget(responses))
    }
  }

  const exitTour = () => {
    history.push(
      previousTour ? `/tour?id=${previousTour}` : tourTarget(tourResponses),
    )
  }

  return (
    <AppLayout
      name="onboarding"
      headerConfig={{ label: trackObj?.title || 'Tour' }}
      cnames={
        isCompleted
          ? 'one-column-content ui-dark is-full-width'
          : 'one-column-content ui-dark'
      }
    >
      <article className="app-content">
        <section className="app-content--inner tab-content-assessment">
          {trackObj && !isCompleted && (
            <Assessment
              key={tourId}
              setBackgroundImage={setBackgroundImage}
              onNext={completeTour}
              onExitAssessment={exitTour}
              responses={tourResponses}
              updateResponse={setTourResponses}
              questions={trackObj?.questions || []}
              hideOnboardingSkip
              startingQuestion={
                // tourResponses[
                //   trackObj?.questions[trackObj?.questions?.length - 1].id
                // ]
                //   ? trackObj?.questions?.length - 1
                //   : undefined
                undefined
              }
            />
          )}
          {isCompleted && SummaryComponent && (
            <SummaryComponent
              responses={tourResponses}
              onBack={() => setIsCompleted(false)}
            />
          )}
        </section>
      </article>
    </AppLayout>
  )
}

export default Tour
