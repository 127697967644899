import React, { useEffect, useCallback, useState } from 'react'
import { withRouter } from 'react-router-dom'
import dayjs from 'dayjs'

import AppLayout from '../components/layout/Layout'

import Calendar from '../components/elements/Calendar'
import IconText from '../components/elements/IconText'
import Markdown from '../components/elements/Markdown'

import DetailsBlock from '../components/structures/DetailsBlock'

import {
  useAffiliateOverview,
  useProfile,
  useTrainingCalendar,
} from '../context/atomContext'
import { setBackgroundImage, kitForID, dateMe } from '../Helpers'

import { isMemberType } from './parts-for-affiliates/AffiliatesHelpers'

import StatSheet from './parts-for-affiliates/StatSheet'
import WorkoutRow from './parts-for-affiliates/WorkoutRow'

import SelectKit from '../components/molecules/SelectKit'

type AffiliateOverviewProps = {
  history: Object,
}

function AffiliateOverview(props: AffiliateOverviewProps) {
  const { history } = props

  const queryParams = new URLSearchParams(window.location.search)
  const kitParam = queryParams.get('kit')

  const [activeKit, _setActiveKit] = useState(kitForID(kitParam || 'GYM_KIT'))

  const setActiveKit = kit => {
    const currentUrlParams = new URLSearchParams(window.location.search)

    currentUrlParams.set('kit', kit.id)

    history.replace(
      `${history.location.pathname}?${currentUrlParams.toString()}`,
    )
    _setActiveKit(kit)
  }

  const defaultDate = dayjs().isBefore(dayjs('2022-04-01'))
    ? '2022-04-01'
    : undefined

  const [date, setDate] = useState(
    dateMe(dayjs(queryParams.get('date') || defaultDate).startOf('month')),
  )

  const { data: myProfile, isFetching: isLoadingProfile } = useProfile()

  const {
    data: affiliateOverviewData,
    isFetching: isLoadingAffiliateOverview,
  } = useAffiliateOverview(date, activeKit.id, isMemberType(myProfile, 'coach'))

  const { data: calendarData } = useTrainingCalendar(dateMe(date, 'yearMonth'))

  const visibleKit =
    affiliateOverviewData?.value?.rows?.length &&
    affiliateOverviewData.value.rows[0]

  setBackgroundImage('') // unsets any default bg image hanging around
  useEffect(() => setBackgroundImage('', 'header-bg-image'), [])

  const doSelectDate = useCallback(
    aDate => {
      const currentUrlParams = new URLSearchParams(window.location.search)

      currentUrlParams.set('date', aDate)
      setDate(aDate)

      history.push(
        `${history.location.pathname}?${currentUrlParams.toString()}`,
      )
    },
    [history],
  )

  const filters = (
    <nav
      key="filters"
      id="affiliatesFilters"
      className="rpm-filters affiliates--filters"
    >
      <Calendar
        mode="month"
        date={date}
        handleDate={doSelectDate}
        config={{
          tools: ['previous', 'next'],
          allowWeekends: true,
          shortMonth: true,
          maxDate: calendarData?.value?.maxTrainingDates?.affiliate,
          minDate: '2022-04-01',
        }}
      />
      <SelectKit
        item={activeKit}
        doSelect={setActiveKit}
        track="affiliate"
        isDisabled={false}
      />
    </nav>
  )

  return (
    <AppLayout
      name="affiliates"
      isLoading={isLoadingProfile || isLoadingAffiliateOverview}
      headerConfig={{ label: 'Monthly Overview' }}
      filters={filters}
      cnames="one-column-content is-full-width"
    >
      {isLoadingProfile ||
        isLoadingAffiliateOverview ||
        (!visibleKit && (
          <div className="rpm-description rpm-description--fallback training--fallback">
            <p>No Workouts Programmed</p>
          </div>
        ))}

      {visibleKit && (
        <article className="app-content affiliates--content affiliates--overview">
          <section className="app-content--inner rpm-media--grid">
            {activeKit.id === 'GYM_KIT' && (
              <DetailsBlock lines={10} cnames="affiliates--overview--details">
                <div className="rpm-block">
                  <h5 className="text--caps">Monthly Note</h5>
                  <Markdown className="rpm-md">
                    {visibleKit?.monthlyNote}
                  </Markdown>
                </div>
                <StatSheet
                  sheet={{
                    ...visibleKit?.statSheet,
                    benchmarks: visibleKit?.benchmarkNote,
                    projects: visibleKit?.projectsNote,
                  }}
                />
              </DetailsBlock>
            )}

            {visibleKit?.workouts?.map((workout, index) => (
              <WorkoutRow
                key={String(`${workout?.date}-${index}`)}
                workout={workout}
                kit={activeKit}
              />
            ))}
          </section>

          {visibleKit?.downloadUrl && (
            <footer>
              <p className="text--small-caps text--center">
                <a
                  href={visibleKit.downloadUrl}
                  target="_blank"
                  className="rpm-button rpm-button--primary rpm-button--outlined rpm-button--thin"
                  rel="noreferrer"
                >
                  <IconText
                    icon={{
                      name: 'file-arrow-down',
                      type: 'far',
                      size: 'xl',
                    }}
                    text={`Download ${dayjs(visibleKit?.date).format(
                      'MMM YYYY',
                    )} Overview`}
                  />
                </a>
              </p>
            </footer>
          )}
        </article>
      )}
    </AppLayout>
  )
}

export default withRouter(AffiliateOverview)
