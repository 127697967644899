import React, { useState } from 'react'
import DetailsBlock from '../structures/DetailsBlock'

import Button from '../elements/Button'
import { PlayIcon } from '../elements/Icon'
import Markdown from '../elements/Markdown'
import VideoPlayer from '../structures/VideoPlayer'

import { usePages } from '../../context/atomContext'

// const defaultNutritionData = {
//   videoId: 6327161611112,
//   videoPlaceholder: '/app/images/persist/persist_nutrition.jpg',
//   message:
//     'Welcome to Persist Nutrition! Message your nutrition coach any time - we\'ll respond within 24 hours.\n\nMore Nutrition Resources:\n\n* Cronometer app (food logging). [<i class="fa-regular fa-arrow-up-right-from-square fg--brand"></i>](https://cronometer.com)\n\n* Persist Nutrition Library (articles on the FBB food philosophy). [<i class="fa-regular fa-arrow-up-right-from-square fg--brand"></i>](https://functional-bodybuilding.com/persist-nutrition/)\n\n* Practical Protein Ebook (recipes and practical ways to eat more protein). [<i class="fa-light fa-file-pdf fg--brand"></i>](https://cdn.atom.rpmtraining.com/public/docs/persist/resources/fbb-practicalproteinguide.pdf)\n\n',
// }

type NutritionProps = {
  collapsible?: boolean,
  children?: ReactNode,
}

Nutrition.defaultProps = {
  collapsible: true,
  children: undefined,
}

function Nutrition({ collapsible, children }: NutritionProps) {
  const [vidOpen, setVidOpen] = useState(false)
  const { data: pageData, isLoading: pageIsLoading } =
    usePages('persist.nutrition')
  const nutritionData = pageData?.value?.rows?.[0]

  if (pageIsLoading) return null

  return (
    <div className="nutrition--item">
      <div className="rpm-hero">
        {!vidOpen && (
          <div
            className="nutrition--item--image"
            style={{
              '--background-image-url': `url("${nutritionData.videoPlaceholder}")`,
            }}
          >
            <div className="rpm-hero--cta training--header--video-cue">
              <Button kind="text" onClick={() => setVidOpen(true)}>
                <PlayIcon />
              </Button>
            </div>
          </div>
        )}
        {vidOpen && (
          <VideoPlayer
            videoId={nutritionData.videoId}
            showWorkoutOverlay={false}
            autoPlay
          />
        )}
      </div>
      <DetailsBlock cnames="nutrition--item--details" collapsible={collapsible}>
        <h5 className="text--caps">Persist Nutrition</h5>
        {children && <p>{children}</p>}
        {!children && <Markdown>{nutritionData.message}</Markdown>}
      </DetailsBlock>
    </div>
  )
}

export default Nutrition
