import React, { useEffect, useRef, useState } from 'react'

import Button from './Button'

import { printClasses } from '../../Helpers'

type Props = {
  isOpen: boolean,
  handleDismiss: Object,
  children?: any,
  header?: string,
  headerMarquee?: mixed,
  cnames?: string,
  showDismiss?: boolean,
}

Dialog.defaultProps = {
  children: null,
  header: null,
  headerMarquee: null,
  cnames: null,
  showDismiss: true,
}

function Dialog(props: Props) {
  const {
    isOpen,
    handleDismiss,
    children,
    header,
    headerMarquee,
    cnames,
    showDismiss,
  } = props

  const [scrollPosition, setScrollPosition] = useState(0)
  const [elementIsOpen, setElementIsOpen] = useState(undefined)
  const elementRef = useRef(null)

  const dismissElement = () => setElementIsOpen(false)

  useEffect(() => {
    setElementIsOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    const element = elementRef.current

    const listener = () => {
      if (!elementIsOpen) {
        handleDismiss()
      }
    }

    element?.addEventListener('transitionend', listener)

    return () => {
      element?.removeEventListener('transitionend', listener)
    }
  }, [elementRef, elementIsOpen, handleDismiss])

  const classes = [
    'rpm-dialog',
    elementIsOpen ? 'is-open' : 'just-is',
    scrollPosition > 0 && 'is-scrolling',
    cnames,
  ]

  const dismissButton = showDismiss && (
    <Button
      onClick={dismissElement}
      cnames="rpm-dialog--dismiss"
      kind="text"
      icon={{
        name: 'times-circle',
        type: 'fal',
        size: '2x',
        fw: false,
      }}
      canRipple={false}
    />
  )

  return (
    <div ref={elementRef} className={printClasses(classes)}>
      <div
        className="rpm-container rpm-dialog--box"
        onScroll={event => setScrollPosition(event.target.scrollTop)}
      >
        {isOpen && (
          <>
            <article className="rpm-dialog--content">
              {(header || headerMarquee) && (
                <header className="rpm-dialog--header">
                  <div className="flex--auto-gap">
                    {headerMarquee}
                    {header && <h3>{header}</h3>}
                  </div>
                  {dismissButton}
                </header>
              )}

              {children}
            </article>

            {!header && dismissButton}
          </>
        )}
      </div>
    </div>
  )
}

export default Dialog
