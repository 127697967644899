import React from 'react'
import PickList from '../elements/PickList'

type Props = {
  item: string,
  doSelect: object,
  open?: boolean,
}

Select10kDivision.defaultProps = {
  open: false,
}

function Select10kDivision(props: Props) {
  const { item, open, doSelect } = props
  const defaultItem = item

  const items = [
    {
      id: 'team',
      label: 'Teams',
    },
    {
      id: 't1-17',
      label: '17 and under',
    },
    {
      id: 't18-34',
      label: '18–34',
    },
    {
      id: 't35-39',
      label: '35–39',
    },
    {
      id: 't40-44',
      label: '40–44',
    },
    {
      id: 't45-49',
      label: '45–49',
    },
    {
      id: 't50-54',
      label: '50–54',
    },
    {
      id: 't55-59',
      label: '55–59',
    },
    {
      id: 't60+',
      label: '60+',
    },
    {
      id: '0',
      label: 'Do not filter by division',
      cnames: 'text--small text--caps',
      altLabel: 'Division',
      icon: { name: 'ellipsis-h', type: 'far' },
    },
  ]

  const placeholder = {
    label: 'Division',
    icon: { name: 'ellipsis-h', type: 'far' },
  }

  const selectItem = element => doSelect(element)

  return (
    <PickList
      item={defaultItem}
      open={open}
      placeholder={placeholder}
      items={items}
      doSelect={selectItem}
    />
  )
}

export default Select10kDivision
