import React, { useEffect, useState } from 'react'
import { withRouter, useLocation } from 'react-router-dom'

import AppHeader from './Header'
import AppFooter from './Footer'

import LoadingData from '../molecules/LoadingData'
import SubscribeDialog from '../molecules/SubscribeDialog'

import { useProfile } from '../../context/atomContext'

import { cleanString, debounce, printClasses } from '../../Helpers'

type Props = {
  name: string,
  renderOrder?: Array,
  filters?: any,
  subheader?: any,
  heels?: any,
  aside?: any,
  hasHeader?: boolean,
  hasFooter?: boolean,
  isLoading?: boolean,
  isError?: boolean,
  errorMessage?: string,
  errorAction?: Object,
  headerConfig?: Object,
  cnames?: string,
  styles?: Object,
  requireTerms?: boolean,
  history: Object,
  hideActivities?: boolean,
}

Layout.defaultProps = {
  renderOrder: ['userRow', 'hero', 'filters', 'subheader', 'children'],
  filters: null,
  subheader: null,
  heels: null,
  aside: null,
  hasHeader: true,
  hasFooter: true,
  isLoading: false,
  isError: false,
  errorMessage: null,
  errorAction: () => {},
  headerConfig: null,
  cnames: null,
  styles: null,
  requireTerms: true,
  hideActivities: false,
}

function Layout(props: Props) {
  const {
    name,
    renderOrder,
    filters,
    subheader,
    heels,
    aside,
    hasHeader,
    hasFooter,
    isLoading,
    isError,
    errorMessage,
    errorAction,
    headerConfig,
    cnames,
    styles,
    requireTerms,
    history,
    hideActivities,
  } = props

  const contentClasses = ['app-main', 'rpm-container', cnames]
  const [filterBarHeight, setFilterBarHeight] = useState(0)
  const [subheaderHeight, setSubheaderHeight] = useState(0)
  const [showSubDialog, setShowSubDialog] = useState(undefined)
  const { data: myProfile } = useProfile(requireTerms)
  const filterBarId = filters?.props?.id
  const subheaderId = subheader?.props?.id

  useEffect(() => {
    if (requireTerms && myProfile?.status === 200 && !myProfile?.value?.terms) {
      history.push('/simpleprofile')
    }
  }, [myProfile, requireTerms, history])

  const location = useLocation()
  useEffect(() => {
    window.Intercom('update', {
      last_request_at: parseInt(new Date().getTime() / 1000, 10),
    })
    window.Intercom('onUnreadCountChange', unreadCount => {
      const event = new CustomEvent('atomSupportUnreadCountChange', {
        detail: {
          unreadCount,
        },
      })
      window.dispatchEvent(event)
    })
  }, [location])

  useEffect(() => {
    document.body.className = cleanString(name)
  }, [name])

  useEffect(() => {
    setFilterBarHeight(document.getElementById(filterBarId)?.clientHeight || 0)
  }, [filterBarId])

  useEffect(() => {
    setSubheaderHeight(document.getElementById(subheaderId)?.clientHeight || 0)
  }, [subheaderId])

  window.onresize = debounce(() => {
    setFilterBarHeight(document.getElementById(filterBarId)?.clientHeight || 0)
    setSubheaderHeight(document.getElementById(subheaderId)?.clientHeight || 0)
  })

  const finalStyles = {
    ...styles,
    '--height-of-filters': filterBarHeight > 0 ? `${filterBarHeight}px` : '',
    '--height-of-subheader': subheaderHeight > 0 ? `${subheaderHeight}px` : '',
  }

  return (
    <>
      {hasHeader && (
        <AppHeader
          config={headerConfig}
          isError={isError}
          errorMessage={errorMessage}
          errorAction={errorAction}
          hideActivities={hideActivities}
          openSubscribeDialog={val => setShowSubDialog(val || true)}
          closeSubscribeDialog={() => setShowSubDialog(false)}
        />
      )}

      <div className={printClasses(contentClasses)} style={finalStyles}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {renderOrder.map(element => props[element])}
      </div>

      {aside && <aside className="rpm-container">{aside}</aside>}

      {hasFooter && <AppFooter />}

      {heels}
      <SubscribeDialog
        isOpen={showSubDialog}
        handleDismiss={() => setShowSubDialog(false)}
      />

      <LoadingData isLoading={isLoading} />
    </>
  )
}

export default withRouter(Layout)
