import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { setBackgroundImage } from '../Helpers'
import AppLayout from '../components/layout/Layout'

import Button from '../components/elements/Button'
import Dialog from '../components/elements/Dialog'
import IconText from '../components/elements/IconText'
import Panel from '../components/elements/Panel'

import RadioGroup from '../components/inputs/RadioGroup'
import Search from '../components/inputs/Search'

import LoadingData from '../components/molecules/LoadingData'

import UserProfileRow from '../components/structures/UserProfileRow'

import PanelInviteMembers from './parts-for-affiliates/PanelInviteMembers'
import DialogEditMember from './parts-for-affiliates/DialogEditMember'
import {
  memberTypes,
  isMemberType,
} from './parts-for-affiliates/AffiliatesHelpers'

import {
  useMyAffiliate,
  useUpdateAffiliateUser,
  useDeleteAffiliateUser,
  useProfile,
} from '../context/atomContext'

type Props = {
  history: Object,
}

function AffiliateStaff(props: Props) {
  // State
  const { history } = props
  const defaultMemberFilter = {
    id: 9,
    label: <IconText icon={{ name: 'users-line', type: 'fas' }} text="All" />,
    plural: 'Everyone',
    value: '',
  }

  const [panel, setPanel] = useState(undefined)
  const [filteredMembers, setFilteredMembers] = useState([])
  const [memberToEdit, setMemberToEdit] = useState(null)
  const [memberFilter, setMemberFilter] = useState(defaultMemberFilter.value)
  const [activeMemberType, setActiveMemberType] = useState(
    defaultMemberFilter.value,
  )
  const [searchTerm, setSearchTerm] = useState('')
  const { data: myAffData } = useMyAffiliate()
  const { data: myProfile } = useProfile()
  const isAffiliateAdmin = isMemberType(myProfile, 'admin')

  const updateUser = useUpdateAffiliateUser()
  const deleteUser = useDeleteAffiliateUser()

  const id = myAffData?.value?.id

  setBackgroundImage('') // unsets any default bg image hanging around
  useEffect(() => setBackgroundImage('', 'header-bg-image'), [])

  // Context
  // API
  // Methods
  const doSearch = value => setSearchTerm(value.toLowerCase())
  const clearSearch = () => doSearch('')

  const dismissEditDialog = () => setMemberToEdit(null)
  const openInviteMembers = () => setPanel('inviteMembersPanel')
  const dismissPanel = () => setPanel(null)
  const sortList = list =>
    list.sort((a, b) => {
      if (a.status === 'invited') return true
      if (b.status === 'invited') return false
      return a.name > b.name
    })

  const resetFilters = () => {
    clearSearch()
    setMemberFilter(defaultMemberFilter.value)
  }

  const removeMember = memberId => {
    deleteUser.mutate({
      affiliateId: myAffData?.value?.id,
      userId: memberId,
    })
    dismissEditDialog()
  }

  const updateMember = editMember => {
    updateUser.mutate({
      affiliateId: myAffData?.value?.id,
      userId: editMember?.id,
      role: editMember?.role,
    })
    dismissEditDialog()
  }

  useEffect(() => {
    if (myAffData?.value?.name === 'Atom Affiliate')
      history.push('/profile', {
        startTab: 'affiliate',
      })
  }, [myAffData, history])

  useEffect(() => {
    const members = myAffData?.value?.members || []

    const groupedList = Object.is(memberFilter, '')
      ? members
      : [...members].filter(member => Object.is(member.role, memberFilter))

    const newList = [...groupedList].filter(
      member =>
        member?.name?.toLowerCase().includes(searchTerm) ||
        member?.username?.toLowerCase().includes(searchTerm) ||
        member?.role?.toLowerCase().includes(searchTerm),
    )
    setActiveMemberType(
      memberTypes?.find(type => Object.is(type.value, memberFilter)),
    )
    setFilteredMembers(sortList(searchTerm ? newList : groupedList))
  }, [searchTerm, memberFilter, myAffData])

  // Variables
  const isAnythingLoading = false

  const pendingMember = { name: 'Pending' }

  const memberFilterOptions = [
    defaultMemberFilter,
    ...memberTypes.map(type => ({
      id: type.id,
      label: (
        <IconText
          icon={{ name: type.icon.name, type: type.icon.type }}
          text={type.plural}
        />
      ),
      value: type.value,
    })),
  ]

  const filters = (
    <nav
      id="affiliatesStaffFilters"
      className="rpm-filters affiliates--filters affiliates--staff--filters"
      key="filters"
    >
      <RadioGroup
        selectedValue={memberFilter}
        options={memberFilterOptions}
        handleChange={event =>
          setMemberFilter(
            memberFilterOptions.find(type =>
              Object.is(type.value, event.target.value),
            ).value,
          )
        }
      />

      <Search
        id="search"
        placeholder="Find..."
        value={searchTerm}
        changed={doSearch}
        clear={clearSearch}
        maxlength={10}
      />
    </nav>
  )

  const heels = (
    <>
      <LoadingData isLoading={isAnythingLoading} />

      <Panel
        isOpen={panel}
        handleDismiss={dismissPanel}
        header="Invite Members"
        opensFrom="right"
        cnames="team-panel team-panel--invites"
      >
        <PanelInviteMembers dismissPanel={dismissPanel} affiliateId={id} />
      </Panel>

      <Dialog
        isOpen={memberToEdit?.id}
        handleDismiss={dismissEditDialog}
        showDismiss={false}
      >
        <DialogEditMember
          member={memberToEdit}
          dismiss={dismissEditDialog}
          update={updateMember}
          remove={removeMember}
          isAdmin={isAffiliateAdmin}
        />
      </Dialog>
    </>
  )

  return (
    <AppLayout
      name="affiliates"
      headerConfig={{ label: 'Staff' }}
      filters={filters}
      heels={heels}
      cnames="one-column-content"
    >
      <article className="app-content affiliates--content affiliates--staff">
        <div className="rpm-subheader flex--auto-spread text--caps">
          <h5 className="flex--auto-gap">
            <span>
              {searchTerm
                ? `Found: ${filteredMembers.length}`
                : `${activeMemberType?.plural || defaultMemberFilter.plural} (${
                    filteredMembers.length
                  })`}
            </span>
            {(searchTerm || memberFilter) && (
              <Button
                kind="link"
                icon={{
                  name: 'arrow-rotate-left',
                  type: 'far',
                  size: 'lg',
                }}
                onClick={resetFilters}
              />
            )}
          </h5>

          {isAffiliateAdmin && (
            <Button kind="text" onClick={openInviteMembers}>
              <IconText
                icon={{
                  type: 'far',
                  name: 'plus-circle',
                  size: 'lg',
                  fw: false,
                }}
                text="Invite"
                cnames="text--700 text--link"
                flipped
              />
            </Button>
          )}
        </div>
        <section className="app-content--inner">
          <section className="section-block">
            {filteredMembers.map(member => {
              const mType =
                member.status !== 'invited'
                  ? memberTypes.find(type => Object.is(type.value, member.role))
                  : {
                      id: 99,
                      label: 'Invited',
                      plural: 'Invited',
                      value: 'invited',
                      icon: { name: 'paper-plane', type: 'far' },
                    }

              return (
                <UserProfileRow
                  key={member.id}
                  user={
                    member.name !== null || member.username !== null
                      ? member
                      : pendingMember
                  }
                  isPublic
                  avatarSize={3}
                  openProfile={() => setMemberToEdit(member)}
                  // openProfile={() => socialContext.openProfile(member.id)}
                  cnames={`is-${mType.value}`}
                  action={
                    <Button
                      kind="icon"
                      onClick={() => {
                        setMemberToEdit(member)
                      }}
                      cnames="flex--auto-gap"
                    >
                      <IconText
                        icon={{
                          name: mType.icon.name,
                          type: mType.icon.type,
                          size: 'xl',
                          fw: false,
                        }}
                        text={mType.label}
                        cnames="text--caps text--small member-type"
                        flipped
                      />
                    </Button>
                  }
                />
              )
            })}
          </section>
        </section>
      </article>
    </AppLayout>
  )
}

export default withRouter(AffiliateStaff)
