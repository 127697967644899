import React, { useState, useEffect, useCallback } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  useTrainingCalendar,
  useProfile,
  useTraining,
  useTrainingJournal,
} from '../context/atomContext'

import dataEventChallenges from '../data/eventChallenges.json'

import {
  dateMe,
  setBackgroundImage,
  printClasses,
  getMessageFromError,
  isRoleType,
} from '../Helpers'

import AppLayout from '../components/layout/Layout'

import Button from '../components/elements/Button'
import Calendar from '../components/elements/Calendar'
import Dialog from '../components/elements/Dialog'
import { PlayIcon } from '../components/elements/Icon'
import IconText from '../components/elements/IconText'
import Markdown from '../components/elements/Markdown'
import RowMarker from '../components/elements/RowMarker'

import PickList from '../components/elements/PickList'

import FeaturedProducts from '../components/molecules/FeaturedProducts'
import VideoPlayer from '../components/structures/VideoPlayer'

import MyChallenge from './parts-for-10k/MyChallenge'
import TeamChallenge from './parts-for-10k/TeamChallenge'
import CommunityChallenge from './parts-for-10k/CommunityChallenge'
import Submit10kScore from './parts-for-10k/Submit10kScore'
import Finisher from './parts-for-10k/Finisher'

import training10kdata from '../data/training10k.json'

type Training10KProps = {
  history: Object,
}

function Training10k(props: Training10KProps) {
  const { history } = props
  const { data: profileData, isLoading: isProfileLoading } = useProfile()

  setBackgroundImage('') // unsets any default bg image hanging around
  // unset any header image laying around
  useEffect(() => {
    setBackgroundImage('', 'header-bg-image')
  }, [])

  const defaultDate = aDate => {
    const queryParams = new URLSearchParams(window.location.search)
    const date = queryParams.get('date') || aDate
    if (dayjs(date).isBefore(dayjs('2023-01-02'))) {
      return '2023-01-02'
    }
    if (dayjs(date).isAfter(dayjs('2023-01-31'))) {
      return '2023-01-31'
    }
    return date
  }

  const giftIcon = {
    name: 'gift',
    type: 'fal',
    size: 2,
    cnames: 'fg--brand-gold ghost',
    selectedCnames: 'fg--light ghost',
  }
  const giftDates = {
    '2023-01-06': giftIcon,
    '2023-01-13': giftIcon,
    '2023-01-20': giftIcon,
    '2023-01-27': giftIcon,
    '2023-01-29': giftIcon,
    '2023-01-30': giftIcon,
    '2023-01-31': giftIcon,
  }

  const [calendarDate, setCalendarDate] = useState(defaultDate(dateMe()))

  const [date, setDate] = useState(defaultDate(dateMe()))
  const [activeChallenge, setActiveChallenge] = useState(dataEventChallenges[0])
  const [showDemoVideo, setShowDemoVideo] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState('main')
  const [showTrainingVideo, setShowTrainingVideo] = useState(false)
  const [showScoreForm, setShowScoreForm] = useState(undefined)
  const [showFinisher, setShowFinisher] = useState(undefined)

  const mediaFeeds = ['main']

  // API
  const {
    data: trainingData,
    isFetching: isTrainingFetching,
    isError: isTrainingError,
    error: trainingError,
  } = useTraining(date, true, 'event/challenge')

  const {
    data: myTrainingData,
    isFetching: journalIsFetching,
    refetch: refetchTrainingJournal,
    isError: isTrainingJournalError,
  } = useTrainingJournal(null, null, null, '-date', false, 'event/challenge')

  const { data: calendarData } = useTrainingCalendar(
    dateMe(calendarDate, 'yearMonth'),
  )

  const trainingDates =
    calendarData?.value?.challengeUserTraining?.length > 0
      ? calendarData?.value?.challengeUserTraining
      : []

  const workout = Array.isArray(trainingData?.value?.rows)
    ? trainingData?.value?.rows?.length > 0 && trainingData.value.rows[0]
    : trainingData?.value?.rows

  const training = workout

  const location = useLocation()

  const updateDate = useCallback(
    aDate => {
      setDate(defaultDate(aDate))
    },
    [setDate],
  )

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const aDate = params.get('date') || undefined

    if (aDate && date !== aDate) {
      updateDate(aDate)
      setSelectedVideo('main')
    }
  }, [date, location, updateDate])

  const doSelectDate = aDate => {
    const currentUrlParams = new URLSearchParams(window.location.search)
    setShowTrainingVideo(false)

    currentUrlParams.set('date', aDate)
    history.push(`${history.location.pathname}?${currentUrlParams.toString()}`)

    updateDate(aDate)
  }

  const headerClasses = [
    'rpm-hero',
    'training--header',
    `${showTrainingVideo ? 'is-playing' : 'is-not-playing'}`,
  ]

  const products =
    training?.featuredProducts ||
    training10kdata?.value.rows[0].featuredProducts

  // @NOTE leaving here for the next round... clue for maybe this data to come from the API ??
  // const maxDate = trainingData?.user?.permissions?.futureTraining
  //   ? dateMe(dayjs())
  //   : '2022-02-02'

  const maxDate =
    calendarData?.value?.maxTrainingDates?.challenge || '2023-01-31'
  const workoutMedia = workout?.media ? workout?.media : workout?.video
  const workoutMediaDetails =
    workoutMedia && workoutMedia[selectedVideo].custom_fields
  const workoutLocation = workoutMediaDetails?.location || null
  const workoutCoach = workoutMediaDetails?.coaches || null
  const workoutStar = workoutMediaDetails?.star || null
  const workoutTitle = workout?.title || '10K Challenge'
  const workoutTitleDate = workout?.subtitle
  const canSubmitScore =
    dayjs(date).isBefore(dayjs().add(1, 'days'), 'day') &&
    (dayjs().isAfter(dayjs('2023-01-01'), 'day') ||
      isRoleType(profileData, 'developer'))
  const allTimeCombined = myTrainingData?.value?.totals?.combined

  const dayData = myTrainingData?.value?.rows?.find(day =>
    Object.is(day?.date, date),
  )

  const videoMetadata = workoutMedia && workoutMedia[selectedVideo]
  const showVideoPlayButton = () =>
    videoMetadata?.id &&
    (Object.is(videoMetadata?.tags, null) ||
      !videoMetadata?.tags?.includes('novideo'))

  const hero = (
    <header className={printClasses(headerClasses)} key="hero">
      {!showTrainingVideo && workoutMedia && (
        <>
          {mediaFeeds.map(key => {
            if (workoutMedia[key]) {
              return (
                <div
                  key={key}
                  className={printClasses([
                    'poster',
                    Object.is(selectedVideo, key) ? 'active' : '',
                  ])}
                  style={{
                    '--image-url': `url(${workoutMedia[key].poster})`,
                  }}
                />
              )
            }

            return ''
          })}

          <div className="training--header--details">
            <div className="rpm-hero--info training--header--info text--caps">
              <p className="training--header--location">{workoutLocation}</p>

              <h2 className="training--header--title">{workoutTitle}</h2>

              {workoutTitleDate && (
                <h3 className="training--header--subtitle">
                  {workoutTitleDate}
                </h3>
              )}

              {workoutCoach && (
                <p className="training--header--coach">
                  <IconText
                    icon={{ name: 'coach', type: 'svg' }}
                    text={workoutCoach}
                  />
                </p>
              )}

              {workoutStar && (
                <p className="training--header--star">
                  <IconText
                    icon={{ name: 'star', type: 'svg' }}
                    text={workoutStar}
                  />
                </p>
              )}
            </div>

            {showVideoPlayButton() && (
              <div className="rpm-hero--cta training--header--video-cue">
                <Button kind="text" onClick={() => setShowTrainingVideo(true)}>
                  <PlayIcon />
                </Button>
              </div>
            )}
          </div>
        </>
      )}

      {showTrainingVideo && (
        <VideoPlayer
          videoId={workoutMedia[selectedVideo].id || workoutMedia.main.id}
          autoPlay
          showWorkoutOverlay={workoutMedia[selectedVideo].cue_points !== null}
          workout={<></>}
        />
      )}
    </header>
  )

  const filters = (
    <nav
      id="trainingFilters"
      className="rpm-filters training--filters"
      key="filters"
    >
      <Calendar
        mode="day"
        date={workout?.date || date}
        eventDates={trainingDates}
        eventBackgrounds={giftDates}
        updateEventDatesForDate={d => setCalendarDate(d)}
        handleDate={doSelectDate}
        config={{
          tools: ['previous', 'next'],
          maxDate,
          allowWeekends: true,
          minDate: '2023-01-02',
        }}
      />
      <PickList
        item={activeChallenge}
        items={dataEventChallenges}
        doSelect={setActiveChallenge}
      />
    </nav>
  )

  const heels = (
    <>
      <Dialog
        isOpen={!!showDemoVideo.videoId}
        handleDismiss={() => setShowDemoVideo(false)}
        header={showDemoVideo.title || 'Demo Video'}
        cnames="training--dialog--demo-video ui-dark"
      >
        <VideoPlayer
          videoId={showDemoVideo.videoId}
          showWorkoutOverlay={false}
        />
      </Dialog>

      <Dialog
        isOpen={showScoreForm}
        handleDismiss={() => setShowScoreForm(false)}
        headerMarquee={
          <RowMarker
            line1={dayjs(workout?.date).format('MMM')}
            line2={dayjs(workout?.date).format('DD')}
            theme="brand"
          />
        }
        header="Log Your Jumps"
        cnames="training--dialog--submit-score rpm-submit-score"
      >
        <Submit10kScore
          trainingId={workout?.id}
          date={workout?.date}
          totals={{
            doubles: dayData?.doubles,
            singles: dayData?.singles,
            allTimeCombined,
          }}
          whenSubmit={boom => {
            refetchTrainingJournal()
            setShowScoreForm(false)
            if (boom) setShowFinisher(true)
          }}
          handleDismiss={() => setShowScoreForm(false)}
        />
      </Dialog>

      <Dialog
        isOpen={showFinisher}
        handleDismiss={() => setShowFinisher(false)}
        cnames="event10k--dialog--finisher ui-dark"
      >
        <Finisher />
      </Dialog>
    </>
  )

  return (
    <AppLayout
      name="event10k"
      headerConfig={{ label: '10K CHALLENGE' }}
      hero={hero}
      filters={filters}
      aside={
        <FeaturedProducts cnames="training--products" products={products} />
      }
      isLoading={isProfileLoading && isTrainingFetching && journalIsFetching}
      isError={
        !isTrainingJournalError &&
        !isProfileLoading &&
        !profileData?.value?.registrations?.event10k2023 &&
        !isTrainingError
      }
      errorMessage={
        getMessageFromError(trainingError) ||
        'This content requires registration'
      }
      heels={heels}
      errorAction={() => window.location.reload()}
    >
      {Object.is(activeChallenge?.id, 'MY_CHALLENGE') && (
        <>
          <MyChallenge
            history={history}
            openScoreForm={canSubmitScore ? () => setShowScoreForm(true) : null}
            journalData={myTrainingData?.value}
            refetchData={() => {
              refetchTrainingJournal()
            }}
            showFinisher={() => setShowFinisher(true)}
            workout={workout}
            date={date}
          >
            {workout?.description && (
              <section className="section-block">
                <div className="rpm-description">
                  <Markdown>{workout.description}</Markdown>
                </div>
              </section>
            )}
          </MyChallenge>
          <TeamChallenge />
        </>
      )}

      {Object.is(activeChallenge?.id, 'COMMUNITY_CHALLENGE') && (
        <CommunityChallenge communityData={myTrainingData?.value?.community} />
      )}
    </AppLayout>
  )
}

export default withRouter(Training10k)
