import React, { useState } from 'react'

import Avatar from '../elements/Avatar'
import Button from '../elements/Button'
import ConfirmationDialog from '../molecules/ConfirmationDialog'

import { useImageUpload, useImageDelete } from '../../context/atomContext'

import { printClasses } from '../../Helpers'

type ProfileImageFormProps = {
  image: string,
  placeholderIcon?: string,
  allowDelete?: boolean,
  deleteDialogContent?: React.Node,
  headline?: string,
  action?: string,
  avatarSize?: number,
  formId?: string,
  inputName?: string,
  removeLabel?: string,
  emptyLabel?: string,
  cnames?: string,
  styles?: Object,
  noUpload?: boolean,
  onChange?: Object => mixed,
  cacheKey?: array,
}

ProfileImageForm.defaultProps = {
  placeholderIcon: null,
  allowDelete: true,
  headline: null,
  action: '/user/profile/image',
  avatarSize: '7',
  formId: 'profileImageForm',
  inputName: 'profileImage',
  removeLabel: 'Remove Profile Image',
  emptyLabel: '',
  deleteDialogContent: null,
  cnames: null,
  styles: null,
  noUpload: false,
  onChange: null,
  cacheKey: ['user', 'me'],
}

function ProfileImageForm(props: ProfileImageFormProps) {
  const {
    image,
    placeholderIcon,
    allowDelete,
    deleteDialogContent,
    headline,
    action,
    avatarSize,
    formId,
    inputName,
    removeLabel,
    emptyLabel,
    cnames,
    styles,
    noUpload,
    onChange,
    cacheKey,
  } = props

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [imgData, setImgData] = useState()

  const imageUpload = useImageUpload(action, cacheKey)
  const imageDelete = useImageDelete(action, cacheKey)

  const profileImageDeleteDialog = () => (
    <>
      {allowDelete && image && (
        <ConfirmationDialog
          isOpen={showDeleteDialog}
          header="Remove Profile Image?"
          primaryText="Delete"
          primaryAction={() => {
            setShowDeleteDialog(false)
            imageDelete.mutate()
          }}
          secondaryText="Cancel"
          secondaryAction={() => setShowDeleteDialog(false)}
          cnames="profile-image--delete-dialog"
        >
          {deleteDialogContent}
          {!deleteDialogContent && (
            <>
              <p>
                <b>Are you sure you want to delete your profile image?</b>
              </p>

              <p>This can&apos;t be undone.</p>
            </>
          )}
        </ConfirmationDialog>
      )}
    </>
  )

  const loadFile = e => {
    const form = document.getElementById(formId)
    const data = new FormData(form)
    setImgData(URL.createObjectURL(e.target.files[0]))

    if (onChange) onChange(data)
    if (noUpload) return

    imageUpload.mutate({ postData: data })
    form.reset()
  }

  return (
    <section
      className={printClasses(['profile', 'profile--image', cnames])}
      style={styles}
    >
      {headline && <h5 className="text--caps">{headline}</h5>}

      <form action={action} id={formId}>
        {/* eslint-disable jsx-a11y/label-has-associated-control */}

        <label htmlFor="profileImage" style={{ cursor: 'pointer' }}>
          <Avatar
            size={avatarSize}
            image={imgData || image}
            icon={
              !(imgData || image) && {
                name: placeholderIcon || 'upload-image',
                type: placeholderIcon ? 'fas' : 'svg',
                size: placeholderIcon && avatarSize - 2,
              }
            }
            isIcon={!(imgData || image)}
          />

          <input
            type="file"
            accept="image/*"
            name={inputName}
            id="profileImage"
            onChange={e => loadFile(e)}
          />
        </label>
        {/* eslint-enable jsx-a11y/label-has-associated-control */}
      </form>

      {image && (
        <p>
          <Button
            kind="link"
            size="small"
            label={removeLabel}
            icon={{ name: 'trash-alt', type: 'far' }}
            cnames="profile--image--delete"
            onClick={event => {
              event.preventDefault()
              setShowDeleteDialog(true)
            }}
          />
        </p>
      )}

      {!image && emptyLabel && (
        <p className="text--caps ish">
          <small>{emptyLabel}</small>
        </p>
      )}

      {profileImageDeleteDialog()}
    </section>
  )
}

export default ProfileImageForm
