import React from 'react'

import Button from '../../elements/Button'
import IconText from '../../elements/IconText'
import Markdown from '../../elements/Markdown'

import { kitLabelWithIcon } from '../../../Helpers'

type DetailsProps = {
  onClick: () => void,
  track: String,
}

const atomTrackDetails = {
  AXIS_KIT: {
    text: 'With minimal equipment needed and fewer technical movements, Axis is our most accessible Atom GPP track. It requires the least amount of equipment and is great for those just starting their functional fitness journey.\n\nRecommended equipment: dumbbells, kettlebell, sandbag, plyo box, jump rope, rower or air bike.',
  },
  FLY_KIT: {
    text: 'If you have access to a pull-up bar, the Fly track opens up a world of programming options, including pull-ups, ring rows, ring dips, toes-to-bar, and more, making it a favorite track of all the aspiring bodyweight ninjas out there.\n\nRecommended equipment: dumbbells, kettlebell, sandbag, plyo box, jump rope, pull-up bar, gymnastics rings, rower or air bike.',
  },
  POWER_KIT: {
    text: 'If improving strength is your priority, nothing beats a barbell. The Power track exposes athletes to traditional weightlifting movements like snatch, clean & jerk, and deadlift, with plenty of instruction and progressions making it suitable for beginners and advanced athletes alike.\n\nRecommended equipment: dumbbells, kettlebell, sandbag, plyo box, jump rope, pull-up bar, gymnastics rings, barbell & plates, rower or air bike.',
  },
  ELEMENT_KIT: {
    text: 'Got dumbbells? If so, you’re ready to go. The Element track optimizes for convenience and consistency by utilizing dumbbells as the only equipment required. So whether you’re on the road or stuck at home with minimal equipment, Element will keep you on track.\n\nRecommended equipment: dumbbells, jump rope',
  },
}

function AtomDetails(props: DetailsProps) {
  const { onClick, track } = props

  const kitObject = track && kitLabelWithIcon(track, 'sm')
  const rowIcons = kitObject?.icons ? [...kitObject?.icons] : []

  const rowLabel = kitObject?.label

  const paragraphClasses = ''

  const hasCustomDescription = Object.keys(atomTrackDetails).includes(track)

  return (
    <>
      <div className="dashboard--details--description">
        <h4 className="text--caps text--700">
          Atom GPP{' '}
          {track && (
            <>
              -{' '}
              <IconText
                icons={rowIcons}
                text={rowLabel}
                cnames="user--meta--kit"
              />
            </>
          )}
        </h4>
        {!hasCustomDescription && (
          <>
            <p className={paragraphClasses}>
              Atom GPP is the first authentic virtual functional fitness class
              experience in the industry.
            </p>
            <p className={paragraphClasses}>
              The program gives you access to over 120 classes featuring
              legendary coach Pat Barber, who will guide you, and three fellow
              athletes of all fitness levels through a warm-up, skill
              development, workout, and cool down.
            </p>
            <p className={paragraphClasses}>
              With three gear variations of the workout to choose from, you can
              do Atom GPP even if you’re stuck at home with limited equipment.
            </p>
            <p className={paragraphClasses}>
              The Atom level system (with four scaling options for each
              movement) allows Atom to recommend a version of the workout that’s
              most appropriate for you based on your current fitness level. Who
              is Atom GPP for?
            </p>
            <ul className={paragraphClasses}>
              <li>
                Beginners looking to learn the fundamentals of functional
                fitness
              </li>
              <li>
                Experienced athletes who love the extra push of friendly
                competition
              </li>
              <li>Anyone looking for that class vibe at home.</li>
            </ul>
            <p className={paragraphClasses}>
              Functional fitness is possibly the most effective and efficient
              way to increase capacity and improve body composition ever known.
              Now anyone can learn and develop this breakthrough methodology
              from home, gym, or wherever they train.
            </p>
            <p className={paragraphClasses}>Required equipment: dumbbells</p>
            <p className={paragraphClasses}>
              Recommended equipment: kettlebell, sandbag, plyo box, jump rope
            </p>
            <p className={paragraphClasses}>
              Optional equipment: barbell & plates, pull-up bar, rower or air
              bike.
            </p>
          </>
        )}
        {hasCustomDescription && (
          <Markdown>{atomTrackDetails[track].text}</Markdown>
        )}
        <p className={paragraphClasses}>
          <Button label="Start Training" onClick={onClick} />
        </p>
      </div>
    </>
  )
}

export default AtomDetails
