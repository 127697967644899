import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import AppMenu from './AppMenu'
import Activities from '../screens/Activities'

import Logo from '../elements/Logo'
import Button from '../elements/Button'
import IconText from '../elements/IconText'
import IconBadge from '../elements/IconBadge'

import { useActivities, useProfile } from '../../context/atomContext'
import { useSupport } from '../../hooks/SupportHooks'

type Props = {
  config?: Object,
  isError?: boolean,
  errorMessage?: string,
  errorAction?: Object,
  hideActivities?: boolean,
  openSubscribeDialog?: Object,
  closeSubscribeDialog?: Object,
}

Header.defaultProps = {
  config: null,
  isError: false,
  errorMessage: null,
  errorAction: () => {},
  hideActivities: false,
  openSubscribeDialog: () => {},
  closeSubscribeDialog: () => {},
}

function Header(props: Props) {
  const {
    config,
    isError,
    errorMessage,
    errorAction,
    hideActivities,
    openSubscribeDialog,
    closeSubscribeDialog,
  } = props
  const [menuOpen, setMenuOpen] = useState(undefined)
  const [activitiesOpen, setActivitiesOpen] = useState(undefined)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const { data: activities } = useActivities()
  const { data: profile } = useProfile()
  const { openSupport, supportTracks, numSupportMessages } = useSupport()
  const totalSupportMessages = Object.keys(numSupportMessages).reduce(
    (prev, cur) => prev + numSupportMessages[cur],
    0,
  )

  const match = useRouteMatch()
  const currentTrack =
    match?.path === '/leaderboard' || match?.path === '/training'
      ? 'atomic'
      : match?.params?.track
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentTrack || supportTracks.length <= 1) openSupport(currentTrack)
    else setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const logo = profile?.value?.branding?.logo || 'atom'
  const backToCaret = (
    <Button
      onClick={config?.backAction}
      kind="icon"
      cnames="app-header--action app-header--action--back"
      canRipple={false}
      icon={{
        name: 'angle-left',
        type: 'far',
        size: '2',
        fw: false,
      }}
    />
  )

  const hamburgerMenu = (
    <Button
      onClick={() => setMenuOpen(true)}
      cnames="app-header--action app-header--action--menu"
      kind="icon"
      icon={{
        name: 'bars',
        type: 'far',
        size: 'xl',
        cnames: 'app-header--action--menu--icon',
        fw: false,
      }}
      canRipple={false}
    />
  )

  const iconTitle =
    logo !== 'persist' ? (
      <span className="app-header--logo">
        <Logo name="atom" alt="ATOM" />
      </span>
    ) : (
      <IconText
        icons={[
          { name: logo, type: 'svg', size: '3x' },
          {
            name: 'pipe',
            type: 'fal',
            size: '2x',
            cnames: 'ghost icon-pad',
            fw: false,
          },
          {
            name: 'atom-a-contained',
            type: 'svg',
            size: 'xl',
            cnames: 'ghost',
          },
        ]}
      />
    )

  const headerlabel = config?.label ? (
    <span className="app-header--label">{config.label}</span>
  ) : (
    iconTitle
  )

  const headerError = isError && (
    <div className="app-header app-header--error">
      <div className="app-header--error--content rpm-container">
        {errorMessage ? (
          <p>{errorMessage}</p>
        ) : (
          <>
            <p>
              <strong>We experienced an internal issue.</strong>
            </p>

            <p>
              Please retry. If you continue to see this error, please contact{' '}
              <Button
                kind="link"
                label="RPM customer support"
                onClick={() => {
                  window.zE.activate({ hideOnClose: true })
                }}
              />
              .
            </p>
          </>
        )}

        <p className="text--center">
          <Button label="Retry" kind="danger" onClick={() => errorAction()} />
        </p>
      </div>
    </div>
  )

  return (
    <>
      <header className="app-header">
        {config?.backAction ? backToCaret : hamburgerMenu}

        {config?.altAction && (
          <Button
            onClick={event => config.altAction?.onClick(event)}
            cnames="app-header--action app-header--action--alt"
            kind="text"
            icon={config.altAction?.icon}
            label={config.altAction?.label}
            disabled={!config.altAction?.isActive}
            canRipple={false}
            isFlipped
          />
        )}

        {headerlabel}

        {!config?.altAction && !hideActivities && (
          <div className="app-header--action app-header--action--alt">
            <Button
              onClick={handleClick}
              cnames="app-header--action--activities"
              kind="icon"
              canRipple={false}
            >
              <IconBadge
                icon={{
                  name: 'message',
                  type: 'fal',
                  size: 'xl',
                  cnames: 'activities--icon',
                  fw: false,
                }}
                badge={numSupportMessages[currentTrack] || totalSupportMessages}
              />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {supportTracks?.map(t => (
                <MenuItem
                  onClick={() => {
                    handleClose()
                    openSupport(t.id)
                  }}
                  key={t.name}
                >
                  <IconText
                    text={`${t.name} Support`}
                    icon={
                      numSupportMessages[t.id] > 0
                        ? {
                            name: 'circle',
                            type: 'fas',
                            cnames: 'brand-fg',
                            fw: false,
                          }
                        : undefined
                    }
                    cnames="flex--auto-spread"
                    flipped
                  />
                </MenuItem>
              ))}
            </Menu>

            <Button
              onClick={() => setActivitiesOpen(true)}
              cnames="app-header--action--activities"
              kind="icon"
              canRipple={false}
            >
              <IconBadge
                icon={{
                  name: 'bell',
                  type: 'fal',
                  size: 'xl',
                  cnames: 'activities--icon',
                  fw: false,
                }}
                badge={activities?.value?.unviewedCount || 0}
              />
            </Button>
          </div>
        )}
      </header>
      {headerError}

      <AppMenu
        menuOpen={menuOpen}
        handleDismiss={() => setMenuOpen(false)}
        openSubscribeDialog={openSubscribeDialog}
        closeSubscribeDialog={closeSubscribeDialog}
      />

      <Activities
        isOpen={activitiesOpen}
        handleDismiss={() => setActivitiesOpen(false)}
      />
    </>
  )
}

export default Header
