import React from 'react'
import { cleanString, printClasses } from '../../Helpers'

type Props = {
  type: string, // svg | fal, far, fas | flag
  name: string, // svg: symbol ID (index.html) | FA Icon: name (no prefix: rocket) | Flag: 2-char country code
  cnames?: string,
  size?: string | number, // lg, 1–10 [1]
  fw?: boolean, // fixed-width [true]
}

Icon.defaultProps = {
  cnames: null,
  size: null,
  fw: true,
}

function Icon(props: Props) {
  const { name, type, cnames, size, fw } = props
  const prefix = 'rpm'
  const isSvg = !!Object.is(type, 'svg')
  const isFlag = !!Object.is(type, 'flag')
  const tag = isSvg ? 'svg' : 'span'
  const classes = [`${prefix}-icon`, cnames]
  const cleanName = cleanString(name)

  const content =
    isSvg && React.createElement('use', { href: `#svg-${cleanName}` }, null)

  if (fw) {
    classes.push('fa-fw')
  }

  if (isSvg) {
    classes.push(`${prefix}-icon--svg`, `${prefix}-icon--${cleanName}`)
  } else if (isFlag) {
    classes.push('flag-icon', `flag-icon-${cleanName}`)
  } else {
    classes.push(type, `fa-${cleanName}`)
  }

  if (size) {
    const actualSize = Number(size) > 0 ? `${size}x` : size
    classes.push(`fa-${actualSize}`)
  }

  return React.createElement(
    tag,
    { className: printClasses(classes), role: 'img' },
    content,
  )
}

export default Icon

export const PlayIcon = () => (
  <span className="rpm-icon rpm-icon--play fa-stack">
    <i className="fa-solid fa-fw fa-circle fa-stack-1x fg--dark ish fa-2x" />
    <i className="far fa-fw fa-circle-play fa-stack-1x fa-inverse brand-fg fa-2x" />
  </span>
)
