import React from 'react'
import { Link } from 'react-router-dom'

import DetailsBlock from '../../components/structures/DetailsBlock'
import Markdown from '../../components/elements/Markdown'
import WorkoutStats from '../../components/molecules/WorkoutStats'

import { useIsPermission } from '../../hooks/FeatureHooks'

type Props = {
  visibleSelectedKit: Object,
  track: Object,
}

UserTrainingDetails.defaultProps = {}

function UserTrainingDetails(props: Props) {
  const { visibleSelectedKit, track } = props
  const isStatsVisible = useIsPermission(['beta', 'statsVisible'])

  return (
    <>
      {visibleSelectedKit?.notes && (
        <DetailsBlock
          label="Daily Training Notes"
          collapsible={false}
          cnames="rpm-description training--info-block training--notes"
        >
          <Markdown>{visibleSelectedKit.notes}</Markdown>
        </DetailsBlock>
      )}

      {track?.optionalSections?.includes('warmup') &&
        visibleSelectedKit?.coaching?.warmup && (
          <DetailsBlock
            label="Brief & Warmup"
            cnames="rpm-description training--info-block"
          >
            <Markdown>{visibleSelectedKit.coaching.warmup}</Markdown>
          </DetailsBlock>
        )}

      {track?.optionalSections?.includes('prep') &&
        visibleSelectedKit?.coaching?.prep && (
          <DetailsBlock
            label="Workout Prep"
            cnames="rpm-description training--info-block"
          >
            <Markdown>{visibleSelectedKit.coaching.prep}</Markdown>
          </DetailsBlock>
        )}

      {visibleSelectedKit?.equipment && (
        <DetailsBlock
          label="Equipment Needed"
          collapsible={false}
          cnames="rpm-description training--info-block training--equipment"
        >
          <Markdown>{visibleSelectedKit.equipment}</Markdown>
        </DetailsBlock>
      )}

      {visibleSelectedKit && isStatsVisible && (
        <DetailsBlock
          label="Workout Stats"
          tooltip={
            <>
              <p>
                <strong>Total workouts</strong> - Total workouts logged
                (all-time) across the entire Atom platform.
              </p>
              <p>
                <strong>Streak</strong> - Consecutive weeks of training without
                missing your workout goal. Your default goal is 3 workouts/wk,
                which is editable in <Link to="/profile">profile</Link>.
              </p>
            </>
          }
          collapsible={false}
          cnames="rpm-description training--info-block training--notes"
        >
          <WorkoutStats />
        </DetailsBlock>
      )}
    </>
  )
}

export default UserTrainingDetails
