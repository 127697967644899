import React from 'react'

import Button from '../../components/elements/Button'
import Icon from '../../components/elements/Icon'

export const memberTypes = [
  {
    id: 1,
    label: 'Member',
    plural: 'Members',
    value: 'member',
    icon: { name: 'circle-user', type: 'far' },
  },
  {
    id: 2,
    label: 'Coach',
    plural: 'Coaches',
    value: 'coach',
    icon: { name: 'circle-star', type: 'far' },
  },
  {
    id: 3,
    label: 'Admin',
    plural: 'Admins',
    value: 'admin',
    icon: { name: 'gear', type: 'far' },
  },
]

export const workoutIcon = (name, type, size, cnames = '') => (
  <Icon name={name} type={type} size={size} cnames={cnames} />
)

export const displayExpander = (value, action) => (
  <Button kind="link" onClick={action} cnames="expander">
    <Icon name={`angle-${value ? 'up' : 'down'}`} type="far" fw={false} />
    <small>
      <b>{value ? 'Less' : 'More'}</b>
    </small>
  </Button>
)

export const isMemberType = (profile, type = 'member') => {
  const affiliate = profile?.value?.affiliate
  if (!affiliate) return false
  if (affiliate.role === 'member' && type === 'member') return true
  if (affiliate.role === 'coach' && (type === 'member' || type === 'coach'))
    return true
  if (affiliate.role === 'admin') return true
  return false
}
