import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'

import {
  useStatus,
  useTrainingJournal,
  useDeleteScore,
  useProfile,
} from '../context/atomContext'

import AppLayout from '../components/layout/Layout'

import TrainingJournalRow from '../components/structures/TrainingJournalRow'

import Button from '../components/elements/Button'
import Calendar from '../components/elements/Calendar'
import Dialog from '../components/elements/Dialog'
import Pager from '../components/elements/Pager'
import IconText from '../components/elements/IconText'
import Spinner from '../components/elements/Spinner'
import Zipper from '../components/elements/Zipper'

import Search from '../components/inputs/Search'
import TextField from '../components/inputs/TextField'

import ConfirmDeleteScore from '../components/molecules/ConfirmDeleteScore'
import EditDeleteDialog from '../components/molecules/EditDeleteDialog'

import SubmitCustomScore from '../components/screens/SubmitCustomScore'

import {
  setBackgroundImage,
  dateMe,
  debounce,
  urlForTraining,
} from '../Helpers'

type Props = {
  kind?: string,
  searchTerm?: string,
  dialogs?: Object,
}

TrainingJournal.defaultProps = {
  kind: 'page',
  searchTerm: '',
  dialogs: undefined,
}

function TrainingJournal(props: Props) {
  const { kind, searchTerm, dialogs } = props
  const location = useLocation()
  const history = useHistory()

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const [searchDebounced, setSearchDebounced] = useState({
    page: 1,
    search: {
      search: searchTerm,
      movementSearchTerm: '',
      notesSearchTerm: '',
    },
  })
  const [globalSearchTerm, setGlobalSearchTerm] = useState(
    searchTerm.substring(0, 64),
  )
  const [movementSearchTerm, setMovementSearchTerm] = useState('')
  const [notesSearchTerm, setNotesSearchTerm] = useState('')
  const defaultStartDate = dateMe(dayjs('2021-11-15'))
  const [fromSearchDate, setfromSearchDate] = useState(undefined)
  const defaultEndDate = dateMe(dayjs())
  const [toSearchDate, settoSearchDate] = useState(undefined)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTermLoaded, setSearchTermLoaded] = useState(false)

  const debounceSearch = useMemo(
    () =>
      debounce(term => {
        setSearchDebounced(term)
        if (kind !== 'component') {
          window.scrollTo(0, 0)
        }
      }, 500),
    [setSearchDebounced, kind],
  )
  useEffect(() => {
    debounceSearch({
      page: currentPage,
      search: {
        search: globalSearchTerm,
        movementSearchTerm,
        notesSearchTerm,
        fromSearchDate,
        toSearchDate,
      },
    })
  }, [
    globalSearchTerm,
    movementSearchTerm,
    notesSearchTerm,
    fromSearchDate,
    toSearchDate,
    currentPage,
    debounceSearch,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [
    globalSearchTerm,
    movementSearchTerm,
    notesSearchTerm,
    fromSearchDate,
    toSearchDate,
  ])

  useEffect(() => {
    setGlobalSearchTerm(searchTerm.substring(0, 64))
    setSearchTermLoaded(true)
  }, [searchTerm])

  useEffect(() => {
    if (kind !== 'component') window.scrollTo(0, 0)
  }, [kind])

  const defaultShowScore = location?.state?.showScoreForm || false

  const [showScoreForm, setShowScoreForm] = useState(
    location?.state?.commentItem || defaultShowScore,
  )
  const [showScoreTools, _setShowScoreTools] = useState(false)
  const setShowScoreTools = dialogs?.setShowScoreTools || _setShowScoreTools
  const dismissShowScoreTools = () => setShowScoreTools(false)

  const [showConfirmDeleteScore, setShowConfirmDeleteScore] = useState(false)
  const dismissConfirmDeleteScore = () => setShowConfirmDeleteScore(false)
  const displayConfirmDeleteScore = () => {
    setShowConfirmDeleteScore(showScoreTools)
    dismissShowScoreTools()
  }

  const status = useStatus()
  const deleteScore = useDeleteScore()
  const { data: myProfile } = useProfile()
  const manualSubscription = myProfile?.value?.subscriptions?.manual
  const perPage = 14
  const searchEnabled =
    (searchTermLoaded && kind === 'component') || kind !== 'component'
  const {
    data: journalData,
    isFetching,
    isError,
  } = useTrainingJournal(
    null,
    searchDebounced.page,
    perPage,
    '-created',
    true,
    'journal',
    searchEnabled,
    searchDebounced.search,
  )

  const totalPages =
    journalData?.value?.totalCount &&
    Math.ceil(journalData?.value?.totalCount / perPage)

  const packUpRow = row => {
    if (row.track === 'challenge') {
      return {
        ...row,
        score: row.totals.combined,
        beatCap: true,
        userId: status?.user?.id,
      }
    }
    return {
      ...row,
      userId: status?.user?.id,
    }
  }

  const hasJournalData = journalData?.value?.rows?.length
  const noJournalData = !isFetching && !hasJournalData

  const dismissScoreForm = () => {
    setShowScoreForm(false)
  }

  setBackgroundImage('') // unsets any default bg image hanging around

  useEffect(() => {
    setBackgroundImage(
      noJournalData ? `/app/images/journal/no-data-journal.webp` : '',
      'content-bg',
    )
  }, [noJournalData])

  const headerConfig = {
    label: 'Training Journal',
  }

  const heels = (
    <>
      <Dialog
        isOpen={showScoreForm}
        handleDismiss={dismissScoreForm}
        header="Nice Work, Let's Log It!"
        cnames="training--dialog--submit-score rpm-submit-score rpm-submit-score--custom"
      >
        <SubmitCustomScore
          handleDismiss={dismissScoreForm}
          lastScore={showScoreForm}
          openDeleteScore={setShowConfirmDeleteScore}
        />
      </Dialog>

      <EditDeleteDialog
        isOpen={showScoreTools}
        dismiss={dismissShowScoreTools}
        displayConfirmDeleteScore={displayConfirmDeleteScore}
        displayEditScoreForm={() => {
          history.push(urlForTraining(showScoreTools))
        }}
      />

      <ConfirmDeleteScore
        isOpen={showConfirmDeleteScore}
        action={() => {
          dismissConfirmDeleteScore()
          deleteScore.mutate({
            id: showConfirmDeleteScore.id,
            track: showConfirmDeleteScore.track,
          })
        }}
        dismissAction={dismissConfirmDeleteScore}
      />
    </>
  )

  const filters = (
    <nav
      className="rpm-filters journal--filters"
      id="leaderboardFilters"
      key="filters"
    >
      <>
        <Search
          id="search"
          noResults={
            <IconText
              icon={{ type: 'fal', name: 'users-slash', size: 'lg' }}
              text="No data found, try again..."
              cnames="fg--accent"
            />
          }
          helpMessage={
            <IconText
              icon={{ type: 'fal', name: 'question-square', size: 'lg' }}
              text="Use at least two characters"
              cnames="ish"
            />
          }
          hasResults
          value={globalSearchTerm}
          debouncedValue=""
          changed={val => setGlobalSearchTerm(val)}
          clear={() => setGlobalSearchTerm('')}
          maxlength={64}
          alternates={
            <Button
              kind="icon"
              icon={{ name: 'sliders', type: 'fal', size: 'lg', fw: false }}
              cnames="fg--link"
              onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
            />
          }
          prefix={
            <div className="text--caps text--700 text--center text--small beta-flag fg--light bg--brand ">
              Beta
            </div>
          }
        />
        {showAdvancedFilters && (
          <>
            <div className="grid journal--filters--advanced">
              <Calendar
                mode="day"
                date={fromSearchDate || defaultStartDate}
                handleDate={d => setfromSearchDate(d)}
                config={{
                  tools: ['previous', 'next'],
                  allowWeekends: true,
                  minDate: defaultStartDate,
                  maxDate: defaultEndDate,
                }}
                label="From:"
              />
              <Calendar
                mode="day"
                date={toSearchDate || defaultEndDate}
                handleDate={d => settoSearchDate(d)}
                config={{
                  tools: ['previous', 'next'],
                  allowWeekends: true,
                  minDate: defaultStartDate,
                  maxDate: defaultEndDate,
                }}
                label="To:"
              />

              <div className="flex--auto-space grid-column-end-2">
                <TextField
                  id="notes"
                  name="Notes"
                  label="Notes"
                  type="text"
                  value={notesSearchTerm}
                  maxlength={30}
                  changed={event => setNotesSearchTerm(event.target.value)}
                  isThin
                />
              </div>
              <div className="flex--auto-space grid-column-end-2">
                <TextField
                  id="notes"
                  name="Movements"
                  label="Movements"
                  type="text"
                  value={movementSearchTerm}
                  maxlength={30}
                  changed={event => setMovementSearchTerm(event.target.value)}
                  isThin
                />
              </div>
            </div>
          </>
        )}
        {!showAdvancedFilters &&
          ((fromSearchDate && fromSearchDate !== defaultStartDate) ||
            (toSearchDate && toSearchDate !== defaultEndDate) ||
            notesSearchTerm !== '' ||
            movementSearchTerm !== '') && (
            <div className="rpm-block flex--auto-gap journal--filters--chips">
              {fromSearchDate && fromSearchDate !== defaultStartDate && (
                <Chip
                  label={`From: ${fromSearchDate}`}
                  onDelete={() => setfromSearchDate(undefined)}
                />
              )}
              {toSearchDate && toSearchDate !== defaultEndDate && (
                <Chip
                  label={`To: ${toSearchDate}`}
                  onDelete={() => settoSearchDate(undefined)}
                />
              )}

              {notesSearchTerm !== '' && (
                <Chip
                  label={`Notes: ${notesSearchTerm}`}
                  onDelete={() => setNotesSearchTerm('')}
                />
              )}
              {movementSearchTerm !== '' && (
                <Chip
                  label={`Movement: ${movementSearchTerm}`}
                  onDelete={() => setMovementSearchTerm('')}
                />
              )}
            </div>
          )}
      </>
      <Zipper isActive={isFetching} />
    </nav>
  )

  const body = (
    <>
      {hasJournalData ? (
        <article
          className={`${
            kind !== 'component' && 'app-content'
          } journal--content`}
        >
          {hasJournalData && kind !== 'component' && (
            <div className="rpm-subheader journal--subheader">
              {manualSubscription && (
                <Button
                  icon={{
                    name: 'square-plus',
                    type: 'fas',
                    size: 'xl',
                  }}
                  label="Add Manual Activity"
                  kind="text"
                  disabled={false}
                  onClick={() => setShowScoreForm(true)}
                />
              )}
              <span className="spacer" />
              <span>Result</span>
            </div>
          )}

          {hasJournalData && (
            <ul className="app-content--inner rpm-data-list journal--results">
              {journalData.value.rows.map(row => (
                <TrainingJournalRow
                  row={packUpRow(row)}
                  key={String(`${row.track}-${row.id}`)}
                  openManualEdit={() => setShowScoreForm(row)}
                  disabled={
                    !(
                      myProfile?.value?.workshops?.[row.training?.slug] ||
                      myProfile?.value?.subscriptions?.[row.track]
                    )
                  }
                  action={
                    row.status === 'draft' ? setShowScoreTools : undefined
                  }
                />
              ))}
            </ul>
          )}

          {totalPages && totalPages > 1 && (
            <footer>
              <Pager
                pages={totalPages}
                currentPage={currentPage}
                selectPage={setCurrentPage}
              />
            </footer>
          )}
        </article>
      ) : (
        <article className="app-content journal--no-results">
          <div className="app-content--inner rpm-block">
            {!isFetching && <p className="text--caps">No results</p>}
          </div>
        </article>
      )}
    </>
  )

  const [transitionComplete, setTransitionComplete] = useState(
    journalData?.value !== undefined,
  )
  if (kind === 'component') {
    return (
      <div
        className={`journal rpm-container journal--component ${
          transitionComplete ? 'journal--component--visible' : ''
        }`}
      >
        {journalData?.value === undefined && (
          <div className="text--center rpm-block">
            <Spinner size={2} />
          </div>
        )}
        <Collapse
          addEndListener={(node, done) => {
            node.addEventListener(
              'transitionend',
              e => {
                setTransitionComplete(true)
                done(e)
              },
              false,
            )
          }}
          in={journalData?.value !== undefined}
          timeout={1000}
          collapsedSize={0}
        >
          {filters}

          {body}
        </Collapse>
      </div>
    )
  }

  return (
    <AppLayout
      name="journal"
      headerConfig={headerConfig}
      isLoading={isFetching}
      isError={isError}
      heels={heels}
      filters={filters}
      errorAction={() => window.location.reload()}
      cnames="one-column-content"
    >
      {body}
    </AppLayout>
  )
}

export default TrainingJournal
