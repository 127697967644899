import React from 'react'

import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { printClasses } from '../../Helpers'

type Props = {
  children: String,
  className?: String,
  allowedElements?: Array | undefined,
  forbiddenElements?: Array | undefined,
  components?: Object | undefined,
}

MarkdownRaw.defaultProps = {
  className: '',
  allowedElements: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'a',
    'br',
    'strong',
    'em',
    'blockquote',
    'ol',
    'ul',
    'li',
    'pre',
    'code',
    'hr',
    'i',
    'small',
  ],
  forbiddenElements: [],
}

function MarkdownRaw(props: Props) {
  const {
    children,
    className,
    allowedElements,
    forbiddenElements,
    components,
  } = props

  const renderedChildren = React.useMemo(() => {
    const finalElements = allowedElements.filter(
      item => !forbiddenElements.includes(item),
    )

    const process = aString => {
      // For now remove metadata markup from strings
      const processedString = aString
        .replace(/#(key|search)+\{(.*?)\}/g, '')
        .replace(/#mvt\{(.*?)\}/g, '**$1**')
        .replace(/#[a-zA-Z0-9]+\{(.*?)\}/g, '$1')
      if (finalElements && finalElements.indexOf('ol') === -1) {
        return processedString
          .replace(/(\d+)\.( )/g, '$1\\.$2')
          .replace(/(\d+)\)( )/g, '$1\\)$2')
      }
      return processedString
    }
    if (!children) return ''
    return (
      <ReactMarkdown
        className={printClasses([className, 'rpm-markdown'])}
        rehypePlugins={[rehypeRaw]}
        allowedElements={finalElements}
        unwrapDisallowed
        components={components}
      >
        {process(children)}
      </ReactMarkdown>
    )
  }, [children, className, allowedElements, forbiddenElements, components])

  return renderedChildren
}

export default MarkdownRaw
