import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useStatus, useProfile, useDashboard } from '../../context/atomContext'
import { useSupport } from '../../hooks/SupportHooks'

import Panel from '../elements/Panel'
import Logo from '../elements/Logo'
import Icon from '../elements/Icon'
import Button from '../elements/Button'

import Panel10kSubscribed from '../molecules/Panel10kSubscribed'

import { displayTrackName, printClasses } from '../../Helpers'
import { isMemberType } from '../../routes/parts-for-affiliates/AffiliatesHelpers'

type Props = {
  menuOpen: boolean,
  handleDismiss: Object,
  openSubscribeDialog?: Object,
}

AppMenu.defaultProps = {
  openSubscribeDialog: () => {},
}

function AppMenu(props: Props) {
  const { menuOpen, handleDismiss, openSubscribeDialog } = props

  const [show10kPanel, setShow10kPanel] = useState(undefined)
  // const open10kPanel = () => setShow10kPanel(true)
  const status = useStatus()
  const { data: myProfile } = useProfile()
  const { openSupport, supportTracks, numSupportMessages } = useSupport()
  const totalSupportMessages = Object.keys(numSupportMessages).reduce(
    (prev, cur) => prev + numSupportMessages[cur],
    0,
  )
  const atomSubscription = myProfile?.value?.subscriptions?.atomic
  const persistSubscription = myProfile?.value?.subscriptions?.persist
  const persistNutritionSubscription =
    myProfile?.value?.subscriptions?.persist_nutrition

  // const event10k2022Subscription = myProfile?.value?.registrations?.event10k2022
  const event10k2023Subscription = myProfile?.value?.registrations?.event10k2023
  const affiliateSubscription = isMemberType(myProfile, 'coach')
  const affiliateMemberSubscription = isMemberType(myProfile, 'member')
  const upsell = myProfile?.value?.upsell

  const affiliateTrackName =
    myProfile?.value?.affiliate?.name || displayTrackName('affiliate')

  const { data: dashboardData, isLoading: isLoadingDashboard } =
    useDashboard(true)

  const todaysAffiliateTraining = dashboardData?.value?.training?.affiliate
  const workshops = !isLoadingDashboard
    ? dashboardData?.value?.workshops
    : undefined

  const loginExtras =
    typeof window !== 'undefined'
      ? `onSuccess=${window?.location?.protocol}//${
          window?.location?.hostname
        }${window?.location?.port ? `:${window?.location?.port}` : ''}`
      : ''

  const menus = [
    {
      section: 1,
      items: [
        {
          label: 'Dashboard',
          route: '/dashboard',
          icon: { name: 'list', type: 'fal' },
        },
        {
          label: 'Training Journal',
          route: '/journal',
          icon: { name: 'notebook', type: 'fal' },
        },
        {
          label: 'Video Library',
          route:
            atomSubscription || persistSubscription || affiliateSubscription
              ? '/library'
              : '',
          icon: {
            name: 'video',
            type: 'fal',
          },
          upsell: upsell?.persist && !upsell?.atomic ? 'persist' : 'atomic',
        },
        {
          hide: !persistNutritionSubscription,
          label: 'Nutrition',
          route: '/persist/nutrition',
          icon: {
            name: 'plate-utensils',
            type: 'fal',
          },
        },
        {
          hide: workshops?.length === 0,
          label: 'Workshops',
          route: '/workshops',
          icon: { name: 'calendar-check', type: 'fal' },
        },
        {
          hide: !affiliateSubscription && !persistSubscription,
          label: 'Additional Resources',
          route: '/resources',
          icon: {
            name: 'square-plus',
            type: 'fal',
          },
        },
      ],
    },
    {
      section: 2,
      label: 'Daily Training',
      icon: { name: 'calendar-day', type: 'fal' },
      items: [
        {
          hide: !affiliateMemberSubscription,
          label: affiliateTrackName,
          route: todaysAffiliateTraining ? '/affiliate/training' : undefined,
          hideDialog: true,
          upsell: 'atomic',
        },
        {
          hide: !persistSubscription && !upsell?.persist,
          label: displayTrackName('persist'),
          route: persistSubscription ? '/persist/training' : '',
          upsell: 'persist',
        },
        {
          hide: !atomSubscription && !upsell?.atomic,
          label: displayTrackName('atomic'),
          route: atomSubscription ? '/atomic/training' : '',
          upsell: 'atomic',
        },
      ],
    },
    {
      section: 3,
      label: 'Events',
      hide: !event10k2023Subscription,
      icon: { name: 'calendar-star', type: 'fal' },
      items: [
        {
          label: displayTrackName('event10k'),
          hide: !event10k2023Subscription,
          route: '/events/10k',
        },
      ],
    },
    {
      section: 4,
      label: 'WUWO Affiliate',
      hide: !affiliateSubscription,
      icon: { name: 'shop', type: 'fal' },
      items: [
        {
          label: 'Session Plan',
          route: '/affiliate/coaching',
        },
        {
          label: 'Monthly Overview',
          route: '/affiliate/overview',
        },
        {
          label: 'Staff',
          route: '/affiliate/staff',
        },
      ],
    },
    {
      section: 5,
      label: 'Support',
      hide: !supportTracks || supportTracks?.length < 2,
      icon: { name: 'circle-question', type: 'fal' },
      items: supportTracks?.map(supTrack => ({
        label: supTrack.name,
        icon:
          numSupportMessages[supTrack.id] > 0
            ? {
                name: 'circle',
                type: 'fas',
                cnames: 'brand-fg',
                flipped: true,
              }
            : undefined,
        action: () => openSupport(supTrack.id),
      })),
    },
    {
      section: 6,
      items: [
        {
          label: 'Profile',
          route: '/profile',
          icon: { name: 'user-circle', type: 'fal' },
        },
        {
          label: 'Account',
          route: '/account',
          icon: { name: 'gear', type: 'fal' },
        },
        {
          label: 'Shop Programs',
          href: '/shop',
          icon: { name: 'shopping-cart', type: 'fal' },
        },
        {
          hide: supportTracks?.length > 1,
          label: 'Support',
          action: openSupport,
          icon: {
            name: 'question-circle',
            type: 'fal',
          },
          trailingIcon:
            totalSupportMessages > 0
              ? {
                  name: 'circle',
                  type: 'fas',
                  cnames: 'brand-fg',
                }
              : undefined,
        },
        {
          label: 'Log Out',
          hide: !status?.loggedIn,
          href: `/api/authenticate/logout?${loginExtras}`,
          target: false,
          icon: { name: 'sign-out', type: 'fal' },
        },
        {
          label: 'Log In',
          hide: status?.loggedIn,
          href: `/api/authenticate/login?view=login&${loginExtras}`,
          target: false,
          icon: { name: 'sign-in', type: 'fal' },
        },
      ],
    },
  ]

  const itemDisplay = (icon, text, isHeading = false) => (
    <>
      {icon && !icon.flipped ? (
        <Icon
          name={icon.name}
          type={icon.type}
          size={icon.size}
          cnames={printClasses([icon.cnames, 'menu-item-icon ish'])}
        />
      ) : (
        <span className="menu-item-spacer" />
      )}

      <span className={isHeading ? 'text--small-caps' : ''}>{text}</span>
      {icon && icon.flipped ? (
        <Icon
          name={icon.name}
          type={icon.type}
          size={icon.size}
          cnames={printClasses([icon.cnames, 'menu-item-icon'])}
        />
      ) : (
        ''
      )}
    </>
  )

  return (
    <>
      <Panel
        isOpen={menuOpen}
        handleDismiss={handleDismiss}
        cnames="app-menu"
        opensFrom="left"
        showDismiss={false}
      >
        <header className="app-menu--header">
          <Button onClick={handleDismiss} kind="secret">
            <Logo name="atom" alt="ATOM" color="black" size={1} />
          </Button>
        </header>

        <nav className="app-menu--content">
          {menus.map(
            menu =>
              !menu.hide && (
                <div className="app-menu--section" key={menu.section}>
                  {menu.label && (
                    <h4>{itemDisplay(menu.icon, menu.label, true)}</h4>
                  )}

                  <ul key={menu.section}>
                    {menu.items.map(
                      item =>
                        !item.hide && (
                          <li key={item.label}>
                            {item.route && (
                              <>
                                {window.location.pathname === item.route ||
                                window.location.pathname ===
                                  `/app${item.route}` ? (
                                  <Button kind="text" onClick={handleDismiss}>
                                    {itemDisplay(item.icon, item.label)}
                                  </Button>
                                ) : (
                                  <Link to={item.route}>
                                    {itemDisplay(item.icon, item.label)}
                                  </Link>
                                )}
                              </>
                            )}

                            {item.href && (
                              <a
                                href={item.href}
                                target={item.target ? '_blank' : undefined}
                                rel="noreferrer"
                              >
                                {itemDisplay(item.icon, item.label)}
                              </a>
                            )}

                            {item.action && (
                              <>
                                <Button kind="text" onClick={item.action}>
                                  {itemDisplay(item.icon, item.label)}
                                  {item.trailingIcon && (
                                    <Icon
                                      name={item.trailingIcon.name}
                                      type={item.trailingIcon.type}
                                      fw={item.trailingIcon.fw}
                                      cnames={item.trailingIcon.cnames}
                                    />
                                  )}
                                </Button>
                              </>
                            )}

                            {!item.route && !item.href && !item.action && (
                              <>
                                {!item.hideDialog && (
                                  <>
                                    <Button
                                      kind="text"
                                      onClick={() =>
                                        openSubscribeDialog(item.upsell)
                                      }
                                      cnames="has-icon ghost flex--auto-spread"
                                    >
                                      {itemDisplay(item.icon, item.label)}

                                      <Icon
                                        name="lock-alt"
                                        type="fal"
                                        fw={false}
                                      />
                                    </Button>
                                  </>
                                )}
                                {item.hideDialog && (
                                  <div className="has-icon ghost">
                                    {itemDisplay(item.icon, item.label)}
                                    <Icon
                                      name="lock-alt"
                                      type="fal"
                                      fw={false}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </li>
                        ),
                    )}
                  </ul>
                </div>
              ),
          )}
          <div className="app-menu--section">
            <ul>
              <li>
                <Button kind="secret" onClick={() => window.location.reload()}>
                  <small className="ghost">{`v${process.env.REACT_APP_VERSION}`}</small>
                </Button>
              </li>
            </ul>
          </div>
        </nav>
      </Panel>{' '}
      <Panel10kSubscribed
        isOpen={show10kPanel}
        handleDismiss={() => setShow10kPanel(false)}
      />
    </>
  )
}

export default AppMenu
