import React from 'react'
import IconText from '../elements/IconText'
import Button from '../elements/Button'
import ButtonComments from '../molecules/ButtonComments'
import ButtonLikes from '../molecules/ButtonLikes'

import { printClasses, kitLabelWithIcon, cleanedLevel } from '../../Helpers'

type Props = {
  kit: Object,
  level: string,
  rank?: string | Number,
  total?: string | Number,
  commentCount: Number,
  likeCount: Number,
  isLikeUser: boolean,
  openComments: Object,
  toggleLike: Object,
  disableComments?: boolean,
  disableLikes?: boolean,
  cnames?: string,
  showRank?: boolean,
  showComments?: Boolean,
  showLikes?: Boolean,
  details?: Any,
}

UserMeta.defaultProps = {
  cnames: null,
  rank: null,
  total: null,
  disableComments: false,
  disableLikes: false,
  showRank: false,
  showComments: true,
  showLikes: true,
  details: null,
}

function UserMeta(props: Props) {
  const {
    kit,
    level,
    rank,
    total,
    commentCount,
    likeCount,
    isLikeUser,
    openComments,
    toggleLike,
    disableComments,
    disableLikes,
    cnames,
    showRank,
    showComments,
    showLikes,
    details,
  } = props

  const kitObject = kit && kitLabelWithIcon(kit)
  const levelIcon = { type: 'svg', name: `L${cleanedLevel(level)}`, size: 'lg' }
  const rowIcons = kitObject?.icons
    ? [...kitObject?.icons, levelIcon]
    : [levelIcon]

  const rowLabel = !kitObject?.icons && kitObject?.label

  return (
    <p
      className={printClasses([
        'user--meta--kcl',
        'flex--wrap',
        'flex--auto-gap',
        'text--small',
        'text--caps',
        cnames,
      ])}
    >
      {kit && level && (
        <Button
          kind="text"
          onClick={() => {
            if (!disableComments) openComments()
          }}
          label={
            <IconText
              icons={rowIcons}
              text={rowLabel}
              cnames="user--meta--kit"
              flipped
            />
          }
        />
      )}

      {details}

      {showRank && rank && total && (
        <IconText
          icon={{ type: 'svg', name: 'rank' }}
          text={rank}
          subtext={`/${total}`}
          cnames="user--meta--rank"
        />
      )}

      {showComments && (
        <ButtonComments
          action={() => openComments()}
          count={commentCount}
          isDisabled={disableComments}
        />
      )}

      {showLikes && (
        <ButtonLikes
          action={() => toggleLike()}
          count={likeCount}
          isLikeUser={isLikeUser}
          isDisabled={disableLikes}
        />
      )}
    </p>
  )
}

export default UserMeta
