import React, { useState } from 'react'

import Button from '../elements/Button'
import Icon from '../elements/Icon'
import Tooltip from '../elements/Tooltip'

import { feltLikeChoices } from '../../Helpers'

type SelectByFeelProps = {
  value: Number,
  onChange: Number => void,
  disabled?: Boolean,
}

SelectByFeel.defaultProps = {
  disabled: false,
}

function SelectByFeel(props: SelectByFeelProps) {
  const { value, onChange, disabled } = props

  const [showTooltip, setShowTooltip] = useState(false)

  const dismissTooltip = () => setShowTooltip(false)
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const onChanged = val => {
    if (value === val) {
      onChange(0)
    } else {
      onChange(val)
    }
  }

  const currentSelected =
    value !== undefined && feltLikeChoices.find(m => value === m.value)

  return (
    <>
      <Tooltip
        isOpen={showTooltip}
        handleDismiss={dismissTooltip}
        cnames="training--tooltip--demo-video"
        message="This is a subjective measure of how your body (and mind) felt during a workout. In other words... were you on your game?"
        hasArrow={false}
      />

      <span className="rpm-input--label">
        Health/Energy
        <Button
          onClick={toggleTooltip}
          kind="text"
          icon={{ name: 'circle-question', type: 'fal' }}
          label=""
        />
      </span>

      <div className="rpm-block feltLike--display flex--auto-spread text--small">
        <div>
          <span className="ish">How did you feel today? </span>
          <strong>{currentSelected?.label || '-'}</strong>
        </div>
        {!disabled && value !== 0 && value !== undefined && (
          <Button label="Clear Entry" kind="link" onClick={() => onChange(0)} />
        )}
      </div>

      <div className="rpm-block is-flex flex--auto-spread choices">
        {feltLikeChoices.map(choice => (
          <div className="is-flex is-flex--column" key={choice.value}>
            <Button
              onClick={() => onChanged(choice.value)}
              cnames={
                choice.value === value ? 'is-selected has-border' : 'has-border'
              }
              kind="text"
            >
              <Icon
                name={choice.icon.name}
                type={
                  choice.value === currentSelected?.value
                    ? 'fas'
                    : choice.icon.type
                }
                size="xl"
              />
            </Button>
            <Button onClick={() => onChanged(choice.value)} kind="text">
              <span className="ish text--small ish">{choice?.label}</span>
            </Button>
          </div>
        ))}
      </div>
    </>
  )
}

export default SelectByFeel
