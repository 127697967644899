import React from 'react'
import { abbreviateNumber, formatRawNumber } from '../../Helpers'

type Props = {
  data: any,
  label?: string,
  placeholder?: string,
  abbreviate?: boolean,
}

DailyJumps.defaultProps = {
  label: 'Daily Jumps',
  placeholder: '---',
  abbreviate: false,
}

function DailyJumps(props: Props) {
  // State
  const { data, label, placeholder, abbreviate } = props

  // Temp
  // State
  // Context
  // API
  // test data
  // Variables
  // functions
  const max =
    data && data.reduce((a, b) => (Number(a.total) > Number(b.total) ? a : b))

  const bar = (jump, index) => {
    const pct =
      (Number(jump.total) &&
        Math.ceil((Number(jump.total) / Number(max.total)) * 100)) ||
      0

    const value =
      (Number(jump.total) > 0 &&
        (abbreviate
          ? abbreviateNumber(parseInt(jump.total, 10))
          : formatRawNumber(parseInt(jump.total, 10)))) ||
      placeholder

    return (
      <div
        key={String(`${index}`)}
        className="chart--stacked--bar"
        style={{ '--pct': pct }}
      >
        <span>{value}</span>
        <small className="ish">{jump.id}</small>
      </div>
    )
  }

  return (
    <div className="chart chart--stacked">
      <div className="chart--stacked--row">
        {data && data.map((jump, index) => bar(jump, index))}
      </div>
      {label && <div className="chart--stacked--label">{label}</div>}
    </div>
  )
}

export default DailyJumps
