import React, { createContext, useState, useEffect, useContext } from 'react'
import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
  useMutation,
} from 'react-query'
import axios from 'axios'

import { sendEvent } from '../Helpers'

export const AtomContext = createContext()

type Props = {
  children: object,
}

// FOR TESTING PURPOSES ONLY
// axios.interceptors.request.use(
//   async config => {
//     // Add a Sleep delay to every request
//     const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
//     await sleep(3000)
//     return config
//   },
//   error =>
//     // Do something with request error
//     Promise.reject(error),
// )

// axios.interceptors.response.use(
//   response => {
//     // fail a leaderboard query 50% of the time
//     // if (response.config.url === 'leaderboard' && Math.random() > 0.5) {
//     //   response.data.status = 401
//     // }
//     console.log("search for this string")
//     return response
//   },
//   error =>
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     Promise.reject(error),
// )

const handleIfError = (code, url) => {
  switch (code) {
    case 401:
    case 403:
      if (url !== 'user/login' && url !== 'user/login/link/authenticate') {
        // only redirect in the case we aren't already on the login page
        if (window.location !== '/login') {
          const loginExtras =
            typeof window !== 'undefined'
              ? `&onSuccess=${window?.location?.protocol}//${
                  window?.location?.hostname
                }${window?.location?.port ? `:${window?.location?.port}` : ''}`
              : ''

          window.location = `/api/authenticate/login?view=login${loginExtras}`
        }
      }
      break
    case 460: {
      // No active subscription for this content
      const e = Error('This content requires an active subscription.')
      e.name = 'ErrorNoSubscription'
      throw e
    }
    case 503:
      window.location = '/app/maintenance.html'
      break
    default:
  }
}

// Add a response interceptor to test login
axios.interceptors.response.use(
  response => {
    handleIfError(response.data.status, response.config.url)
    return response
  },
  error => {
    handleIfError(error.status, error.config.url)
    Promise.reject(error)
  },
)

const AtomProvider = ({ children }: Props) => {
  const isDemoMode = process.env.REACT_APP_DEMO_MODE === '1'
  const version = process.env.REACT_APP_VERSION
  const [needsRestart, setNeedsRestart] = useState(false)
  const isDebug = ['development', 'test'].includes(process.env.NODE_ENV)

  const { data: versionData, isLoading } = useQuery(
    ['cfgData'],
    async () => {
      const baseAxios = axios.create({})
      baseAxios.defaults.baseURL = '/'
      const { data } = await baseAxios.get(`/app/cfgData.json?${Date.now()}`, {
        params: {},
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return data
    },
    {
      staleTime: 5000,
      refetchInterval: 60 * 1000 * 60,
      enabled: !isDebug,
    },
  )

  const { data: status, isLoading: isStatusLoading } = useQuery(
    ['status'],
    async () => {
      const baseAxios = axios.create({})
      baseAxios.defaults.baseURL = '/api'
      const { data } = await baseAxios.get('authenticate/status', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return data
    },
    {
      staleTime: 60000,
    },
  )

  useEffect(() => {
    if (isLoading) return
    if (versionData?.version !== version) setNeedsRestart(true)
    if (versionData?.version === version) setNeedsRestart(false)
  }, [isLoading, version, versionData])

  return (
    <AtomContext.Provider
      value={{
        status,
        isStatusLoading,
        isDemoMode,
        version,
        needsRestart,
      }}
    >
      {children}
    </AtomContext.Provider>
  )
}

export function useStatus() {
  const context = useContext(AtomContext)

  return context.status
}

export function useAffiliateOverview(date, kit, enabled) {
  return useQuery(
    ['affiliate', 'overview', date, kit],
    async ({ queryKey }) => {
      const [, , overviewDate, overviewKit] = queryKey

      const params = {
        date: overviewDate,
        kit: overviewKit,
      }

      const { data } = await axios.get(`/affiliate/overview`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Affiliate Overview data.')

      return data
    },
    {
      enabled,
      keepPreviousData: true,
      staleTime: 60000,
    },
  )
}

export function useMyAffiliate(enabled: boolean = true) {
  const context = useContext(AtomContext)
  const enabledState = enabled !== undefined ? enabled : true

  return useQuery(
    ['affiliate', 'myAffiliate'],
    async () => {
      if (context?.profile && !context?.profile?.username) {
        return {}
      }
      const { data } = await axios.get('affiliate/user/myaffiliate', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch User Profile data.')
      return data
    },
    {
      staleTime: 60000,
      enabled: enabledState,
    },
  )
}

export function useSetAffiliateProfile() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('affiliate/update', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (results.data.status === 422) {
        throw new FormError(
          'Error posting affiliate profile.',
          results?.data?.value,
        )
      }

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Affiliate-Profile-Set')
        queryClient.invalidateQueries(['affiliate', 'myAffiliate'])
      },
    },
  )
}

export function useUpdateAffiliateUser() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('affiliate/user/update', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (results.data.status === 422) {
        throw new FormError(
          'Error Updating Affilliate User.',
          results?.data?.value,
        )
      }

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Affiliate-User-Edit')
        queryClient.invalidateQueries(['affiliate', 'myAffiliate'])
      },
    },
  )
}

export function useDeleteAffiliateUser() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.delete('affiliate/user/delete', {
        data: postData,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError(
          'Error Deleting Affilliate User.',
          results?.data?.value,
        )
      }

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Affiliate-User-Delete')
        queryClient.invalidateQueries(['affiliate', 'myAffiliate'])
      },
    },
  )
}

export function useInviteAffiliateUsers() {
  // const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('affiliate/user/invite', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw Error('Error Inviting to Team.')
      }

      return results
    },
    {
      onSuccess: response => {
        console.log('response', response)
        // queryClient.invalidateQueries([
        //   'event',
        //   'team',
        //   response.data?.value?.teamId,
        // ])
        // queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

export function useTraining(date, enabled, track, options = []) {
  return useQuery(
    ['training', track || 'atomic', date, options],
    async ({ queryKey }) => {
      // const json = localStorage.getItem('config')
      // const savedConfig = JSON.parse(json)

      const [, trainingTrack, trainingDate, trainingOptions] = queryKey

      const params = {
        date: trainingDate,
        ...trainingOptions,
      }

      const { data } = await axios.get(`${trainingTrack}/training/view`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Training data.')

      return data
    },
    {
      enabled,
      keepPreviousData: true,
      staleTime: 60000,
    },
  )
}

export function useTrainingJournal(
  date,
  page,
  perPage,
  sort,
  training,
  track,
  enabled,
  searchData,
  onSuccess,
) {
  return useQuery(
    [
      'training_journal',
      track || 'atomic',
      date,
      searchData,
      page,
      perPage,
      sort,
      training,
    ],
    async ({ queryKey }) => {
      const [
        ,
        trainingTrack,
        apiDate,
        apiSearchData,
        apiPage,
        apiPerPage,
        apiSort,
        apiTraining,
      ] = queryKey

      const {
        search,
        notesSearchTerm,
        fromSearchDate,
        toSearchDate,
        movementSearchTerm,
      } = apiSearchData || {}

      const options = {
        date: apiDate,
        page: apiPage,
        'per-page': apiPerPage,
        sort: apiSort,
        training: apiTraining,
        search,
        notesSearchTerm,
        movementSearchTerm,
        fromSearchDate,
        toSearchDate,
      }

      const params = Object.keys(options).reduce((lastObj, key) => {
        const value = options[key]
        const newObj = { ...lastObj }

        if (value !== null && value !== '') {
          newObj[key] = value
        }

        return newObj
      }, {})

      let url = 'user/journal'
      if (track !== 'journal') {
        url = `${trainingTrack}/user/training`
      }

      const { data } = await axios.get(url, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Training data.')

      return data
    },
    {
      enabled,
      staleTime: 60000,
      keepPreviousData: true,
      onSuccess,
    },
  )
}

export function useTrainingCalendar(yearMonth) {
  return useQuery(
    ['training_calendar', yearMonth],
    async ({ queryKey }) => {
      const [, apiYearMonth] = queryKey
      if (!apiYearMonth) return {}

      const { data } = await axios.get(`calendar/${apiYearMonth}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Error downloading training calendar.')

      return data
    },
    {
      staleTime: 60000,
    },
  )
}

export function usePromotions(initialPromotion, enabled) {
  return useQuery(
    ['promotions'],
    async () => {
      const { data } = await axios.get('promotion', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Error downloading promotions.')

      return data
    },
    {
      enabled,
      placeholderData: initialPromotion,
      staleTime: 60000 * 10,
    },
  )
}

export function useActivities(onSuccess) {
  return useQuery(
    ['activities'],
    async () => {
      const { data } = await axios.get('activity/feed', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Error downloading activities.')

      return data
    },
    {
      refetchOnMount: 'always',
      onSuccess: result => {
        if (onSuccess) onSuccess(result)
      },
      staleTime: 60000 * 10,
    },
  )
}

export function useMarkActivitiesRead() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      if (!postData) return

      const results = await axios.post('activity/viewed', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries(['activities'])
        console.log(response)
      },
    },
  )
}

export function useComments(
  commentId,
  track = 'atomic',
  sort = '-created',
  config,
) {
  return useQuery(
    ['user', 'comments', commentId, track, sort, config],
    async () => {
      if (!commentId || !track) return {}
      const params = {
        [config?.commentIdName || 'userTrainingId']: commentId,
        sort,
      }
      const apiPath = config?.apiPath || '/user/training/comment'
      const { data } = await axios.get(`${track}${apiPath}`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Error downloading comments.')

      return data
    },
    {
      staleTime: 60000,
    },
  )
}

export function useSetComment(config) {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const track = postData?.track || 'atomic'
      const apiPath = config?.apiPath || '/user/training/comment'

      const results = await axios.post(`${track}${apiPath}`, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw Error('Error posting comment.')
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        sendEvent('ATOM-Comment-Posted')
        queryClient.invalidateQueries([
          'user',
          'comments',
          response.data.value[config?.commentIdName || 'userTrainingId'],
          vars?.track || 'atomic',
        ])
      },
    },
  )
}

export function useDeleteComment(config) {
  const queryClient = useQueryClient()

  return useMutation(
    async vars => {
      if (!vars?.id || !vars?.track) return {}
      const apiPath = config?.apiPath || '/user/training/comment'

      const results = await axios.delete(`${vars.track}${apiPath}`, {
        data: {
          id: vars.id,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        if (!vars?.threadId) return
        sendEvent('ATOM-Comment-Deleted')
        queryClient.invalidateQueries([
          'user',
          'comments',
          vars.threadId,
          vars.track,
        ])
      },
    },
  )
}

export function useToggleLike(config) {
  return useMutation(
    async postData => {
      const track = config?.track || postData?.track || 'atomic'
      const apiPath = config?.apiPath || '/user/training/like'

      const results = await axios.post(`${track}${apiPath}`, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Training-Like')
      },
    },
  )
}

export function useSubscriptions(enabled: boolean = true) {
  const enabledState = enabled !== undefined ? enabled : true

  return useQuery(
    ['user', 'subscriptions'],
    async () => {
      const { data } = await axios.get('user/subscriptions', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return data
    },
    {
      staleTime: 60000,
      enabled: enabledState,
    },
  )
}

export function useProfile(enabled: boolean = true) {
  const enabledState = enabled !== undefined ? enabled : true

  return useQuery(
    ['user', 'me'],
    async () => {
      const { data } = await axios.get('user/profile', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return data
    },
    {
      staleTime: 60000,
      enabled: enabledState,
    },
  )
}

export function useSetProfile() {
  const queryClient = useQueryClient()
  const context = useContext(AtomContext)

  return useMutation(
    async postData => {
      const results = await axios.post('user/profile', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (results.data.status === 422) {
        throw new FormError('Error posting profile.', results?.data?.value)
      }

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: response => {
        sendEvent('ATOM-Profile-Set')
        queryClient.setQueryData(['user', 'me'], response.data)
        queryClient.invalidateQueries(['user', context?.status?.user?.id])
        queryClient.invalidateQueries(['status'])
      },
    },
  )
}

export function usePublicProfile(userId, enabled) {
  return useQuery(
    ['user', userId],
    async () => {
      if (!userId) return {}
      const { data } = await axios.get(`user/profile/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (data && data.status !== 200)
        throw Error('Unable to fetch Profile data.')
      return data
    },
    { enabled, staleTime: 60000 },
  )
}

export function useSetProfileImage() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('user/profile/image', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Profile-Image-Upload')
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

export function useImageUpload(action, key, onSuccess?: Function) {
  const queryClient = useQueryClient()

  return useMutation(
    async data => {
      const results = await axios.post(action, data.postData, {
        params: data.params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        if (key) queryClient.invalidateQueries(key)
        if (onSuccess) onSuccess()
      },
    },
  )
}

export function useImageDelete(action, key, onSuccess?: Function) {
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      const results = await axios.delete(action, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        if (key) queryClient.invalidateQueries(key)
        if (onSuccess) onSuccess()
      },
    },
  )
}

export function useDeleteProfileImage() {
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      const results = await axios.delete('user/profile/image', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Profile-Image-Delete')
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

export function useSetAssessment() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('user/assessment', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Assessment-Set')
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

export function useSetKit() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post('user/kit', postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Kit-Set')
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

export function useAddTourResponse() {
  return useMutation(async postData => {
    const results = await axios.post('user/tour', postData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (results.data.status !== 200) {
      throw new Error(results)
    }
    return results
  }, {})
}

export function FormError(message, data, code = 'FormError') {
  this.message = message
  this.code = code
  this.data = data
}

export function useSubmitScore() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      // This needs a proper error check
      if (
        !postData?.track ||
        (!postData?.trainingId && postData?.track !== 'manual')
      )
        return {}
      let url = `${postData.track}/user/training`
      if (postData.id) {
        url = `${postData.track}/user/training/update`
      }
      const results = await axios.post(url, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError(
          'Error posting user training.',
          results?.data?.value,
        )
      }
      if (results.data.status !== 200) {
        throw Error('Error posting user training.')
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        sendEvent('ATOM-Training-Submit')
        queryClient.invalidateQueries([
          'leaderboard',
          vars?.track,
          response?.data?.value?.rows[0].date,
        ])
        if (vars?.track === 'workshop')
          queryClient.invalidateQueries(['workshops'])
        if (vars?.track === 'event/challenge') {
          queryClient.invalidateQueries(['event', 'team', 'myTeam'])
        }
        queryClient.invalidateQueries(['training_journal'])
      },
    },
  )
}

export function useDeleteScore() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      // This needs a proper error check
      if (!postData?.id || !postData?.track) return {}
      const results = await axios.delete(`${postData.track}/user/training`, {
        data: { id: postData?.id },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError(
          'Error deleting user training.',
          results?.data?.value,
        )
      }
      if (results.data.status !== 200) {
        throw Error('Error deleting user training.')
      }

      return results
    },
    {
      onSuccess: () => {
        sendEvent('ATOM-Training-Delete')
        queryClient.invalidateQueries(['training_journal'])
        queryClient.invalidateQueries(['training'])
        queryClient.invalidateQueries(['dashboard'])
        queryClient.invalidateQueries(['user', 'me'])
      },
    },
  )
}

export function useLeaderboard(
  leaderboardDate: string,
  filters: Object = {},
  athletes: boolean,
  track?: string,
  enabled: boolean = true,
) {
  return useInfiniteQuery(
    ['leaderboard', track || 'atomic', leaderboardDate, filters, athletes],
    async ({ queryKey, pageParam = 1 }) => {
      const [, leaderboardTrack, date, myFilters, featured: athletes] = queryKey

      const featuredAthletes = featured || null

      const params = JSON.parse(
        JSON.stringify({
          date,
          ...myFilters,
          featuredAthletes,
          'per-page': 100,
          page: pageParam,
        }),
      ) // remove any non-value properties

      if (Object.is(parseInt(myFilters?.level, 10), 0)) {
        delete params.level
      }

      if (Object.is(parseInt(myFilters?.division, 10), 0)) {
        delete params.division
      }

      const { data } = await axios.get(`${leaderboardTrack}/leaderboard`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Leaderboard data.')

      return data
    },
    {
      getNextPageParam: lastPage => {
        if (!lastPage?.value?.pagination?.next) return undefined
        const myurl = new URL(lastPage.value.pagination.next)
        return myurl.searchParams.get('page')
      },
      getPreviousPageParam: firstPage => {
        if (!firstPage?.value?.pagination?.prev) return undefined
        const myurl = new URL(firstPage.value.pagination.prev)
        return myurl.searchParams.get('page')
      },
      keepPreviousData: true,
      staleTime: 60000,
      enabled,
    },
  )
}

export function useEventTeam(id, enabled) {
  return useQuery(
    ['event', 'team', id],
    async () => {
      const params = { id }
      const { data } = await axios.get('event/challenge/team/view', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Event Team data.')

      return data
    },
    {
      enabled,
      staleTime: 60000,
    },
  )
}

export function useMyEventTeam(enabled = true) {
  return useQuery(
    ['event', 'team', 'myTeam'],
    async () => {
      const { data } = await axios.get('event/challenge/team/user/myteam', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch My Team data.')

      return data
    },
    {
      enabled,
      staleTime: 60000,
    },
  )
}

export function useCreateTeam(teamId) {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const url = `event/challenge/team/${teamId ? 'update' : 'create'}`
      const results = await axios.post(url, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError('Error creating team.', results?.data?.value)
      }
      if (results.data.status !== 200) {
        throw Error('Error creating team.')
      }

      return results
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries(['event', 'team', response.data.value.id])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

export function useJoinTeam() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post(`event/challenge/team/user`, postData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status === 422) {
        throw new FormError('Error Joining team.', results?.data?.value)
      }
      if (results.data.status !== 200) {
        throw Error('Error Joining team.')
      }

      return results
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries([
          'event',
          'team',
          response.data?.value?.teamId,
        ])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

export function useInviteTeam() {
  const queryClient = useQueryClient()

  return useMutation(
    async postData => {
      const results = await axios.post(
        `event/challenge/team/user/invite`,
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      if (results.data.status !== 200) {
        throw Error('Error Inviting to Team.')
      }

      return results
    },
    {
      onSuccess: response => {
        queryClient.invalidateQueries([
          'event',
          'team',
          response.data?.value?.teamId,
        ])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

export function useLeaveTeam() {
  const queryClient = useQueryClient()

  return useMutation(
    async vars => {
      if (!vars?.teamId || !vars?.userId) return {}
      const results = await axios.delete('event/challenge/team/user', {
        data: {
          teamId: vars.teamId,
          userId: vars.userId,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        sendEvent('ATOM-User-Left-Team')
        queryClient.invalidateQueries(['event', 'team', vars?.teamId])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

export function useDeleteTeam() {
  const queryClient = useQueryClient()

  return useMutation(
    async id => {
      if (!id) return {}
      const results = await axios.delete('event/challenge/team', {
        data: {
          id,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (results.data.status !== 200) {
        throw new Error(results)
      }

      return results
    },
    {
      onSuccess: (response, vars) => {
        sendEvent('ATOM-User-Deleat-Team')
        queryClient.invalidateQueries(['event', 'team', vars?.teamId])
        queryClient.invalidateQueries(['event', 'team', 'myTeam'])
      },
    },
  )
}

export function useSearchTeam(search, enabled) {
  return useQuery(
    ['event', 'team', 'search', search],
    async () => {
      const params = { search, 'per-page': 500 }
      const { data } = await axios.get('event/challenge/team', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to search Event Team data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

export function useSearchUsers(search, enabled) {
  return useQuery(
    ['user', 'search', search],
    async () => {
      const params = { search }
      const { data } = await axios.get('user/search', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to search Event User data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

export function useSearchEventUsers(search, enabled) {
  return useQuery(
    ['event', 'user', 'search', search],
    async () => {
      const params = { search }
      const { data } = await axios.get('event/challenge/user', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to search Event User data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

// Video Library
export function useVideoLibrary(params, enabled = true) {
  const flattenParams = () => {
    const flattenedParams = { track: 'affiliate' }
    const paramKeys = Object.keys(params)
    for (let k = 0; k < paramKeys.length; k += 1) {
      const paramKey = paramKeys[k]
      const param = params[paramKey]
      if (Array.isArray(param)) {
        if (param.length > 0) flattenedParams[paramKey] = param.join(',')
      } else {
        flattenedParams[paramKey] = param
      }
    }
    return flattenedParams
  }

  return useInfiniteQuery(
    ['media', params],
    async ({ pageParam = 1 }) => {
      const flattenedParams = flattenParams()
      const myParams = { ...flattenedParams, page: pageParam }
      const { data } = await axios.get('media', {
        params: myParams,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to search Video Library.')

      return data
    },
    {
      getNextPageParam: lastPage => {
        if (!lastPage?.value?.pagination?.next) return undefined
        const myurl = new URL(lastPage.value.pagination.next)
        return myurl.searchParams.get('page')
      },
      getPreviousPageParam: firstPage => {
        if (!firstPage?.value?.pagination?.prev) return undefined
        const myurl = new URL(firstPage.value.pagination.prev)
        return myurl.searchParams.get('page')
      },
      keepPreviousData: true,
      staleTime: 60000 * 60, // one hour cache
      enabled,
    },
  )
}

export function useDashboard(enabled) {
  const queryClient = useQueryClient()

  return useQuery(
    ['dashboard'],
    async () => {
      const { data } = await axios.get(`dashboard`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Training data.')

      const templateResponse = {
        value: {},
        status: 200,
        success: true,
        source: 'dashboard',
      }

      if (data?.value?.promotions) {
        queryClient.setQueryData(['promotions'], {
          ...templateResponse,
          value: data?.value?.promotions,
        })
      }

      if (data?.value?.affiliate) {
        queryClient.setQueryData(['affiliate', 'myAffiliate'], {
          ...templateResponse,
          value: data?.value?.affiliate,
        })
      }

      return data
    },
    {
      enabled,
      keepPreviousData: true,
      staleTime: 60000,
    },
  )
}

export function useWorkshops(enabled = true) {
  return useQuery(
    ['workshops'],
    async () => {
      const { data } = await axios.get('workshop', {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to load Workshop data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

export function useWorkshopTraining(day, id, enabled = true) {
  return useQuery(
    ['workshop', 'training', id, day],
    async ({ queryKey }) => {
      // const json = localStorage.getItem('config')
      // const savedConfig = JSON.parse(json)

      const [, , workshopId, trainingDay] = queryKey

      const params = {
        day: trainingDay,
        workshopId,
      }

      const { data } = await axios.get(`workshop/training/view`, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Training data.')

      return data
    },
    {
      enabled,
      keepPreviousData: true,
      staleTime: 60000,
    },
  )
}

export function useWorkshopTrainingJournal(
  day,
  workshopId,
  page,
  perPage,
  sort,
  training,
  track,
  enabled,
) {
  return useQuery(
    [
      'training_journal',
      track || 'workshop',
      day,
      workshopId,
      page,
      perPage,
      sort,
      training,
    ],
    async ({ queryKey }) => {
      const [
        ,
        trainingTrack,
        apiDay,
        apiWorkshopId,
        apiPage,
        apiPerPage,
        apiSort,
        apiTraining,
      ] = queryKey

      const options = {
        day: apiDay,
        workshopId: apiWorkshopId,
        page: apiPage,
        'per-page': apiPerPage,
        sort: apiSort,
        training: apiTraining,
      }

      const params = Object.keys(options).reduce((lastObj, key) => {
        const value = options[key]
        const newObj = { ...lastObj }

        if (value !== null) {
          newObj[key] = value
        }

        return newObj
      }, {})

      const url = `${trainingTrack}/user/training`

      const { data } = await axios.get(url, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200)
        throw Error('Unable to fetch Workshop Training data.')

      return data
    },
    {
      enabled,
      staleTime: 60000,
      keepPreviousData: true,
    },
  )
}

export function usePages(key, enabled = true) {
  return useQuery(
    ['pages', key],
    async () => {
      const params = {
        key,
      }
      const { data } = await axios.get('pages', {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (data && data.status !== 200) throw Error('Unable to load Page data.')

      return data
    },
    {
      staleTime: 60000,
      enabled,
    },
  )
}

export default AtomProvider
