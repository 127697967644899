import React from 'react'

import { printClasses } from '../../Helpers'
import ProductCards from '../structures/ProductCards'

type Props = {
  products: any,
  label?: string,
  cnames?: string,
  titleCnames?: String,
}

FeaturedProducts.defaultProps = {
  label: 'Featured Products',
  cnames: null,
  titleCnames: '',
}

function FeaturedProducts(props: Props) {
  const { products, label, cnames, titleCnames } = props

  return (
    <section className={printClasses([cnames])}>
      {products?.length > 0 && (
        <div className="rpm-featured-products">
          <h4 className={`${titleCnames} text--caps`}>{label}</h4>

          <ProductCards products={products} />
        </div>
      )}
    </section>
  )
}

export default FeaturedProducts
