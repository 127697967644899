import React, { useContext } from 'react'

import { useQueryClient } from 'react-query'
import AtomMarkup from '../../elements/AtomMarkup'

import ClampedText from '../ClampedText'
import ActivityRow from './ActivityRow'

import { usePublicProfile } from '../../../context/atomContext'
import { SocialContext } from '../../../context/socialContext'

import { useOpen10kUserComment } from '../../../routes/parts-for-10k/10kHelpers'

type Props = {
  activity: Object,
}

function MentionActivity(props: Props) {
  const { activity } = props

  // context
  const { openComments } = useContext(SocialContext)
  const open10kUserComment = useOpen10kUserComment()
  const queryClient = useQueryClient()

  const { data: userProfile } = usePublicProfile(
    activity?.itemData?.userTrainingUserId,
    activity?.itemData?.userTrainingUserId !== null,
  )

  const action = Object.is(
    activity.itemKey,
    'EventChallengeUserTrainingMention',
  )
    ? () => {
        queryClient.invalidateQueries([
          'user',
          'comments',
          activity?.itemData?.userTrainingUserId,
          'event/challenge',
        ])

        open10kUserComment({
          ...userProfile.value,
          id: activity?.itemData?.userTrainingUserId,
        })
      }
    : () => {
        queryClient.invalidateQueries([
          'user',
          'comments',
          activity?.userTraining?.id,
          activity?.userTraining?.track,
        ])
        openComments({
          ...activity?.userTraining,
          workshop: activity?.workshop,
        })
      }

  return (
    <ActivityRow
      icon="comment-lines"
      activity={activity}
      action={action}
      avatars={activity?.user}
    >
      {activity?.comment && (
        <ClampedText
          cnames="activity--row--comment ish"
          text={<AtomMarkup plainText>{activity.comment}</AtomMarkup>}
        />
      )}
    </ActivityRow>
  )
}

export default MentionActivity
