import React, { useState, useMemo } from 'react'
import { debounce, printClasses } from '../../Helpers'

import Button from './Button'
import Icon from './Icon'
import IconText from './IconText'

type Props = {
  handleDate: Object,
  selectedDay?: Number,
  eventDates?: any,
  eventIcons?: any,
  allowedDates?: any,
  config?: Object,
  days: Number,
}

DayPicker.defaultProps = {
  selectedDay: 1,
  eventDates: [],
  eventIcons: [],
  allowedDates: [],
  config: {
    tools: ['reset', 'previous', 'next', 'dismiss'],
    minDate: 1,
    maxDate: 35,
  },
}

function DayPicker(props: Props) {
  const {
    selectedDay,
    config,
    handleDate,
    eventDates,
    eventIcons,
    allowedDates,
    days,
  } = props
  const { minDate, maxDate, tools } = config
  const [now, setNow] = useState(parseInt(selectedDay, 10))
  const [activeMode, setActiveMode] = useState('closed')

  const debounceDate = useMemo(
    () =>
      debounce(d => {
        handleDate(d)
      }),
    [handleDate],
  )

  const modeIsClosed = Object.is(activeMode, 'closed')
  const modeIsOpen = !modeIsClosed

  const displayDate = `Day ${now}`
  const displayIcon = modeIsOpen ? null : { name: 'calendar-days', type: 'fal' }

  const hasPrevious = tools === undefined ? true : tools.includes('previous')
  const hasNext = tools === undefined ? true : tools.includes('next')

  const activeDays = [...Array(days)].map((element, index) => {
    const padDay = `${index + 1}`.padStart(2, '0')

    return {
      day: index + 1,
      dayString: padDay,
    }
  })

  const fillerDays = [...Array(Number(7 - (activeDays.length % 7)))].map(
    (element, index) => ({
      kind: 'filler',
      day: `filler-${index}`,
    }),
  )

  const toggleMode = () => setActiveMode(modeIsOpen ? 'closed' : 'open')
  const dismiss = () => setActiveMode('closed')
  const dateIsToday = () => false
  const dayIsSelected = aDay => aDay === now

  const previous = () => {
    let d = now - 1
    if (modeIsClosed && allowedDates.length) {
      const dayIndex = allowedDates.indexOf(now)

      if (dayIndex - 1 > 0) {
        d = allowedDates[dayIndex - 1]
      }
    }

    if (minDate && d < minDate) {
      d = minDate
    }

    setNow(d)

    debounceDate(d)
  }

  const next = () => {
    let d = now + 1

    if (modeIsClosed && allowedDates.length) {
      const dayIndex = allowedDates.indexOf(now)

      if (dayIndex + 1 < allowedDates.length) {
        d = allowedDates[dayIndex + 1]
      }
    }

    if (maxDate && d > maxDate) {
      d = maxDate
    }

    setNow(d)

    debounceDate(d)
  }

  const dayHasEvent = aDay => eventDates.includes(aDay)

  const selectDay = aDay => {
    setNow(aDay)
    handleDate(aDay)
    dismiss()
  }

  const dayExceedsLimits = aDay =>
    (minDate && aDay < minDate) ||
    (maxDate && aDay > maxDate) ||
    (allowedDates.length && !allowedDates.includes(aDay))

  const dayClasses = (aDay: string, classes: string) => [
    classes,
    dateIsToday(aDay) && 'is-today',
    dayIsSelected(aDay) && 'is-selected',
    dayHasEvent(aDay) && 'has-event',
    dayExceedsLimits(aDay) && 'is-out-of-bounds',
  ]

  const calendarButton = (d: Object, classes: string = '') => (
    <button
      className={printClasses(dayClasses(d.day, classes))}
      key={d.day}
      onClick={() => {
        if (d.kind !== 'filler') selectDay(d.day)
      }}
      disabled={d.kind === 'filler' || dayExceedsLimits(d.day)}
    >
      {d.dayString}
      {Array.isArray(eventIcons[d.day]) && (
        <div className="rpm-calendar--day-icons">
          {eventIcons[d.day].map((eventIcon, index) => (
            <Icon
              name={eventIcon.name}
              type={eventIcon.type}
              size={eventIcon.size}
              cnames={
                dayIsSelected(d.day)
                  ? eventIcon.selectedCnames
                  : eventIcon.cnames
              }
              key={String(`${d.day}-${index}`)}
            />
          ))}
        </div>
      )}
    </button>
  )

  const previousIsBeforeMinDate = () => {
    const d = now
    return minDate && d - 1 < minDate
  }

  const nextIsAfterMaxDate = () => {
    const d = now
    return maxDate && d + 1 > maxDate
  }

  const calendarClasses = [
    'rpm-calendar',
    modeIsOpen ? 'is-open-mode' : `is-${activeMode}-mode`,
  ]

  return (
    <div open={modeIsOpen} className={printClasses(calendarClasses)}>
      <div className="rpm-calendar--controls">
        {(hasPrevious || modeIsOpen) && (
          <button
            onClick={previous}
            className="rpm-calendar--control rpm-calendar--control--previous"
            disabled={previousIsBeforeMinDate()}
          >
            <Icon name="chevron-left" type="fas" />
          </button>
        )}

        <button
          onClick={toggleMode}
          className="rpm-calendar--control rpm-calendar--control--toggle rpm-label"
        >
          <IconText icon={displayIcon} text={displayDate} />
        </button>

        {(hasNext || modeIsOpen) && (
          <button
            onClick={next}
            className="rpm-calendar--control rpm-calendar--control--next"
            disabled={nextIsAfterMaxDate()}
          >
            <Icon name="chevron-right" type="fas" />
          </button>
        )}
      </div>

      {modeIsOpen && (
        <>
          <Button
            kind="shield"
            cnames="rpm-calendar--shield"
            onClick={dismiss}
            canRipple={false}
          />

          <div className="rpm-calendar--body">
            <section className="rpm-calendar--grid rpm-calendar--days">
              {activeDays.map(d => calendarButton(d))}
              {fillerDays.map(d =>
                calendarButton(d, 'filler is-out-of-bounds'),
              )}
            </section>
          </div>
        </>
      )}
    </div>
  )
}

export default DayPicker
