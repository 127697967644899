import React, { useState } from 'react'
import { Swiper } from 'swiper/react'
import { Navigation } from 'swiper'

type DashboardSliderProps = {
  children: ReactNode,
  defaultItem: Int,
  details?: ReactNode,
  onActiveIndexChange?: () => void,
  onClick?: () => void,
  onTap?: () => void,
  onSliderMove?: () => void,
  spaceBetween?: Int,
  className?: String,
  swiperProps?: Object,
  width?: String,
  slidesPerView?: Number,
  loop?: Boolean,
  centeredSlides?: Boolean,
  navigation?: Boolean,
  rewind?: Boolean,
}

DashboardSlider.defaultProps = {
  details: undefined,
  onActiveIndexChange: undefined,
  onClick: undefined,
  onTap: undefined,
  onSliderMove: undefined,
  spaceBetween: -8,
  className: undefined,
  swiperProps: {},
  width: undefined,
  slidesPerView: 1.25,
  loop: true,
  centeredSlides: true,
  navigation: true,
  rewind: true,
}

function DashboardSlider(props: DashboardSliderProps) {
  const {
    children,
    defaultItem,
    details,
    onActiveIndexChange,
    onClick,
    onTap,
    onSliderMove,
    spaceBetween,
    className,
    swiperProps,
    width,
    slidesPerView,
    loop,
    centeredSlides,
    navigation,
    rewind,
  } = props
  const [swiperSlideWidth, setSwiperSlideWidth] = useState(undefined)

  const bigScreenSlidesNum = 1.5

  const filteredChildren = children.filter(
    value => value !== null && value !== undefined,
  )

  // Note that to work around an issue in swiperjs we have to actually have #slides >= slidesPerView for loop mode to work
  return (
    <div className={`dashboard-slider ${className || ''}`}>
      <Swiper
        modules={[Navigation]}
        onInit={swip => {
          if (swip.slides.length === 0) return
          setSwiperSlideWidth(swip.slides[swip.realIndex].clientWidth)
          if (onActiveIndexChange) onActiveIndexChange(swip)
        }}
        onResize={swip => {
          if (swip.slides.length === 0) return
          setSwiperSlideWidth(swip.slides[swip.realIndex].clientWidth)
        }}
        onActiveIndexChange={swip => {
          if (onActiveIndexChange) onActiveIndexChange(swip)
        }}
        onSliderMove={swip => {
          if (onSliderMove) onSliderMove(swip)
        }}
        onClick={(swip, event) => {
          if (onClick) onClick(swip, event)
        }}
        onTap={(swip, event) => {
          if (onTap) onTap(swip, event)
        }}
        navigation={navigation}
        centeredSlides={centeredSlides}
        loop={loop && filteredChildren.length >= bigScreenSlidesNum * 2}
        rewind={!loop && rewind}
        spaceBetween={spaceBetween}
        initialSlide={defaultItem > 0 ? defaultItem : 0}
        slidesPerView={slidesPerView}
        breakpoints={
          swiperProps.breakpoints || {
            1024: {
              slidesPerView: bigScreenSlidesNum,
            },
          }
        }
        className="dashboard-slider--swiper"
        style={{
          '--dashboard-slider--slide-width': `${width || swiperSlideWidth}px`,
          overflow: 'visible',
        }}
      >
        {filteredChildren}
      </Swiper>
      {details && (
        <div className="flex--auto-space dashboard-slider--details">
          <div
            style={{
              width: `${width || swiperSlideWidth}px` || '100%',
            }}
          >
            {details}
          </div>
        </div>
      )}
    </div>
  )
}

export default DashboardSlider
