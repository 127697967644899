import React, { useEffect, useRef, useState } from 'react'

import Button from './Button'
import IconText from './IconText'

import { printClasses } from '../../Helpers'

type Props = {
  item: Object,
  isOpen: boolean,
  handleDismiss: Object,
  cnames?: string,
  styles?: Object,
}

Toast.defaultProps = {
  cnames: null,
  styles: {},
}

function Toast(props: Props) {
  const { item, isOpen, handleDismiss, cnames, styles } = props

  const [elementIsOpen, setElementIsOpen] = useState(undefined)
  const [elementIsclosing, setElementIsClosing] = useState(undefined)
  const elementRef = useRef(null)

  const computedStyles =
    elementRef?.current && window.getComputedStyle(elementRef.current)
  const height = computedStyles?.getPropertyValue('height') || '100%'

  const dismissElement = () => {
    setElementIsOpen(false)
    setElementIsClosing(true)
  }

  useEffect(() => {
    setElementIsOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    const element = elementRef.current

    const listener = () => {
      if (!elementIsOpen) {
        setElementIsClosing(false)
        if (item?.onDismiss) item.onDismiss()
        handleDismiss(item.id)
      }
    }

    element?.addEventListener('transitionend', listener)

    return () => {
      element?.removeEventListener('transitionend', listener)
    }
  }, [elementRef, elementIsOpen, handleDismiss, item.id, item])

  const classes = [
    'rpm-toast',
    elementIsOpen ? 'is-open' : 'just-is',
    elementIsclosing && 'is-closing',
    item?.kind && `rpm-toast--${item.kind}`,
    cnames,
  ]

  const getIcon = () => {
    if (item?.icon) return item.icon

    let iconName = 'circle-check'
    if (item?.kind === 'danger') iconName = 'triangle-exclamation'
    else if (item?.kind === 'warning') iconName = 'circle-exclamation'
    return {
      name: iconName,
      type: 'fas',
      size: 'xl',
      subTextCnames: item?.subTextCnames,
    }
  }

  const icon = (
    <IconText
      text={<div className="text--bold">{item.title}</div>}
      subtext={item.label}
      icon={getIcon()}
    />
  )

  return (
    <li
      ref={elementRef}
      className={printClasses(classes)}
      style={{ ...styles, '--height': height }}
    >
      {item?.action ? (
        <Button kind="text" onClick={item?.action} canRipple={false}>
          {icon || item.label}
        </Button>
      ) : (
        <div>{icon || item.label}</div>
      )}
      {item?.canDismiss !== false && (
        <Button
          onClick={dismissElement}
          cnames="rpm-toast--dismiss ghost"
          kind="text"
          icon={{
            name: `times-circle`,
            type: 'fal',
            size: 'lg',
            fw: false,
          }}
          canRipple={false}
        />
      )}
    </li>
  )
}

export default Toast
