import React, { useState } from 'react'

import Button from '../elements/Button'
import IconText from '../elements/IconText'
import Tooltip from '../elements/Tooltip'

import perceivedEfforts from '../../data/perceivedEffort.json'

import {
  formattedScore,
  getMyTrainingScoring,
  kitLabelWithIcon,
  feltLikeChoices,
  formatRawNumber,
} from '../../Helpers'

type Props = {
  data: Object,
  label?: String,
  showCompletedLabel?: boolean,
  isLight?: boolean,
  showHealthEnergy?: boolean,
  showPerceivedExertion?: Boolean,
}

TrainingScore.defaultProps = {
  label: 'Score',
  showCompletedLabel: false,
  isLight: false,
  showHealthEnergy: false,
  showPerceivedExertion: true,
}

function TrainingScore(props: Props) {
  const {
    data,
    label,
    showCompletedLabel,
    isLight,
    showHealthEnergy,
    showPerceivedExertion,
  } = props

  const [showTooltip, setShowTooltip] = useState(false)

  const dismissTooltip = () => setShowTooltip(false)
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const kitObj = kitLabelWithIcon(data?.kit)
  const trainingIcons = kitObj?.icons
    ? [...kitObj?.icons, { type: 'svg', name: data?.level, size: 'lg' }]
    : [{ type: 'svg', name: data?.level, size: 'lg' }]

  return (
    <div className="rpm-training-score">
      {label && <p className="text--small">{label}</p>}

      <p>
        {data.completedOnly && (
          <IconText
            icon={{
              name: 'check-circle',
              type: 'fas',
              fw: false,
            }}
            text={showCompletedLabel && 'Completed'}
          />
        )}

        {!data.completedOnly &&
          (data.beatCap || data?.track === 'manual') &&
          formattedScore(data.score, getMyTrainingScoring(data))}

        {!data.completedOnly && !data.beatCap && data.tiebreak}
      </p>

      {data.totals?.combined && (
        <span className="is-flex--column flex--auto-spread">
          <p className="flex--auto-gap">
            <span className="fg--light">Total </span>
            {formatRawNumber(data.totals?.combined || 0)}
          </p>
          <IconText
            icon={{ name: 'chevron-up', type: 'fal', cnames: 'fg--light' }}
            text={formatRawNumber(data.totals?.singles || 0)}
            cnames="flex--auto-spread"
          />
          <IconText
            icon={{ name: 'chevrons-up', type: 'fal', cnames: 'fg--light' }}
            text={formatRawNumber(data.totals?.doubles || 0)}
            cnames="flex--auto-spread"
          />
        </span>
      )}

      <p className="text--small">
        {data?.kit && (
          <IconText
            icons={trainingIcons}
            text=""
            cnames={`user--meta--kit ${
              isLight ? 'user--meta--kit--light' : ''
            }`}
          />
        )}
      </p>
      {data?.scalingScore && (
        <p className="text--smaller ish">
          Scaling Score = {parseFloat(data.scalingScore, 10).toFixed(2)}
        </p>
      )}
      {!data?.isCurrentTraining && (
        <>
          <Tooltip
            isOpen={showTooltip}
            handleDismiss={dismissTooltip}
            cnames="text--no-transform text--left text--body"
            hasArrow={false}
            message="The original programming for this day was amended by a coach after you logged your workout. Your results are safe, but any leaderboard status will now show as “complete”."
          />
          <p className="text--smaller ish">
            <IconText
              text="Amended Workout"
              icon={{
                name: 'circle-exclamation',
                type: 'fas',
                size: 'xl',
              }}
            />

            <Button
              onClick={toggleTooltip}
              kind="text"
              icon={{ name: 'circle-question', type: 'fal' }}
              label=""
            />
          </p>
        </>
      )}

      {showPerceivedExertion && parseInt(data?.perceivedEffort, 10) > 0 && (
        <p className="text--smaller ghost">
          Perceived Exertion ={' '}
          {perceivedEfforts.find(p => p.value === data?.perceivedEffort).label}
        </p>
      )}
      {showHealthEnergy && parseInt(data?.feltLike, 10) > 0 && (
        <p className="text--smaller ghost">
          Health/Energy ={' '}
          {feltLikeChoices.find(p => p.value === data?.feltLike).label}
        </p>
      )}
    </div>
  )
}

export default TrainingScore
