import React from 'react'
import Button from '../elements/Button'
import IconText from '../elements/IconText'

import { formatRawNumber } from '../../Helpers'

type Props = {
  action: any,
  count: number,
  isLikeUser: boolean,
  isDisabled?: boolean,
}

ButtonLikes.defaultProps = {
  isDisabled: false,
}

function ButtonLikes(props: Props) {
  const { action, count, isLikeUser, isDisabled } = props

  return (
    <Button
      kind="icon"
      onClick={action}
      disabled={isDisabled}
      cnames={isLikeUser && 'is-liked'}
    >
      <IconText
        icon={{
          name: 'thumbs-up',
          type: isLikeUser ? 'fas' : 'fal',
        }}
        text={formatRawNumber(count)}
        cnames="user--meta--likes"
      />
    </Button>
  )
}

export default ButtonLikes
