import React from 'react'
import { withRouter } from 'react-router-dom'

function TermsOfUse() {
  const lastModifiedDate = '5/27/2021'

  return (
    <article className="app-content">
      <section className="app-content--inner rpm-block">
        <h2>Web Site Terms and Conditions of Use </h2>
        <h3>1. Terms</h3>

        <p>
          By accessing this web site, you are agreeing to be bound by these web
          site Terms and Conditions of Use, all applicable laws and regulations,
          and agree that you are responsible for compliance with any applicable
          local laws. If you do not agree with any of these terms, you are
          prohibited from using or accessing this site. The materials contained
          in this web site are protected by applicable copyright and trade mark
          law.
        </p>
      </section>

      <section className="app-content--inner rpm-block">
        <h3>2. Use License</h3>

        <ol type="a">
          <li>
            Permission is granted to temporarily download one copy of the
            materials (information or software) on RPM Fitness, Inc&rsquo;s web
            site for personal, non-commercial transitory viewing only. This is
            the grant of a license, not a transfer of title, and under this
            license you may not:
            <ol type="i">
              <li>modify or copy the materials;</li>

              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </li>

              <li>
                attempt to decompile or reverse engineer any software contained
                on RPM Training Co&rsquo;s web site;
              </li>

              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>

              <li>
                transfer the materials to another person or &ldquo;mirror&rdquo;
                the materials on any other server.
              </li>
            </ol>
          </li>

          <li>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by RPM Training Co at any
            time. Upon terminating your viewing of these materials or upon the
            termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed
            format.
          </li>
        </ol>
      </section>

      <section className="app-content--inner rpm-block">
        <h3>3. Disclaimer</h3>

        <ol type="a">
          <li>
            The materials on RPM Training Co&rsquo;s web site are provided
            &ldquo;as is&rdquo;. RPM Training Co makes no warranties, expressed
            or implied, and hereby disclaims and negates all other warranties,
            including without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property or other violation of
            rights. Further, RPM Training Co does not warrant or make any
            representations concerning the accuracy, likely results, or
            reliability of the use of the materials on its Internet web site or
            otherwise relating to such materials or on any sites linked to this
            site.
          </li>
        </ol>
      </section>

      <section className="app-content--inner rpm-block">
        <h3>4. Limitations</h3>

        <p>
          In no event shall RPM Training Co or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption,) arising out of the use or
          inability to use the materials on RPM Training Co&rsquo;s Internet
          site, even if RPM Training Co or a RPM Fitness, Inc authorized
          representative has been notified orally or in writing of the
          possibility of such damage. Because some jurisdictions do not allow
          limitations on implied warranties, or limitations of liability for
          consequential or incidental damages, these limitations may not apply
          to you.
        </p>
      </section>

      <section className="app-content--inner rpm-block">
        <h3>5. Revisions and Errata</h3>

        <p>
          The materials appearing on RPM Training Co&rsquo;s web site could
          include technical, typographical, or photographic errors. RPM Training
          Co does not warrant that any of the materials on its web site are
          accurate, complete, or current. RPM Training Co may make changes to
          the materials contained on its web site at any time without notice.
          RPM Training Co does not, however, make any commitment to update the
          materials.
        </p>
      </section>

      <section className="app-content--inner rpm-block">
        <h3>6. Links</h3>

        <p>
          RPM Training Co has not reviewed all of the sites linked to its
          Internet web site and is not responsible for the contents of any such
          linked site. The inclusion of any link does not imply endorsement by
          RPM Fitness, Inc of the site. Use of any such linked web site is at
          the user&rsquo;s own risk.
        </p>
      </section>

      <section className="app-content--inner rpm-block">
        <h3>7. Site Terms of Use Modifications</h3>

        <p>
          RPM Training Co may revise these terms of use for its web site at any
          time without notice. By using this web site you are agreeing to be
          bound by the then current version of these Terms and Conditions of
          Use.
        </p>
      </section>

      <section className="app-content--inner rpm-block">
        <h3>8. Governing Law</h3>

        <p>
          Any claim relating to RPM Training Co&rsquo;s web site shall be
          governed by the laws of the State of California without regard to its
          conflict of law provisions.
        </p>

        <p>General Terms and Conditions applicable to Use of a Web Site.</p>
      </section>

      <section className="app-content--inner rpm-block">
        <h3>9. Privacy Policy</h3>

        <p>
          Your privacy is very important to us. Accordingly, we have developed
          this Policy in order for you to understand how we collect, use,
          communicate and disclose and make use of personal information. The
          following outlines our privacy policy.
        </p>

        <p>
          <strong>Privacy policy</strong> <br /> This Privacy Policy outlines
          the types of information RPM Training (referred to from here on as
          &ldquo;we,&rdquo; &ldquo;our,&rdquo; and &ldquo;us&rdquo;)collects
          while you are using our website. This Privacy Policy does not apply to
          information that we gather from you offlineor by other means (for
          example, over the phone or through e-mail). By using our Site, you
          consent to our privacy policy.Please read this privacy policy
          carefully.
        </p>

        <p>
          <strong>What information do we collect?</strong> <br />
          We collect information from you when you subscribe to our newsletter
          or fill out a form. When ordering or registering on our site, as
          appropriate, you may be asked to enter your: name or e-mail address.
          Youmay, however, visit our site anonymously.
        </p>

        <p>
          <strong>What do we use information for?</strong> <br />
          Any of the information we collect from you may be used in one of the
          following ways:
        </p>

        <ul>
          <li>
            To build your beauty profile and make personalized recommendations.
          </li>
          <li>
            To improve our website. We continually strive to improve our website
            offerings based on the information and feedback we receive from you.
          </li>
          <li>
            To improve customer service. Your information helps us to more
            effectively respond to your customer service requests and support
            needs.
          </li>
          <li>
            To send periodic emails. The email address you provide when you sign
            up for our newsletter may be used to sendyou information and
            updates, including occasional company news, updates, marketing
            emails, related product orservice information, etc. Note: If at any
            time you would like to unsubscribe from receiving future emails, we
            include unsubscribe instructions at the bottom of each email.
          </li>
        </ul>

        <p>
          <strong>How do we protect your information?</strong> <br />
          We implement a variety of security measures to maintain the safety of
          your personal information when you enter, submit,or access your
          personal information.
        </p>

        <p>
          We offer the use of a secure server. All supplied sensitive/credit
          information is transmitted via Secure Socket Layer (SSL)technology and
          then encrypted into our Payment gateway providers database only to be
          accessible by those authorizedwith special access rights to such
          systems, and are required to keep the information confidential.
        </p>

        <p>
          We use cookies to compile aggregate data about site traffic and site
          interaction so that we can offer better siteexperiences and tools in
          the future. All of this information is non-personally identifiable. We
          may contract with third-partyservice providers like Google Analytics
          to assist us in better understanding our site visitors. These service
          providers arenot permitted to use the information collected on our
          behalf except to help us conduct and improve our business.
        </p>

        <p>
          <strong>Do we disclose any information to outside parties?</strong>
          <br />
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information. This does notinclude trusted
          third parties who assist us in operating our website, conducting our
          business, or servicing you, so long asthose parties agree to keep this
          information confidential. We may also release your information when we
          believe releaseis appropriate to comply with the law, enforce our site
          policies, or protect ours or others rights property, or safety.
          However,non-personally identifiable visitor information may be
          provided to other parties for marketing, advertising, or other uses.
        </p>

        <p>
          <strong>Third party links</strong> <br /> Occasionally, at our
          discretion, we may include or offer third party products or services
          on our website. These third partysites have separate and independent
          privacy policies. We therefore have no responsibility or liability for
          the content andactivities of these linked sites. Nonetheless, we seek
          to protect the integrity of our site and welcome any feedback
          aboutthese sites.
        </p>

        <p>
          <strong>California Online Privacy Protection Act Compliance</strong>
          <br /> Because we value your privacy we have taken the necessary
          precautions to be in compliance with the California OnlinePrivacy
          Protection Act. We therefore will not distribute your personal
          information to outside parties without your consent.
        </p>

        <p>
          <strong>
            Children&rsquo;s Online Privacy Protection Act Compliance
          </strong>
          <br /> We are in compliance with the requirements of COPPA
          (Children&rsquo;s Online Privacy Protection Act). We do not collect
          anyinformation from anyone under 13 years of age. Our website,
          products, and services are all directed to people who are atleast 13
          years or older.
        </p>

        <p>
          <strong>Your consent</strong> <br />
          By using our site, you consent to our web site privacy policy.
        </p>

        <p>Changes to our Privacy Policy</p>

        <p>
          If we decide to change our privacy policy, we will post those changes
          on this page, and/or update the Privacy Policy modification date
          below.
        </p>

        <p>This policy was last modified on {lastModifiedDate}.</p>

        <p>
          Contacting Us <br /> If there are any questions regarding this privacy
          policy you may contact us{' '}
          <a href="mailto:support@rpmtraining.com">support@rpmtraining.com</a>
        </p>
      </section>

      <section className="app-content--inner rpm-block">
        <h3>10. Accessibility</h3>

        <p>
          If you have difficulty using or accessing any services on the RPM
          Training Co. platform, please contact us at support@rpmtraining.com.
          We will work with you to provide assistance using any method that is
          accessible for you consistent with applicable law (for example,
          through telephone support).
        </p>

        <p>
          RPM Training Co. is committed to making our website&rsquo;s content
          and services accessible to everyone, including those with
          disabilities. Our site conforms with the Web Content Accessibility
          Guidelines (WCAG) 2.1, Level A and AA success criteria, developed by
          the World Wide Web Consortium. If you have any feedback for how we can
          improve our accessibility, email us anytime at{' '}
          <a href="mailto:support@rpmtraining.com">support@rpmtraining.com</a>.
          Please let us know the specific element or item that caused difficulty
          and the nature of the access need.
        </p>
      </section>
    </article>
  )
}

export default withRouter(TermsOfUse)
