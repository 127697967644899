import React from 'react'
import { Link } from 'react-router-dom'

import Icon from '../../elements/Icon'

type Props = {
  item: Object,
}

function RouteResource(props: Props) {
  const { item } = props
  const { href, label, icon } = item
  const { type, name } = icon || {}

  return (
    <Link to={href} className="resource--row is-route">
      <span className="resource--row--label">{label}</span>
      <Icon
        type={type || 'fal'}
        name={name || 'arrow-circle-right'}
        cnames="resource--row--icon"
      />
    </Link>
  )
}

export default RouteResource
