import React from 'react'

import UserProfileRow from './UserProfileRow'

import Button from '../elements/Button'
import IconText from '../elements/IconText'

import ButtonMembers from '../molecules/ButtonMembers'

import { useStatus, useLeaveTeam } from '../../context/atomContext'

import { formatRawNumber, printClasses } from '../../Helpers'

type Props = {
  groups: Object,
  openGroupProfile: () => mixed,
  allowIgnore?: boolean,
  action?: mixed,
}

ListGroups.defaultProps = {
  allowIgnore: false,
  action: null,
}

function ListGroups(props: Props) {
  // State
  const { groups, openGroupProfile, allowIgnore, action } = props

  // API
  const status = useStatus()
  const leaveTeam = useLeaveTeam()

  // Functions
  const translateGroup = group => ({
    name: group.name,
    username: group.name,
    profileImage: group.image,
  })

  return (
    <>
      {groups?.length > 0 &&
        groups.map(group => (
          <UserProfileRow
            key={group.id}
            user={translateGroup(group)}
            avatarSize={3}
            showKitLevel={false}
            openProfile={() => openGroupProfile(group.id)}
            action={
              action ||
              (allowIgnore && (
                <Button
                  kind="link"
                  onClick={() => {
                    leaveTeam.mutate({
                      teamId: group.id,
                      userId: status?.user?.id,
                    })
                  }}
                >
                  <IconText
                    icon={{
                      type: 'fal',
                      name: 'trash-alt',
                      fw: false,
                    }}
                    text="Ignore"
                    cnames="text--small text--caps text--normal"
                  />
                </Button>
              ))
            }
          >
            <p
              className={printClasses([
                'user--meta--kcl',
                'flex--auto-gap',
                'text--small',
                'text--caps',
              ])}
            >
              <ButtonMembers
                action={() => openGroupProfile(group.id)}
                count={group?.memberCount}
              />

              {group?.raised > 0 && (
                <Button kind="icon" onClick={() => openGroupProfile(group.id)}>
                  <IconText
                    icon={{ type: 'svg', name: 'iron-compass' }}
                    text={`$${formatRawNumber(group?.raised?.toString()) || 0}`}
                    cnames="user--meta--raised"
                  />
                </Button>
              )}
            </p>
          </UserProfileRow>
        ))}
    </>
  )
}

export default ListGroups
