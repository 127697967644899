import React from 'react'
import PropTypes from 'prop-types'

type ErrorBoundryProps = {
  children: PropTypes.node.isRequired,
}

export default class ErrorBoundary extends React.Component<ErrorBoundryProps> {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error)
    return { hasError: true }
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            padding: '10em 2em',
            height: '100vh',
            width: '100vw',
            textAlign: 'center',
            background: '#e9e9ec',
            color: 'black',
          }}
        >
          <img
            src="/app/images/atom-black.png"
            alt="atom logo"
            style={{ width: '100%', maxWidth: 414 }}
          />
          <p>
            We&apos;re sorry, the page you were looking for couldn&apos;t be
            loaded.
          </p>
          <p>
            Our team is investigating the issue. Please try again later or
            contact our support team (
            <a href="mailto:support@trainatom.com">support@trainatom.com</a>) if
            the problem persists.
          </p>
          <h3>
            <a href="/app/dashboard">Dashboard</a>
          </h3>
        </div>
      )
    }

    return children
  }
}
