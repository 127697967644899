import React from 'react'
import Icon from './Icon'
import { printClasses } from '../../Helpers'

type Props = {
  image?: string,
  icon?: Object,
  flag?: Object,
  badge?: Object,
  label?: string,
  children?: any,
  cnames?: string,
  styles?: Object,
  size?: string | number,
  isIcon?: boolean,
}

Avatar.defaultProps = {
  image: null,
  icon: {},
  flag: {},
  badge: {},
  label: '',
  children: null,
  cnames: null,
  styles: null,
  size: null,
  isIcon: false,
}

function Avatar(props: Props) {
  const {
    label,
    image,
    icon,
    flag,
    badge,
    children,
    cnames,
    styles,
    size,
    isIcon,
  } = props

  const {
    name: iconName,
    type: iconType,
    size: iconSize,
    fw: iconFw,
    cnames: iconCnames,
  } = icon

  const {
    name: flagName,
    type: flagType,
    size: flagSize,
    fw: flagFw,
    cnames: flagCnames,
  } = flag

  const {
    name: badgeName,
    type: badgeType,
    size: badgeSize,
    fw: badgeFw,
    cnames: badgeCnames,
  } = badge

  const avatarString = () => {
    const nameParts = label?.toString().trim().split(' ') || []

    if (nameParts.length === 1) {
      return nameParts[0][Symbol.iterator]().next().value
    }

    const finalAvatarString = `${
      nameParts[0][Symbol.iterator]().next().value || ''
    }${nameParts[nameParts.length - 1][Symbol.iterator]().next().value || ''}`

    return nameParts.length > 1 && typeof finalAvatarString === 'string'
      ? finalAvatarString.toUpperCase()
      : ''
  }

  const avatarIcon = () =>
    (iconName && iconType && (
      <Icon
        name={iconName}
        type={iconType}
        size={iconSize}
        fw={iconFw}
        cnames={iconCnames}
      />
    )) ||
    null

  const avatarFlag = () =>
    (flagName && (
      <Icon
        name={flagName}
        type={flagType || 'flag'}
        size={flagSize}
        fw={flagFw}
        cnames={flagCnames}
      />
    )) ||
    null

  const avatarBadge = () =>
    (badgeName && (
      <Icon
        name={badgeName}
        type={badgeType || 'far'}
        size={badgeSize}
        fw={badgeFw}
        cnames={printClasses(['badge-icon', badgeCnames])}
      />
    )) ||
    null

  const classes = [
    cnames,
    'avatar',
    size && `avatar--${parseInt(size, 10)}x`,
    image && 'has-image',
    isIcon && avatarIcon() && 'is-icon',
    ((!isIcon && avatarIcon()) || avatarFlag() || avatarBadge()) && 'has-icon',
    avatarFlag() && 'has-flag-icon',
    avatarBadge() && 'has-badge-icon',
  ]

  const avatar =
    avatarIcon() && isIcon ? avatarIcon() : children || avatarString() || '...'

  return (
    <div
      className={printClasses(classes)}
      style={{ ...styles, '--bg-image': image && `url(${image})` }}
      data-testid="avatar-main"
    >
      {avatar}
      {!isIcon && avatarIcon()}
      {avatarFlag()}
      {avatarBadge()}
    </div>
  )
}

export default Avatar
