import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  useOpen10kUserComment,
  use10kToggleUserLike,
  jumpData,
} from './10kHelpers'

import { useProfile, useStatus } from '../../context/atomContext'
import { SocialContext } from '../../context/socialContext'

import { formatRawNumber, printClasses, dateMe } from '../../Helpers'

import Button from '../../components/elements/Button'

import ButtonComments from '../../components/molecules/ButtonComments'
import ButtonLikes from '../../components/molecules/ButtonLikes'

import UserProfileRow from '../../components/structures/UserProfileRow'

import DailyJumps from './ChartDailyJumps'
import JumpProgress from './ChartJumpProgress'
import JumpStats from './JumpStats'
import YearlyJumps from './ChartYearlyJumps'

type Props = {
  history: Object,
  openScoreForm?: () => {},
  showFinisher?: () => {},
  journalData?: Object,
  refetchData: () => mixed,
  date: string,
  workout?: Object,
  children?: mixed,
}

MyChallenge.defaultProps = {
  openScoreForm: () => {},
  showFinisher: () => {},
  journalData: null,
  workout: null,
  children: null,
}

function MyChallenge(props: Props) {
  // State
  const {
    history,
    openScoreForm,
    showFinisher,
    journalData,
    refetchData,
    date,
    workout,
    children,
  } = props

  // Temp
  // Context
  const { openProfile } = useContext(SocialContext)
  const location = useLocation()

  // API
  const status = useStatus()
  const { data: myProfile, isLoading: isLoadingProfile } = useProfile()
  const open10kUserComment = useOpen10kUserComment()
  const toggle10kUserLike = use10kToggleUserLike()

  // Variables
  const allDoubleUnders = journalData?.totals.doubles
  const allSingleUnders = journalData?.totals.singles
  const allJumps = journalData?.totals.combined
  const dayData = journalData?.rows?.find(day => day?.date === date)

  const myJumps = [
    {
      id: 'doubles',
      value: (dayData?.doubles > 0 && dayData?.doubles) || null,
      isBold: true,
      divish: false,
      labelish: true,
      label: 'Double-unders',
      icon: { name: 'chevrons-up', type: 'fal' },
    },
    {
      id: 'singles',
      value: (dayData?.singles > 0 && dayData?.singles) || null,
      isBold: true,
      divish: false,
      labelish: true,
      label: 'Single-unders',
      icon: { name: 'chevron-up', type: 'fal' },
    },
  ]

  const jumpStats = [
    {
      id: 'total',
      value: (allJumps > 0 && allJumps) || null,
      isBold: true,
      divish: false,
      labelish: true,
      label: 'Total Jumps',
    },
    {
      id: 'doubles',
      value: (allDoubleUnders > 0 && allDoubleUnders) || null,
      isBold: false,
      divish: true,
      labelish: false,
      label: 'Double-unders',
      icon: { name: 'chevrons-up', type: 'fal' },
    },
    {
      id: 'singles',
      value: (allSingleUnders > 0 && allSingleUnders) || null,
      isBold: false,
      divish: true,
      labelish: false,
      label: 'Single-unders',
      icon: { name: 'chevron-up', type: 'fal' },
    },
  ]

  const yearlyTotals = [
    { total: journalData?.userData?.[2016]?.totals?.combined, label: '2016' },
    { total: journalData?.userData?.[2017]?.totals?.combined, label: '2017' },
    { total: journalData?.userData?.[2018]?.totals?.combined, label: '2018' },
    { total: journalData?.userData?.[2020]?.totals?.combined, label: '2020' },
    { total: journalData?.userData?.[2021]?.totals?.combined, label: '2021' },
    { total: journalData?.userData?.[2022]?.totals?.combined, label: '2022' },
    { total: journalData?.userData?.[2023]?.totals?.combined, label: '2023' },
  ]
  const yearsParticipated = yearlyTotals.reduce(
    (tot, cur) => (cur?.total !== undefined ? tot + 1 : tot),
    0,
  )

  const daysToJump = () => {
    const end = dayjs('2023-01-02').add(31, 'days')
    const now = dayjs()
    let diff = end.diff(now, 'day')
    if (diff > 30) diff = 30

    return (diff >= 0 ? diff : 0).toString().padStart(2, '0')
  }

  const sponsor = workout?.sponsor
  const showDaysToJump = true

  // Functions
  const goToLeaderboard = () => {
    history.push('/events/10k/leaderboard', {
      returnTo: location,
    })
  }

  return (
    <section className="my-stats">
      {children}

      {(myProfile || isLoadingProfile) && (
        <UserProfileRow
          user={myProfile?.value}
          isMe
          avatarSize={3}
          showKitLevel={false}
          openProfile={() => openProfile(status?.user?.id)}
        >
          <div
            className={printClasses([
              'flex--auto-spread',
              'text--small',
              'text--caps',
            ])}
          >
            <p className={printClasses(['user--meta--kcl', 'flex--auto-gap'])}>
              <span>
                Today{' '}
                <b>
                  {(Number(journalData?.totals?.today) > 0 &&
                    formatRawNumber(journalData?.totals?.today)) ||
                    '---'}
                </b>
              </span>

              <ButtonComments
                action={
                  () =>
                    open10kUserComment({
                      ...myProfile?.value,
                      title: myProfile?.value?.username,
                      id: status?.user?.id,
                      onCommentSuccess: () => refetchData(),
                    })
                  // socialContext.openComments({
                  //   ...myProfile?.value,
                  //   id: me.userid,
                  //   track: 'event/challenge',
                  //   config: { commentIdName: 'userTrainingUserId' },
                  //   onCommentSuccess: () => console.log('refetch data'),
                  // })
                }
                count={journalData?.commentCount}
              />

              <ButtonLikes
                action={() =>
                  toggle10kUserLike(status?.user?.id, {
                    onSuccess: () => refetchData(),
                  })
                }
                count={journalData?.likeCount}
                isLikeUser={journalData?.isLikeUser}
              />
            </p>

            <p>
              <b>{formatRawNumber(allJumps)}</b>
            </p>
          </div>
        </UserProfileRow>
      )}

      {journalData?.totals?.finishDate && (
        <section className="section-block">
          <div className="rpm-description text--caps text--center text--link">
            <Button
              kind="text"
              label={`Finished! ${dateMe(
                journalData?.totals?.finishDate,
                'title',
              )}`}
              icon={{ name: 'trophy', type: 'far', size: 'lg' }}
              onClick={showFinisher}
              cnames="text--bold"
            />
          </div>
        </section>
      )}

      <section className="section-block">
        <div className="rpm-description">
          <h5 className="text--caps">My Jumps</h5>
          <JumpStats data={myJumps} />
        </div>

        <div className="rpm-button-block is-shaded">
          <Button
            kind="primary"
            label="Log Jumps"
            onClick={() => openScoreForm()}
            disabled
          />
          <Button
            kind="primary"
            label="View Leaderboard"
            onClick={() => goToLeaderboard()}
            disabled={openScoreForm === null}
          />
        </div>
      </section>

      <section className="section-block jump-stats">
        <div className="rpm-description">
          <h5 className="text--caps">My Jump Stats</h5>
          <JumpStats data={jumpStats} />
        </div>

        <div className="rpm-description">
          <JumpProgress value={allJumps} goal={10000} />
        </div>

        <div className="rpm-description">
          <DailyJumps data={jumpData(journalData?.userData?.[2023]?.daily)} />
        </div>

        {yearsParticipated > 1 && (
          <div className="rpm-description">
            <YearlyJumps data={yearlyTotals} abbreviate />
          </div>
        )}
      </section>

      {showDaysToJump && (
        <section className="section-block days-to-jump">
          <div className="rpm-description flex--auto-spread">
            <div className="days-to-jump--sponsor">
              {sponsor?.img && (
                <img src={sponsor.img} alt={sponsor.name || 'sponsor'} />
              )}
            </div>
            <div className="days-to-jump--countdown text--caps text--bold">
              <small>Days To Jump </small>
              <span className="flip-character">{daysToJump()[0]}</span>
              <span className="flip-character">{daysToJump()[1]}</span>
            </div>
          </div>

          {sponsor && <div className="days-to-jump--breaker-bar" />}
        </section>
      )}
    </section>
  )
}

export default MyChallenge
