import React from 'react'

import IconText from '../../components/elements/IconText'
import Markdown from '../../components/elements/Markdown'

type Props = {
  sheet: Object,
}

function StatSheet(props: Props) {
  const { sheet } = props

  return (
    <>
      <div className="affiliates--overview--statsheet">
        <div className="rpm-block">
          <h5 className="text--caps">Skill Level</h5>
          <ul className="un-list flex--auto-gap">
            <li>
              <IconText
                icon={{
                  name: 'circle',
                  type: 'fas',
                }}
                text={`Low (${sheet.low})`}
                cnames="skill-level--low"
              />
            </li>
            <li>
              <IconText
                icon={{
                  name: 'circle',
                  type: 'fas',
                }}
                text={`Med (${sheet.med})`}
                cnames="skill-level--med"
              />
            </li>
            <li>
              <IconText
                icon={{
                  name: 'circle',
                  type: 'fas',
                }}
                text={`High (${sheet.high})`}
                cnames="skill-level--high"
              />
            </li>
          </ul>

          <h3 className="text--small-caps">
            <IconText
              icon={{
                name: 'chart-mixed',
                type: 'fas',
              }}
              text="Benchmarks"
            />
          </h3>
          <Markdown className="rpm-md">{sheet.benchmarks}</Markdown>

          <h3 className="text--small-caps">
            <IconText
              icon={{
                name: 'wrench',
                type: 'fas',
              }}
              text="Projects"
            />
          </h3>

          <Markdown className="rpm-md">{sheet.projects}</Markdown>
        </div>
      </div>
    </>
  )
}

export default StatSheet
