import React, { useContext } from 'react'

import ActivityRow from './ActivityRow'

import { SocialContext } from '../../../context/socialContext'

type Props = {
  activity: Object,
}

function InviteActivity(props: Props) {
  const { activity } = props

  // context
  const { openGroupProfile } = useContext(SocialContext)

  const avatar = activity?.team && {
    id: activity?.id,
    image: activity?.team?.image,
    name: activity?.team?.name,
  }

  return (
    <ActivityRow
      icon="envelope"
      activity={activity}
      action={() => {
        openGroupProfile(activity?.team)
      }}
      avatars={avatar}
    />
  )
}

export default InviteActivity
