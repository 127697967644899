import React from 'react'

import DetailsBlock from '../../components/structures/DetailsBlock'

import IconText from '../../components/elements/IconText'
import Markdown from '../../components/elements/Markdown'

type Props = {
  visibleSelectedKit: Object,
  downloadUrl?: String,
}

CoachesTrainingDetails.defaultProps = {
  downloadUrl: undefined,
}

function CoachesTrainingDetails(props: Props) {
  const { visibleSelectedKit, downloadUrl } = props

  return (
    <>
      {visibleSelectedKit?.coaching?.design && (
        <DetailsBlock
          label="Workout Design & Logistics"
          cnames="rpm-description training--info-block"
        >
          <Markdown>{visibleSelectedKit.coaching.design}</Markdown>
        </DetailsBlock>
      )}

      {visibleSelectedKit?.scoringNotes && (
        <DetailsBlock
          label="Scoring"
          cnames="rpm-description training--info-block"
        >
          <Markdown>{visibleSelectedKit.scoringNotes}</Markdown>
        </DetailsBlock>
      )}

      {visibleSelectedKit?.coaching?.goal && (
        <DetailsBlock
          label="Daily Training Goal"
          cnames="rpm-description training--info-block"
        >
          <Markdown>{visibleSelectedKit.coaching.goal}</Markdown>
        </DetailsBlock>
      )}

      {visibleSelectedKit?.coaching?.scaling && (
        <DetailsBlock
          label="Scaling"
          cnames="rpm-description training--info-block"
        >
          <Markdown>{visibleSelectedKit.coaching.scaling}</Markdown>
        </DetailsBlock>
      )}

      {visibleSelectedKit?.coaching?.study && (
        <DetailsBlock
          label="For Further Study"
          cnames="rpm-description training--info-block"
        >
          <Markdown>{visibleSelectedKit.coaching.study}</Markdown>
        </DetailsBlock>
      )}

      {visibleSelectedKit?.coaching?.warmup && (
        <DetailsBlock
          label="Brief & Warmup"
          cnames="rpm-description training--info-block"
        >
          <Markdown>{visibleSelectedKit.coaching.warmup}</Markdown>
        </DetailsBlock>
      )}

      {visibleSelectedKit?.coaching?.prep && (
        <DetailsBlock
          label="Workout Prep"
          cnames="rpm-description training--info-block"
        >
          <Markdown>{visibleSelectedKit.coaching.prep}</Markdown>
        </DetailsBlock>
      )}

      {visibleSelectedKit?.coaching?.workout && (
        <DetailsBlock
          label="Workout"
          cnames="rpm-description training--info-block"
        >
          <Markdown>{visibleSelectedKit.coaching.workout}</Markdown>
        </DetailsBlock>
      )}

      {visibleSelectedKit?.coaching?.recovery && (
        <DetailsBlock
          label="Recovery"
          cnames="rpm-description training--info-block"
        >
          <Markdown>{visibleSelectedKit.coaching.recovery}</Markdown>
        </DetailsBlock>
      )}

      {downloadUrl && (
        <article>
          <footer
            style={{
              padding: 'var(--block-padding)',
            }}
          >
            <p className="text--small-caps text--center">
              <a
                href={downloadUrl}
                target="_blank"
                className="rpm-button rpm-button--primary rpm-button--outlined rpm-button--thin"
                rel="noreferrer"
              >
                <IconText
                  icon={{
                    name: 'file-arrow-down',
                    type: 'far',
                    size: 'xl',
                  }}
                  text="Download &amp; Print Workout"
                />
              </a>
            </p>
          </footer>
        </article>
      )}
    </>
  )
}

export default CoachesTrainingDetails
