import React, { useState, useContext } from 'react'

import Button from '../../components/elements/Button'
import IconText from '../../components/elements/IconText'
import Checkbox from '../../components/inputs/Checkbox'
import RadioGroup from '../../components/inputs/RadioGroup'
import InputGroup from '../../components/inputs/InputGroup'

import UserProfileHeader from '../../components/structures/UserProfileHeader'

import { checkIsMe } from '../../Helpers'
import { memberTypes } from './AffiliatesHelpers'

import { SocialContext } from '../../context/socialContext'
import { useStatus } from '../../context/atomContext'

type Props = {
  member: Object,
  dismiss: mixed,
  update: mixed,
  remove: mixed,
  isAdmin: boolean,
}

function EditMember(props: Props) {
  const status = useStatus()

  // State
  const { member, dismiss, update, remove, isAdmin } = props
  const [memberType, setMemberType] = useState(member?.role)
  const [doRemove, setDoRemove] = useState(false)

  const { openProfile } = useContext(SocialContext)

  const memberTypeOptions = memberTypes.map(option => ({
    id: `edit-${option.id}`,
    label: (
      <IconText
        icon={{ name: option.icon.name, type: option.icon.type }}
        text={option.label}
      />
    ),
    value: option.value,
    selected: false,
  }))

  const handleRemoveToggle = event => setDoRemove(event.target.checked)

  return (
    <>
      <UserProfileHeader user={member} context="dialog" />

      <section className="flex--auto-spread text--small text--caps">
        <Button kind="link" onClick={() => openProfile(member.id)}>
          <IconText
            icon={{ name: 'image-user', type: 'far' }}
            text="View Profile"
          />
        </Button>
        <Checkbox
          id="removeMember"
          name="removeMember"
          label="Remove Member"
          checked={doRemove}
          changed={event => handleRemoveToggle(event)}
          disabled={checkIsMe(status, member, 'id') || !isAdmin}
          cnames="text--danger"
        />
      </section>

      <section>
        <RadioGroup
          label="Member Type"
          selectedValue={memberType}
          options={memberTypeOptions}
          handleChange={event => setMemberType(event.target.value)}
          disabled={doRemove || checkIsMe(status, member, 'id') || !isAdmin}
        />
      </section>

      <footer>
        <InputGroup columns="1fr 1fr">
          <Button label="Cancel" kind="cancel" onClick={dismiss} />
          {doRemove ? (
            <Button
              label="Remove"
              kind="danger"
              onClick={() => remove(member.id)}
              disabled={!isAdmin}
            />
          ) : (
            <Button
              label="Update"
              kind="primary"
              onClick={() => update({ ...member, role: memberType })}
              disabled={!isAdmin}
            />
          )}
        </InputGroup>
      </footer>
    </>
  )
}
export default EditMember
