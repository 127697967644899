import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../elements/Button'
import Icon, { PlayIcon } from '../elements/Icon'
import IconText from '../elements/IconText'
import VideoPlayer from './VideoPlayer'
import WorkshopDetails from '../screens/Dashboard/WorkshopDetails'

type WorkshopItemProps = {
  workshop: Object,
  setShowSubDialog: (val: string | boolean) => void,
  showHero?: Boolean,
  showStats?: Boolean,
  detailsOpen?: Boolean,
  toggleDetails?: (val: Boolean) => void,
}

WorkshopItem.defaultProps = {
  showHero: true,
  showStats: true,
  detailsOpen: undefined,
  toggleDetails: undefined,
}

const getWorkshopUrl = workshop => {
  const url = `/workshops/${workshop.slug}/training`
  let day = ''
  const dayNum = parseInt(workshop?.user?.nextSessionDay, 10) || 1
  day = `?day=${dayNum}`
  return url + day
}

function WorkshopItem({
  workshop,
  setShowSubDialog,
  showHero,
  showStats,
  detailsOpen,
  toggleDetails,
}: WorkshopItemProps) {
  const [localDetailsOpen, setLocalDetailsOpen] = useState(detailsOpen || false)
  const [playVideo, setPlayVideo] = useState(false)
  const history = useHistory()

  const isDetailsOpen = () => {
    if (toggleDetails !== undefined) return detailsOpen
    return localDetailsOpen
  }
  const toggle = () => {
    if (isDetailsOpen()) {
      const dashboardSection = document.querySelector(
        '.dashboard--section--workshops',
      )
      if (dashboardSection)
        dashboardSection.scrollIntoView({ behavior: 'smooth' })
    }

    if (toggleDetails !== undefined) toggleDetails(!detailsOpen)
    setLocalDetailsOpen(!localDetailsOpen)
  }

  return (
    <div className="workshop--item">
      {!playVideo && showHero && (
        <div className="rpm-hero">
          <div
            className="workshop--item--image"
            style={{ '--background-image-url': `url(${workshop.image})` }}
          />
          <div className="workshop--item--hero-title">
            <h4>{workshop.title}</h4>
            {workshop.subtitle && (
              <p className="workshop--item--hero-subtitle">
                {workshop.subtitle}
              </p>
            )}
          </div>
          {workshop.videoID && (
            <Button
              kind="text"
              onClick={() => {
                setPlayVideo(true)
              }}
              cnames="workshop--item--hero-button"
            >
              <PlayIcon />
            </Button>
          )}
        </div>
      )}
      {playVideo && showHero && workshop?.videoID && (
        <div className="workshop--video">
          <VideoPlayer
            videoId={workshop?.videoID}
            showWorkoutOverlay={false}
            autoPlay
          />
        </div>
      )}

      {showStats && (
        <>
          <div
            className="flex--auto-spread workshop--item--stats"
            key={workshop.id}
          >
            <div className="is-flex--column">
              <h4>{workshop.title}</h4>
              <IconText
                icon={{ name: 'coach', type: 'svg' }}
                text={workshop.coaches}
                cnames="text--bold workshops--icon-text text--small"
              />
              <div className="flex--auto-gap text--small">
                <IconText
                  icon={{ name: 'calendars', type: 'fal' }}
                  text={workshop.sessions.toString()}
                  subtext=" sessions"
                />
                <IconText
                  icon={{ name: 'clock', type: 'fal' }}
                  text={workshop.time}
                  subtext=" hrs"
                />
              </div>
              {workshop?.user?.percentage > 0 && (
                <IconText
                  icon={{ name: 'badge-check', type: 'fal' }}
                  text={workshop?.user?.percentage}
                  subtext="% completed"
                  style={{ justifyContent: 'left' }}
                  cnames="text--small"
                />
              )}
            </div>
            <div>
              {!workshop?.subscribed && (
                <p>
                  <Button
                    label="Subscribe"
                    gtm="Atom-Dashboard-Subscribe-Workshop"
                    onClick={() => {
                      setShowSubDialog(true)
                    }}
                  />
                </p>
              )}

              {workshop?.subscribed && !workshop.comingSoon && (
                <p>
                  <Button
                    isThin
                    label="Start"
                    disabled={workshop?.comingSoon || workshop?.sessions === 0}
                    gtm="Atom-Dashboard-Start-Workshop"
                    onClick={() => {
                      history.push(getWorkshopUrl(workshop))
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          {isDetailsOpen() && <WorkshopDetails workshop={workshop} />}
          <div className="workshop--item--details-button details--expander text--small-caps">
            <Button
              kind="text"
              onClick={() => toggle()}
              cnames="expander brand-fg"
            >
              <Icon
                name={`angle-${isDetailsOpen() ? 'up' : 'down'}`}
                type="far"
              />
              <small>
                <b>{isDetailsOpen() ? 'Less' : 'More'}</b>
              </small>
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default WorkshopItem
