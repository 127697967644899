import React from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

import RowMarker from '../../components/elements/RowMarker'
import Markdown from '../../components/elements/Markdown'
import DetailsBlock from '../../components/structures/DetailsBlock'
import { workoutIcon } from './AffiliatesHelpers'

type Props = {
  workout: Object,
  kit: String,
}

function WorkoutRow(props: Props) {
  const { workout, kit } = props

  return (
    <section className="affiliates--overview--summary-row">
      <Link
        to={`/affiliate/coaching?date=${workout.date}&kit=${kit.id}`}
        className={`date skill-level--${workout.skill}`}
      >
        <RowMarker
          line1={dayjs(workout.date).format('ddd')}
          line2={dayjs(workout.date).format('DD')}
          theme="neutral"
        />

        {workout.skill && workoutIcon('circle', 'fas')}
        {workout.project && workoutIcon('wrench', 'fas')}
        {workout.benchmark && workoutIcon('chart-mixed', 'fas')}
      </Link>

      <DetailsBlock lines="6.5">
        <Markdown className="rpm-md">{workout.workout}</Markdown>
      </DetailsBlock>
    </section>
  )
}

export default WorkoutRow
