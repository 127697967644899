import React from 'react'

import Button from '../elements/Button'
import Dialog from '../elements/Dialog'

import { links } from '../../Helpers'

type Props = {
  isOpen: boolean,
  handleDismiss: mixed,
}

function SubscribeDialog(props: Props) {
  const { isOpen, handleDismiss } = props

  const body =
    isOpen === 'persist'
      ? 'Good news! For a limited time, get a 14-day free trial now!'
      : 'Good news! For a limited time, get a 7-day free trial now!'
  const link = isOpen === 'persist' ? links.PERSIST_SHOP : links.ATOM_SHOP
  return (
    <Dialog
      isOpen={isOpen}
      handleDismiss={handleDismiss}
      header="Membership Needed"
      cnames="dashboard--dialog dashbaord--dialog--subscription"
    >
      <section>
        <p>{body}</p>

        <p>
          <Button
            label="START FREE TRIAL"
            kind="accent"
            gtm="Atom-Dashboard-Join-Dialog"
            onClick={() => {
              window.open(link, '_blank')
              handleDismiss()
            }}
          />
        </p>
      </section>
    </Dialog>
  )
}

export default SubscribeDialog
