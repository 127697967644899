import React from 'react'
import PickList from '../elements/PickList'

import dataDivisions from '../../data/divisions.json'

type Props = {
  item: object,
  doSelect: object,
  open?: boolean,
}

FilterByDivision.defaultProps = {
  open: false,
}

function FilterByDivision(props: Props) {
  const { item, open, doSelect } = props

  const items = [
    ...dataDivisions,
    {
      id: '0',
      label: 'Do not filter by division',
      cnames: 'text--small text--caps',
      altLabel: 'Division',
      icon: { name: 'ellipsis-h', type: 'far' },
    },
  ]

  const placeholder = {
    label: 'Division',
    icon: { name: 'ellipsis-h', type: 'far' },
  }

  const selectItem = element => doSelect(element)

  return (
    <PickList
      item={item}
      open={open}
      placeholder={placeholder}
      items={items}
      doSelect={selectItem}
    />
  )
}

export default FilterByDivision
