import React, { useEffect, useState } from 'react'
import Icon from '../elements/Icon'

import { animateCSS } from '../../Helpers'

import { useProfile } from '../../context/atomContext'

function WorkoutStats() {
  const { data: profileData } = useProfile()
  const [prevWorkoutTotal, setPrevWorkoutTotal] = useState(undefined)
  const [prevStreak, setPrevStreak] = useState(undefined)

  useEffect(() => {
    if (
      prevWorkoutTotal !== undefined &&
      prevWorkoutTotal !== profileData?.value?.userTraining?.totalTrainingCount
    ) {
      animateCSS('.total-training-block', 'fadeInDown')
    }
    setPrevWorkoutTotal(profileData?.value?.userTraining?.totalTrainingCount)
  }, [profileData?.value?.userTraining?.totalTrainingCount, prevWorkoutTotal])

  useEffect(() => {
    if (
      prevStreak !== undefined &&
      prevStreak !== profileData?.value?.userTraining?.streaks?.training
    ) {
      animateCSS('.streak-training-block', 'fadeInDown')
    }
    setPrevStreak(profileData?.value?.userTraining?.streaks?.training)
  }, [profileData?.value?.userTraining?.streaks?.training, prevStreak])

  return (
    <div className="workout-stats">
      <div className="workout-stats--list">
        <div className="flex--auto-space">
          <div className="flex--auto-gap workout-stats--item">
            <Icon name="video-segment-main" type="svg" cnames="ish" size="xl" />
            <div className="is-flex--column">
              <span className="ish">Total Workouts</span>{' '}
              <strong
                className="total-training-block"
                style={{ transform: 'translateZ(0)' }}
              >
                {profileData?.value?.userTraining?.totalTrainingCount}
              </strong>
            </div>
          </div>
          <div className="flex--auto-gap workout-stats--item">
            <Icon name="streak" type="svg" cnames="ish" size="xl" />
            <div className="is-flex--column">
              <span className="ish">Current Streak</span>{' '}
              <strong
                className="streak-training-block"
                style={{ transform: 'translateZ(0)' }}
              >
                {profileData?.value?.userTraining?.streaks?.training}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkoutStats
