import React from 'react'

import { printClasses } from '../../Helpers'

import Checkbox from './Checkbox'
import InputGroup from './InputGroup'

type Props = {
  name: string,
  options: array,
  handleChange: object,
  mode?: string,
  label?: string,
  selectedValue?: any,
  disabled?: boolean,
  cnames?: string,
  styles?: string,
}

CheckboxGroup.defaultProps = {
  mode: 'list',
  label: null,
  selectedValue: null,
  disabled: false,
  cnames: null,
  styles: null,
}

function CheckboxGroup(props: Props) {
  const {
    name,
    options,
    handleChange,
    mode,
    label,
    selectedValue,
    disabled,
    cnames,
    styles,
  } = props

  const classes = [
    'rpm-input--group--checkbox',
    `is-${mode}`,
    disabled && 'is-disabled',
    cnames,
  ]

  return (
    <InputGroup
      label={label}
      cnames={printClasses(classes)}
      styles={{ ...styles, '--cols': options.length }}
    >
      {options.map(option => {
        const { id, label: optLabel, value, selected } = option
        const checked = selected || Object.is(value, selectedValue)

        return (
          <Checkbox
            name={name}
            id={id}
            key={id}
            value={value}
            label={optLabel}
            checked={checked}
            changed={handleChange}
            disabled={disabled}
          />
        )
      })}
    </InputGroup>
  )
}

export default CheckboxGroup
