/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react'

import { printClasses } from '../../Helpers'

type Props = {
  text: string,
  cnames: string,
  lines?: Number,
  clamp?: boolean,
}

ClampedText.defaultProps = {
  lines: 2,
  clamp: true,
}

function ClampedText(props: Props) {
  const { text, lines, clamp, cnames } = props
  const [showMore, setShowMore] = useState(false)

  const classes = [
    'rpm-line-clamp',
    (showMore || !clamp) && 'show-more',
    cnames,
  ]

  return (
    <>
      {clamp ? (
        <p
          className={printClasses(classes)}
          onClick={() => setShowMore(!showMore)}
          onKeyPress={() => setShowMore(!showMore)}
          role="button"
          style={{ '--lines': lines }}
          tabIndex="0"
        >
          {text}
        </p>
      ) : (
        <p className={cnames}>{text}</p>
      )}
    </>
  )
}

export default ClampedText
