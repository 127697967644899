import React, { useEffect, useState } from 'react'

import { setBackgroundImage } from '../Helpers'

import AppLayout from '../components/layout/Layout'
import Dialog from '../components/elements/Dialog'
import TabSet from '../components/elements/TabSet'
import VideoPlayer from '../components/structures/VideoPlayer'

import { useProfile } from '../context/atomContext'

import TextDetailsResource from '../components/structures/resource-types/TextDetailsResource'
import VideoResource from '../components/structures/resource-types/VideoResource'
import LinkResource from '../components/structures/resource-types/LinkResource'
import RouteResource from '../components/structures/resource-types/RouteResource'

import { isMemberType } from './parts-for-affiliates/AffiliatesHelpers'

import affiliateResources from '../data/affiliateResources.json'
import persistResources from '../data/persistResources.json'

function Resources() {
  const { data: myProfile, isLoading } = useProfile()

  const [activeVideo, setActiveVideo] = useState(null)

  const persistSubscription = myProfile?.value?.subscriptions?.persist
  const affiliateSubscription = isMemberType(myProfile, 'coach')

  const startTab = affiliateSubscription ? 'affiliate' : 'persist'

  setBackgroundImage('') // unsets any default bg image hanging around
  useEffect(() => setBackgroundImage('', 'header-bg-image'), [])

  const heels = (
    <>
      <Dialog
        isOpen={activeVideo}
        handleDismiss={() => setActiveVideo(false)}
        header=" "
        cnames="training--dialog--demo-video ui-dark"
      >
        <VideoPlayer
          videoId={activeVideo}
          autoPlay
          showWorkoutOverlay={false}
        />
      </Dialog>
    </>
  )

  const itemContent = item => {
    switch (item.type) {
      case 'route':
        return <RouteResource item={item} />

      case 'link':
        return <LinkResource item={item} />

      case 'video':
        return (
          <VideoResource
            item={item}
            onClick={() => setActiveVideo(item.videoId)}
          />
        )

      case 'text':
        return <TextDetailsResource item={item} />

      default:
        return ''
    }
  }

  return (
    <AppLayout
      name="resources"
      headerConfig={{ label: 'Additional Resources' }}
      heels={heels}
      cnames="resources one-column-content"
    >
      {!isLoading && (
        <TabSet cnames="app-content" startTab={startTab}>
          {affiliateSubscription && (
            <div id="affiliate" label="WUWO">
              <article className="app-content resources--content resources--resources">
                <section className="app-content--inner">
                  {affiliateResources?.sections?.map(
                    (section, sectionIndex) => (
                      <div
                        key={String(`${sectionIndex}`)}
                        className="rpm-block"
                      >
                        <h5 className="text--caps">{section.title}</h5>

                        {section.description && (
                          <p className="ish">
                            <small>{section.description}</small>
                          </p>
                        )}

                        {section.items?.length && (
                          <ul className="un-list">
                            {section.items?.map((item, index) => (
                              <li key={String(`${index}`)}>
                                {itemContent(item)}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ),
                  )}
                </section>
              </article>
            </div>
          )}
          {persistSubscription && (
            <div id="persist" label="Persist">
              <article className="app-content resources--content resources--resources">
                <section className="app-content--inner">
                  {persistResources?.sections?.map((section, sectionIndex) => (
                    <div key={String(`${sectionIndex}`)} className="rpm-block">
                      <h5 className="text--caps">{section.title}</h5>

                      {section.description && (
                        <p className="ish">
                          <small>{section.description}</small>
                        </p>
                      )}

                      {section.items?.length && (
                        <ul className="un-list">
                          {section.items?.map((item, index) => (
                            <li key={String(`${index}`)}>
                              {itemContent(item)}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </section>
              </article>
            </div>
          )}
        </TabSet>
      )}
    </AppLayout>
  )
}

export default Resources
