import React from 'react'
import PickList from '../elements/PickList'

type Props = {
  item: string,
  doSelect: object,
  open?: boolean,
}

Select10kJumpType.defaultProps = {
  open: false,
}

function Select10kJumpType(props: Props) {
  const { item, open, doSelect } = props
  const defaultItem = item

  const items = [
    {
      id: 'doubles',
      label: 'Double-unders',
      icons: [
        {
          label: 'Double-unders',
          name: 'chevrons-up',
          type: 'fal',
          size: 'lg',
          fw: false,
          cnames: 'rpm-icon--kit',
        },
      ],
    },
    {
      id: 'singles',
      label: 'Single-unders',
      icons: [
        {
          label: 'Single-unders',
          name: 'chevron-up',
          type: 'fal',
          size: 'lg',
          fw: false,
          cnames: 'rpm-icon--kit',
        },
      ],
    },
    {
      id: 'combined',
      label: 'Combined',
    },
  ]

  const placeholder = {
    label: 'Jump Type',
    icon: { name: 'ellipsis-h', type: 'far' },
  }

  const selectItem = element => doSelect(element)

  return (
    <PickList
      item={defaultItem}
      open={open}
      placeholder={placeholder}
      items={items}
      doSelect={selectItem}
    />
  )
}

export default Select10kJumpType
