import React from 'react'

import { movementOptionForLevel } from './TrainingHelpers'
import { glueThese, formattedTime } from '../../Helpers'

import MarkdownRaw from '../../components/elements/MarkdownRaw'
import Button from '../../components/elements/Button'
import Icon from '../../components/elements/Icon'

function getMaxEntries(data) {
  if (!data) return 0
  let maxEntries = 0
  const keys = Object.keys(data)
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    if (Array.isArray(data[key])) {
      maxEntries = Math.max(maxEntries, data[key].length)
    }
  }
  return maxEntries
}

function getTrackinigString(data, index, totalRounds) {
  if (!data) return undefined
  const trackingStrings = []
  let count = 0
  if (data?.weight?.[index]) {
    count += 1
    trackingStrings.push(`${data.weight[index]} lbs`)
  }
  if (data?.time?.[index]) {
    count += 1
    trackingStrings.push(formattedTime(data.time[index]))
  }
  if (data?.reps?.[index]) {
    count += 1
    let suffix = ''
    if (count !== 1 || totalRounds > 1)
      suffix = data.reps[index] > 1 ? ' reps' : ' rep'
    trackingStrings.push(`${data.reps[index]}${suffix}`)
  }
  if (count === 0) return '-'
  return trackingStrings.join(', ')
}

function getSectionTrackingString(section, tracking) {
  let resultUnit = ''
  let result
  if (section.reps) {
    resultUnit = ' reps'
    result = tracking.reps
  }
  if (section.weight) {
    resultUnit = ' lbs'
    result = tracking.weight
  }
  if (section.time) {
    result = tracking.time
  }

  result = result
    .map(value => {
      if (!value) return '-'
      if (section.time) return formattedTime(value)
      return value
    })
    .join(', ')

  return `${result} ${resultUnit}`
}

function getSectionItemTitle(section) {
  if (section.weight) {
    return 'Weight'
  }
  if (section.reps) {
    return 'Reps'
  }
  if (section.time) {
    return 'Time'
  }
  return ''
}

function getTrackingItemTitle(data, index, totalRounds) {
  let count = 0
  let title = ''
  if (data?.weight?.[index]) {
    count += 1
    title = 'Weight'
  }
  if (data?.reps?.[index]) {
    count += 1
    title = 'Reps'
  }
  if (data?.time?.[index]) {
    count += 1
    title = 'Time'
  }

  return count === 1 && totalRounds === 1 ? title : `Round ${index + 1}`
}

type WorkoutOverlayProps = {
  activeWorkout: Object,
  workoutFormat: Object,
  movementSelections: Object,
  workoutHeading?: String,
  defaultLevel?: string,
  sectionNotes?: Object,
  workoutNotes?: string,
  tracking?: Object,
}

WorkoutOverlay.defaultProps = {
  defaultLevel: 'l1',
  workoutHeading: 'Workout',
  sectionNotes: undefined,
  workoutNotes: undefined,
  tracking: undefined,
}

function WorkoutOverlay(props: WorkoutOverlayProps) {
  const {
    activeWorkout,
    workoutFormat,
    movementSelections,
    workoutHeading,
    defaultLevel,
    sectionNotes,
    workoutNotes,
    tracking,
  } = props

  const [headers, setHeaders] = React.useState({})
  const [sectionParents, setSectionParents] = React.useState({})

  React.useEffect(() => {
    const shownSections = ['title', 'workoutSection']

    const newHeaders = {}
    const newSectionParents = {}
    const sectionOrder = []

    let currentHeader

    for (let i = 0; i < activeWorkout.length; i += 1) {
      const part = activeWorkout[i]
      sectionOrder.push(part.id)
      if (part.type === 'header' || !currentHeader) currentHeader = part.id
      if (part.type === 'header') newHeaders[part.id] = false
      if (part.type !== 'header' && shownSections.includes(part.type))
        newSectionParents[part.id] = currentHeader
    }

    for (let i = 0; i < sectionOrder.length; i += 1) {
      if (Object.values(newSectionParents).includes(sectionOrder[i])) {
        newHeaders[sectionOrder[i]] = true
        break
      }
    }

    setHeaders(newHeaders)
    setSectionParents(newSectionParents)
  }, [activeWorkout])

  function hasChildren(id) {
    return Object.values(sectionParents).includes(id)
  }

  const selectionForPartMovement = (partIndex, movementIndex) => {
    if (
      !movementSelections ||
      !movementSelections[partIndex] ||
      !movementSelections[partIndex][movementIndex]
    ) {
      return { level: defaultLevel }
    }

    return movementSelections[partIndex][movementIndex]
  }

  return (
    <>
      {workoutHeading && (
        <h2 className="workout-section workout-section--header ">
          {workoutHeading}
        </h2>
      )}
      {workoutFormat?.title && (
        <h3 className="workout-section workout-section--title--title ">
          {workoutFormat.title}
        </h3>
      )}
      {workoutFormat?.subtitle && (
        <h3 className="workout-section workout-section--title--subtitle ">
          {workoutFormat.subtitle}
        </h3>
      )}

      <ul>
        {activeWorkout.map((part, partIndex) => {
          if (
            part.type !== 'header' &&
            part.id &&
            !headers[sectionParents[part.id]]
          )
            return null
          if (part.type && part.type !== 'workoutSection') {
            if (part.type === 'title') {
              return (
                <React.Fragment
                  key={String(
                    `${part.type || part.format.l1.title}-${partIndex}`,
                  )}
                >
                  <li className="workout-section workout-section--title--title">
                    <h3>
                      <MarkdownRaw>{part.title}</MarkdownRaw>
                    </h3>
                  </li>
                  {part.subtitle ? (
                    <li className="workout-section workout-section--title--subtitle">
                      <MarkdownRaw>{part.subtitle}</MarkdownRaw>
                    </li>
                  ) : null}
                </React.Fragment>
              )
            }
            if (part.type === 'header') {
              if (!hasChildren(part.id)) return null
              return (
                <li
                  className="workout-section workout-section--header text--700 text--caps"
                  key={String(
                    `${part.type || part.format.l1.title}-${partIndex}`,
                  )}
                >
                  <Button
                    kind="text"
                    onClick={() =>
                      setHeaders({ ...headers, [part.id]: !headers[part.id] })
                    }
                    cnames="flex--auto-spread"
                  >
                    <MarkdownRaw className="w-full">{part.value}</MarkdownRaw>
                    <Icon
                      name={headers[part.id] ? 'circle-minus' : 'circle-plus'}
                      type="fal"
                      size="lg"
                    />
                  </Button>
                </li>
              )
            }
            if (part.type === 'spacer')
              return (
                <li
                  key={String(
                    `${part.type || part.format.l1.title}-${partIndex}`,
                  )}
                />
              )
            return null
          }

          return (
            <li
              className="workout-section workout-section--workout"
              key={String(`${part.type || part.format.l1.title}-${partIndex}`)}
            >
              {part?.format?.l1?.header && (
                <h2>
                  <MarkdownRaw>
                    {part?.format?.l1?.header && part.format.l1.header}
                  </MarkdownRaw>
                </h2>
              )}
              {part?.format?.l1?.title && (
                <h3>
                  <MarkdownRaw>{part.format.l1.title}</MarkdownRaw>
                </h3>
              )}
              {part?.format?.l1?.subtitle && (
                <MarkdownRaw>{part.format.l1.subtitle}</MarkdownRaw>
              )}

              <ul>
                {part.movement.map((movement, movementIndex) => {
                  const option = movementOptionForLevel(
                    movement,
                    selectionForPartMovement(partIndex, movementIndex),
                  )
                  const trackedRounds = getMaxEntries(tracking?.[option.id])

                  return (
                    <li
                      key={String(
                        `${movement.options[0].title}-${movementIndex}`,
                      )}
                      className="is-flex--column workout-overlay--movement"
                    >
                      <div className="text--italic">
                        <MarkdownRaw
                          allowedElements={['i', 'em', 'strong', 'b']}
                        >
                          {option.title}
                        </MarkdownRaw>

                        {option.levels.length !== 4 && (
                          <sup className="text--caps">
                            {glueThese(option.levels)}
                          </sup>
                        )}
                      </div>
                      {Array.from({ length: trackedRounds }).map((_, i) => {
                        const itemTitle = getTrackingItemTitle(
                          tracking?.[option.id],
                          i,
                          trackedRounds,
                        )

                        return (
                          <div className="workout-overlay--movement--result fg--brand-gray-3">
                            {itemTitle} |{' '}
                            <span className="fg--brand text--bold">
                              {getTrackinigString(
                                tracking?.[option.id],
                                i,
                                trackedRounds,
                              )}
                            </span>
                          </div>
                        )
                      })}
                    </li>
                  )
                })}
                {part.tracking && tracking?.[part.tracking?.id] && (
                  <li className="is-flex--column workout-overlay--movement">
                    <div className="workout-overlay--movement--result fg--brand-gray-3">
                      {getSectionItemTitle(part.tracking)} |{' '}
                      <span className="fg--brand text--bold">
                        {getSectionTrackingString(
                          part.tracking,
                          tracking?.[part.tracking?.id],
                        )}
                      </span>
                    </div>
                  </li>
                )}
              </ul>
              {sectionNotes && sectionNotes[partIndex] && (
                <>
                  <h3 className="text--italic">Section Note</h3>
                  <p className="fg--brand text--section-notes">
                    {sectionNotes[partIndex]}
                  </p>
                </>
              )}
            </li>
          )
        })}
        {workoutNotes && (
          <li>
            <h4 className="fg--brand text--section-notes text--italic">
              &quot;{workoutNotes}&quot;
            </h4>
          </li>
        )}
      </ul>
    </>
  )
}

export default WorkoutOverlay
