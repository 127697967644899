import React, { useState, useRef, useEffect } from 'react'

import Icon from '../elements/Icon'
import Button from '../elements/Button'
import Tooltip from '../elements/Tooltip'

import { printClasses } from '../../Helpers'

type DetailsBlockProps = {
  label?: string,
  tooltip?: string,
  collapsible?: boolean,
  defaultOpen?: Boolean,
  children: any,
  cnames?: string,
  buttonCnames?: string,
  lines?: Number,
  scrollTo?: String,
  onToggle?: Boolean => void,
}

DetailsBlock.defaultProps = {
  label: undefined,
  tooltip: undefined,
  collapsible: true,
  defaultOpen: false,
  lines: 4,
  cnames: '',
  buttonCnames: '',
  scrollTo: undefined,
  onToggle: undefined,
}

function DetailsBlock(props: DetailsBlockProps) {
  const {
    label,
    tooltip,
    collapsible,
    defaultOpen,
    lines,
    children,
    cnames,
    buttonCnames,
    scrollTo,
    onToggle,
  } = props

  const [isOpen, setIsOpen] = useState(
    defaultOpen !== undefined ? defaultOpen : !collapsible,
  )

  const [needsExpander, setNeedsExpander] = useState(true)
  const detailsRef = useRef()

  const [showTooltip, setShowTooltip] = useState(false)

  const dismissTooltip = () => setShowTooltip(false)
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const computedStyles =
    detailsRef?.current && window.getComputedStyle(detailsRef.current)
  const fontSize = computedStyles?.getPropertyValue('font-size') || 17

  const initialHeight = Math.round(parseFloat(lines) * parseFloat(fontSize))

  useEffect(() => {
    setNeedsExpander(detailsRef?.current?.offsetHeight >= initialHeight)
  }, [initialHeight, detailsRef, isOpen])

  const toggleOpenState = () => {
    if (scrollTo && isOpen)
      document
        .querySelector(`.${scrollTo}`)
        .scrollIntoView({ behavior: 'smooth' })
    setIsOpen(!isOpen)
    if (onToggle) onToggle(!isOpen)
  }

  const classes = [
    'rpm-details-block',
    isOpen && 'is-expanded',
    collapsible && needsExpander && 'is-expandable',
    cnames,
  ]

  return (
    <div
      className={printClasses(classes)}
      style={{ '--initial-height': `${initialHeight}px` }}
    >
      <Tooltip
        isOpen={showTooltip}
        handleDismiss={dismissTooltip}
        cnames=""
        hasArrow={false}
      >
        {tooltip}
      </Tooltip>
      {label && (
        <h4 className="text--caps">
          {label}{' '}
          {tooltip && (
            <Button
              onClick={toggleTooltip}
              kind="text"
              icon={{ name: 'circle-question', type: 'fal', size: 'sm' }}
              label=""
            />
          )}
        </h4>
      )}

      <div className="details" ref={detailsRef}>
        {children}
      </div>

      {collapsible && needsExpander && (
        <div className={`details--expander text--small-caps ${buttonCnames}`}>
          <Button kind="link" onClick={toggleOpenState} cnames="expander">
            <Icon name={`angle-${isOpen ? 'up' : 'down'}`} type="far" />
            <small>
              <b>{isOpen ? 'Less' : 'More'}</b>
            </small>
          </Button>
        </div>
      )}
    </div>
  )
}

export default DetailsBlock
