import React from 'react'
import { useHistory } from 'react-router-dom'

import ActivityRow from './ActivityRow'
import Markdown from '../../elements/Markdown'

type Props = {
  activity: Object,
}

function GenericActivity(props: Props) {
  const { activity } = props
  const history = useHistory()

  const action = () => {
    if (activity?.link) history.push(activity.link)
    if (activity?.href) window.open(activity.href, '_blank')
  }

  return (
    <ActivityRow
      icon={activity?.icon || 'star'}
      activity={{ ...activity, description: undefined }}
      action={action}
      avatarAction={action}
      avatars={activity?.images || []}
    >
      <Markdown>{activity.description}</Markdown>
    </ActivityRow>
  )
}

export default GenericActivity
