import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'

import { useActivities, useMarkActivitiesRead } from '../../context/atomContext'

import GenericActivity from '../structures/activity-types/GenericActivity'
import InviteActivity from '../structures/activity-types/InviteActivity'
import LikeActivity from '../structures/activity-types/LikeActivity'
import CommentActivity from '../structures/activity-types/CommentActivity'
import MentionActivity from '../structures/activity-types/MentionActivity'

import Panel from '../elements/Panel'
import LoadingData from '../molecules/LoadingData'

type Props = {
  isOpen: boolean,
  handleDismiss: Object,
  zIndex?: number,
}

Activities.defaultProps = {
  zIndex: null,
}

function Activities(props: Props) {
  const { isOpen, handleDismiss, zIndex } = props
  const [timestamp, setTimestamp] = useState(null)

  // API
  const markActivitiesRead = useMarkActivitiesRead()
  const { data: activities, isLoading } = useActivities(result => {
    if (result?.value?.timestamp) setTimestamp(result.value.timestamp)
  })

  useEffect(() => {
    if (
      isOpen &&
      timestamp &&
      activities?.value?.unviewedCount > 0 &&
      activities?.status === 200
    ) {
      markActivitiesRead.mutate({ lastViewed: timestamp })
      setTimestamp(null)
    }
  }, [activities, isOpen, markActivitiesRead, timestamp])

  const commentReg = /.+TrainingComment$/
  const likeReg = /.+TrainingLike/
  const mentionReg = /.+TrainingMention/

  const activityRow = activity => {
    if (commentReg.test(activity.itemKey)) {
      return <CommentActivity activity={activity} key={activity.id} />
    }
    if (likeReg.test(activity.itemKey)) {
      return <LikeActivity activity={activity} key={activity.id} />
    }
    if (mentionReg.test(activity.itemKey)) {
      return <MentionActivity activity={activity} key={activity.id} />
    }
    if (activity.itemKey === 'EventChallengeTeamInvite') {
      return <InviteActivity activity={activity} key={activity.id} />
    }
    return <GenericActivity activity={activity} key={activity.id} />
  }

  const activitySectionHeader = label => (
    <div className="rpm-subheader is-thin sticky-top">
      <h4 className="text--caps">{label}</h4>
    </div>
  )

  const hasActivities =
    activities?.value?.rows &&
    Array.isArray(activities.value.rows) &&
    activities.value.rows.length > 0

  const todayEnd = dayjs().endOf('day').subtract(2, 'days')
  const thisWeek = dayjs().endOf('day').subtract(7, 'days')
  const thisMonth = dayjs().endOf('day').subtract(30, 'days')

  const newActivities =
    (hasActivities &&
      activities.value.rows.filter(activity =>
        todayEnd.isBefore(dayjs(activity.created)),
      )) ||
    []

  const thisWeekActivities =
    (hasActivities &&
      activities.value.rows.filter(
        activity =>
          todayEnd.isAfter(dayjs(activity.created)) &&
          thisWeek.isBefore(dayjs(activity.created)),
      )) ||
    []

  const thisMonthActivities =
    (hasActivities &&
      activities.value.rows.filter(
        activity =>
          thisWeek.isAfter(dayjs(activity.created)) &&
          thisMonth.isBefore(dayjs(activity.created)),
      )) ||
    []

  const earlierActivities =
    (hasActivities &&
      activities.value.rows.filter(activity =>
        thisMonth.isAfter(dayjs(activity.created)),
      )) ||
    []

  const isAnythingLoading = isLoading

  return (
    <Panel
      isOpen={isOpen}
      isLoading={isAnythingLoading}
      handleDismiss={handleDismiss}
      header="Activities"
      opensFrom="right"
      cnames="app-activities activities"
      zIndex={zIndex}
    >
      <section className="app-content activities--content">
        {!isLoading && (
          <>
            {newActivities?.length > 0 && (
              <section className="section-block">
                {activitySectionHeader('New')}

                <ul className="app-content--inner activities--list">
                  {newActivities.map(activity => activityRow(activity))}
                </ul>
              </section>
            )}

            {thisWeekActivities?.length > 0 && (
              <section className="section-block">
                {activitySectionHeader('This Week')}

                <ul className="app-content--inner activities--list">
                  {thisWeekActivities.map(activity => activityRow(activity))}
                </ul>
              </section>
            )}

            {thisMonthActivities?.length > 0 && (
              <section className="section-block">
                {activitySectionHeader('This Month')}

                <ul className="app-content--inner activities--list">
                  {thisMonthActivities.map(activity => activityRow(activity))}
                </ul>
              </section>
            )}

            {earlierActivities?.length > 0 && (
              <section className="section-block">
                {activitySectionHeader('Earlier')}

                <ul className="app-content--inner activities--list">
                  {earlierActivities.map(activity => activityRow(activity))}
                </ul>
              </section>
            )}

            {!hasActivities && (
              <section className="app-content--inner rpm-description rpm-fallback">
                <p className="ish">
                  <em>No activities to show...</em>
                </p>
              </section>
            )}
          </>
        )}
      </section>
      <LoadingData isLoading={isLoading} />
    </Panel>
  )
}

export default Activities
