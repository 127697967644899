import React, { useState, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isToday from 'dayjs/plugin/isToday'

import { debounce, dateMe, printClasses } from '../../Helpers'

import Button from './Button'
import Icon from './Icon'
import IconText from './IconText'

dayjs.extend(localizedFormat)
dayjs.extend(isToday)

type Props = {
  mode: string,
  handleDate: Object,
  updateEventDatesForDate?: Object,
  date?: string,
  eventDates?: any,
  eventNutritionDates?: any,
  eventBackgrounds?: any,
  eventIcons?: any,
  allowedDates?: any,
  config?: Object,
  kind?: string,
  label?: string,
}

Calendar.defaultProps = {
  date: dateMe(dayjs()),
  eventDates: [],
  eventNutritionDates: [],
  eventBackgrounds: [],
  eventIcons: [],
  allowedDates: undefined,
  updateEventDatesForDate: () => {},
  config: {
    tools: ['reset', 'previous', 'next', 'dismiss'],
    minDate: 0,
    maxDate: 0,
    allowWeekends: true,
    shortMonth: false,
  },
  kind: 'control',
  label: undefined,
}

function Calendar(props: Props) {
  const {
    date,
    mode,
    config,
    handleDate,
    eventDates,
    eventNutritionDates,
    eventBackgrounds,
    eventIcons,
    allowedDates,
    updateEventDatesForDate,
    kind,
    label,
  } = props
  const { minDate, maxDate, tools, allowWeekends, shortMonth } = config
  const [now, setNow] = useState(dayjs(date))
  const [activeMode, setActiveMode] = useState(mode)

  const debounceDate = useMemo(
    () =>
      debounce(d => {
        handleDate(d)
      }),
    [handleDate],
  )

  const weekdayNames = [
    'Sunday',
    'Monday',
    'Tueday',
    'Wednesday',
    'Thusday',
    'Friday',
    'Saturday',
  ]

  const start = now.date(1).day()
  const end = now.endOf('month').day()
  const modeIsDay = Object.is(activeMode, 'day')
  const modeIsIcon = Object.is(activeMode, 'icon')
  const modeIsMonth = !modeIsDay && !modeIsIcon

  let displayDateFormat = modeIsIcon ? 'full' : 'month'
  displayDateFormat = modeIsDay ? 'day' : displayDateFormat

  const displayDate = dateMe(now, displayDateFormat)
  const displayIcon = modeIsMonth
    ? null
    : { name: 'calendar-days', type: 'fal' }
  const daysInPreviousMonth = now.subtract(1, 'month').daysInMonth()

  const hasReset = tools === undefined ? true : tools.includes('reset')
  const hasPrevious = tools === undefined ? true : tools.includes('previous')
  const hasNext = tools === undefined ? true : tools.includes('next')
  const hasDismiss = tools === undefined ? true : tools.includes('dismiss')

  const days = []

  for (let iterator = 0; iterator < start; iterator += 1) {
    const padDay = `${daysInPreviousMonth - iterator}`.padStart(2, '0')

    days.push({
      date: `${now.subtract(1, 'month').format('YYYY-MM-')}${padDay}`,
      day: padDay,
    })
  }

  const beforeMonth = days.reverse()

  const activeMonth = [...Array(now.daysInMonth())].map((element, index) => {
    const padDay = `${index + 1}`.padStart(2, '0')

    return {
      date: `${now.format('YYYY-MM-')}${padDay}`,
      day: padDay,
    }
  })

  const afterMonth = [...Array(Number(6 - end))].map((element, index) => {
    const padDay = `${index + 1}`.padStart(2, '0')

    return {
      date: `${now.add(1, 'month').format('YYYY-MM-')}${padDay}`,
      day: padDay,
    }
  })

  const toggleMode = () => setActiveMode(modeIsMonth ? mode : 'month')
  const dismiss = () => setActiveMode(mode)
  const dateIsToday = day => dayjs(day).isToday()
  const dateIsSelected = day => dayjs(day).isSame(now, 'day')
  const dayInWeekend = day => [0, 6].includes(dayjs(day).day())

  const previous = () => {
    const limit = dayjs(minDate)
    let d = now.subtract(1, activeMode)

    if (modeIsDay && allowedDates !== undefined) {
      const dayIndex = allowedDates.indexOf(dateMe(now))

      if (dayIndex - 1 >= 0) {
        d = dayjs(allowedDates[dayIndex - 1])
      }
    } else if (modeIsDay && !allowWeekends && dayInWeekend(d)) {
      d = dayjs(d).subtract(2, activeMode)
    }

    if (minDate && d.isBefore(limit)) {
      d = limit
    }

    setNow(d)

    if (modeIsDay || shortMonth) {
      debounceDate(dateMe(d))
    }
    updateEventDatesForDate(dateMe(d))
  }

  const next = () => {
    const limit = dayjs(maxDate)
    let d = now.add(1, activeMode)

    if (modeIsDay && allowedDates !== undefined) {
      const dayIndex = allowedDates.indexOf(dateMe(now))

      if (dayIndex + 1 < allowedDates.length) {
        d = dayjs(allowedDates[dayIndex + 1])
      }
    } else if (modeIsDay && !allowWeekends && dayInWeekend(d)) {
      d = dayjs(d).add(2, activeMode)
    }

    if (maxDate && d.isAfter(limit)) {
      d = limit
    }

    setNow(d)

    if (modeIsDay || shortMonth) {
      debounceDate(dateMe(d))
    }
    updateEventDatesForDate(dateMe(d))
  }

  const today = () => {
    const d = dayjs()

    setNow(d)

    if (modeIsDay) {
      handleDate(dateMe(d))
    }
  }

  const dayHasEvent = day => eventDates.includes(day)
  const dayHasNutritionEvent = day => eventNutritionDates.includes(day)

  const selectDay = day => {
    setNow(dayjs(day))
    handleDate(dateMe(day))
    updateEventDatesForDate(dateMe(day))
    dismiss()
  }

  const dateExceedsLimits = day =>
    (minDate && dayjs(day).isBefore(minDate)) ||
    (maxDate && dayjs(day).isAfter(maxDate)) ||
    (!allowWeekends && dayInWeekend(day)) ||
    (allowedDates !== undefined && !allowedDates.includes(dateMe(day)))

  const dayClasses = (day: string, classes: string) => [
    classes,
    dateIsToday(day) && 'is-today',
    dateIsSelected(day) && 'is-selected',
    dayHasEvent(day) && 'has-event',
    !allowWeekends && dayInWeekend(day) && 'is-weekend',
    dateExceedsLimits(day) && 'is-out-of-bounds',
    dayHasNutritionEvent(day) && 'has-nutrition',
  ]

  const calendarButton = (d: Object, classes: string = '') => (
    <button
      className={printClasses(dayClasses(d.date, classes))}
      key={d.date}
      onClick={() => selectDay(d.date)}
      disabled={dateExceedsLimits(d.date)}
    >
      {d.day}
      {eventBackgrounds[d.date] !== undefined && (
        <div style={{ position: 'absolute' }}>
          <Icon
            name={eventBackgrounds[d.date].name}
            type={eventBackgrounds[d.date].type}
            size={eventBackgrounds[d.date].size}
            cnames={
              dateIsSelected(d.date)
                ? eventBackgrounds[d.date].selectedCnames
                : eventBackgrounds[d.date].cnames
            }
          />
        </div>
      )}
      {Array.isArray(eventIcons[d.date]) && (
        <div className="rpm-calendar--day-icons">
          {eventIcons[d.date].map((eventIcon, index) => (
            <Icon
              name={eventIcon.name}
              type={eventIcon.type}
              size={eventIcon.size}
              cnames={
                dateIsSelected(d.date)
                  ? eventIcon.selectedCnames
                  : eventIcon.cnames
              }
              key={String(`${d.date}-${index}`)}
            />
          ))}
        </div>
      )}
    </button>
  )

  useEffect(() => {
    setNow(dayjs(date))
  }, [date])

  const previousIsBeforeMinDate = () => {
    const d = modeIsMonth ? dayjs(dateMe(now, 'yearMonth')) : dayjs(now)
    const edge = modeIsMonth ? dayjs(minDate).startOf('month') : minDate

    return minDate && d.subtract(1, activeMode).isBefore(edge)
  }

  const nextIsAfterMaxDate = () => {
    const d = modeIsMonth ? dayjs(dateMe(now, 'yearMonth')) : dayjs(now)
    const edge = modeIsMonth ? dayjs(maxDate).endOf('month') : maxDate

    return maxDate && d.add(1, activeMode).isAfter(edge)
  }

  const calendarClasses = [
    'rpm-calendar',
    modeIsMonth ? 'is-month-mode' : `is-${activeMode}-mode`,
    modeIsMonth && shortMonth && 'is-short-month',
    kind && `is-kind-${kind}`,
  ]

  return (
    <div open={modeIsMonth} className={printClasses(calendarClasses)}>
      {label && (
        <span className="rpm-input--label rpm-calendar--label">{label}</span>
      )}
      {kind === 'input' && (
        <div className="rpm-input--field">
          <span className="rpm-input--label">Date of Activity</span>
          <Button
            kind="text"
            onClick={toggleMode}
            canRipple={false}
            cnames="rpm-calendar--control rpm-calendar--control--toggle rpm-label rpm-calendar--input-button"
          >
            {dateMe(date, 'long')}
          </Button>
        </div>
      )}
      {kind === 'control' && (
        <div className="rpm-calendar--controls">
          {hasReset && modeIsMonth && (
            <button
              onClick={today}
              className="rpm-calendar--control rpm-calendar--control--today"
              aria-label="reset"
            >
              <Icon name="undo" type="far" />
            </button>
          )}

          {(hasPrevious || modeIsMonth) && (
            <button
              onClick={previous}
              className="rpm-calendar--control rpm-calendar--control--previous"
              disabled={previousIsBeforeMinDate()}
              aria-label="previous"
            >
              <Icon name="chevron-left" type="fas" />
            </button>
          )}

          <button
            onClick={toggleMode}
            className="rpm-calendar--control rpm-calendar--control--toggle rpm-label"
            aria-label="expand"
          >
            <IconText icon={displayIcon} text={displayDate} />
          </button>

          {(hasNext || modeIsMonth) && (
            <button
              onClick={next}
              className="rpm-calendar--control rpm-calendar--control--next"
              disabled={nextIsAfterMaxDate()}
              aria-label="next"
            >
              <Icon name="chevron-right" type="fas" />
            </button>
          )}

          {hasDismiss && modeIsMonth && (
            <button
              onClick={dismiss}
              className="rpm-calendar--control rpm-calendar--control--dismiss"
              aria-label="dismiss"
            >
              <Icon name="times" type="fal" size="lg" />
            </button>
          )}
        </div>
      )}
      {modeIsMonth && !shortMonth && (
        <>
          <Button
            kind="shield"
            cnames="rpm-calendar--shield"
            onClick={dismiss}
            canRipple={false}
          />

          <div className="rpm-calendar--body">
            {kind === 'input' && (
              <section className="is-flex flex--auto-spread rpm-calendar--control-bar">
                {hasReset && modeIsMonth && (
                  <button onClick={today} className="rpm-calendar--control">
                    <Icon name="undo" type="far" />
                  </button>
                )}

                {(hasPrevious || modeIsMonth) && (
                  <button
                    onClick={previous}
                    className="rpm-calendar--control rpm-calendar--control--previous"
                    disabled={previousIsBeforeMinDate()}
                  >
                    <Icon name="chevron-left" type="fas" />
                  </button>
                )}
                <span>{displayDate}</span>
                {(hasNext || modeIsMonth) && (
                  <button
                    onClick={next}
                    className="rpm-calendar--control rpm-calendar--control--next"
                    disabled={nextIsAfterMaxDate()}
                  >
                    <Icon name="chevron-right" type="fas" />
                  </button>
                )}
                {hasDismiss && modeIsMonth && (
                  <button onClick={dismiss} className="rpm-calendar--control">
                    <Icon name="times" type="fal" size="lg" />
                  </button>
                )}
              </section>
            )}
            <section className="rpm-calendar--grid rpm-calendar--days-of-week">
              {weekdayNames.map(d => (
                <div key={d}>{d.substring(0, 3)}</div>
              ))}
            </section>

            <section className="rpm-calendar--grid rpm-calendar--days">
              {beforeMonth.map(d => calendarButton(d, 'filler'))}
              {activeMonth.map(d => calendarButton(d))}
              {afterMonth.map(d => calendarButton(d, 'filler'))}
            </section>
          </div>
        </>
      )}
    </div>
  )
}

export default Calendar
