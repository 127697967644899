import React, { useEffect } from 'react'

import MentionRow from './MentionRow'

import { printClasses, scrollToElement } from '../../Helpers'

type Props = {
  rows: Array,
  search: String,
  action: Object,
  selectedIndex?: Number,
  children?: any,
  cnames?: String,
}

Mentions.defaultProps = {
  selectedIndex: 0,
  children: null,
  cnames: null,
}

function Mentions(props: Props) {
  const { rows, search, action, selectedIndex, children, cnames } = props

  useEffect(
    () => scrollToElement('.mentions--results li.is-selected', 'center'),
    [selectedIndex],
  )

  const noResults = rows.length === 0 && (
    <li className="mentions--result no-results">
      <i>Nothing found for &ldquo;@{search}...&rdquo;</i>
    </li>
  )

  return (
    <div className={printClasses(['mentions', cnames])}>
      {search && (
        <div className="mentions--results">
          <ul>
            {rows.map((row, index) => (
              <MentionRow
                key={row.id}
                user={row}
                avatarSize={2}
                action={() => action(row)}
                isSelected={Object.is(index, selectedIndex)}
              />
            ))}

            {noResults}
          </ul>
        </div>
      )}

      {children}
    </div>
  )
}

export default Mentions
