import React from 'react'
import Icon from './Icon'

import { printClasses } from '../../Helpers'

type Props = {
  icon: Object,
  badge: string | number,
  cnames?: string,
}

IconText.defaultProps = {
  cnames: null,
}

function IconText(props: Props) {
  const { icon, badge, cnames } = props
  const { name, type, size, fw, cnames: iconCnames } = { ...icon }

  const displayBadge = badge > 9 ? '9+' : badge

  return (
    <span
      className={printClasses([
        'rpm-icon--with-badge',
        badge < 1 && 'ghost',
        cnames,
      ])}
    >
      <Icon name={name} type={type} size={size} fw={fw} cnames={iconCnames} />
      {badge > 0 && <sup className="rpm-icon--badge">{displayBadge}</sup>}
    </span>
  )
}

export default IconText
