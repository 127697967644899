import React, { useState, useEffect, useMemo, useContext } from 'react'

import IconText from '../../components/elements/IconText'
import Search from '../../components/inputs/Search'
import ListGroups from '../../components/structures/ListGroups'

import { SocialContext } from '../../context/socialContext'
import { useSearchTeam, useMyEventTeam } from '../../context/atomContext'

import { debounce } from '../../Helpers'

type Props = {
  dismissPanel: mixed,
}

function JoinTeam(props: Props) {
  // State
  const { dismissPanel } = props
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermDebounced, setSearchTermDebounced] = useState('')
  const [results, setResults] = useState([])
  const [hasResults, setHasResults] = useState(false)

  // Context
  const { openGroupProfile: scOpenGroupProfile } = useContext(SocialContext)

  // API
  const { data: searchData, isFetching } = useSearchTeam(
    searchTermDebounced.length > 1 ? searchTermDebounced : '',
  )

  const { data: teamData } = useMyEventTeam()

  // test data
  // Variables
  useEffect(() => setResults(searchData?.value?.rows), [searchData])
  useEffect(() => setHasResults(results?.length > 0), [results])

  const invitations = teamData?.value?.teamInvitations

  // functions
  const debounceSearch = useMemo(
    () => debounce(term => setSearchTermDebounced(term), 500),
    [setSearchTermDebounced],
  )

  const doSearch = value => {
    setSearchTerm(value)
    debounceSearch(value)
  }

  const openGroupProfile = teamId =>
    scOpenGroupProfile({ id: teamId, onJoin: dismissPanel })

  return (
    <>
      {invitations?.length > 0 && (
        <>
          <div className="rpm-header">
            <h5 className="text--caps">
              <span>My Invitations</span>
            </h5>
          </div>

          <ListGroups
            groups={invitations}
            openGroupProfile={openGroupProfile}
            allowIgnore
          />
        </>
      )}

      <section
        className="rpm-header sticky-top sticky-bottom"
        style={{ '--sticky-top': '3.5em' }}
      >
        <Search
          id="searchTeams"
          placeholder="Look for a Team..."
          noResults={
            <IconText
              icon={{ type: 'fal', name: 'users-slash', size: 'lg' }}
              text="No teams found, try again..."
              cnames="fg--accent"
            />
          }
          helpMessage={
            <IconText
              icon={{ type: 'fal', name: 'question-square', size: 'lg' }}
              text="Use at least two characters"
              cnames="ish"
            />
          }
          value={searchTerm}
          isSearching={isFetching}
          debouncedValue={searchTermDebounced}
          hasResults={hasResults}
          changed={doSearch}
          maxlength={20}
        />
      </section>

      <ListGroups groups={results} openGroupProfile={openGroupProfile} />
    </>
  )
}

export default JoinTeam
