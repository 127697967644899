import React from 'react'

import { printClasses } from '../../Helpers'

type Props = {
  id: string | number,
  name: string,
  label: string,
  checked?: boolean,
  changed?: any,
  cnames?: string,
}

CheckboxInput.defaultProps = {
  checked: false,
  changed: null,
  cnames: null,
}

function CheckboxInput(props: Props) {
  const { id, name, label, checked, changed, cnames } = props

  const classes = [
    'rpm-input',
    'rpm-input--control',
    'rpm-input--toggle',
    checked && 'is-active',
    cnames,
  ]

  return (
    <label htmlFor={id} className={printClasses(classes)}>
      <span className="rpm-input--toggle--label">{label}</span>
      <span className="rpm-input--toggle--switch">
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={event => changed(event.target.checked)}
        />
        <span className="labels text--no-style">
          <b className="on">yes</b>
          <b className="off">no</b>
        </span>
      </span>
    </label>
  )
}

export default CheckboxInput
