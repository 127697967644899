import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import Comments from '../components/screens/Comments'
import Toasts from '../components/molecules/Toasts'
import PublicProfile from '../components/screens/PublicProfile'
import GroupProfile from '../components/screens/GroupProfile'
import { AtomContext, useToggleLike, useStatus } from './atomContext'
import { sendEvent } from '../Helpers'

export const SocialContext = createContext()

type Props = {
  children: object,
}

const SocialProvider = ({ children }: Props) => {
  const { needsRestart } = useContext(AtomContext)
  const [commentsOpen, setCommentsOpen] = useState(undefined)
  const [commentItem, setCommentItem] = useState(undefined)
  const [profileOpen, setProfileOpen] = useState(undefined)
  const [profileUser, setProfileUser] = useState(undefined)
  const [groupProfileOpen, setGroupProfileOpen] = useState(undefined)
  const [profileGroup, setProfileGroup] = useState(undefined)
  const [panelList, setPanelList] = useState([])
  const [toasts, setToasts] = useState([])

  const apiToggleLike = useToggleLike()
  const status = useStatus()

  const addToast = useCallback(
    toast => {
      const newToast = {
        ...toast,
        id:
          toast.id ||
          String(`${toast.label}-${Math.floor(Math.random() * 1000)}`),
      }
      setToasts([...toasts, newToast])
    },
    [toasts],
  )

  const removeToast = useCallback(
    id => {
      const newToasts = toasts.filter(t => t.id !== id)
      setToasts(newToasts)
    },
    [toasts],
  )

  useEffect(() => {
    const subTextElem = (
      <>
        Atom has been updated.
        <br />
        <span className="text--underline">Click here</span> to get the latest.
      </>
    )

    const restartToast = {
      id: 'new-atom-version',
      label: subTextElem,
      title: 'BETTER THAN YESTERDAY',
      action: () => {
        window.location.reload()
      },
      silenceTime: 60 * 1000 * 30,
    }
    const hasToast = toasts.find(t => t.id === restartToast.id)
    if (!hasToast && needsRestart) addToast(restartToast)
    if (hasToast && !needsRestart) removeToast('new-atom-version')
  }, [addToast, needsRestart, removeToast, toasts])

  const openComments = (item: Object) => {
    setCommentItem(item)
    setCommentsOpen(true)
    setPanelList([...panelList, 'comments'])
    sendEvent('ATOM-Comment-Open')
  }

  const closeComments = () => {
    setCommentsOpen(false)
    setPanelList(panelList.filter(element => element !== 'comments'))
  }

  const openProfile = (pUser: Object) => {
    setProfileUser(pUser)
    setProfileOpen(true)
    setPanelList([...panelList, 'userProfile'])
    sendEvent('ATOM-PublicProfile-Open')
  }

  const openGroupProfile = (pGroup: Object) => {
    setProfileGroup(pGroup)
    setGroupProfileOpen(true)
    setPanelList([...panelList, 'groupProfile'])
    sendEvent('ATOM-GroupProfile-Open')
  }

  const closeProfile = () => {
    setProfileOpen(false)
    setPanelList(panelList.filter(element => element !== 'userProfile'))
  }
  const closeGroupProfile = joined => {
    setGroupProfileOpen(false)
    if (joined && profileGroup?.onJoin) profileGroup.onJoin()
    setPanelList(panelList.filter(element => element !== 'groupProfile'))
  }

  const toggleLike = (
    userTrainingId: integer,
    track: string,
    callbacks: Object = {},
  ) => {
    const postData = { userTrainingId, track: track || 'atomic' }
    apiToggleLike.mutate(postData, callbacks)
  }

  const panelZIndex = (panelName: string): number => {
    const elementIndex = panelList.findIndex(element => element === panelName)
    if (elementIndex === -1) {
      return null
    }
    return elementIndex + 125
  }

  return (
    <SocialContext.Provider
      value={{
        addToast,
        removeToast,
        commentItem,
        commentsOpen,
        openComments,
        closeComments,
        profileOpen,
        openProfile,
        closeProfile,
        toggleLike,
        openGroupProfile,
        closeGroupProfile,
      }}
    >
      {children}
      {status?.user?.id !== undefined && (
        <>
          <Comments
            isOpen={commentsOpen}
            handleDismiss={closeComments}
            commentItem={commentItem}
            openProfile={openProfile}
            zIndex={panelZIndex('comments')}
          />
          <PublicProfile
            isOpen={profileOpen}
            handleDismiss={closeProfile}
            userId={profileUser}
            zIndex={panelZIndex('userProfile')}
          />
          <GroupProfile
            isOpen={groupProfileOpen}
            handleDismiss={closeGroupProfile}
            openUserProfile={openProfile}
            teamId={profileGroup?.id}
            zIndex={panelZIndex('groupProfile')}
          />
        </>
      )}
      <Toasts toasts={toasts} setToasts={setToasts} />
    </SocialContext.Provider>
  )
}

export default SocialProvider
