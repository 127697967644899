import React from 'react'
import PickList from '../elements/PickList'

import dataTypes from '../../data/affiliateFilterTypes.json'

type Props = {
  item: Object | String,
  doSelect: Object,
  open?: Boolean,
}

FilterByType.defaultProps = {
  open: false,
}

function FilterByType(props: Props) {
  const { item, open, doSelect } = props

  const defaultItem =
    typeof item === 'string'
      ? dataTypes.find(type => type.id === item) || {}
      : item

  const items = [...dataTypes]

  const placeholder = {
    label: '',
    icon: { name: 'ellipsis-h', type: 'far' },
  }

  const selectItem = element => doSelect(element)

  return (
    <PickList
      item={defaultItem}
      open={open}
      placeholder={placeholder}
      items={items}
      doSelect={selectItem}
    />
  )
}

export default FilterByType
