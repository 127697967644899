import React, { useContext } from 'react'

import Button from './Button'

import { SocialContext } from '../../context/socialContext'

type RenderLinkProps = {
  href: String,
  children: Any,
  displayVideo: video => {},
}

const RenderLink = (props: RenderLinkProps) => {
  const { href, children, displayVideo } = props
  const { openProfile } = useContext(SocialContext)

  const videoPrefix = '///videos/'
  const userPrefix = '///users/'
  const ytPrefix = 'https://www.youtube.com/watch?v='
  const ytPrefix2 = 'https://youtu.be/'
  if (href.startsWith(videoPrefix))
    return (
      <Button
        kind="link"
        onClick={() =>
          displayVideo({ videoId: href.substring(videoPrefix.length) })
        }
        cnames="fg--dark text--underline"
      >
        {children}
      </Button>
    )
  if (href.startsWith(ytPrefix))
    return (
      <Button
        kind="link"
        onClick={() => {
          const url = new URL(href)
          displayVideo({ ytVideoId: url.searchParams.get('v') })
        }}
        cnames="fg--dark text--underline"
      >
        {children}
      </Button>
    )

  if (href.startsWith(ytPrefix2))
    return (
      <Button
        kind="link"
        onClick={() => {
          const url = new URL(href)
          if (url.pathname) displayVideo({ ytVideoId: url.pathname })
        }}
        cnames="fg--dark text--underline"
      >
        {children}
      </Button>
    )

  if (href.startsWith(userPrefix))
    return (
      <Button
        kind="link"
        onClick={() => openProfile(href.substring(userPrefix.length))}
        cnames="fg--dark text--underline"
      >
        {children}
      </Button>
    )

  if (href.startsWith('http'))
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    )

  return <a href={href}>{children}</a>
}

export default RenderLink
