import React, { useState, useEffect } from 'react'

import Button from './Button'
import Icon from './Icon'
import IconText from './IconText'

type Props = {
  item: Object,
  doSelect: Object,
  placeholder?: Object,
  open?: Boolean,
  isDisabled?: Boolean,
  caret?: string,
  items?: any,
  eventOnly?: boolean,
  className?: String,
  flipped?: Boolean,
}

PickList.defaultProps = {
  caret: null,
  open: false,
  isDisabled: false,
  placeholder: { label: '...', icon: null },
  items: [],
  eventOnly: false,
  className: undefined,
  flipped: false,
}

function PickList(props: Props) {
  const {
    item,
    open,
    isDisabled,
    caret,
    placeholder,
    items,
    doSelect,
    eventOnly,
    className,
    flipped,
  } = props
  const [selected, setSelected] = useState(item)
  const [isOpen, setIsOpen] = useState(open)
  const [openChildGroup, setOpenChildGroup] = useState(undefined)

  const activeItem = Object.keys(selected).length ? selected : placeholder
  const {
    id: aId,
    label: aLabel,
    altLabel,
    icon: aIcon,
    icons: aIcons,
  } = activeItem
  const hasCaret = !!caret
  const caretIsAfter = Object.is(caret, 'right') || Object.is(caret, 'after')
  const caretIsBefore = !caretIsAfter

  useEffect(() => {
    if (JSON.stringify(item) !== JSON.stringify(selected)) setSelected(item)
  }, [item, selected])

  const toggle = () => items.length > 1 && setIsOpen(!isOpen)

  const caretState = () => {
    const rotation = caretIsBefore ? 'fa-rotate-90' : 'fa-rotate-180'

    return isOpen ? rotation : ''
  }

  const selectItem = element => {
    if (!eventOnly) setSelected(element)
    doSelect(element)
    setIsOpen(false)
    setOpenChildGroup(undefined)
  }

  const listItem = (element, hideIcon) => {
    const {
      id: eId,
      label: eLabel,
      icon: eIcon,
      icons: eIcons,
      cnames,
      children,
    } = element
    const selectedChild = element?.children?.find(c => Object.is(aId, c.id))

    const isSelected = Object.is(aId, eId) || selectedChild
    const checkIcon = isSelected && (
      <Icon type="far" name="check" cnames="brand-fg" />
    )

    const itemDisplay = !eIcons ? (
      <IconText
        icon={hideIcon ? null : eIcon}
        text={selectedChild?.label || eLabel}
        cnames={cnames}
      />
    ) : (
      <span className="rpm-icontext--set">
        {eIcons.map(icon => (
          <IconText icon={icon} text={icon.label} key={icon.name} />
        ))}
      </span>
    )

    return (
      <li
        key={eId}
        className={isSelected ? 'is-selected' : ''}
        open={openChildGroup && openChildGroup === element.groupName}
      >
        <button
          onClick={() => {
            if (
              element.collapse &&
              element.groupName &&
              openChildGroup !== element.groupName
            )
              setOpenChildGroup(element.groupName)
            else if (
              element.collapse &&
              element.groupName &&
              openChildGroup === element.groupName
            )
              setOpenChildGroup(undefined)
            else selectItem(element)
          }}
        >
          <div className="flex--auto-gap">
            {flipped && (checkIcon || <span className="rpm-icon fa-fw" />)}
            {itemDisplay}
          </div>
          <div className="rpm-picklist--icons">
            {element.collapse && (
              <Icon type="far" name="angle-right" cnames="marker" />
            )}
            {!flipped && checkIcon}
          </div>
        </button>
        {children &&
          (!element.collapse || openChildGroup === element.groupName) && (
            <ul>{children.map(child => listItem(child, true))}</ul>
          )}
      </li>
    )
  }

  const label = (
    <>
      {hasCaret && caretIsBefore && (
        <Icon
          type="far"
          name="angle-right"
          cnames={`rpm-picklist--caret rpm-picklist--caret--is-before ${caretState()}`}
        />
      )}

      {activeItem && !aIcons && (
        <IconText icon={aIcon} text={altLabel || aLabel} />
      )}

      {activeItem && aIcons && (
        <IconText icons={aIcons} text={altLabel || aLabel} />
      )}

      {hasCaret && caretIsAfter && (
        <Icon
          type="far"
          name="angle-down"
          cnames={`rpm-picklist--caret rpm-picklist--caret--is-after ${caretState()}`}
        />
      )}
    </>
  )

  return (
    <div className={`rpm-picklist ${className}`} open={isOpen}>
      {items.length > 1 && !isDisabled ? (
        <>
          <div className="rpm-picklist--label">
            <button className="rpm-label" onClick={toggle}>
              {label}
            </button>
          </div>

          {isOpen && (
            <Button
              kind="shield"
              cnames="rpm-picklist--shield"
              onClick={toggle}
              canRipple={false}
            />
          )}

          <ul className="rpm-picklist--content">
            {items.map(element => listItem(element))}
          </ul>
        </>
      ) : (
        <div className="rpm-picklist--label">
          <div className="rpm-label">{label}</div>
        </div>
      )}
    </div>
  )
}

export default PickList
