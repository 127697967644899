import React, { useState } from 'react'
import { rpmStoreLink } from '../../Helpers'

import FlipCard from '../elements/FlipCard'
import TextField from '../inputs/TextField'
import Button from '../elements/Button'

type Props = {
  products: Array,
}

const handleCopyToClipboard = text => {
  navigator.clipboard.writeText(text).then(
    () => {},
    () => {
      console.log('Error copying code to clipboard')
    },
  )
}

function ProductCards(props: Props) {
  const { products } = props
  const [selectedCard, setSelectedCard] = useState(undefined)

  const Card = item => {
    const {
      id,
      title,
      brand,
      handle,
      image,
      price,
      promoCode,
      href,
      markerText,
      discounted,
    } = item

    return (
      <li className="rpm-card rpm-card--product" key={id}>
        <div className="rpm-card--content">
          <FlipCard
            isFlipped={selectedCard === id}
            front={
              <Button
                kind="secret"
                className="rpm-card--image-container"
                onClick={() => setSelectedCard(id)}
              >
                {markerText && (
                  <small className="rpm-card--marker">
                    <b className="rpm-card--marker--text text--caps">
                      {markerText}
                    </b>
                  </small>
                )}
                <img
                  className="rpm-card--image"
                  src={image.src}
                  alt={image.alt}
                  loading="lazy"
                />
              </Button>
            }
            back={
              <>
                <Button
                  kind="secret"
                  ariaLabel="close"
                  onClick={() => setSelectedCard(undefined)}
                  cnames="rpm-card--product--close"
                />

                <div className="is-flex--column rpm-card--product--back">
                  {promoCode && (
                    <div className="code-section">
                      <div>
                        <TextField isThin readOnly value={promoCode} />
                        <Button
                          label="Copy the code"
                          onClick={() => handleCopyToClipboard(promoCode)}
                          isThin
                          cnames="rpm-card--product--copy"
                        />
                      </div>
                    </div>
                  )}
                  {!promoCode && (
                    <div className="code-section">
                      <h4 className="text--caps">
                        {discounted
                          ? 'Discount code automatically applied'
                          : 'Ready to Explore?'}
                      </h4>
                    </div>
                  )}
                  <div className="bottom-section">
                    <Button
                      label="Shop Now"
                      onClick={() => {
                        window.open(href || rpmStoreLink(handle), '_blank')
                      }}
                      isThin
                      cnames="rpm-card--product--open"
                    />
                  </div>
                </div>
              </>
            }
          />
          <div>
            {brand && (
              <p className="rpm-card--text rpm-card--title">
                <b>{brand}</b>
              </p>
            )}

            {title && <p className="rpm-card--text rpm-card--title">{title}</p>}

            {price && (
              <p className="rpm-card--text rpm-card--price ghost">{price}</p>
            )}
          </div>
        </div>
      </li>
    )
  }

  return <ul className="rpm-card-deck">{products.map(item => Card(item))}</ul>
}

export default ProductCards
