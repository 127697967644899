import React from 'react'

import Dialog from '../elements/Dialog'
import Button from '../elements/Button'
import InputGroup from '../inputs/InputGroup'
import { printClasses } from '../../Helpers'

type Props = {
  isOpen: boolean,
  header?: String,
  children?: any,
  primaryText?: String,
  primaryAction: Object,
  primaryKind?: String,
  secondaryText?: String,
  secondaryAction?: Object,
  secondaryKind?: String,
  handleDismiss?: Object,
  cnames?: string,
}

ConfirmationDialog.defaultProps = {
  header: 'Are you sure?',
  children: null,
  primaryText: 'Yes',
  primaryKind: 'danger',
  secondaryText: null,
  secondaryAction: () => {},
  secondaryKind: 'cancel',
  handleDismiss: null,
  cnames: null,
}

function ConfirmationDialog(props: Props) {
  const {
    isOpen,
    header,
    children,
    primaryText,
    primaryAction,
    primaryKind,
    secondaryText,
    secondaryAction,
    secondaryKind,
    handleDismiss,
    cnames,
  } = props

  return (
    <Dialog
      isOpen={isOpen}
      handleDismiss={handleDismiss || secondaryAction}
      header={header}
      cnames={printClasses(['rpm-dialog--confirmation', cnames])}
    >
      <section>{children}</section>

      <footer>
        <InputGroup columns="1fr 1fr">
          <Button
            label={secondaryText}
            kind={secondaryKind}
            onClick={secondaryAction}
          />
          <Button
            label={primaryText}
            kind={primaryKind}
            onClick={primaryAction}
          />
        </InputGroup>
      </footer>
    </Dialog>
  )
}

export default ConfirmationDialog
