import React from 'react'

import { printClasses } from '../../Helpers'

import Avatar from '../elements/Avatar'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import AtomMarkup from '../elements/AtomMarkup'

type Props = {
  row: Object,
  cnames?: string,
  avatarSize?: string | number,
  openProfile: Object,
  deleteComment: Object,
}

CommentRow.defaultProps = {
  cnames: '',
  avatarSize: 2,
}

function CommentRow(props: Props) {
  const { row, cnames, avatarSize, openProfile, deleteComment } = props

  const classes = ['app-comments--row', cnames]

  return (
    <div className={printClasses(['data-row', ...classes])}>
      <div className="app-comments--avatar">
        <Button kind="text" onClick={() => openProfile(row.userId)}>
          <Avatar
            image={row.profileImage}
            label={row.name}
            size={avatarSize}
            flag={{ name: row.country }}
          />
        </Button>
      </div>

      <div className="app-comments--details">
        <div className="flex--auto-spread">
          <Button
            kind="text"
            onClick={() => openProfile(row.userId)}
            cnames="app-comments--username"
            label={row.username ? `@${row.username}` : row.name}
          />

          {row.allowDelete && (
            <Button
              kind="text"
              onClick={() => deleteComment()}
              label={<Icon type="fal" name="trash-alt" />}
              cnames="app-comments--delete"
            />
          )}
        </div>

        <p className="app-comments--comment">
          <AtomMarkup openProfile={openProfile}>{row.comment}</AtomMarkup>
        </p>

        {row.timeAgo && (
          <p className="ghost">
            <small>{row.timeAgo}</small>
          </p>
        )}
      </div>
    </div>
  )
}

export default CommentRow
