import React from 'react'
import { abbreviateNumber, formatRawNumber, printClasses } from '../../Helpers'

import IconText from '../../components/elements/IconText'

type Props = {
  data: any,
  placeholder?: string,
  abbreviate?: boolean,
}

JumpStats.defaultProps = {
  placeholder: '---',
  abbreviate: false,
}

function JumpStats(props: Props) {
  // State
  const { data, placeholder, abbreviate } = props

  // Temp
  // State
  // Context
  // API
  // test data
  // Variables

  // functions
  return (
    <div className="flex--auto-space stats--row">
      {data.map(stat => {
        const value =
          (Number(stat?.value) > 0 &&
            (abbreviate
              ? abbreviateNumber(parseInt(stat?.value, 10))
              : formatRawNumber(parseInt(stat?.value, 10)))) ||
          placeholder

        return (
          <div key={stat.id} className={printClasses([stat?.divish && 'ish'])}>
            <p className="text--large">
              {stat.isBold ? <b>{value}</b> : value}
            </p>
            <p
              className={printClasses([
                'text--smaller-caps',
                stat?.labelish && 'ish',
              ])}
            >
              {stat.icon === undefined && stat.label}
              {stat.icon && (
                <IconText icon={stat.icon} text={stat.label} cnames="" />
              )}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default JumpStats
