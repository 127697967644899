import React from 'react'

import AppLayout from '../components/layout/Layout'
import SubscribeDialog from '../components/molecules/SubscribeDialog'
import WorkshopItem from '../components/structures/WorkshopItem'

import { setBackgroundImage, displayTrackName } from '../Helpers'
import { useWorkshops } from '../context/atomContext'

function Workshops() {
  const { data } = useWorkshops()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [showSubDialog, setShowSubDialog] = React.useState(false)
  const workshops = data?.value?.rows || []
  setBackgroundImage('') // unsets any default bg image hanging around

  const headerConfig = {
    label: 'Workshops',
  }

  const workshopGroups = workshops
    .map(workshop => workshop.track)
    .reduce(
      (acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]),
      [undefined],
    )

  const heels = (
    <SubscribeDialog
      isOpen={showSubDialog}
      handleDismiss={() => setShowSubDialog(false)}
    />
  )

  return (
    <AppLayout
      name="workshops"
      headerConfig={headerConfig}
      heels={heels}
      isLoading={false}
      isError={false}
      errorAction={() => window.location.reload()}
      cnames="workshops one-column-content"
    >
      <div className="app-content--inner">
        {workshopGroups.map(group => {
          const groupWorkshops = workshops.filter(
            workshop => workshop.track === group,
          )
          return groupWorkshops.length > 0 ? (
            <React.Fragment key={group || 'undefined'}>
              <h5 className="dashboard--section--title text--caps">
                {displayTrackName(group || 'atomic')}
              </h5>
              {groupWorkshops.map(workshop => (
                <WorkshopItem
                  workshop={workshop}
                  key={workshop.id}
                  setShowSubDialog={setShowSubDialog}
                />
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment key="undefined" />
          )
        })}
      </div>
    </AppLayout>
  )
}

export default Workshops
