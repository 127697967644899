import React, { useState } from 'react'
import MUITooltip from '@mui/material/Tooltip'
import MUIButton from '@mui/material/Button'
import MUIClickAwayListener from '@mui/material/ClickAwayListener'

type TrainingToolTipProps = {
  tooltip: mixed,
  children: mixed,
  buttonStyles?: mixed,
}

TrainingToolTip.defaultProps = {
  buttonStyles: undefined,
}

function TrainingToolTip(props: TrainingToolTipProps) {
  const { tooltip, children, buttonStyles } = props

  const [open, setOpen] = useState(false)

  const dismissTT = () => setOpen(false)
  const openTT = () => setOpen(true)

  return (
    <MUIClickAwayListener onClickAway={dismissTT}>
      <MUITooltip
        placement="bottom"
        arrow
        PopperProps={{
          disablePortal: true,
        }}
        onClose={dismissTT}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <div className="affiliates">
            <div className="rpm-tooltip--content">{tooltip}</div>
          </div>
        }
      >
        <MUIButton
          disableRipple
          onClick={open ? dismissTT : openTT}
          sx={buttonStyles}
        >
          {children}
        </MUIButton>
      </MUITooltip>
    </MUIClickAwayListener>
  )
}

export default TrainingToolTip
