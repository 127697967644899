import React, { useState, useContext } from 'react'

import Button from '../../components/elements/Button'
import ColorPicker from '../../components/elements/ColorPicker'

import InputGroup from '../../components/inputs/InputGroup'
import TextField from '../../components/inputs/TextField'
import Textarea from '../../components/inputs/Textarea'
import ProfileImageForm from '../../components/inputs/ProfileImageForm'
import ListUsers from '../../components/structures/ListUsers'

import ConfirmationDialog from '../../components/molecules/ConfirmationDialog'

import {
  useCreateTeam,
  useImageUpload,
  useLeaveTeam,
  useDeleteTeam,
} from '../../context/atomContext'

import { SocialContext } from '../../context/socialContext'

import { foregroundAdjust, filterCollection } from '../../Helpers'

type Props = {
  dismissPanel: mixed,
  myTeam?: Object,
}

CreateTeam.defaultProps = {
  myTeam: null,
}

function CreateTeam(props: Props) {
  // State
  const { dismissPanel, myTeam } = props
  const [teamName, setTeamName] = useState(myTeam?.name || '')
  const [teamColor, setTeamColor] = useState(myTeam?.color || '')
  const [teamBio, setTeamBio] = useState(myTeam?.description || '')
  const [profileImageData, setProfileImageData] = useState(null)
  const [showDeleteTeam, setShowDeleteTeam] = useState(false)

  // Context
  const { openProfile } = useContext(SocialContext)

  // API
  const createTeam = useCreateTeam(myTeam?.id)
  const leaveTeam = useLeaveTeam()
  const deleteTeam = useDeleteTeam()
  const imageUpload = useImageUpload('event/challenge/team/image', [
    'event',
    'team',
    'myTeam',
  ])

  // test data
  // Variables
  const useColorPicker = false
  const teamMembers = filterCollection(myTeam?.members, 'status', 'member')
  const pendingInvites = filterCollection(myTeam?.members, 'status', 'invited')
  const profileImageFormStyles = teamColor
    ? {
        '--block-bg': teamColor,
        '--block-fg': foregroundAdjust(teamColor),
      }
    : null

  // functions
  const dismissDeleteTeam = () => setShowDeleteTeam(false)

  const submitCreateTeam = () => {
    const postData = {
      name: teamName,
      description: teamBio,
    }

    createTeam.mutate(postData, {
      onSuccess: response => {
        if (profileImageData) {
          imageUpload.mutate(
            {
              postData: profileImageData,
              params: { id: response.data.value.id },
            },
            {
              onSuccess: () => {
                dismissPanel()
              },
              onError: anError => {
                console.log(anError)
              },
            },
          )
        } else {
          dismissPanel()
        }
      },
      onError: anError => {
        console.log(anError)
      },
    })
  }

  // validators
  const getTeamNameError = () => {
    if (createTeam?.error?.data?.name?.length > 0)
      return createTeam.error.data.name[0]
  }

  return (
    <>
      <ProfileImageForm
        image={myTeam?.image}
        deleteDialogContent={
          <>
            <p>
              <b>Are you sure you want to delete your team profile image?</b>
            </p>
            <p>This can&apos;t be undone.</p>
          </>
        }
        action="event/challenge/team/image"
        placeholderIcon="users"
        emptyLabel="Change Team Image"
        cnames="rpm-marquee team-avatar"
        styles={profileImageFormStyles}
        noUpload
        onChange={setProfileImageData}
        inputName="image"
        cacheKey={['event', 'team', 'myTeam']}
      />

      <section className="section-block">
        <div className="rpm-block">
          <InputGroup
            columns="1fr auto"
            styles={
              useColorPicker && {
                '--input-group-items-border-left': 'none',
                '--group-gap': 'var(--space-default)',
                '--group-border-radius': 'var(--radius-default)',
              }
            }
          >
            <TextField
              id="teamName"
              label="Team Name"
              placeholder="Name"
              value={teamName}
              error={getTeamNameError()}
              changed={e => setTeamName(e.target.value)}
              maxlength={20}
              required
            />
            {useColorPicker && (
              <ColorPicker
                label="Color"
                color={teamColor}
                setColor={setTeamColor}
                randomize
              />
            )}
          </InputGroup>

          <p>
            <Textarea
              id="bioInput"
              name="bioInput"
              placeholder="Description"
              value={teamBio}
              label="Bio"
              rows={6}
              changed={event => setTeamBio(event.target.value)}
            />
          </p>
        </div>
        <div className="rpm-button-block">
          {myTeam?.id && (
            <Button
              kind="danger"
              label="Delete Team"
              onClick={() => setShowDeleteTeam(true)}
              isOutlined
            />
          )}

          <Button
            kind="primary"
            label="Save Team"
            onClick={() => submitCreateTeam()}
            disabled={createTeam.isLoading || imageUpload.isLoading}
          />
        </div>
      </section>

      {teamMembers?.length > 0 && (
        <section className="section-block">
          <div className="rpm-header">
            <h5 className="text--caps">
              <span>Team Members</span>
            </h5>
          </div>

          <ListUsers
            users={teamMembers}
            openUserProfile={openProfile}
            isAdmin={myTeam.isTeamAdmin}
            onRemove={(userId, options) => {
              leaveTeam.mutate({ teamId: myTeam.id, userId }, options)
            }}
            showRemove
          />
        </section>
      )}

      {pendingInvites?.length > 0 && (
        <section className="section-block">
          <div className="rpm-header">
            <h5 className="text--caps">
              <span>Pending Invites</span>
            </h5>
          </div>

          <ListUsers
            users={pendingInvites}
            openUserProfile={openProfile}
            isAdmin={myTeam.isTeamAdmin}
            onRemove={(userId, options) => {
              leaveTeam.mutate({ teamId: myTeam.id, userId }, options)
            }}
            showRemove
          />
        </section>
      )}

      <ConfirmationDialog
        isOpen={showDeleteTeam}
        header="Delete Team"
        primaryText="Delete"
        primaryAction={() => {
          deleteTeam.mutate(myTeam.id, {
            onSuccess: () => {
              dismissDeleteTeam()
              dismissPanel()
            },
            onError: anError => {
              console.log(anError)
              dismissDeleteTeam()
            },
          })
        }}
        secondaryText="Cancel"
        secondaryAction={dismissDeleteTeam}
        cnames="app-comments--delete-dialog"
      >
        <p>
          <b>
            You are deleting this team. If you continue, this team will be
            removed from the community leaderboard and all team members will be
            free to join other teams.
          </b>
        </p>
        <p>This cannot be undone.</p>
      </ConfirmationDialog>
    </>
  )
}

export default CreateTeam
