import React from 'react'

import {
  useEventTeam,
  useMyEventTeam,
  useJoinTeam,
  useStatus,
  useProfile,
} from '../../context/atomContext'

import UserProfileRow from '../structures/UserProfileRow'

import Panel from '../elements/Panel'
import Button from '../elements/Button'

import ListUsers from '../structures/ListUsers'

import LoadingData from '../molecules/LoadingData'

import { filterCollection } from '../../Helpers'

type Props = {
  isOpen: boolean,
  handleDismiss: Object,
  openUserProfile: () => mixed,
  teamId: Object,
  zIndex?: number,
}

GroupProfile.defaultProps = {
  zIndex: null,
}

function GroupProfile(props: Props) {
  // State
  const { isOpen, handleDismiss, openUserProfile, teamId, zIndex } = props

  // API
  const status = useStatus()
  const { data: myProfile, isLoading: isLoadingProfile } = useProfile()

  const { data, isLoading, isError, refetch } = useEventTeam(
    teamId,
    teamId !== undefined &&
      status?.loggedIn &&
      !isLoadingProfile &&
      myProfile?.value?.registrations?.event10k2023,
  )
  const { data: teamData, isLoading: myTeamIsLoading } = useMyEventTeam(
    !isLoadingProfile && myProfile?.value?.registrations?.event10k2023,
  )
  const joinTeam = useJoinTeam()

  // If we have no team then don't bother rendering this
  if (!teamId) return ''

  // Variables
  const team = data?.value
  const teamMembers = filterCollection(team?.members, 'status', 'member')
  const pendingInvites = filterCollection(team?.members, 'status', 'invited')

  // Functions
  const translateTeam = t => ({
    name: t.name,
    username: t.name,
    profileImage: t.image,
  })

  const onJoinTeam = () => {
    const postData = {
      userId: status?.user?.id,
      teamId,
    }
    joinTeam.mutate(postData, {
      onSuccess: () => {
        handleDismiss(true)
      },
    })
  }

  // Fragments
  const panelFooter = !myTeamIsLoading && !teamData.value?.id && (
    <div className="rpm-description">
      <Button label="Join Team" onClick={onJoinTeam} />
    </div>
  )

  return (
    <Panel
      isOpen={isOpen}
      isLoading={isLoading}
      handleDismiss={handleDismiss}
      header="Team Profile"
      footer={panelFooter}
      opensFrom="right"
      cnames="team-panel team-panel--profile"
      zIndex={zIndex}
    >
      {isLoading && <LoadingData isLoading={isLoading} />}

      {isError && (
        <div className="app-header--error--content rpm-container">
          <p>
            <strong>We experienced an internal issue.</strong>
          </p>

          <p>
            Please retry. If you continue to see this error, please contact RPM
            customer support.
          </p>

          <p className="text--center">
            <Button label="Retry" kind="danger" onClick={() => refetch()} />
          </p>
        </div>
      )}

      {!isLoading && !isError && (
        <>
          <UserProfileRow
            user={translateTeam(team)}
            isPublic
            showKitLevel={false}
            cnames="rpm-marquee"
          />

          <section className="section-block">
            <div className="rpm-block">
              <h4>BIO</h4>
              <p>{team?.description}</p>
            </div>
          </section>

          <section className="section-block">
            <div className="rpm-block">
              <h4 className="flex--auto-spread text--caps">
                <span>Team Members ({teamMembers.length})</span>
              </h4>
            </div>
          </section>

          <ListUsers
            users={teamMembers}
            openUserProfile={openUserProfile}
            disableComments
            disableLikes
          />

          {pendingInvites.length > 0 && (
            <>
              <section className="section-block">
                <div className="rpm-block">
                  <h4 className="flex--auto-spread text--caps">
                    <span>Pending Invites ({pendingInvites.length})</span>
                  </h4>
                </div>
              </section>

              <ListUsers
                users={pendingInvites}
                openUserProfile={openUserProfile}
                disableComments
                disableLikes
              />
            </>
          )}
        </>
      )}
    </Panel>
  )
}

export default GroupProfile
