import React from 'react'

import { printClasses } from '../../Helpers'

type Props = {
  children?: any,
  label?: string,
  columns?: string,
  gap?: string,
  cnames?: string,
  styles?: any,
}

InputGroup.defaultProps = {
  children: null,
  label: null,
  columns: '1fr 1fr',
  gap: null,
  cnames: null,
  styles: null,
}

function InputGroup(props: Props) {
  const { children, label, columns, gap, cnames, styles } = props
  const hasGap = gap !== null && 'has--gap'
  const classes = ['rpm-input--group', cnames]

  return (
    <div
      className={printClasses(classes)}
      style={{ ...styles, '--columns': columns, '--group-gap': gap }}
    >
      {label && <div className="rpm-input--label">{label}</div>}
      <div className={printClasses(['rpm-input--group--items', hasGap])}>
        {children}
      </div>
    </div>
  )
}

export default InputGroup
