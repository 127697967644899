import React from 'react'
import dayjs from 'dayjs'
import { useStatus } from '../../context/atomContext'

function Footer() {
  const status = useStatus()

  return (
    <footer className="app-footer">
      <small>
        &copy; {dayjs().format('YYYY')} Atom All Rights Reserved.{' '}
        {status?.loggedIn && (
          <>
            <br />
            <a href="/terms-of-service" target="_blank" rel="noreferrer">
              Terms&nbsp;of&nbsp;Service
            </a>{' '}
            |{' '}
            <a href="/privacy-policy" target="_blank" rel="noreferrer">
              Privacy&nbsp;Policy
            </a>{' '}
            |{' '}
            <a href="/terms-of-sale" target="_blank" rel="noreferrer">
              Terms of Sale
            </a>{' '}
            |{' '}
            <a href="/accessibility" target="_blank" rel="noreferrer">
              Accessibility
            </a>
          </>
        )}
      </small>
    </footer>
  )
}

export default Footer
