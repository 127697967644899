import React, { useState } from 'react'

import Dialog from './Dialog'
import MarkdownRaw from './MarkdownRaw'

import RenderLink from './RenderLink'
import VideoPlayer from '../structures/VideoPlayer'

type Props = {
  children: String,
  className?: String,
  allowedElements?: Array | undefined,
  forbiddenElements?: Array | undefined,
}

Markdown.defaultProps = {
  className: '',
  allowedElements: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'a',
    'br',
    'strong',
    'em',
    'blockquote',
    'ol',
    'ul',
    'li',
    'pre',
    'code',
    'hr',
    'i',
    'small',
    'sup',
    'center',
  ],
  forbiddenElements: [],
}

function Markdown(props: Props) {
  const { children, className, allowedElements, forbiddenElements } = props
  const [demoVideo, setDemoVideo] = useState(undefined)
  const [demoVideoMetaData, setDemoVideoMetaData] = useState(undefined)
  const dismissDemoVideo = () => setDemoVideo(false)

  const renderedChildren = React.useMemo(() => {
    if (!children) return ''
    return (
      <MarkdownRaw
        className={className}
        allowedElements={allowedElements}
        forbiddenElements={forbiddenElements}
        components={{
          a({ children: child, ...options }) {
            return (
              <RenderLink href={options.href} displayVideo={setDemoVideo}>
                {child}
              </RenderLink>
            )
          },
        }}
      >
        {children}
      </MarkdownRaw>
    )
  }, [children, className, allowedElements, forbiddenElements])

  return (
    <>
      {renderedChildren}
      {demoVideo && (
        <Dialog
          isOpen={demoVideo}
          handleDismiss={dismissDemoVideo}
          header={demoVideoMetaData?.name || 'Demo'}
          cnames="training--dialog--demo-video ui-dark"
        >
          {demoVideo?.videoId && (
            <VideoPlayer
              videoId={demoVideo?.videoId}
              showWorkoutOverlay={false}
              resume={false}
              autoPlay
              onMetaDataLoad={player => setDemoVideoMetaData(player?.mediainfo)}
            />
          )}
          {demoVideo?.ytVideoId && (
            <div
              style={{
                padding: '58.67% 0 0 0',
                position: 'relative',
              }}
            >
              <iframe
                title="Youtube Video Embed"
                id="ytplayer"
                type="text/html"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                src={`https://www.youtube.com/embed/${demoVideo.ytVideoId}?autoplay=1`}
                frameBorder="0"
                autoPlay
              />
            </div>
          )}
        </Dialog>
      )}
    </>
  )
}

export default Markdown
