import dayjs from 'dayjs'
import dataKits from './data/kits.json'
import dataDivisions from './data/divisions.json'
import dataTypes from './data/affiliateFilterTypes.json'
import dataLevels from './data/levels.json'
import Countries from './data/countries.json'
import Tracks from './data/tracks.json'
import Tours from './data/tours.json'

export const videoFilters = {
  movements: [
    {
      id: 'm1',
      label: 'Mobility',
      value: 'mobility',
      group: 'movement',
    },
    {
      id: 'm2',
      label: 'Warm-ups',
      value: 'warm_ups',
      group: 'movement',
    },
    {
      id: 'm3',
      label: 'Workouts',
      value: 'workouts',
      group: 'movement',
    },
  ],
  bodyParts: [
    {
      id: 'b1',
      label: 'Hips/Back',
      value: 'hips_back',
      group: 'body',
    },
    {
      id: 'b2',
      label: 'Legs',
      value: 'legs',
      group: 'body',
    },
    {
      id: 'b3',
      label: 'Shoulder/Arms',
      value: 'shoulder_arms',
      group: 'body',
    },
  ],
  equipment: [
    {
      id: 'e1',
      label: 'Equipment',
      value: 'equipment',
      group: 'equipment',
    },
    {
      id: 'e2',
      label: 'No Equipment',
      value: 'no_equipment',
      group: 'equipment',
    },
  ],
}

export const feltLikeChoices = [
  {
    value: 1,
    icon: {
      name: 'face-grin-stars',
      type: 'fal',
      fw: false,
    },
    label: 'Amazing',
  },
  {
    value: 2,
    icon: {
      name: 'face-smile',
      type: 'fal',
      fw: false,
    },
    label: 'Good',
  },
  {
    value: 3,
    icon: {
      name: 'face-meh',
      type: 'fal',
      fw: false,
    },
    label: 'Average',
  },
  {
    value: 4,
    icon: {
      name: 'face-frown',
      type: 'fal',
      fw: false,
    },
    label: 'Subpar',
  },
  {
    value: 5,
    icon: {
      name: 'face-eyes-xmarks',
      type: 'fal',
      fw: false,
    },
    label: 'Awful',
  },
]

export function getLogoutUrl() {
  const loginExtras =
    typeof window !== 'undefined'
      ? `onSuccess=${window?.location?.protocol}//${
          window?.location?.hostname
        }${window?.location?.port ? `:${window?.location?.port}` : ''}`
      : ''

  return `/api/authenticate/logout?${loginExtras}`
}

export function checkIsMe(me, row, property = 'userId') {
  return (
    row &&
    Object.keys(row).length > 0 &&
    row[property] &&
    Object.is(row[property], me?.user?.id)
  )
}

export const isRoleType = (profile, role) => {
  const roles = profile?.user?.roles
  if (!roles) return false
  if (Array.isArray(role)) {
    for (let i = 0; i < role.length; i += 1) {
      if (roles[role[i]]) return true
    }
  } else if (roles[role]) {
    return true
  }
  return false
}

export const isPermissionType = (profile, permission) => {
  const permissions = profile?.user?.permissions
  if (!permissions) return false
  if (Array.isArray(permission)) {
    for (let i = 0; i < permission.length; i += 1) {
      if (permissions[permission[i]]) return true
    }
  } else if (permissions[permission]) {
    return true
  }
  return false
}

export function getTrack(trackId) {
  const realTrackId = trackId === 'challenge' ? 'event10k' : trackId
  return Tracks.find(entry => Object.is(entry.id, realTrackId))
}

export function getTrackString(
  subscriptions,
  key,
  defaultValue,
  ignore = ['manual'],
) {
  const myTracks =
    subscriptions &&
    Object.keys(subscriptions)
      .filter(k => !ignore.includes(k) && subscriptions[k])
      .map(k => getTrack(k))

  return myTracks?.length === 1 &&
    myTracks[0] !== undefined &&
    myTracks?.[0].strings?.[key]
    ? myTracks?.[0].strings?.[key]
    : defaultValue
}

export function getTour(tourId) {
  return Tours[tourId]
}

export function getWorkshop(workshopID, workshops) {
  return workshops.find(entry => Object.is(entry.slug, workshopID))
}

export function displayTrackName(trackId) {
  return getTrack(trackId)?.name
}

export function displayTrackDescription(trackId) {
  return getTrack(trackId)?.description
}

export function displayUserLocale(user: Object) {
  const country = Countries.find(entry => Object.is(entry.code, user?.country))

  const province =
    country?.provinces?.length > 0 &&
    country.provinces.find(entry => Object.is(entry.code, user?.province))

  return (
    ((province || country) &&
      glueThese([province?.name, country?.name], 'all', ', ')) ||
    null
  )
}

export function userData(user) {
  const usersName =
    user?.name || glueThese([user?.firstName, user?.lastName], 'all', ' ')

  const profileName = user?.username && `@${user.username}`

  const kit = user?.kit && kitLabelWithIcon(user.kit)

  const level =
    user?.level && Math.min(4, Math.round(Number(user.level.replace('L', ''))))

  return {
    name: usersName,
    username: profileName,
    locale: displayUserLocale(user),
    kit,
    level,
  }
}

export function sendEvent(eventName) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: eventName,
  })
}

export function rpmStoreLink(slug) {
  return `https://rpmtraining.com/products/${slug}`
}

export function debounce(callback, time) {
  let timer

  return (...args) => {
    if (timer) clearTimeout(timer)

    timer = setTimeout(() => {
      timer = null
      callback.apply(this, args)
    }, time || 200)
  }
}

export function randomColor() {
  const hexCode = Math.floor(Math.random() * 16777216).toString(16)

  return `#${`00${hexCode}`.substr(-6)}`
}

export function isValidColor(color: string) {
  return /#[A-Fa-f0-9]{6}$/i.test(color)
}

export function fixColor(color: string, full: boolean = false) {
  let fixedColor = color.replace(/[^A-Fa-f0-9]/g, '')

  if (full && fixedColor.length < 6) {
    fixedColor = `${fixedColor[0]}${fixedColor[0]}${fixedColor[1]}${fixedColor[1]}${fixedColor[2]}${fixedColor[2]}`
  }

  return `#${fixedColor}`
}

export function hex2rgb(hex: string) {
  let color = isValidColor(hex) ? hex : fixColor(hex, true)
  color = color.replace(/#/g, '')
  const r = parseInt(color.substring(0, 2), 16) || 0
  const g = parseInt(color.substring(2, 4), 16) || 0
  const b = parseInt(color.substring(4, 6), 16) || 0

  return { r, g, b }
}

export function brightness(rgb: Object) {
  return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
}

export function foregroundAdjust(
  hex: string,
  test: number = 154,
  lightHex: string = 'var(--text-light)',
  darkHex: string = 'var(--text-dark)',
) {
  return brightness(hex2rgb(hex)) < test ? lightHex : darkHex
}

export function setBackgroundImage(
  image: string,
  prop: string = 'bg-image',
  screen: number,
): any {
  const styles = [
    { key: `--${prop}`, value: image ? `url(${image})` : '' },
    { key: '--bg-screen', value: screen >= 0 ? screen : '' },
  ]

  return styles.forEach(style =>
    document.documentElement.style.setProperty(style.key, style.value),
  )
}

export function formatRawNumber(value: number) {
  return value?.toLocaleString()
}

export function abbreviateNumber(value: number) {
  const exp = value
    .toExponential()
    .split('e+')
    .map(el => +el)

  const mod = exp[1] % 3
  exp[0] *= 10 ** mod
  exp[1] = ['', 'K', 'M', 'B', 'T', 'Quad', 'Quint', 'Sext'][(exp[1] - mod) / 3]

  const num = exp[0]
  let fix

  if (parseInt(num, 10).toString().length === 3) fix = 0 // Removes decimal on numbers with 3 digitx; ex: 999
  if (parseInt(num, 10).toString().length === 2) fix = 1 // Only allow 1 decimal on numbers with 2 digits; ex: 99.9
  if (parseInt(num, 10).toString().length === 1) fix = 2 // Allow up to 2 decimals on numbers with 1 digitM ex; 9.99

  return `${num.toFixed(fix) * 1}${exp[1]}`
}

export function formattedScore(aScore: number, aType: string = 'reps') {
  let min = 0
  let sec = 0

  switch (aType) {
    case 'time':
      min = Math.floor(Number(aScore) / 60).toString()
      sec = Number(aScore) % 60
      if (sec < 10) sec = `0${sec}`
      return `${min}:${sec}`

    case 'reps':
      return aScore

    default:
      return aScore
  }
}

export function getMyTrainingScoring(myTraining) {
  const workout = myTraining && myTraining?.kit && myTraining?.training?.workout

  if (!workout) return 'reps'

  const myTrainingScoring =
    workout[myTraining?.kit]?.scoring ||
    workout[myTraining?.kit]?.format?.scoring

  return myTrainingScoring || 'reps'
}

export function kitForID(id) {
  return dataKits.find(kit => kit.id === id) || {}
}

export function trainingKits(track, allowedKits = [], kits = undefined) {
  const allowAllKits = allowedKits.length === 0
  const allKits = kits || dataKits
  return allKits.filter(
    kit =>
      kit.tracks.includes(track) &&
      (allowAllKits || allowedKits.includes(kit.id)),
  )
}

export function kitLabel(kitType: string | number) {
  const foundKit = kitForID(kitType)

  return foundKit?.label || '—'
}

export function defaultKitByTrack(track, profileKit) {
  const lastSelectedKit = localStorage.getItem(`kitForTrack-${track}`)
  if (lastSelectedKit) return lastSelectedKit
  if (profileKit) return profileKit
  if (track === 'element') return 'ELEMENT_KIT'
  if (track === 'persist') return 'PERSIST_PILLARS'
  if (track === 'affiliate') return 'GYM_KIT'
  if (track === 'strength') return 'STRENGTH_KIT'
  return 'POWER_KIT'
}

export const KIT_MAP = {
  1: 'AXIS_KIT',
  2: 'FLY_KIT',
  3: 'POWER_KIT',
  4: 'ELEMENT_KIT',
  14: 'STRENGTH_KIT',
  16: 'PERSIST_PILLARS',
}

export const POWER_KIT = 'POWER_KIT'
export const AXIS_KIT = 'AXIS_KIT'
export const FLY_KIT = 'FLY_KIT'
export const ELEMENT_KIT = 'ELEMENT_KIT'
export const ATOM_KITS = [POWER_KIT, FLY_KIT, AXIS_KIT]

export function kitLabelWithIcon(
  kitType: string | number,
  iconSize: string = 'lg',
) {
  let kitId = kitType

  if (Number(kitType)) {
    kitId = KIT_MAP[kitType]
  }

  const foundKit = kitForID(kitId)
  const { icons, label } = foundKit
  const resizedIcons = icons?.map(icon => ({ ...icon, size: iconSize }))

  return { icons: resizedIcons, label } || { label: '–' }
}

export function cleanedLevel(level) {
  return level
    ? Math.min(4, Math.round(Number(level?.toString().replace('L', ''))))
    : null
}

export function divisionForID(id) {
  let aDivision

  dataDivisions.forEach(divisionGroup => {
    if (divisionGroup.id === id) aDivision = divisionGroup

    divisionGroup.children.forEach(division => {
      if (division.id === id) aDivision = division
    })
  })

  return aDivision
}

export function levelForID(id) {
  return dataLevels.find(level => level.id === id) || {}
}

export function typeForID(id) {
  return dataTypes.find(type => type.id === id) || {}
}

export function cleanString(string: string): any {
  return string?.length ? string.replace(/\s+/g, '-').toLowerCase() : 'missing'
}

export function formattedTime(seconds: number): any {
  const min = Math.floor(seconds / 60)
  const sec = `0${String(Math.floor(seconds % 60))}`
  return `${min}:${sec.substr(sec.length - 2)}`
}

export function calcTimeString(time: string) {
  const parts = time.toString().split(':')
  const minutes = Number(parts[0] || 0)
  const seconds = Number(parts[1] || 0)

  return minutes * 60 + seconds
}

export function dateMe(date: any, kind: string) {
  const formats = {
    day: 'MMM DD',
    month: 'MMM YYYY',
    full: 'MMM DD, YYYY',
    long: 'MMMM DD, YYYY',
    data: 'YYYY-MM-DD',
    title: 'MM.DD.YY',
    yearMonth: 'YYYY-MM',
  }

  return dayjs(date).format(formats[kind] || formats.data)
}

export function roundToStep(value: number, step: number = 1.0) {
  const mod = 1 / Number(step)

  return Math.round(Number(value) * mod) / mod
}

export function toImperial(from: string, value: number) {
  const kpp = 2.2 // kilograms per pound
  const cpf = 30.48 // centimeters per foot
  let result

  switch (from) {
    case 'cm':
    case 'length':
    case 'height': {
      const actual = Number(value || 0) / cpf
      const feet = Math.floor(actual)
      const inches = roundToStep((actual - feet) * 12, 0.5)
      result = { feet, inches }
      break
    }

    case 'kg':
    case 'weight': {
      result = roundToStep(Number(value || 0) * kpp, 0.1)
      break
    }

    default: {
      result = 0
    }
  }

  return result
}

export function toMetric(from: string, value: number | Object) {
  const kpp = 2.2 // kilograms per pound
  const cpi = 2.54 // centimeters per inch
  let result

  switch (from) {
    case 'lbs':
    case 'weight': {
      result = roundToStep(Number(value || 0) / kpp, 0.1)
      break
    }

    case 'length':
    case 'height': {
      const inches = Number(value.feet || 0) * 12 + Number(value.inches || 0)
      result = Math.round(inches * cpi)
      break
    }

    default: {
      result = 0
    }
  }

  return result
}

export function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) // iPad on iOS 13 detection
  )
}

export function glueThese(items, whichOnes = 'ends', using = '–') {
  let array = items

  if (Object.is(whichOnes, 'ends')) {
    const first = items && items[0]
    const last = items?.length > 1 && items[items.length - 1]
    array = [first, last]
  }

  return [...array].filter(Boolean).join(using)
}

export function printClasses(classes: any) {
  return glueThese(classes, 'all', ' ')
}

export const findClosestAbsolutelyPositionedAncestor = element => {
  let currentElement = element.parentElement
  while (currentElement) {
    const { position } = window.getComputedStyle(currentElement)
    if (position === 'absolute') {
      return currentElement
    }
    currentElement = currentElement.parentElement
  }
  return null
}

export function ripple(
  event: any,
  inverted: boolean = false,
  time: number = 1500,
) {
  const element = event.touches ? event.touches[0].target : event.target

  // Find the closest absolutely positioned ancestor (if any)
  const closestAncestor = findClosestAbsolutelyPositionedAncestor(element)

  // Calculate the relative position of the click based on the element's positioning
  const pageX = closestAncestor
    ? event.clientX - closestAncestor.getBoundingClientRect().left
    : event.pageX - element.offsetLeft
  const pageY = closestAncestor
    ? event.clientY - closestAncestor.getBoundingClientRect().top
    : event.pageY - element.offsetTop

  const rppl = document.createElement('span')
  const scale = Math.max(element.offsetWidth, element.offsetHeight)
  const moreTime = scale > 200 ? time * 1.5 : time
  const lessTime = scale < 100 ? time * 0.75 : time
  const computedTime = scale > 200 ? moreTime : lessTime

  const remove = () => element.removeChild(rppl)

  rppl.className = inverted ? 'ripple ripple--invert' : 'ripple'
  rppl.style.setProperty('--left', `${pageX}px`)
  rppl.style.setProperty('--top', `${pageY}px`)
  rppl.style.setProperty('--scale', scale.toString())
  rppl.style.setProperty('--time', `${computedTime}ms`)

  element.appendChild(rppl)

  setTimeout(remove, time)
}

export function getMessageFromError(error: Error) {
  if (error?.name) {
    switch (error?.name) {
      case 'ErrorNoSubscription':
        return error?.message
      default:
        return null
    }
  }
  return null
}

export function filterCollection(
  collection: any,
  prop: string = '',
  value: any,
) {
  return collection?.filter(item => Object.is(item[prop], value)) || []
}

export function urlForTraining(training) {
  let kitVal = ''
  if (training.kit) {
    kitVal = `&kit=${training.kit}`
  }

  let url = `/training?date=${training.date}${kitVal}`

  if (training.track === 'challenge') {
    url = '/events/10k'
  } else if (training.track === 'element' && training.kit === 'ELEMENT_KIT') {
    url = `/element/training?date=${training.date}${kitVal}`
  } else if (training.kit === 'STRENGTH_KIT') {
    url = `/strength/training?date=${training.date}${kitVal}`
  } else if (training.track === 'affiliate') {
    url = `/affiliate/training?date=${training.date}${kitVal}`
  } else if (training.track === 'workshop') {
    url = `/workshops/${training.training.slug}/training?day=${training.day}${kitVal}`
  } else if (training.track === 'persist') {
    url = `/persist/training?date=${training.date}${kitVal}`
  } else if (
    training.track === 'manual' &&
    training?.metadata?.tags?.includes('persist_nutrition')
  ) {
    url = `/persist/nutrition?date=${training.date}`
  } else if (training.track === 'manual') {
    url = '/journal'
  }

  return url
}

export const links = {
  JOIN_NOW: '/shop',
  ATOM: '/shop',
  REGISTER_10K: 'https://rpmtraining.com/pages/10k-challenge',
  ATOM_SHOP: 'https://trainatom.com/atom/gpp/',
  PERSIST_SHOP: 'https://trainatom.com/persist',
}

export function scrollToElement(selector, parkAt) {
  document
    .querySelector(selector)
    ?.scrollIntoView({ behavior: 'smooth', block: parkAt })
}

export function animateCSS(element, animation, prefix = 'animate__') {
  // We create a Promise and return it
  return new Promise(resolve => {
    let animationName = []
    if (Array.isArray(animation)) {
      animationName = animation.map(val => `${prefix}${val}`)
    } else {
      animationName = [`${prefix}${animation}`]
    }
    const nodes = document.querySelectorAll(element)
    let node
    for (let i = 0; i < nodes.length; i += 1) {
      // offsetParent will be null if this element or any ancestor parent is not visible
      if (nodes[i].offsetParent !== null) {
        node = nodes[i]
        break
      }
    }
    if (!node) {
      // no visible animation so just resolve
      resolve('Animation ended')
    }

    node.classList.add(`${prefix}animated`, ...animationName)

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation()
      node.classList.remove(`${prefix}animated`, ...animationName)
      resolve('Animation ended')
    }

    node.addEventListener('animationend', handleAnimationEnd, { once: true })
  })
}

export function trainingDates(calendarData) {
  if (
    calendarData?.elementUserTraining.length > 0 ||
    calendarData?.persistUserTraining?.length > 0 ||
    calendarData?.strengthUserTraining.length > 0 ||
    calendarData?.atomicUserTraining.length > 0 ||
    calendarData?.affiliateUserTraining.length > 0
  ) {
    const combinedDates = calendarData?.elementUserTraining || []
    return combinedDates.concat(
      calendarData?.persistUserTraining || [],
      calendarData?.atomicUserTraining || [],
      calendarData?.affiliateUserTraining || [],
      calendarData?.strengthUserTraining || [],
    )
  }
  return []
}

export function getCalendarIcons(calendarData) {
  const icons = {}
  const training = trainingDates(calendarData)
  for (let i = 0; i < training.length; i += 1) {
    const curDate = training[i]
    const currentDay = icons[curDate] || []
    const containsTraining =
      currentDay.findIndex(elem => elem.name === 'person-running') !== -1
    if (!containsTraining) {
      icons[curDate] = [
        ...currentDay,
        {
          name: 'person-running',
          type: 'fas',
          size: '2xs',
        },
      ]
    }
  }
  for (
    let i = 0;
    i < calendarData?.persistNutritionUserTraining.length;
    i += 1
  ) {
    const curDate = calendarData?.persistNutritionUserTraining[i]
    const currentDay = icons[curDate] || []
    const containsNutrition =
      currentDay.findIndex(elem => elem.name === 'apple-whole') !== -1
    if (!containsNutrition) {
      icons[curDate] = [
        ...currentDay,
        {
          name: 'apple-whole',
          type: 'fas',
          size: '2xs',
        },
      ]
    }
  }

  return icons
}

// Video Helpers
// keep video place for 2 weeks
const ttl = 1000 * 60 * 60 * 24 * 14

export const getRecentVideos = () => {
  const now = new Date()
  const expireTime = now.getTime()
  const data = window.localStorage.getItem('recentVideos')
  if (data) {
    const jData = JSON.parse(data)
    const newData = {}
    const keys = Object.keys(jData)
    // Get rid of video stamps past our ttl
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i]
      if (jData[key].expires > expireTime) newData[key] = jData[key]
    }
    return newData
  }
  return {}
}

export const updateRecentVideo = (aVideoId, progress) => {
  const recentVideos = getRecentVideos()
  const now = new Date()
  const newData = {
    ...recentVideos,
    [aVideoId]: { progress, expires: now.getTime() + ttl },
  }
  window.localStorage.setItem('recentVideos', JSON.stringify(newData))
  return newData
}
