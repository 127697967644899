import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../elements/Button'
import IconText from '../../elements/IconText'
import Markdown from '../../elements/Markdown'

import { persistTrackData } from '../PersistTourSummary'

import { kitLabelWithIcon } from '../../../Helpers'

type DetailsProps = {
  tourEnabled?: boolean,
  track?: String,
}

PersistDetails.defaultProps = {
  tourEnabled: true,
  track: undefined,
}

function PersistDetails({ tourEnabled, track }: DetailsProps) {
  const history = useHistory()

  const kitObject = track && kitLabelWithIcon(track, 'sm')
  const rowIcons = kitObject?.icons ? [...kitObject?.icons] : []

  const rowLabel = kitObject?.label

  const goToTour = () => history.push('/persist/tour')
  const goToQuiz = () => history.push('/tour?id=persist-quiz')

  return (
    <>
      <div className="dashboard--details--description">
        <h4 className="text--caps text--700 flex--auto-spread">
          <div>
            Persist{' '}
            {track && (
              <>
                -{' '}
                <IconText
                  icons={rowIcons}
                  text={rowLabel}
                  cnames="user--meta--kit"
                />
              </>
            )}
          </div>
        </h4>
        {track && (
          <div className="flex--auto-spread persist-tour-summary--details--subtitle">
            <i>{persistTrackData[track].subTitle}</i>
          </div>
        )}
        {track && (
          <div className="flex--auto-gap persist-tour-summary--details--title">
            <div>{persistTrackData[track].time}</div> /{' '}
            <span>{persistTrackData[track].days}</span>
          </div>
        )}

        {!track && (
          <p>
            Welcome to Functional Bodybuilding! Within the Persist program
            you’ll find 4 different tracks: Pump, Perform, Pillars, and
            Minimalist. Feel free to explore, or jump into Pillars if you’re not
            sure where to start. Our coaches are ready for any questions, so
            message us any time. Happy training!
          </p>
        )}
        {track && <Markdown>{persistTrackData[track].text}</Markdown>}
        <p className="flex--auto-gap">
          <Button
            label="Start Tour"
            onClick={goToTour}
            disabled={!tourEnabled}
          />
          <Button label="Track Quiz" onClick={goToQuiz} />
        </p>
      </div>
    </>
  )
}

export default PersistDetails
