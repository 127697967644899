import React from 'react'
import axios from 'axios'
import confetti from 'canvas-confetti'

import Button from '../../components/elements/Button'
import IconText from '../../components/elements/IconText'

function Finisher() {
  async function downloadVideo() {
    const url = '/app/assets/videos/rpm-training-2023-10k-finisher-counter.mp4'

    const baseAxios = axios.create({})
    baseAxios.defaults.baseURL = '/'
    const { data, status } = await baseAxios.get(url, {
      responseType: 'blob',
    })
    if (status === 200) {
      const myBlob = data
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(myBlob)
      link.download = 'rpm-training-2023-10k-finisher-counter.mp4'
      link.click()
    }
  }

  const boom = () => {
    const duration = 5 * 1000
    const animationEnd = Date.now() + duration
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 200 }

    const inRange = (min, max) => Math.random() * (max - min) + min

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      const particleCount = 150 * (timeLeft / duration)

      // since particles fall down, start a bit higher than random
      confetti({
        ...defaults,
        particleCount,
        origin: { x: inRange(0.1, 0.3), y: Math.random() - 0.2 },
      })

      confetti({
        ...defaults,
        particleCount,
        origin: { x: inRange(0.7, 0.9), y: Math.random() - 0.2 },
      })
    }, 250)
  }

  boom()

  return (
    <>
      <section className="rpm-dialog--section rpm-dialog--section--content">
        <img
          src="/app/images/10k/10k-finisher.png"
          alt="10K Challenge Finisher!"
          width="100%"
          className="is-block"
        />
      </section>

      <section className="rpm-dialog--section rpm-dialog--section--buttons text--smaller-caps text--link">
        <div className="rpm-dialog--buttons">
          <a
            href="https://rpmtraining.com/discount/10k%2520Finisher%25202023?redirect=/products/10k-finisher-gift-box-2023"
            target="_blank"
            rel="noreferrer"
            className="has-icon"
          >
            <IconText
              text="Get My Finisher Gift Box"
              icon={{ name: 'gift', type: 'far', size: 'lg' }}
              cnames="text--bold"
            />
          </a>
        </div>

        <div className="rpm-dialog--buttons">
          <Button kind="link" cnames="has-icon" onClick={downloadVideo}>
            <IconText
              icon={{ type: 'fal', name: 'cloud-download', size: 'lg' }}
              text="Download and Share"
            />
          </Button>
        </div>
      </section>
    </>
  )
}

export default Finisher
