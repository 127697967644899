import React, { useEffect, useState } from 'react'
import 'animate.css'

import { Link } from 'react-router-dom'

import {
  printClasses,
  userData,
  cleanedLevel,
  animateCSS,
  scrollToElement,
} from '../../Helpers'

import { useProfile } from '../../context/atomContext'

import { useIsPermission } from '../../hooks/FeatureHooks'

import Avatar from '../elements/Avatar'
import Button from '../elements/Button'
import IconText from '../elements/IconText'
import Icon from '../elements/Icon'

type Props = {
  user: Object,
  isMe?: boolean,
  isPublic?: boolean,
  showKitLevel?: boolean,
  showUsernameKitLevel?: boolean,
  moreDetails?: String,
  openProfile?: Object,
  action?: Object,
  avatarSize?: number,
  avatarBadge?: Object,
  cnames?: string,
  children?: any,
  showWorkoutStats?: boolean,
}

UserProfileRow.defaultProps = {
  isMe: false,
  isPublic: false,
  showKitLevel: true,
  showUsernameKitLevel: false,
  moreDetails: '',
  openProfile: null,
  action: null,
  avatarSize: 5,
  avatarBadge: {},
  cnames: '',
  children: null,
  showWorkoutStats: false,
}

function UserProfileRow(props: Props) {
  const {
    user,
    isMe,
    isPublic,
    showKitLevel,
    showUsernameKitLevel,
    moreDetails,
    openProfile,
    action,
    avatarSize,
    avatarBadge,
    cnames,
    children,
    showWorkoutStats,
  } = props

  const { data: myProfile } = useProfile()
  const isStatsVisible = useIsPermission(['beta', 'statsVisible'])

  const [prevWorkoutTotal, setPrevWorkoutTotal] = useState(undefined)
  const [prevStreak, setPrevStreak] = useState(undefined)

  const { name, username, locale, kit, level } = userData(user)
  const levelIcon = { type: 'svg', name: `L${cleanedLevel(level)}`, size: 'lg' }
  const rowIcons = kit?.icons ? [...kit?.icons, levelIcon] : [levelIcon]

  const classes = ['user--row', isMe && 'is-me', action && 'has-action', cnames]

  const userKitLevelIcon = (kitIconClasses = 'user--meta--kit text--small') => (
    <IconText icons={rowIcons} text="" cnames={kitIconClasses} />
  )

  useEffect(() => {
    if (
      prevWorkoutTotal !== undefined &&
      prevWorkoutTotal !== myProfile?.value?.userTraining?.totalTrainingCount
    ) {
      animateCSS('.total-training-profile', 'fadeInDown')
    }
    setPrevWorkoutTotal(myProfile?.value?.userTraining?.totalTrainingCount)
  }, [myProfile?.value?.userTraining?.totalTrainingCount, prevWorkoutTotal])

  useEffect(() => {
    if (
      prevStreak !== undefined &&
      prevStreak !== myProfile?.value?.userTraining?.streaks?.training
    ) {
      animateCSS('.streak-training-profile', 'fadeInDown')
    }
    setPrevStreak(myProfile?.value?.userTraining?.streaks?.training)
  }, [myProfile?.value?.userTraining?.streaks?.training, prevStreak])

  return (
    <section className={printClasses(classes)}>
      <div className="user--row--avatar">
        <Button kind="secret" onClick={openProfile} smartify="div">
          <Avatar
            image={user?.profileImage}
            label={name}
            size={avatarSize}
            flag={{ name: user?.country }}
            badge={avatarBadge}
          />
        </Button>
      </div>

      <div className="user--row--details">
        <p className="user--row--username">
          {name || username ? (
            <Button kind="text" onClick={openProfile} smartify="b">
              <b>{isPublic ? name || username : username}</b>
              {showUsernameKitLevel && level && !isPublic && userKitLevelIcon()}
            </Button>
          ) : (
            <b className="ghost">@...</b>
          )}
        </p>

        {(isPublic || showKitLevel) && (
          <>
            <div className="user--row--meta flex--auto-gap">
              {isPublic &&
                (username ? (
                  <Button kind="text" onClick={openProfile} smartify>
                    <small className="flex--auto-gap">
                      {username}
                      {showUsernameKitLevel && userKitLevelIcon()}
                    </small>
                  </Button>
                ) : (
                  <b className="ghost">@...</b>
                ))}

              {showKitLevel &&
                (kit || level ? (
                  <small>
                    <IconText icons={rowIcons} text="" />
                  </small>
                ) : (
                  isMe && (
                    <small>
                      <Link to="/profile" className="ghost">
                        Set Level
                      </Link>
                    </small>
                  )
                ))}

              {moreDetails && <>{moreDetails}</>}

              {showWorkoutStats && isStatsVisible && (
                <>
                  <Icon name="dash" type="fas" cnames="fa-rotate-90" />
                  <div className="flex--auto-gap workout-stats-profile">
                    <Button
                      kind="text"
                      onClick={() =>
                        scrollToElement('.workout-stats', 'center')
                      }
                    >
                      <div className="flex--auto-gap workout-stats--item">
                        <Icon name="video-segment-main" type="svg" cnames="" />
                        <strong className="underline-lower ">
                          <div
                            className="total-training-profile"
                            style={{ transform: 'translateZ(0)' }}
                          >
                            {myProfile?.value?.userTraining?.totalTrainingCount}
                          </div>
                        </strong>
                      </div>
                    </Button>

                    <Button
                      kind="text"
                      onClick={() =>
                        scrollToElement('.workout-stats', 'center')
                      }
                    >
                      <div className="flex--auto-gap workout-stats--item">
                        <Icon name="streak" type="svg" cnames="" />
                        <strong className="underline-lower">
                          <div
                            className="streak-training-profile"
                            style={{ transform: 'translateZ(0)' }}
                          >
                            {myProfile?.value?.userTraining?.streaks?.training}
                          </div>
                        </strong>
                      </div>
                    </Button>
                  </div>
                </>
              )}
            </div>

            {isPublic && locale && (
              <p className="user--row--locale">{locale}</p>
            )}
          </>
        )}

        {children}
      </div>

      {action && <div className="user--row--action">{action}</div>}
    </section>
  )
}

export default UserProfileRow
