import React, { useState } from 'react'

import Button from './Button'
import IconText from './IconText'

type Props = {
  item: Object,
  children: any,
  open?: Boolean,
  isDisabled?: Boolean,
}

Flyout.defaultProps = {
  open: false,
  isDisabled: false,
}

function Flyout(props: Props) {
  const { item, children, open, isDisabled } = props
  const [isOpen, setIsOpen] = useState(open)

  const label = <IconText icon={item.icon} text={item.label} />

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div className="rpm-flyout" open={isOpen}>
      {!isDisabled ? (
        <>
          <div className="rpm-flyout--label">
            <button className="rpm-label" onClick={toggle}>
              {label}
            </button>
          </div>

          {isOpen && (
            <Button
              kind="shield"
              cnames="rpm-Flyout--shield"
              onClick={toggle}
              canRipple={false}
            />
          )}

          <div className="rpm-flyout--content">{children}</div>
        </>
      ) : (
        <div className="rpm-flyout--label">
          <div className="rpm-label">{label}</div>
        </div>
      )}
    </div>
  )
}

export default Flyout
