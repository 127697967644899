import React from 'react'
import { printClasses } from '../../Helpers'

type Props = {
  isActive: boolean,
}

function Zipper(props: Props) {
  const { isActive } = props
  const classes = ['rpm-zipper', isActive && 'is-active']

  return <div className={printClasses(classes)} />
}

export default Zipper
