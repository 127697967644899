import React from 'react'

import { useLeaderboard } from '../../context/atomContext'

import { formattedScore } from '../../Helpers'

import LeaderboardRow from '../structures/LeaderboardRow'

type Props = {
  date: String,
  updateSocialData: () => void,
  socialContext: Object,
  track: String,
}

function FeaturedAthletes(props: Props) {
  const { date, updateSocialData, socialContext, track } = props
  const { openComments, toggleLike, openProfile } = socialContext

  const { data: athletesData, refetch } = useLeaderboard(
    date,
    { kit: 'POWER_KIT' },
    true,
    track,
    track === 'atomic',
  )

  const refresh = () => {
    updateSocialData()
    refetch()
  }

  const hasData =
    athletesData?.pages?.length > 0 &&
    athletesData.pages[0].value?.rows?.length > 0

  const athletes = hasData && athletesData.pages[0].value.rows
  const totalAthletes = hasData && athletesData.pages[0].value.totalCount
  const { scoring } = hasData && athletesData.pages[0].value

  return (
    hasData && (
      <section className="training--athletes">
        <div className="rpm-description training--featured-athletes">
          <h4 className="text--caps">Featured Athlete Results</h4>
          <ul className="rpm-data-list leaderboard--results">
            {athletes.map(row => (
              <LeaderboardRow
                row={row}
                showRank={false}
                results={totalAthletes}
                comments={row.commentCount.toString()}
                likes={row.likeCount.toString()}
                toggleLike={() =>
                  toggleLike(row.id, track, {
                    onSuccess: refresh,
                  })
                }
                score={formattedScore(row.score, scoring)}
                key={`${row.name}-${row.rank}`}
                openComments={() =>
                  openComments({
                    ...row,
                    onCommentSuccess: refresh,
                  })
                }
                openProfile={() => openProfile(row.userId)}
              />
            ))}
          </ul>
        </div>
      </section>
    )
  )
}

export default FeaturedAthletes
