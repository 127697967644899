import React from 'react'

import Panel from '../elements/Panel'
import Button from '../elements/Button'

type EditDeleteDialogProps = {
  isOpen: Boolean,
  dismiss: () => void,
  displayConfirmDeleteScore: () => void,
  displayEditScoreForm: () => void,
}

function EditDeleteDialog(props: EditDeleteDialogProps) {
  const { isOpen, dismiss, displayConfirmDeleteScore, displayEditScoreForm } =
    props

  return (
    <Panel
      isOpen={isOpen}
      opensFrom="bottom"
      showDismiss
      dismissLabel="Cancel"
      dismissIcon={{}}
      handleDismiss={dismiss}
      cnames="training--panel--score-tools"
    >
      <ul>
        <li>
          <Button
            kind="simple"
            onClick={displayConfirmDeleteScore}
            cnames="text--danger"
          >
            Delete
          </Button>
        </li>
        <li>
          <Button kind="simple" onClick={displayEditScoreForm}>
            Edit
          </Button>
        </li>
      </ul>
    </Panel>
  )
}

export default EditDeleteDialog
