import React, { useState, useEffect, useContext, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

import { useStatus, useLeaderboard } from '../context/atomContext'
import {
  useOpen10kUserComment,
  useOpen10kTeamComment,
  use10kToggleUserLike,
  use10kToggleTeamLike,
} from './parts-for-10k/10kHelpers'
import { SocialContext } from '../context/socialContext'

import {
  checkIsMe,
  dateMe,
  debounce,
  formatRawNumber,
  getMessageFromError,
} from '../Helpers'

import AppLayout from '../components/layout/Layout'

import Button from '../components/elements/Button'
import Zipper from '../components/elements/Zipper'
import Spinner from '../components/elements/Spinner'
import IconText from '../components/elements/IconText'

import Search from '../components/inputs/Search'

import Select10kDivision from '../components/molecules/Select10kDivision'
import Select10kJumpType from '../components/molecules/Select10kJumpType'

import LeaderboardRow from '../components/structures/LeaderboardRow'

type Props = {
  history: Object,
  location: Object,
}

const defaultJumpType = {
  id: 'combined',
  label: 'Combined',
}

function Leaderboard10k(props: Props) {
  const { history, location } = props
  const [activeDivision, setActiveDivision] = useState(
    location?.state?.division || {},
  )
  const [activeJumpType, setActiveJumpType] = useState(defaultJumpType)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermDebounced, setSearchTermDebounced] = useState('')

  const [popComment, setPopComment] = useState(null)

  const {
    openComments: scOpenComments,
    openGroupProfile,
    openProfile,
  } = useContext(SocialContext)

  const selectDivision = item => setActiveDivision(item)
  const selectJumpType = item => setActiveJumpType(item)

  // API
  const {
    isLoading,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    error,
    isError: isLeaderboardError,
    data,
    refetch,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useLeaderboard(
    null,
    {
      division: activeDivision.id,
      jumpType: activeJumpType?.id,
      search: searchTermDebounced,
    },
    false,
    'event/challenge',
  )
  const open10kUserComment = useOpen10kUserComment()
  const open10kTeamComment = useOpen10kTeamComment()
  const toggle10kUserLike = use10kToggleUserLike()
  const toggle10kTeamLike = use10kToggleTeamLike()

  const openComments =
    activeDivision?.id === 'team' ? open10kTeamComment : open10kUserComment
  const toggleLike =
    activeDivision?.id === 'team' ? toggle10kTeamLike : toggle10kUserLike

  const status = useStatus()

  const returnTo = location?.state?.returnTo
  const commentId = location?.state?.commentId

  const hasData =
    data?.pages?.length > 0 && data.pages[0].value?.rows?.length > 0

  // functions
  const debounceSearch = useMemo(
    () => debounce(term => setSearchTermDebounced(term), 500),
    [setSearchTermDebounced],
  )

  const doSearch = value => {
    setSearchTerm(value)
    debounceSearch(value)
  }

  const clearSearch = () => doSearch('')

  const translateTeam = t => ({
    name: t.name,
    username: t.name,
    profileImage: t.image,
    totals: t.totals,
  })

  useEffect(() => {
    console.log('search:', searchTermDebounced)
  }, [searchTermDebounced])

  useEffect(() => {
    if (commentId && popComment !== commentId) {
      scOpenComments({ id: commentId })
      setPopComment(commentId)
    }
  }, [commentId, popComment, scOpenComments])

  const getPageNumber = page => {
    if (!page?.value?.pagination?.self) return undefined
    const myurl = new URL(page.value.pagination.self)
    return myurl.searchParams.get('page')
  }

  const getMyRow = () => data?.pages?.length > 0 && data?.pages[0]?.value?.user
  const getRowScore = row => {
    switch (activeJumpType?.id) {
      case 'singles':
        return formatRawNumber(row?.totals?.singles)
      case 'doubles':
        return formatRawNumber(row?.totals?.doubles)
      default:
        return formatRawNumber(row?.totals.combined)
    }
  }

  const myRow = getMyRow()

  const showUserRow = () => {
    if (!myRow || myRow.length === 0 || !myRow.row) return false
    const myPageUrl = new URL(myRow.page)
    const myPageNum = myPageUrl.searchParams.get('page')
    let returnVal = true
    data.pageParams.forEach((page, index) => {
      if (Number(myPageNum) === Number(page)) returnVal = false
      if (
        page === undefined &&
        Number(myPageNum) === Number(data.pages[index].value.filters.page)
      )
        returnVal = false
    })
    return returnVal
  }

  const getRowDetails = row =>
    row?.totals?.finishDate ? (
      <span>
        <IconText
          icon={{ name: 'trophy', type: 'far' }}
          text="Finished"
          cnames="fg--gold"
        />{' '}
        {dateMe(row?.totals?.finishDate, 'title')}
      </span>
    ) : (
      <>
        {(row?.totals?.todaySingles > 0 || row?.totals?.todayDoubles > 0) && (
          <>
            <span className="ghost">Today</span>{' '}
          </>
        )}
        {row?.totals?.todaySingles > 0 && (
          <IconText
            icon={{ name: 'chevron-up', type: 'fal', cnames: 'ghost' }}
            text={formatRawNumber(row?.totals?.todaySingles || 0)}
            cnames=""
          />
        )}
        {row?.totals?.todayDoubles > 0 && (
          <IconText
            icon={{ name: 'chevrons-up', type: 'fal', cnames: 'ghost' }}
            text={formatRawNumber(row?.totals?.todayDoubles || 0)}
            cnames=""
          />
        )}
        <span className="flex--break"> </span>
        {row?.totals?.singles > 0 && (
          <IconText
            icon={{ name: 'chevron-up', type: 'fal', cnames: 'ghost' }}
            text={formatRawNumber(row?.totals?.singles || 0)}
            cnames=""
          />
        )}
        {row?.totals?.doubles > 0 && (
          <IconText
            icon={{ name: 'chevrons-up', type: 'fal', cnames: 'ghost' }}
            text={formatRawNumber(row?.totals?.doubles || 0)}
            cnames=""
          />
        )}
      </>
    )

  const isAltActionActive =
    Object.keys(activeJumpType).length > 0 ||
    Object.keys(activeDivision).length > 0

  const headerConfig = {
    backAction: () => {
      if (returnTo) history.push(returnTo)
      else history.push('/')
    },
    label: 'Leaderboard',
    altAction: {
      onClick: () => {
        setActiveJumpType(defaultJumpType)
        setActiveDivision({})
        doSearch('')
      },
      icon: { name: 'reset-filters', type: 'svg', size: 'lg' },
      label: 'Filters',
      isActive: isAltActionActive,
    },
  }

  const filters = (
    <nav
      className="rpm-filters leaderboard--filters"
      id="leaderboardFilters"
      key="filters"
    >
      <Select10kDivision item={activeDivision} doSelect={selectDivision} />
      <Select10kJumpType item={activeJumpType} doSelect={selectJumpType} />

      <Search
        id="search"
        noResults={
          <IconText
            icon={{ type: 'fal', name: 'users-slash', size: 'lg' }}
            text="No data found, try again..."
            cnames="fg--accent"
          />
        }
        helpMessage={
          <IconText
            icon={{ type: 'fal', name: 'question-square', size: 'lg' }}
            text="Use at least two characters"
            cnames="ish"
          />
        }
        hasResults
        value={searchTerm}
        debouncedValue={searchTermDebounced}
        changed={doSearch}
        clear={clearSearch}
        maxlength={20}
      />

      <Zipper isActive={isFetching} />
    </nav>
  )

  const subheader = hasData ? (
    <div
      id="leaderboardSubheader"
      className="rpm-subheader leaderboard--subheader"
      key="subheader"
    >
      <span>
        Rank{' '}
        {data?.pages?.length > 0 && data?.pages[0].value?.totalCount && (
          <b>
            <em>/{data.pages[0].value.totalCount}</em>
          </b>
        )}
      </span>
      <span>Result</span>
    </div>
  ) : null

  return (
    <AppLayout
      name="leaderboard-10k"
      headerConfig={headerConfig}
      filters={filters}
      subheader={subheader}
      isLoading={
        isLoading ||
        (isFetching && !isFetchingNextPage && !isFetchingPreviousPage)
      }
      isError={isLeaderboardError}
      errorMessage={getMessageFromError(error)}
      errorAction={() => window.location.reload()}
      cnames="one-column-content"
    >
      <section className="app-content leaderboard--content">
        {((!isLoading && !hasData) || error) && (
          <div className="app-content--inner rpm-description rpm-description--fallback">
            <p>No Leaderboard for these filters.</p>
          </div>
        )}

        {hasData && (
          <>
            {hasPreviousPage && !isFetchingPreviousPage && (
              <Button
                label="Load Previous"
                onClick={() => fetchPreviousPage()}
              />
            )}

            {isFetchingPreviousPage && (
              <div className="leaderboard--infinite-spinner">
                <Spinner size={3} />
              </div>
            )}

            <ul className="app-content--inner rpm-data-list leaderboard--results">
              {data?.pages &&
                data.pages.map(
                  page =>
                    page.value.rows &&
                    page.value.rows.map(row => (
                      <LeaderboardRow
                        isMe={checkIsMe(status, row)}
                        showRank={checkIsMe(status, row)}
                        row={row}
                        results={page.value.totalCount}
                        comments={row.commentCount.toString()}
                        likes={row.likeCount.toString()}
                        details={getRowDetails(row)}
                        toggleLike={() => {
                          const likeId = row.userId || row.id
                          toggleLike(likeId, {
                            onSuccess: () =>
                              refetch({
                                refetchPage: p =>
                                  getPageNumber(page) === getPageNumber(p),
                              }),
                          })
                        }}
                        score={getRowScore(row)}
                        key={`${row.name}-${row.rank}`}
                        openComments={() => {
                          const cId = row.userId || row.id
                          const cRow =
                            activeDivision.id === 'team'
                              ? translateTeam(row)
                              : row

                          openComments({
                            ...cRow,
                            id: cId,
                            onCommentSuccess: () => {
                              refetch({
                                refetchPage: p =>
                                  getPageNumber(page) === getPageNumber(p),
                              })
                            },
                          })
                        }}
                        openProfile={() => {
                          if (activeDivision.id === 'team') {
                            openGroupProfile({ id: row.id })
                          } else {
                            openProfile(row.userId)
                          }
                        }}
                      />
                    )),
                )}

              {((hasNextPage && !isFetchingNextPage) || isFetchingNextPage) && (
                <li>
                  {hasNextPage && !isFetchingNextPage && (
                    <Button label="Load More" onClick={() => fetchNextPage()} />
                  )}
                  {isFetchingNextPage && (
                    <div className="leaderboard--infinite-spinner">
                      <Spinner size={3} />
                    </div>
                  )}
                </li>
              )}

              {showUserRow() && (
                <LeaderboardRow
                  isMe
                  showRank
                  row={myRow.row}
                  details={getRowDetails(myRow.row)}
                  results={data.pages[0].value.totalCount}
                  comments={myRow.row.commentCount.toString()}
                  likes={myRow.row.likeCount.toString()}
                  toggleLike={() => {
                    const likeId = myRow.row.userId || myRow.row.id

                    toggleLike(likeId, {
                      onSuccess: () =>
                        refetch({
                          refetchPage: p => getPageNumber(p) === '1',
                        }),
                    })
                  }}
                  score={getRowScore(myRow.row)}
                  key={`${myRow.row.name}-${myRow.row.rank}`}
                  openComments={() => {
                    const cId = myRow.row.userId || myRow.row.id
                    const cRow =
                      activeDivision.id === 'team'
                        ? translateTeam(myRow)
                        : myRow.row
                    openComments({
                      ...cRow,
                      id: cId,
                      onCommentSuccess: () => {
                        refetch({
                          refetchPage: p => getPageNumber(p) === '1',
                        })
                      },
                    })
                  }}
                  openProfile={() => openProfile(myRow.row.userId)}
                />
              )}
            </ul>
          </>
        )}
      </section>
    </AppLayout>
  )
}

export default withRouter(Leaderboard10k)
