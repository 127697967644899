import React, { useState } from 'react'

import { printClasses } from '../../Helpers'

import IconText from './IconText'

type Props = {
  startTab: string,
  children?: any,
  cnames?: string,
  handleChange?: any,
}

TabSet.defaultProps = {
  children: null,
  cnames: null,
  handleChange: () => {},
}

function TabSet(props: Props) {
  const { startTab, children, cnames, handleChange } = props
  const [activeTab, setActiveTab] = useState(startTab)

  const classes = ['rpm-tabset', cnames]

  const surfaceTab = tab => {
    handleChange(tab)
    setActiveTab(tab)
  }

  const filteredChildren = React.Children.toArray(children).filter(
    c => c !== null,
  )

  const tabIsActive = tab => Object.is(tab, activeTab)

  const hasProgress = (done, progress) =>
    !done && progress && Object.keys(progress).length > 0

  const tab = t => {
    const { id, label, done, progress, cnames: tCnames } = t
    const { step, max } = progress || {}

    const tClasses = [
      hasProgress(done, progress) && 'has-progress',
      tabIsActive(id) && 'is-active',
      done && 'is-done',
      `tab-${id}`,
      tCnames,
    ]

    const check = done && { name: 'check', type: 'far' }

    return (
      <li key={id} className={printClasses(tClasses)}>
        <button onClick={() => surfaceTab(id)}>
          <IconText icon={check} text={label} />
        </button>

        {hasProgress(done, progress) && (
          <progress max={max} value={step}>
            {(step / max) * 100}%
          </progress>
        )}
      </li>
    )
  }

  const tabContent = tc => {
    if (!tabIsActive(tc.id)) return ''

    // if this is type string don't pass additional props, it's plain html
    const propsToPass =
      typeof tc.children.type !== 'string'
        ? {
            setActiveTab: surfaceTab,
          }
        : {}

    const tcClasses = [
      'app-content--inner',
      'rpm-tabset--content',
      tabIsActive(tc.id) && 'is-active',
      `tab-content-${tc.id}`,
    ]

    return (
      <section className={printClasses(tcClasses)} key={tc.id}>
        {React.cloneElement(tc.children, propsToPass)}
      </section>
    )
  }

  return (
    <article className={printClasses(classes)}>
      {filteredChildren.length > 1 && (
        <nav
          className="rpm-tabset--tabs"
          style={{ '--tab-count': filteredChildren.length }}
        >
          {filteredChildren.map(child => child?.props && tab(child.props))}
        </nav>
      )}

      {filteredChildren.map(child => child?.props && tabContent(child.props))}
    </article>
  )
}

export default TabSet
