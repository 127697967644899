import React, { useContext } from 'react'
import { printClasses } from '../../../Helpers'

import Avatar from '../../elements/Avatar'
import Button from '../../elements/Button'

import { SocialContext } from '../../../context/socialContext'

type Props = {
  activity: Object,
  icon?: string,
  action?: any,
  avatars?: mixed,
  children?: mixed,
  styles?: Object,
  cnames?: string,
  avatarAction?: () => void,
}

ActivityRow.defaultProps = {
  icon: 'star',
  action: null,
  avatars: null,
  children: null,
  styles: null,
  cnames: null,
  avatarAction: undefined,
}

function ActivityRow(props: Props) {
  // State
  const {
    activity,
    icon,
    action,
    avatars,
    avatarAction,
    children,
    styles,
    cnames,
  } = props

  // Context
  const { openProfile: scOpenProfile } = useContext(SocialContext)

  // Variables
  const avatarArray = (Array.isArray(avatars) && avatars) || [avatars]
  const classes = ['activities--row', cnames]

  // functions
  const openProfile = () => scOpenProfile(activity.itemData.userId)

  return (
    <li className={printClasses(classes)} style={styles}>
      <div className="activities--row--content">
        {avatarArray && (
          <Button
            kind="block"
            onClick={avatarAction || openProfile}
            className="activities--row--avatar"
          >
            {avatarArray.map(avatar => (
              <Avatar
                key={avatar.id}
                image={avatar.image || avatar.profileImage}
                label={avatar.name}
                icon={{
                  name: icon,
                  type: 'fas',
                }}
                size={3}
              />
            ))}
          </Button>
        )}

        <Button kind="block" onClick={action} cnames="activities--row--details">
          {activity?.description && <p>{activity.description}</p>}

          {children}

          {activity?.timeAgo && (
            <p className="text--smaller-caps ish">{activity.timeAgo}</p>
          )}
        </Button>
      </div>
    </li>
  )
}

export default ActivityRow
