import React from 'react'
import { abbreviateNumber, formatRawNumber } from '../../Helpers'

type Props = {
  data: any,
  label?: string,
  placeholder?: string,
  abbreviate?: boolean,
}

YearlyJumps.defaultProps = {
  label: 'Yearly Jumps',
  placeholder: '---',
  abbreviate: false,
}

function YearlyJumps(props: Props) {
  // State
  const { data, label, placeholder, abbreviate } = props

  const firstYear = data.reduce((val, current) => {
    if (val === undefined && current.total !== undefined)
      return parseInt(current.label, 10)
    return val
  }, undefined)

  // Temp
  // State
  // Context
  // API
  // test data
  // Variables
  // functions
  const max =
    data && data.reduce((a, b) => (Number(a.total) > Number(b.total) ? a : b))

  const bubble = (jump, index) => {
    const pct =
      (Number(jump.total) &&
        Math.ceil((Number(jump.total) / Number(max.total)) * 100)) ||
      0

    const value =
      (Number(jump.total) > 0 &&
        (abbreviate
          ? abbreviateNumber(parseInt(jump.total, 10))
          : formatRawNumber(parseInt(jump.total, 10)))) ||
      placeholder

    return (
      <div
        key={String(`${index}`)}
        className="chart--bubble--segment"
        style={{ '--pct': pct }}
      >
        <small className="bubble-value">{value}</small>
        <small className="bubble-label">{jump.label}</small>
      </div>
    )
  }

  return (
    <div className="chart chart--bubble">
      <div className="chart--bubble--row">
        {data &&
          data.map((jump, index) =>
            firstYear <= parseInt(jump.label, 10) ? bubble(jump, index) : '',
          )}
      </div>
      {label && <div className="chart--bubble--label">{label}</div>}
    </div>
  )
}

export default YearlyJumps
