import React from 'react'

type Props = {
  label?: string,
  size?: number | string,
}

Spinner.defaultProps = {
  label: '',
  size: 1,
}

function Spinner(props: Props) {
  const { label, size } = props

  return (
    <span className="rpm-spinner" style={{ '--spinner-size': size }}>
      <svg viewBox="0 0 50 50">
        <circle className="ring" cx="25" cy="25" r="22.5" />
        <circle className="line" cx="25" cy="25" r="22.5" />
      </svg>
      {label && <span>{label}</span>}
    </span>
  )
}

export default Spinner
