import React from 'react'
import BrightcoveVideoPlayer from './BrightcoveVideoPlayer'
import VideoJS from './VideojsPlayer'

export default function VideoPlayer(options) {
  const { videoId, quality } = options
  const isBCVideo = videoId?.toString().slice(0, 5) !== 'atom.'

  /* eslint-disable react/jsx-props-no-spreading */
  if (isBCVideo) return <BrightcoveVideoPlayer {...options} />
  return <VideoJS {...options} quality={quality} />
  /* eslint-enable react/jsx-props-no-spreading */
}
