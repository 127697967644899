/* eslint-disable jsx-a11y/no-onchange */
import React from 'react'

import { printClasses } from '../../Helpers'

type Props = {
  id: string | number,
  name: string,
  value: any,
  options?: array,
  label?: string,
  placeholder?: string,
  cnames?: string,
  size?: string | number,
  required?: boolean,
  error?: boolean,
  changed?: any,
}

DropdownInput.defaultProps = {
  options: [],
  label: null,
  placeholder: null,
  cnames: null,
  size: null,
  required: false,
  error: false,
  changed: null,
}

function DropdownInput(props: Props) {
  const {
    id,
    name,
    value,
    options,
    label,
    placeholder,
    cnames,
    size,
    required,
    error,
    changed,
  } = props

  const classes = [
    'rpm-input',
    `rpm-input--dropdown`,
    label?.length && 'has-label',
    error && 'has-error',
    cnames,
  ]

  return (
    <label htmlFor={id} className={printClasses(classes)}>
      {label && <span className="rpm-input--label">{label}</span>}

      <select
        id={id}
        className="rpm-input--element"
        name={name}
        size={size}
        required={required}
        defaultValue={value}
        onChange={changed}
      >
        {placeholder && <option value="">{placeholder}</option>}

        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {error && <span className="rpm-input--error">{error}</span>}
    </label>
  )
}

export default DropdownInput
