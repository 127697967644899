import React from 'react'
import Panel from '../elements/Panel'

type Props = {
  isOpen: boolean,
  handleDismiss: mixed,
}

function Panel10kSubscribed(props: Props) {
  const { isOpen, handleDismiss } = props

  return (
    <Panel
      isOpen={isOpen}
      handleDismiss={handleDismiss}
      header="10K Challenge"
      opensFrom="right"
      cnames="dashboard--panel dashboard--panel--10k ui-dark"
    >
      <section>
        <img
          src="https://cdn.atom.rpmtraining.com/public/promotions/10k-panel-202111171140.jpg"
          alt="10K Challenge Promotional Details"
        />
      </section>
    </Panel>
  )
}

export default Panel10kSubscribed
