import React from 'react'
import TrainingToolTip from './TrainingToolTip'

import Icon from '../../components/elements/Icon'
import IconText from '../../components/elements/IconText'

import { printClasses } from '../../Helpers'

type AffiliateMetricsProps = {
  metric: mixed,
}

function AffiliateMetrics(props: AffiliateMetricsProps) {
  const { metric } = props

  // if we don't have metrics to show return null
  if (!metric?.skill && !metric?.benchmark && !metric?.project) return null

  const affiliateMetricClasses = [
    'rpm-description',
    'affiliates',
    'affiliates--metrics',
    'flex--auto-spread',
  ]

  return (
    (metric?.skill || metric?.benchmark || metric?.project) && (
      <>
        <div className={printClasses(affiliateMetricClasses)}>
          <TrainingToolTip
            tooltip={
              <>
                <ul>
                  {metric?.skill === 'low' && (
                    <li>
                      <Icon
                        name="circle"
                        type="fas"
                        cnames="skill-level--low"
                        size="lg"
                      />
                      : Today’s workout is “low-skill”, which means it doesn’t
                      consist of any significant complexity from technical
                      disciplines like Olympic weightlifting or gymnastics.
                    </li>
                  )}
                  {metric?.skill === 'med' && (
                    <li>
                      <Icon
                        name="circle"
                        type="fas"
                        cnames="skill-level--med"
                        size="lg"
                      />
                      : Today’s workout is “medium-skill”, which indicates that
                      it likely involves some complexity from high-skill
                      disciplines, along with a balance of low skill, accessible
                      movements as well.
                    </li>
                  )}
                  {metric?.skill === 'high' && (
                    <li>
                      <Icon
                        name="circle"
                        type="fas"
                        cnames="skill-level--high"
                        size="lg"
                      />
                      : Today’s workout is “high-skill”, meaning there is a
                      significant emphasis on complex, highly technical
                      movements. It’s a good to day to get better!
                    </li>
                  )}
                </ul>
              </>
            }
          >
            <IconText
              icon={{
                name: 'circle',
                type: 'fas',
                size: 'lg',
              }}
              text="Skill Level"
              cnames={`skill-level skill-level--${metric.skill} text--caps`}
            />
          </TrainingToolTip>
          {metric?.benchmark && (
            <TrainingToolTip
              tooltip={
                <p>
                  It’s a benchmark day! Benchmarks are workouts we test and
                  retest to make sure we’re getting fitter. If you’ve done this
                  workout before, compare today’s result to your previous
                  attempts.
                </p>
              }
            >
              <IconText
                icon={{
                  name: 'chart-mixed',
                  type: 'fas',
                  size: 'lg',
                }}
                text="Benchmark"
                cnames={`skill-level skill-level--${metric.skill} text--caps`}
              />
            </TrainingToolTip>
          )}

          {metric?.project && (
            <TrainingToolTip
              tooltip={
                <p>
                  It’s a Project day! A Project is a special skill or movement
                  that we put extra emphasis on for a period of time to improve
                  performance in that area, which is a great way to attack a
                  weakness!
                </p>
              }
            >
              <IconText
                icon={{
                  name: 'wrench',
                  type: 'fas',
                  size: 'lg',
                }}
                text="Project"
                cnames={`skill-level skill-level--${metric.skill} text--caps`}
              />
            </TrainingToolTip>
          )}
        </div>
      </>
    )
  )
}

export default AffiliateMetrics
