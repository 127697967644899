import React, { createContext, useContext, useCallback } from 'react'
import { useImmerReducer } from 'use-immer'
import { current } from 'immer'

import useFeature from '../hooks/FeatureHooks'

import {
  useSubmitScore,
  useDeleteScore as useDeleteScoreCtx,
} from './atomContext'

import { calcTimeString } from '../Helpers'
import { useDebounceEffect } from '../hooks/EffectHooks'

const isDebug = ['development', 'test'].includes(process.env.NODE_ENV)

export const TrainingContext = createContext()
type TrainingProviderProps = {
  children: object,
  defaultState?: object,
}

const defaultScore = {
  haveScore: true,
  visible: undefined,
  beatCap: true,
  reps: '',
  time: 0,
  weight: '',
  perceivedEffort: undefined,
  feltLike: undefined,
  notes: '',
  caption: '',
  tiebreak: '',
}

const trainingReducer = (draft, action) => {
  const [track, date, kit, key] = action.keyPath
  if (action.debugMode) console.log(action)
  switch (action.type) {
    case 'setActiveTraining': {
      draft.activeTraining = action.value
      break
    }
    case 'resetScore': {
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      draft[track][date][kit].movementSelections = []
      draft[track][date][kit].sectionNotes = {}
      draft[track][date][kit].score = { ...defaultScore }
      draft[track][date][kit].tracking = {}
      delete draft.activeTraining
      break
    }
    case 'updateScore': {
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      if (key === undefined) {
        draft[track][date][kit].score = { ...defaultScore, ...action.value }
      } else if (!draft?.[track]?.[date]?.[kit]?.score)
        draft[track][date][kit].score = {
          ...defaultScore,
          [key]: action.value,
        }
      else {
        const currentScore = draft[track][date][kit].score
        draft[track][date][kit].score = {
          ...currentScore,
          [key]: action.value,
        }
      }
      break
    }
    case 'updateTracking': {
      const { id, round } = action
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      if (key === undefined) {
        draft[track][date][kit].tracking = { ...action.value }
        console.log('setting tracking', action.value)
      } else if (!draft?.[track]?.[date]?.[kit]?.tracking) {
        const value = []
        value[round] = action.value
        draft[track][date][kit].tracking = {
          [id]: { [key]: value },
        }
      } else {
        const currentTracking = draft[track][date][kit].tracking[id] || {}
        let value = []
        if (currentTracking[key]) value = currentTracking[key]
        value[round] = action.value
        draft[track][date][kit].tracking[id] = {
          ...currentTracking,
          [key]: value,
        }
      }
      break
    }
    case 'updateSectionNotes': {
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      if (key === undefined) {
        draft[track][date][kit].sectionNotes = action.value
      } else if (!draft?.[track]?.[date]?.[kit]?.sectionNotes) {
        draft[track][date][kit].sectionNotes = {
          [key]: action.value,
        }
      } else {
        const currentSectionNotes = draft[track][date][kit].sectionNotes
        draft[track][date][kit].sectionNotes = {
          ...currentSectionNotes,
          [key]: action.value,
        }
      }
      break
    }
    case 'updateMovementSelections': {
      if (!draft?.[track]) draft[track] = {}
      if (!draft?.[track]?.[date]) draft[track][date] = {}
      if (!draft?.[track]?.[date]?.[kit]) draft[track][date][kit] = {}
      draft[track][date][kit].movementSelections = JSON.parse(
        JSON.stringify(action.value),
      )
      console.log(
        'test',
        action.value,
        draft[track][date][kit].movementSelections,
      )
      break
    }
    default:
      break
  }
  if (!action.skipAutoSave && action.type !== 'updateMovementSelections') {
    const currentTraining = current(draft)
    draft.activeTraining = {
      ...currentTraining[track][date][kit],
      track,
      date,
      kit,
    }
  }
}

const TrainingProvider = ({
  children,
  defaultState,
}: TrainingProviderProps) => {
  const trainingDebug = useFeature('training-debug')
  const debugMode = isDebug || trainingDebug

  const { mutate: submitScore, isLoading: isSavingScore } = useSubmitScore()
  const { mutate: deleteAScore } = useDeleteScoreCtx()

  const [state, dispatch] = useImmerReducer(trainingReducer, defaultState)

  const getValue = callback => callback(state)

  const autosaveTraining = useCallback(
    val => {
      if (!val) return
      if (val.score.status === 'published') return // do not auto save to published workouts
      let newDraftData = {
        trainingId: val.score.trainingId,
        track: val.track,
        kit: val.kit,
        date: val.date,
        sectionNotes: val.sectionNotes,
        tracking: val.tracking,
        selections: val.movementSelections || [],
        notes: val.score?.notes || '',
        caption: val.score?.caption || '',
        reps: val.score?.reps || undefined,
        weight: val.score?.weight || undefined,
        time: val.score?.time || undefined,
        beatCap: val.score?.beatCap || true,
        tiebreak: val.score?.tiebreak || undefined,
        perceivedEffort: val.score.perceivedEffort || undefined,
        feltLike: val.score.feltLike || undefined,
        completedOnly: true,
        visible: val.score.visible || false,
        status: 'draft',
      }
      if (val?.score?.id) newDraftData.id = val.score.id
      if (val.score?.workshopId) {
        // this is a workshop so set it up as such
        const additions = {
          workshopId: val.score?.workshopId,
          day: val.score?.day,
          track: 'workshop',
        }
        newDraftData = { ...newDraftData, ...additions }
        delete newDraftData.date
      }

      submitScore(newDraftData)
      if (debugMode) console.log('training changed', newDraftData)
    },
    [submitScore, debugMode],
  )
  useDebounceEffect(state.activeTraining, 5000, autosaveTraining)

  const deleteScore = (keyPath = undefined, score = undefined) => {
    if (score) {
      deleteAScore(score)
    } else {
      const [track, date, kit] = keyPath
      const scoreToDelete = state?.[track]?.[date]?.[kit]

      let myTrack = track
      if (scoreToDelete.score?.track === 'workshop') myTrack = 'workshop'

      deleteAScore({ id: scoreToDelete.score.id, track: myTrack })
    }
  }

  const saveScore = (
    keyPath,
    mentionsPostData,
    selections,
    level,
    scoring,
    onSubmit,
    postAdditions,
  ) => {
    const [track, date, kit] = keyPath
    const scoreToSubmit = state?.[track]?.[date]?.[kit]
    const localScoring = scoreToSubmit.score?.scoring || scoring[0]

    // cancel any current auto save
    dispatch({
      type: 'setActiveTraining',
      keyPath,
      value: undefined,
      skipAutoSave: true,
    })

    // We don't have anything for this data, TODO error
    if (!scoreToSubmit) return

    const [theCaption, mentionIds] = mentionsPostData

    let score

    if (Object.is(localScoring, 'reps')) {
      score = Number(scoreToSubmit.score.reps)
    }

    if (
      scoreToSubmit.score.beatCap &&
      scoreToSubmit.score.time &&
      Object.is(localScoring, 'time')
    ) {
      score = calcTimeString(scoreToSubmit.score.time)
      if (scoreToSubmit.score.timecap) {
        // if we have a timecap you can never submit a score greater than it
        const maxScore = calcTimeString(`${scoreToSubmit.score.timecap}:00`)
        if (maxScore < score) score = maxScore
      }
    } else if (
      !scoreToSubmit.score.beatCap &&
      Object.is(localScoring, 'time')
    ) {
      score = calcTimeString(`${scoreToSubmit.score.timecap}:00`)
    }

    if (Object.is(localScoring, 'weight')) {
      score = Number(scoreToSubmit.score.weight)
    }

    if (!score) score = 0

    const haveScore =
      (scoreToSubmit.score?.haveScore && score !== 0) ||
      (!scoreToSubmit?.score?.beatCap && scoreToSubmit?.score?.tiebreak !== 0)

    let newDraftData = {
      trainingId: scoreToSubmit.score.trainingId,
      track,
      kit,
      date,
      level,
      sectionNotes: scoreToSubmit.sectionNotes,
      tracking: scoreToSubmit.tracking,
      notes: scoreToSubmit.score?.notes || '',
      caption: theCaption,
      mentions: mentionIds,
      selections: selections || [],
      perceivedEffort: scoreToSubmit.score?.perceivedEffort || undefined,
      feltLike: scoreToSubmit.score?.feltLike || undefined,
      completedOnly: !haveScore,
      visible: scoreToSubmit.score.visible || false,
      status: 'published',
    }
    if (scoreToSubmit?.score?.id) newDraftData.id = scoreToSubmit.score.id

    if (scoreToSubmit.score?.haveScore && score !== 0) {
      const additions = {
        score,
        beatCap: true,
      }
      newDraftData = { ...newDraftData, ...additions }
    }
    if (
      !scoreToSubmit?.score?.beatCap &&
      scoreToSubmit?.score?.tiebreak !== 0
    ) {
      const additions = {
        beatCap: scoreToSubmit.score?.beatCap,
        tiebreak: Number(scoreToSubmit.score?.tiebreak) || null,
        score,
      }
      newDraftData = { ...newDraftData, ...additions }
    }

    if (scoreToSubmit.score?.workshopId) {
      // this is a workshop so set it up as such
      const additions = {
        workshopId: scoreToSubmit.score?.workshopId,
        day: scoreToSubmit.score?.day,
        track: 'workshop',
      }
      newDraftData = { ...newDraftData, ...additions }
      delete newDraftData.date
    }
    if (postAdditions) newDraftData = { ...newDraftData, ...postAdditions }
    if (debugMode) console.log(newDraftData)
    submitScore(newDraftData, {
      onSuccess: () => {
        const previousStatus = scoreToSubmit.score?.status || 'draft'
        dispatch({
          type: 'updateScore',
          keyPath: [...keyPath, 'status'],
          value: 'published',
          skipAutoSave: true,
        })

        if (onSubmit) onSubmit(kit, previousStatus)
      },
      onError: anError => {
        console.log(anError)
      },
    })
  }

  const defaultAction = {
    debugMode,
  }

  return (
    <TrainingContext.Provider
      value={{
        state,
        dispatch,
        getValue,
        saveScore,
        isSavingScore,
        deleteScore,
        debugMode,
        defaultAction,
      }}
    >
      {children}
    </TrainingContext.Provider>
  )
}
TrainingProvider.defaultProps = {
  defaultState: {},
}

export default TrainingProvider

export const useSelector = callback => {
  const { getValue } = useContext(TrainingContext)
  return getValue(callback)
}

export const useScore = keyPath => {
  const [track, date, kit] = keyPath

  return useSelector(
    state => state?.[track]?.[date]?.[kit]?.score || defaultScore,
  )
}

export const useUpdateScore = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const updateScore = (keyPath, value, skipAutoSave = false) => {
    dispatch({
      ...defaultAction,
      type: 'updateScore',
      keyPath,
      value,
      skipAutoSave,
    })
  }

  return updateScore
}

export const useTracking = keyPath => {
  const [track, date, kit] = keyPath

  return useSelector(state => state?.[track]?.[date]?.[kit]?.tracking || {})
}

export const useUpdateTracking = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const updateTracking = (
    keyPath,
    id,
    value,
    round = 0,
    skipAutoSave = false,
  ) => {
    dispatch({
      ...defaultAction,
      type: 'updateTracking',
      keyPath,
      id,
      value,
      round,
      skipAutoSave,
    })
  }

  return updateTracking
}

export const useDeleteScore = () => {
  const { deleteScore } = useContext(TrainingContext)

  return deleteScore
}

export const useResetScore = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const resetScore = (keyPath, skipAutoSave = true) => {
    dispatch({ ...defaultAction, type: 'resetScore', keyPath, skipAutoSave })
  }

  return resetScore
}

export const useSaveScore = () => {
  const { saveScore, isSavingScore } = useContext(TrainingContext)

  return { saveScore, isSavingScore }
}

export const useSectionNotes = keyPath => {
  const [track, date, kit] = keyPath

  return useSelector(state => state?.[track]?.[date]?.[kit]?.sectionNotes || {})
}

export const useUpdateSectionNotes = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const updateSectionNotes = (keyPath, value, skipAutoSave = false) => {
    dispatch({
      ...defaultAction,
      type: 'updateSectionNotes',
      keyPath,
      value,
      skipAutoSave,
    })
  }

  return updateSectionNotes
}

export const useMovementSelections = keyPath => {
  const [track, date, kit] = keyPath

  return useSelector(
    state => state?.[track]?.[date]?.[kit]?.movementSelections || {},
  )
}

export const useUpdateMovementSelections = () => {
  const { dispatch, defaultAction } = useContext(TrainingContext)

  const updateMovementSelections = (keyPath, value, skipAutoSave = false) => {
    dispatch({
      ...defaultAction,
      type: 'updateMovementSelections',
      keyPath,
      value,
      skipAutoSave,
    })
  }

  return updateMovementSelections
}
