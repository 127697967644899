import React from 'react'
import PickList from '../elements/PickList'

import dataLevels from '../../data/levels.json'

type Props = {
  item: string,
  doSelect: object,
  open?: boolean,
}

FilterByLevel.defaultProps = {
  open: false,
}

function FilterByLevel(props: Props) {
  const { item, open, doSelect } = props
  const defaultItem =
    typeof item === 'string'
      ? dataLevels.find(level => level.id === item) || {}
      : item

  const items = [
    ...dataLevels,
    {
      id: '0',
      label: 'Do not filter by level',
      cnames: 'text--small text--caps',
      altLabel: 'Level',
      icon: { name: 'ellipsis-h', type: 'far' },
    },
  ]

  const placeholder = {
    label: 'Level',
    icon: { name: 'ellipsis-h', type: 'far' },
  }

  const selectItem = element => doSelect(element)

  return (
    <PickList
      item={defaultItem}
      open={open}
      placeholder={placeholder}
      items={items}
      doSelect={selectItem}
    />
  )
}

export default FilterByLevel
