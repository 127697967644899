import { useState, useMemo, useEffect } from 'react'

import { useSearchUsers } from '../context/atomContext'

import { debounce } from '../Helpers'
import { extractMentions } from '../components/elements/AtomMarkup'

const extractPostData = (text, users) => {
  let theText = text
  const mentions = []
  const sortedUsers = users.sort(
    (a, b) => a.username?.length < b.username?.length,
  )
  sortedUsers.forEach(user => {
    const regex = RegExp(`@${user.username}`, 'gi')
    if (regex.test(theText)) {
      theText = theText.replace(
        regex,
        `::atomUser[${user.username}]{${user.id}}`,
      )
      mentions.push(user.id)
    }
  })
  return [theText, mentions]
}

export function useMentions(defaultText, elementId, onUpdate) {
  const [extractedString, extractedMentions] = extractMentions(defaultText)
  const [mentionText, _setMentionText] = useState(extractedString)
  const [userToSearch, setUserToSearch] = useState(null)
  const [mentionedUsers, setMentionedUsers] = useState(extractedMentions || [])

  const [searchSelectedIndex, setSearchSelectedIndex] = useState(0)

  const getPostData = () => extractPostData(mentionText, mentionedUsers)

  const setMentionText = (text, skipCallback = false) => {
    _setMentionText(text)
    if (!skipCallback && onUpdate) {
      const [theText] = extractPostData(text, mentionedUsers)
      onUpdate(theText)
    }
  }

  const initializeMentions = newString => {
    const [textString, mentionUsers] = extractMentions(newString)
    setMentionText(textString, true)
    setMentionedUsers(mentionUsers)
  }

  useEffect(() => {
    const [textString, mentionUsers] = extractMentions(defaultText)
    _setMentionText(textString)
    setMentionedUsers(mentionUsers)
  }, [defaultText])

  const addUserToComment = user => {
    const textInput = document.getElementById(elementId)

    const newUsers = [...mentionedUsers, user]
    setMentionedUsers(newUsers)
    if (textInput?.selectionStart) {
      const [, first, last] = lastWord(mentionText, textInput.selectionStart)
      const pre = mentionText.slice(0, first)
      const post = mentionText.slice(last)
      const newMentionText = `${pre}@${user.username}${post} `
      setMentionText(newMentionText)

      // send our callback the most recent data
      const [theText] = extractPostData(newMentionText, newUsers)
      if (onUpdate) onUpdate(theText)
      setUserToSearch('')
      textInput.selectionStart = last
      textInput.focus()
    }
  }

  const debounceSearchUser = useMemo(
    () =>
      debounce(term => {
        setUserToSearch(term)
      }, 500),
    [],
  )

  const lastWord = (iString, startPoint) => {
    const spaces = [' ', '\t', '\n']
    if (!iString) return ''
    let first = startPoint
    let last = startPoint
    for (let i = startPoint - 1; i >= 0; i -= 1) {
      if (spaces.includes(iString[i])) break
      first = i
      // for now we will terminate a word at @ and not go back further.
      // @ is not an allowed character for usernames
      if (iString[i] === '@') break
    }
    for (let j = startPoint; j < iString.length; j += 1) {
      last = j
      if (spaces.includes(iString[j])) break
    }
    return [iString.slice(first, last), first, last]
  }

  const textChanged = data => {
    const textInput = document.getElementById(elementId)
    setMentionText(data)
    if (textInput && data) {
      let [word] = lastWord(data, textInput.selectionStart)
      if (word[0] !== '@') word = ''
      else word = word.slice(1)
      debounceSearchUser(word)
    }
  }

  const setIndex = increment => {
    const mentionResultsCount = filteredSearch.length
    let index = searchSelectedIndex
    index += increment

    if (index > mentionResultsCount - 1) index = 0
    if (index < 0) index = mentionResultsCount - 1

    setSearchSelectedIndex(index)
  }

  const keyWatcher = e => {
    if (!filteredSearch?.length || filteredSearch?.length === 0) return

    switch (e.keyCode) {
      case 38: // up
        e.preventDefault()
        setIndex(-1)
        break

      case 40: // down
        e.preventDefault()
        setIndex(1)
        break

      case 13: // enter
      case 32: // space
        e.preventDefault()
        if (userToSearch?.length > 1)
          addUserToComment(filteredSearch[searchSelectedIndex])
        break

      case 27: // escape
        e.preventDefault()
        setUserToSearch('')
        break

      default:
        break
    }
  }

  const { data: userSearchData } = useSearchUsers(
    userToSearch,
    userToSearch?.length > 1,
  )

  const filteredSearch =
    userSearchData?.value?.rows?.filter(u => u.username) || []

  useEffect(() => setSearchSelectedIndex(0), [userSearchData])

  return {
    mentionText,
    setMentionText: textChanged,
    userSearchData: filteredSearch,
    userToSearch,
    addUserToComment,
    getPostData,
    setUserToSearch,
    setMentionedUsers,
    keyWatcher,
    searchSelectedIndex,
    setSearchSelectedIndex,
    initializeMentions,
  }
}

export default useMentions
