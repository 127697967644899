import React from 'react'

import Icon from '../../elements/Icon'

type Props = {
  item: Object,
}

function LinkResource(props: Props) {
  const { item } = props
  const { href, label, icon, target } = item
  const { type, name } = icon || {}

  return (
    <a href={href} className="resource--row is-link" target={target}>
      <span className="resource--row--label">{label}</span>
      {icon && <Icon type={type} name={name} cnames="resource--row--icon" />}
    </a>
  )
}

export default LinkResource
