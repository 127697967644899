import React, { useState, useEffect, useMemo, useContext } from 'react'

import Button from '../../components/elements/Button'
import Dialog from '../../components/elements/Dialog'
import Icon from '../../components/elements/Icon'
import IconText from '../../components/elements/IconText'

import Search from '../../components/inputs/Search'
import Textarea from '../../components/inputs/Textarea'

import SearchResultRow from '../../components/structures/SearchResultRow'

import { SocialContext } from '../../context/socialContext'
import {
  useSearchUsers,
  useInviteAffiliateUsers,
} from '../../context/atomContext'

import { debounce, printClasses } from '../../Helpers'

type Props = {
  affiliateId: number,
  dismissPanel: () => mixed,
}

function TeamInvites(props: Props) {
  const { affiliateId, dismissPanel } = props

  // State
  const [inviteEmail, setInviteEmail] = useState('')
  const [peopleToInvite, setPeopleToInvite] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchTermDebounced, setSearchTermDebounced] = useState('')
  const [results, setResults] = useState([])
  const [hasResults, setHasResults] = useState(false)
  const [expandResults, setExpandResults] = useState(false)
  const [expandInvites, setExpandInvites] = useState(true)
  const [showDialog, setShowDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  // Context
  const { openProfile } = useContext(SocialContext)

  // API
  const { data: searchData, isFetching } = useSearchUsers(
    searchTermDebounced,
    searchTermDebounced.length > 1,
  )
  const inviteToAffiliate = useInviteAffiliateUsers()

  // test data
  // Variables
  const debug = false
  useEffect(() => setResults(searchData?.value?.rows), [searchData])
  useEffect(() => setHasResults(results?.length > 0), [results])

  // functions
  const inviteMembers = () => {
    if (debug) console.log(peopleToInvite)

    const invites = peopleToInvite.map(person => {
      if (person.email) return { email: person.email }
      if (person.id) return { userId: person.id }

      return {}
    })

    const postData = {
      affiliateId,
      invites,
    }

    inviteToAffiliate.mutate(postData, {
      onSuccess: response => {
        if (response?.data?.value?.affiliateId) {
          setShowErrorDialog(response?.data?.value?.affiliateId)
        } else {
          setShowDialog(true)
        }
      },
      onError: anError => {
        console.log(anError)
      },
    })
  }

  const dismiss = () => {
    setShowDialog(false)
    dismissPanel()
  }

  const debounceSearch = useMemo(
    () => debounce(term => setSearchTermDebounced(term), 500),
    [setSearchTermDebounced],
  )

  const doSearch = value => {
    setSearchTerm(value)
    debounceSearch(value)
    toggleShowResults(!value)
  }

  const clearSearch = () => doSearch('')

  const inviteEmailUsers = list => {
    const emailUsers = []

    list.forEach(email => {
      if (email) {
        const exists = peopleToInvite.filter(person =>
          Object.is(person.email, email),
        )

        if (exists.length === 0) {
          emailUsers.push({
            id: email,
            email,
            name: null,
            type: 'email',
          })
        }
      }
    })

    setPeopleToInvite([...peopleToInvite, ...emailUsers])
  }

  const handleEmailInvites = () => {
    const list = inviteEmail.split(/[\n, ]+/).filter(Boolean)
    inviteEmailUsers(list)
    setInviteEmail('')
  }

  const addInviteUser = user => {
    const exists = peopleToInvite.filter(person =>
      Object.is(person.id, user.id),
    )

    if (debug) console.log(exists)

    if (exists.length === 0) {
      setPeopleToInvite([...peopleToInvite, user])
    }
  }

  const removeInviteUser = user => {
    if (user?.email) {
      const newPeople = peopleToInvite.filter(
        person => !Object.is(person.email, user.email),
      )
      setPeopleToInvite(newPeople)
    } else {
      const newPeople = peopleToInvite.filter(
        person => !Object.is(person.id, user.id),
      )
      setPeopleToInvite(newPeople)
    }
  }

  const findUser = user =>
    peopleToInvite.find(item => Object.is(item.id, user.id))

  const toggleAddUser = user =>
    findUser(user) ? removeInviteUser(user) : addInviteUser(user)

  const toggleShowResults = value => {
    setExpandResults(!value)
  }

  const toggleExpandInvites = value => {
    setExpandInvites(!value)
  }

  if (debug) console.log(results)

  return (
    <>
      <section className="section-block">
        <div className="rpm-header">
          <h5 className="text--caps text--bold">
            Email Invites <span className="text--small">(one per line)</span>
          </h5>
          <div className="flex--auto-gap">
            <Textarea
              id="inviteEmail"
              type="email"
              placeholder="Email"
              value={inviteEmail}
              changed={event => setInviteEmail(event.target.value)}
            />

            <Button
              kind="link"
              onClick={() => handleEmailInvites()}
              disabled={!inviteEmail}
              label="Add"
            />
          </div>
        </div>
      </section>

      <section
        className={printClasses([
          'section-block',
          'invites--search',
          searchTerm && 'sticky-top',
        ])}
      >
        <div className="rpm-header xflex--auto-gap xuse-baseline">
          <h5 className="text--caps">
            In App Invites{' '}
            <span className="text--small">(Existing Atom Users Only)</span>
          </h5>

          <Search
            id="searchUsers"
            placeholder="Search..."
            noResults={
              <IconText
                icon={{ type: 'fal', name: 'user-slash', size: 'lg' }}
                text="No users found, try again..."
                cnames="fg--accent"
              />
            }
            helpMessage={
              <IconText
                icon={{ type: 'fal', name: 'question-square', size: 'lg' }}
                text={
                  results?.length > 0
                    ? `Found ${results?.length} users`
                    : 'Use at least two characters'
                }
                cnames="ish"
              />
            }
            value={searchTerm}
            isSearching={isFetching}
            debouncedValue={searchTermDebounced}
            hasResults={hasResults}
            changed={doSearch}
            clear={clearSearch}
            maxlength={20}
            cnames="use-thin-inputs"
          />
        </div>
      </section>

      <section
        className={printClasses([
          'section-block',
          'invites--search--results',
          'search--results',
          expandResults && 'is-open',
        ])}
      >
        <ul>
          {results?.map(aUser => (
            <SearchResultRow
              key={aUser.id}
              user={aUser}
              avatarSize={3}
              openProfile={() => openProfile(aUser.id)}
              cnames={aUser.isOnMyTeam && 'rpm-marquee ish'}
              isPublic
              action={
                <Button
                  kind="link"
                  onClick={() => toggleAddUser(aUser)}
                  disabled={aUser.isOnMyTeam}
                >
                  <Icon
                    type={findUser(aUser) ? 'fas' : 'fal'}
                    name={findUser(aUser) ? 'check-circle' : 'plus-circle'}
                    size="xl"
                  />
                </Button>
              }
            />
          ))}
        </ul>
      </section>

      <section
        className={printClasses([
          'section-block',
          'invites--pending',
          expandInvites ? 'sticky-top' : 'sticky-bottom',
        ])}
      >
        <div className="rpm-header">
          <h5 className="text--caps">
            Pending Invites{' '}
            <span className="text--small">({peopleToInvite.length})</span>
          </h5>
        </div>
      </section>

      <section
        className={printClasses([
          'section-block',
          'invites--pending--list',
          'search--results',
          !expandInvites && 'rpm-marquee',
          expandInvites && 'is-open',
        ])}
      >
        <ul>
          {peopleToInvite.map(aUser => (
            <SearchResultRow
              key={aUser.id}
              user={aUser}
              avatarSize={3}
              expanded={expandInvites}
              openProfile={
                expandInvites
                  ? () => openProfile(aUser.id)
                  : () => toggleExpandInvites(expandInvites)
              }
              isPublic
              action={
                <Button kind="link" onClick={() => removeInviteUser(aUser)}>
                  <Icon
                    type="fal"
                    name="minus-circle"
                    size="xl"
                    cnames="fg--accent ish"
                  />
                </Button>
              }
              altAction={
                !expandInvites ? () => toggleExpandInvites(expandInvites) : null
              }
            />
          ))}
        </ul>
      </section>

      <section
        className={printClasses([
          'rpm-description',
          expandInvites && 'sticky-bottom',
        ])}
      >
        <Button
          label="Send Invites"
          disabled={peopleToInvite.length === 0}
          onClick={inviteMembers}
        />
      </section>

      <Dialog isOpen={showDialog} handleDismiss={dismiss} header="Invites Sent">
        <section>
          <p>
            Please allow 5-10 minutes for invites to be sent and members to be
            added.
          </p>
        </section>
        <section>
          <Button label="Okay" onClick={dismiss} />
        </section>
      </Dialog>

      <Dialog
        isOpen={showErrorDialog}
        handleDismiss={() => setShowErrorDialog(false)}
        header="You must be crushing it!"
      >
        <section>
          {showErrorDialog &&
            showErrorDialog?.map(error => <p key={String(error)}>{error}</p>)}
        </section>
      </Dialog>
    </>
  )
}

export default TeamInvites
