import React, { useEffect, useRef, useState } from 'react'

import Button from './Button'

import { printClasses } from '../../Helpers'

type Props = {
  isOpen: boolean,
  handleDismiss: Object,
  children?: any,
  header?: string,
  footer?: mixed,
  heels?: mixed,
  opensFrom?: string,
  cnames?: string,
  showDismiss?: boolean,
  dismissLabel?: String,
  dismissIcon?: Object,
  isLoading?: boolean,
  zIndex?: number,
}

Panel.defaultProps = {
  children: null,
  header: null,
  footer: null,
  heels: null,
  opensFrom: 'right',
  cnames: null,
  showDismiss: true,
  dismissLabel: null,
  dismissIcon: {
    name: `times-circle`,
    type: 'fal',
    size: '2x',
    fw: false,
  },
  isLoading: false,
  zIndex: null,
}

function Panel(props: Props) {
  const {
    isOpen,
    handleDismiss,
    children,
    opensFrom,
    header,
    footer,
    heels,
    cnames,
    showDismiss,
    dismissLabel,
    dismissIcon,
    isLoading,
    zIndex,
  } = props

  const [elementIsOpen, setElementIsOpen] = useState(undefined)
  const elementRef = useRef(null)

  const dismissElement = () => setElementIsOpen(undefined)

  useEffect(() => {
    setElementIsOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    const element = elementRef.current

    const listener = () => {
      if (!elementIsOpen) {
        handleDismiss()
      }
    }

    element?.addEventListener('transitionend', listener)

    return () => {
      element?.removeEventListener('transitionend', listener)
    }
  }, [elementRef, elementIsOpen, handleDismiss])

  const animationClasses = () => {
    let classes = null

    if (!Object.is(elementIsOpen, undefined)) {
      classes = elementIsOpen
        ? `is-open app-panel--slide-in app-panel--slide-in--${opensFrom}`
        : `app-panel--slide-out app-panel--slide-out--${opensFrom}`
    } else {
      classes = `just-is app-panel--slide-out app-panel--slide-out--${opensFrom}`
    }

    return classes
  }

  const panelClasses = [
    'app-panel',
    `app-panel--${opensFrom}`,
    cnames,
    animationClasses(),
  ]

  const dismissButton = showDismiss && (
    <Button
      onClick={dismissElement}
      cnames="app-panel--action app-panel--action--dismiss"
      kind="text"
      label={dismissLabel}
      icon={dismissIcon}
      canRipple={false}
    />
  )

  return (
    <div
      ref={elementRef}
      className={printClasses(panelClasses)}
      style={zIndex && { '--panel-z-index': zIndex }}
    >
      <div className="app-panel--inner">
        {isOpen && (
          <>
            {header && (
              <header className="app-panel--header">
                {dismissButton}
                <span className="app-panel--header--label">{header}</span>
              </header>
            )}

            <div
              className={printClasses([
                'rpm-container',
                'app-panel--body',
                isLoading && 'is-loading',
              ])}
            >
              {children}
            </div>

            {footer && <footer className="app-panel--footer">{footer}</footer>}

            {heels}

            {!header && dismissButton}
          </>
        )}
      </div>

      {handleDismiss && (
        <Button
          kind="secret"
          cnames="app-panel--dismiss"
          onClick={dismissElement}
          canRipple={false}
        />
      )}
    </div>
  )
}

export default Panel
