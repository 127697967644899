import React, { useState, useRef, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'

import ReactPlayerLoader from '@brightcove/react-player-loader'
import Icon, { PlayIcon } from '../elements/Icon'
import { SocialContext } from '../../context/socialContext'

import {
  getRecentVideos,
  updateRecentVideo as _updateRecentVideo,
} from '../../Helpers'

import videoCuePoints from '../../data/videoCuePoints.json'

function iconForCuePoint(id) {
  const point = videoCuePoints.filter(p => p.id === id)
  if (point.length > 0) return point[0].icon.name
  return 'video-segment-main'
}

type WorkoutOverlayProps = {
  workout: Object,
  player: Object,
  loaded: boolean,
  playerClass: string,
}

export function WorkoutOverlay(props: WorkoutOverlayProps) {
  const [overlayExpanded, setOverlayExpanded] = useState(false)
  const [progress, setProgress] = useState(0)
  const { workout, player, loaded, playerClass } = props
  const playerContainer = document.getElementsByClassName(playerClass)[0]
  const playerOverlay =
    playerContainer?.getElementsByClassName('workoutOverlay')[0]
  // const globalOverlay =
  //   playerContainer?.getElementsByClassName('vjs-overlay')?.[0]

  const globalOverlay = playerContainer?.querySelector('.vjs-overlay')

  const toggleOverlay = () => {
    if (globalOverlay) globalOverlay.style.zIndex = !overlayExpanded ? 3 : 1
    setOverlayExpanded(!overlayExpanded)
  }

  let cuePoints = []
  let duration = 0

  const getTimePercentage = time =>
    parseFloat(Number((time / duration) * 100).toPrecision(2))

  const timeupdateHandler = () => {
    const updated = getTimePercentage(player.currentTime())

    if (!Object.is(progress, updated)) {
      setProgress(updated)
      player.off('timeupdate', timeupdateHandler)
    }
  }
  const points = player?.mediainfo?.cuePoints || []

  if (loaded) {
    duration = player.duration()
    cuePoints = [...points].reverse()
    player.on('timeupdate', timeupdateHandler)
  }

  const workoutPanelClass = overlayExpanded
    ? 'workout--expanded'
    : 'workout--collapsed'

  return (
    loaded &&
    ReactDOM.createPortal(
      <div
        id="internal-overlay"
        style={overlayExpanded ? { '--overlay-translate-x': '-100%' } : {}}
      >
        <div className="expander">
          <button onClick={toggleOverlay} />
        </div>

        <div className={`workout ${workoutPanelClass}`}>
          <div className="workout--content">{workout}</div>
        </div>

        {loaded && overlayExpanded && cuePoints.length > 0 && (
          <div className="sections">
            {cuePoints.map(point => (
              <div
                key={`${point.name}-${point.time}`}
                className={`cue cue--${point.name} ${
                  progress >= getTimePercentage(point.time)
                    ? 'is-previous'
                    : 'is-next'
                }`}
              >
                <button onClick={() => player.currentTime(point.time)}>
                  <Icon
                    type="svg"
                    fw={false}
                    name={iconForCuePoint(point.name)}
                  />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>,
      playerOverlay,
    )
  )
}

type BrightcoveVideoPlayerProps = {
  videoId: string,
  accountId?: string,
  playerId?: string,
  autoPlay?: boolean,
  resume?: boolean,
  showWorkoutOverlay?: boolean,
  workout?: Object,
  skipToTimeCode?: number,
  onMetaDataLoad?: any,
  muted?: boolean,
  aspectRatio?: string,
  loop?: Boolean,
  controls?: Boolean,
  id?: String,
  preload?: 'auto' | 'metadata' | 'none',
  onError?: Object,
  onEnded?: () => void,
}

BrightcoveVideoPlayer.defaultProps = {
  accountId: '6260977916001',
  playerId: 'UOVFLHmBby',
  resume: true,
  autoPlay: false,
  showWorkoutOverlay: true,
  workout: () => '',
  skipToTimeCode: 0,
  onMetaDataLoad: undefined,
  muted: false,
  aspectRatio: undefined,
  loop: false,
  controls: true,
  id: undefined,
  preload: 'auto',
  onError: undefined,
  onEnded: undefined,
}

function BrightcoveVideoPlayer(props: BrightcoveVideoPlayerProps) {
  const { addToast, removeToast } = useContext(SocialContext)

  const player = useRef(null)
  const [, _setBufferingErrorState] = useState(false)
  const [startPlayTime, _setStartPlaytime] = useState(false)
  const startPlayTimeRef = React.useRef(startPlayTime)
  const [toastId] = useState(Date.now())
  const setStartPlaytime = data => {
    startPlayTimeRef.current = data
    _setStartPlaytime(data)
  }

  const setBufferingErrorState = data => {
    if (data) {
      // we are in an error state so add the toast
      addToast({
        label: 'Strong > Weak: Your connection needs help.',
        id: toastId,
        kind: 'warning',
        title: 'Warning',
      })
    } else {
      // we are clearing the error state so remove the toast
      removeToast(toastId)
    }
    _setBufferingErrorState(data)
  }

  const [loadedMetadata, setLoadedMetadata] = useState(false)

  const {
    videoId,
    accountId,
    playerId,
    autoPlay,
    resume,
    showWorkoutOverlay,
    workout,
    skipToTimeCode,
    onMetaDataLoad,
    muted,
    aspectRatio,
    loop,
    controls,
    id,
    preload,
    onError,
    onEnded,
  } = props
  const customClass = id !== undefined ? id : `video-id-${videoId}`

  useEffect(() => {
    const p = player.current.player
    if (p) {
      p.currentTime(skipToTimeCode.time)
    }
  }, [skipToTimeCode])

  const [recentVideos, setRecentVideos] = useState(getRecentVideos())

  const updateRecentVideo = (aVideoId, progress) => {
    const updatedRecentVideos = _updateRecentVideo(aVideoId, progress)
    setRecentVideos(updatedRecentVideos)
  }

  const playerSkipButton = (skipDirection, clickFn, label) => {
    const button = document.createElement('button')

    button.onclick = () => clickFn.call()
    button.title = label
    button.className = [
      'vjs-control',
      'vjs-button',
      'rpm-video--skip',
      `rpm-video--skip--${skipDirection}`,
    ].join(' ')

    return button
  }

  const onSuccess = success => {
    const localPlayer = success.ref

    const playerContainer = document.getElementsByClassName(customClass)[0]

    ReactDOM.render(
      <PlayIcon />,
      playerContainer.querySelector('.vjs-big-play-button'),
    )

    const loaded =
      playerContainer.getElementsByClassName('rpm-video--skip').length !== 0

    if (!loaded) {
      const backButton = playerSkipButton(
        'backward',
        skipBackwards,
        'Skip Backward 15 Seconds',
      )

      const controlBar =
        playerContainer.getElementsByClassName('vjs-control-bar')[0]

      // Change the class name here to move the icon in the controlBar
      const insertBeforeNode =
        playerContainer.getElementsByClassName('vjs-play-control')[0]

      // Insert the icon div in proper location
      controlBar.insertBefore(backButton, insertBeforeNode)

      ReactDOM.render(
        <Icon name="video-scrub-back15" type="svg" />,
        playerContainer.querySelector('.rpm-video--skip--backward'),
      )

      // ReactDOM.render(
      //   <Icon name="video-scrub-forward15" type="svg" />,
      //   playerContainer.querySelector('.rpm-video--skip--forward'),
      // )
    }

    localPlayer.on('timeupdate', () => {
      const mPlayer = player.current.player
      const time = mPlayer.currentTime()
      if (startPlayTimeRef.current === false) setStartPlaytime(time)

      if (resume) {
        updateRecentVideo(videoId, parseInt(time, 10))
      }
    })
    localPlayer.on('error', () => {
      if (onError) onError(localPlayer)
    })
    localPlayer.on('ended', () => {
      if (onEnded) onEnded(localPlayer)
    })
    localPlayer.on('canplaythrough', () => {
      setBufferingErrorState(false)
    })
    localPlayer.on('waiting', () => {
      const timeCode = player?.current?.player?.currentTime() || 0
      if (startPlayTimeRef.current && timeCode - startPlayTimeRef.current > 5) {
        setBufferingErrorState(Date.now())
      }
    })

    localPlayer.on('loadedmetadata', () => {
      if (onMetaDataLoad) onMetaDataLoad(localPlayer)
      const progressBar = document.getElementsByClassName(
        'vjs-progress-control vjs-control',
      )[0]
      const videoDuration = localPlayer.mediainfo.duration

      for (let i = 0; i < localPlayer.mediainfo.cuePoints.length; i += 1) {
        const cueName = localPlayer.mediainfo.cuePoints[i].name
        if (!['brief', 'end'].includes(cueName)) {
          const elem = document.createElement('button')
          elem.className = 'vjs-marker'
          elem.id = `cp${i}`
          elem.onmousedown = e => {
            // progress bar uses mousedown so we will for the chapter move as well
            localPlayer.currentTime(localPlayer.mediainfo.cuePoints[i].time)
            e.preventDefault()
            e.stopPropagation()
          }

          elem.style.left = `${
            (localPlayer.mediainfo.cuePoints[i].time / videoDuration) * 100
          }%`
          progressBar.appendChild(elem)
        }
      }
      if (showWorkoutOverlay) {
        const overlayLoaded =
          playerContainer.getElementsByClassName('workoutOverlay').length > 0

        if (!overlayLoaded) {
          localPlayer.overlay({
            content: '',
            overlays: [
              {
                align: 'right',
                content: '<div class="workoutOverlay"/>',
                start: 0,
                end: 'never',
              },
            ],
          })
        }

        setLoadedMetadata(true)
      } else {
        localPlayer.overlay({
          content: '',
          overlays: [],
        })
      }
    })

    localPlayer.playsinline(true)

    if (muted) localPlayer.muted(true)

    if (autoPlay) {
      localPlayer
        .play()
        .then(() => {
          if (resume) {
            const lastProgress = recentVideos[videoId]?.progress || 0
            localPlayer.currentTime(lastProgress)
          }
        })
        .then(() => {
          if (skipToTimeCode.click !== 0) {
            localPlayer.currentTime(skipToTimeCode.time)
          }
        })
    }
  }

  const onFailure = () => {
    console.log('Failure to load player')
  }

  // @NOTE leaving this in for future potential use
  // const goBackSection = () => {
  //   const p = player.current.player
  //   const time = p.currentTime()
  //   const start = 0

  //   let goto

  //   for (let i = 0; i < p.mediainfo.cuePoints.length; i += 1) {
  //     if (p.mediainfo.cuePoints[i].time < time - 2) {
  //       goto = p.mediainfo.cuePoints[i].time
  //     }
  //   }

  //   p.currentTime(goto || start)
  // }

  // @NOTE leaving this in for future potential use
  // const goNextSection = () => {
  //   const p = player.current.player
  //   const time = p.currentTime()
  //   const end = p.duration()
  //   let goto

  //   for (let i = 0; i < p.mediainfo.cuePoints.length; i += 1) {
  //     if (
  //       time < p.mediainfo.cuePoints[i].time &&
  //       (p.mediainfo.cuePoints[i].time < goto || !goto)
  //     ) {
  //       goto = p.mediainfo.cuePoints[i].time
  //     }
  //   }

  //   p.currentTime(goto || end)
  // }

  const skipBackwards = () => {
    const p = player.current.player
    const time = p.currentTime()

    p.currentTime(time - 15)
  }

  //   const skipForwards = () => {
  //     const p = player.current.player
  //     const time = p.currentTime()
  //
  //     p.currentTime(time + 15)
  //   }

  return (
    <>
      {player && (
        <WorkoutOverlay
          workout={workout}
          player={player?.current?.player}
          loaded={loadedMetadata}
          playerClass={customClass}
        />
      )}
      <ReactPlayerLoader
        id={id}
        attrs={{
          className: `brightcove-react-player-loader ${customClass} ${
            controls ? '' : 'controls--hidden'
          }`,
        }}
        key={id}
        ref={player}
        accountId={accountId}
        onSuccess={onSuccess}
        onFailure={onFailure}
        videoId={videoId}
        playerId={playerId}
        options={{ loop, preload, controlBar: { play: false } }}
        embedOptions={{
          responsive: aspectRatio ? { aspectRatio } : true,
          unminified: true,
        }}
        allowFullScreen
        playsinline
      />
    </>
  )
}

export default BrightcoveVideoPlayer
