import React from 'react'
import { formatRawNumber, printClasses } from '../../Helpers'

type Props = {
  value: number,
  goal: number,
  label?: string,
  placeholder?: string,
}

JumpProgress.defaultProps = {
  label: 'Progress',
  placeholder: '---',
}

function JumpProgress(props: Props) {
  // State
  const { value, goal, label, placeholder } = props

  // Temp
  // State
  // Context
  // API
  // test data
  // Variables
  const pct = (value / goal) * 100
  const maxPct = pct > 100 ? 100 : pct
  const shift = pct > 80 ? 0 : 1
  const color = pct > 80 ? 'var(--white)' : 'var(--black)'
  const overage = pct > 100 ? pct - 100 : 0

  const classes = ['chart', 'chart--progress', overage > 0 && 'has-overage']

  // functions
  return (
    <div
      className={printClasses(classes)}
      style={{
        '--pct': `${maxPct}%`,
        '--over': `${overage}%`,
        '--shift': shift,
        '--color': color,
      }}
    >
      <div className="chart--progress--goal">{formatRawNumber(goal)}</div>

      <div className="chart--progress--bar">
        <div className="chart--progress--value">
          <b>{formatRawNumber(value) || placeholder}</b>
        </div>
      </div>

      {label && <div className="chart--progress--label">{label}</div>}
    </div>
  )
}

export default JumpProgress
