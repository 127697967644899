import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import dayjs from 'dayjs'

import Questions from '../data/questions.json'

import AppLayout from '../components/layout/Layout'
import TabSet from '../components/elements/TabSet'
import Dialog from '../components/elements/Dialog'
import Button from '../components/elements/Button'

import Profile from './parts-for-onboarding/Profile'
import Assessment from './parts-for-onboarding/Assessment'
import KitRecommendation from './parts-for-onboarding/KitRecommendation'

import {
  useStatus,
  useSetProfile,
  useSetAssessment,
  useSetKit,
} from '../context/atomContext'
import { setBackgroundImage } from '../Helpers'

type OnboardingProps = {
  history: Object,
}

function Onboarding(props: OnboardingProps) {
  const { history } = props
  const [assessmentResponses, setAssessmentResponses] = useState({})
  const [kitResponses, setKitResponses] = useState({})
  const [profile, setProfile] = useState({})
  const [profileDone, setProfileDone] = useState(false)
  const [assessmentDone, setAssessmentDone] = useState(false)
  const [kitDone, setKitDone] = useState(false)
  const [startTab, setStartTab] = useState('profile')
  const [showSkipDialog, setShowSkipDialog] = useState(false)

  const dismissSkipDialog = () => setShowSkipDialog(false)

  const status = useStatus()
  const postProfile = useSetProfile()
  const setAssessment = useSetAssessment()
  const setKit = useSetKit()

  useEffect(() => {
    if (!status?.user?.id) {
      history.push('/')
    }
  }, [history, status])

  const submitProfile = (newProfile, onSuccess, onError) => {
    setProfile(newProfile)
    postProfile.mutate(newProfile, {
      onSuccess: () => {
        console.log('profile saved')
        onSuccess()
        if (newProfile.kitPurchased) setShowSkipDialog(true)
      },
      onError: anError => {
        console.log(anError)
        onError()
      },
    })
  }

  const submitAssessment = responses => {
    setAssessmentDone(true)
    const assessmentScore = computeAssessmentScore(responses)
    setAssessment.mutate({ ...responses, assessmentScore })
  }

  const submitKit = responses => {
    setKitDone(true)
    const kitScore = computeKitScore(responses)
    setKit.mutate({ ...responses, kitScore })
  }

  function assessmentQuestions() {
    if (profile.gender === 'male') {
      return [
        ...Questions.preAssessment,
        ...Questions.maleAssessment,
        ...Questions.postAssessment,
      ]
    }
    return [
      ...Questions.preAssessment,
      ...Questions.femaleAssessment,
      ...Questions.postAssessment,
    ]
  }

  const questionReducer = (
    questions,
    responses,
    reducer,
    useBioScores = false,
  ) => {
    const scores = []

    if (useBioScores) {
      // if we have a non zero weight then they filled out a profile so use the data
      if (profile.weight > 0) {
        const imperialWeight = profile.weight * 2.2

        if (profile.gender === 'male') {
          if (imperialWeight < 140) {
            scores.push(4)
          } else if (imperialWeight >= 140 && imperialWeight < 190) {
            scores.push(3)
          } else if (imperialWeight >= 190 && imperialWeight < 240) {
            scores.push(2)
          } else if (imperialWeight >= 240) {
            scores.push(1)
          }
          // Below is gender !male
        } else if (imperialWeight < 100) {
          scores.push(4)
        } else if (imperialWeight >= 150) {
          scores.push(2)
        }

        const age = dayjs().diff(dayjs(profile.dob, 'MM/DD/YYYY'), 'years')

        if (age < 16 || age > 70) {
          scores.push(4)
        } else if (age < 20 || age > 60) {
          scores.push(3)
        } else {
          scores.push(2)
        }
      }
    }

    questions.forEach(question => {
      const responseVals = []

      if (question.answers) {
        question.answers.forEach(answer => {
          if (
            answer.value > 0 &&
            Array.isArray(responses[question.id]) &&
            responses[question.id].includes(answer.id)
          ) {
            let answerValue = answer.value

            // TODO: find a better way to do exceptions than this.
            if (
              question.id === 'assessment5' &&
              profile.gender !== 'male' &&
              answerValue > 1
            ) {
              answerValue -= 1
            }

            responseVals.push(answerValue)
          }
        })
      }

      if (question.id === 'assessment9' && responses.assessment9) {
        const pushVal = parseInt(responses.assessment9.assessment9Pushups, 10)
        const squatVal = parseInt(responses.assessment9.assessment9Squats, 10)
        const kneePushVal = parseInt(
          responses.assessment9.assessment9KneePushups,
          10,
        )
        const mSquatVal = parseInt(
          responses.assessment9.assessment9ModifiedSquats,
          10,
        )

        if (!pushVal && kneePushVal > 0) responseVals.push(4)
        if (!squatVal && mSquatVal > 0) responseVals.push(4)

        if (pushVal > 0 && pushVal < 5) {
          responseVals.push(4)
        } else if (pushVal >= 5 && pushVal < 10) {
          responseVals.push(3)
        } else if (pushVal >= 10 && pushVal < 30) {
          responseVals.push(2)
        } else if (pushVal >= 30) {
          responseVals.push(1)
        }

        if (squatVal > 0 && squatVal < 10) {
          responseVals.push(4)
        } else if (squatVal >= 10 && squatVal < 30) {
          responseVals.push(3)
        } else if (squatVal >= 30 && squatVal < 60) {
          responseVals.push(2)
        } else if (squatVal >= 60) {
          responseVals.push(1)
        }
      }

      if (responseVals.length > 0) {
        scores.push(responseVals.reduce(reducer) / responseVals.length)
      }
    })

    if (scores.length > 0) {
      return scores.reduce(reducer) / scores.length
    }

    return 4 // If no answers are given at all we just return a default of 4
  }

  const adderReducer = (accumulator, currentValue) => accumulator + currentValue
  const maxReducer = (accumulator, currentValue) =>
    accumulator > currentValue ? accumulator : currentValue

  const score = () => {
    const assessmentScore = computeAssessmentScore(assessmentResponses)
    const kitScore = computeKitScore(kitResponses)
    return [assessmentScore, kitScore]
  }

  const computeAssessmentScore = responses =>
    questionReducer(assessmentQuestions(), responses, adderReducer, true)

  const computeKitScore = responses =>
    questionReducer(Questions.kit, responses, maxReducer, false)

  const heels = (
    <>
      <Dialog
        isOpen={showSkipDialog}
        handleDismiss={dismissSkipDialog}
        header="Hey!"
        cnames="onboarding--dialog--skip-assessment"
      >
        <section>
          <p>
            Looks like you already purchased your equipment kit. What would you
            like to do?
          </p>
        </section>
        <footer
          className="rpm-dialog--footer text text--center"
          style={{ '--local-margin-top': 'var(--space-nudge)' }}
        >
          <p>I know my level.</p>
          <p>
            <Button
              label="Skip Assessment"
              onClick={() => history.push('/')}
              gtm="Atom-Onboarding-Skip-Dialog"
            />
          </p>
          <p style={{ '--local-margin-top': 'var(--space-bump)' }}>
            I&apos;m still curious&hellip;
          </p>
          <p>
            <Button label="Continue Assessment" onClick={dismissSkipDialog} />
          </p>
        </footer>
      </Dialog>
    </>
  )
  /* eslint-disable react/no-unknown-property */

  return (
    <AppLayout
      name="onboarding"
      headerConfig={{ label: 'Onboarding' }}
      cnames="one-column-content ui-dark"
      heels={heels}
      hideActivities
    >
      {!kitDone ? (
        <TabSet startTab={startTab} cnames="app-content">
          <div
            id="profile"
            label="Profile"
            progress={{ step: 0, max: 2 }}
            done={profileDone}
          >
            <Profile
              onSubmit={(newProfile, onSuccess, onError) => {
                submitProfile(newProfile, onSuccess, onError)
              }}
              setProfile={setProfile}
              nextTab="assessment"
              onNext={() => setProfileDone(true)}
              onSkip={oldProfile => {
                if (oldProfile.kitPurchased) setShowSkipDialog(true)
              }}
              profileHeadline="Create Your Profile"
              saveButtonLabel="Next"
              sections={[
                'country',
                'province',
                'firstName',
                'lastName',
                'dob',
                'gender',
                'height',
                'weight',
                'skipOnboarding',
              ]}
              formError={postProfile.isError && postProfile.error}
            />
          </div>

          <div
            id="assessment"
            label="Assessment"
            progress={{
              step: Object.keys(assessmentResponses).length,
              max: assessmentQuestions().length,
            }}
            done={assessmentDone}
          >
            <Assessment
              key="assessment"
              setBackgroundImage={setBackgroundImage}
              nextTab="kit"
              prevTab="profile"
              onNext={val => submitAssessment(val)}
              responses={assessmentResponses}
              updateResponse={setAssessmentResponses}
              questions={assessmentQuestions()}
            />
          </div>

          <div
            id="kit"
            label="Kit"
            progress={{
              step: Object.keys(kitResponses).length,
              max: Questions.kit.length,
            }}
            done={kitDone}
          >
            <Assessment
              key="kit"
              setBackgroundImage={setBackgroundImage}
              prevTab="assessment"
              onNext={val => submitKit(val)}
              responses={kitResponses}
              updateResponse={setKitResponses}
              questions={Questions.kit}
            />
          </div>
        </TabSet>
      ) : (
        <KitRecommendation
          score={score()}
          gender={profile.gender}
          onBack={() => {
            setStartTab('kit')
            setKitDone(false)
          }}
          onNext={() => {
            const myScore = score()
            const kitName = ['', 'AXS', 'FLY', 'PWR']
            const genderName = { male: 'M', female: 'F' }
            const level = Math.min(4, Math.round(Number(myScore[0])))
            let kitQuery = ''
            if (myScore[1] && level)
              kitQuery = `?onboarding=ATM-${kitName[myScore[1]]}-L${level}${
                genderName[profile.gender] || ''
              }`
            window.open(
              `http://rpmtraining.com/products/atom-kit${kitQuery}`,
              '_blank',
            )
          }}
        />
      )}
    </AppLayout>
  )
  /* eslint-enable react/no-unknown-property */
}

export default withRouter(Onboarding)
