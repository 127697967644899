import React from 'react'
import Button from '../elements/Button'
import IconText from '../elements/IconText'

import { formatRawNumber } from '../../Helpers'

type Props = {
  action: any,
  count: number,
  isDisabled?: boolean,
}

ButtonMembers.defaultProps = {
  isDisabled: false,
}

function ButtonMembers(props: Props) {
  const { action, count, isDisabled } = props

  return (
    <Button kind="icon" onClick={action} disabled={isDisabled}>
      <IconText
        icon={{ type: 'fal', name: 'user' }}
        text={formatRawNumber(count)}
        cnames="user--meta--members"
      />
    </Button>
  )
}

export default ButtonMembers
