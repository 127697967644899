import React, { useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  useOpen10kUserComment,
  useOpen10kTeamComment,
  use10kToggleUserLike,
  use10kToggleTeamLike,
  jumpData,
} from './10kHelpers'

import Button from '../../components/elements/Button'
import Panel from '../../components/elements/Panel'
import IconText from '../../components/elements/IconText'
import Icon from '../../components/elements/Icon'

import ButtonComments from '../../components/molecules/ButtonComments'
import ButtonLikes from '../../components/molecules/ButtonLikes'
import ButtonMembers from '../../components/molecules/ButtonMembers'
import ConfirmationDialog from '../../components/molecules/ConfirmationDialog'

import UserProfileRow from '../../components/structures/UserProfileRow'
import ListUsers from '../../components/structures/ListUsers'

import DailyJumps from './ChartDailyJumps'
import JumpStats from './JumpStats'
import CreateTeam from './PanelTeamCreate'
import JoinTeam from './PanelTeamJoin'
import TeamInvites from './PanelTeamInvites'
import TeamProfile from './PanelTeamProfile'

import { SocialContext } from '../../context/socialContext'
import {
  useStatus,
  useLeaveTeam,
  useMyEventTeam,
} from '../../context/atomContext'

import { formatRawNumber, printClasses, filterCollection } from '../../Helpers'

function TeamChallenge() {
  // State
  const [teamPanel, setTeamPanel] = useState(undefined)
  const [showLeaveTeam, setShowLeaveTeam] = useState(false)

  // Context
  const { openProfile, openGroupProfile } = useContext(SocialContext)

  // API
  const status = useStatus()
  const leaveTeam = useLeaveTeam()
  const {
    data: teamData,
    isLoading: myTeamIsLoading,
    refetch,
  } = useMyEventTeam()
  const open10kUserComment = useOpen10kUserComment()
  const open10kTeamComment = useOpen10kTeamComment()
  const toggle10kUserLike = use10kToggleUserLike()
  const toggle10kTeamLike = use10kToggleTeamLike()

  // Variables
  const debug = false
  const myTeam = teamData?.value
  const location = useLocation()

  const todaysJumps = myTeam?.totals?.today || null
  const allDoubleUnders = myTeam?.totals?.doubles || 0
  const allSingleUnders = myTeam?.totals?.singles || 0
  const allJumps = myTeam?.totals?.combined || 0

  const jumpStats = [
    {
      id: 'total',
      value: (allJumps > 0 && allJumps) || null,
      isBold: true,
      divish: false,
      labelish: true,
      label: 'Total Jumps',
    },
    {
      id: 'doubles',
      value: (allDoubleUnders > 0 && allDoubleUnders) || null,
      isBold: false,
      divish: true,
      labelish: false,
      label: 'Double-unders',
      icon: { name: 'chevrons-up', type: 'fal' },
    },
    {
      id: 'singles',
      value: (allSingleUnders > 0 && allSingleUnders) || null,
      isBold: false,
      divish: true,
      labelish: false,
      label: 'Single-unders',
      icon: { name: 'chevron-up', type: 'fal' },
    },
  ]

  const teamMembers = filterCollection(myTeam?.members, 'status', 'member')
  const pendingInvites = filterCollection(myTeam?.members, 'status', 'invited')

  // functions
  const dismissPanel = () => setTeamPanel(null)
  const openTeamPanel = panel => setTeamPanel(panel)
  const openCreateTeam = () => openTeamPanel('createTeamPanel')
  const openEditTeam = () => openTeamPanel('editTeamPanel')
  const openInviteMembers = () => openTeamPanel('inviteToTeamPanel')
  const openJoinTeam = () => openTeamPanel('joinTeamPanel')
  const openLeaveTeam = () => setShowLeaveTeam(true)
  const dismissLeaveTeam = () => setShowLeaveTeam(false)

  const displayedPanel = () => {
    switch (teamPanel) {
      case 'editTeamPanel':
      case 'createTeamPanel':
        return createTeamPanel
      case 'inviteToTeamPanel':
        return inviteToTeamPanel
      case 'joinTeamPanel':
        return joinTeamPanel
      case 'teamProfilePanel':
        return teamProfilePanel
      default:
        return null
    }
  }

  const translateTeam = t => ({
    name: t.name,
    title: t.name,
    username: t.name,
    profileImage: t.image,
  })

  // validators

  // panels
  const teamProfilePanel = {
    header: 'Team Profile',
    opensFrom: 'right',
    cnames: 'team-panel team-panel--profile',
    content: <TeamProfile dismissPanel={dismissPanel} />,
  }

  const createTeamPanel = {
    header: myTeam?.isTeamAdmin ? 'Edit Team' : 'New Team',
    opensFrom: 'right',
    cnames: 'team-panel team-panel--create',
    content: (
      <CreateTeam
        dismissPanel={dismissPanel}
        myTeam={teamPanel === 'editTeamPanel' && myTeam}
      />
    ),
  }

  const getRowDetails = row => (
    <>
      {(row?.results?.totals?.todaySingles > 0 ||
        row?.results?.totals?.todayDoubles > 0) && (
        <>
          <span className="ish">Today</span>{' '}
        </>
      )}
      {row?.results?.totals?.todaySingles > 0 && (
        <IconText
          icon={{ name: 'chevron-up', type: 'fal', cnames: 'ish' }}
          text={formatRawNumber(row?.results?.totals?.todaySingles || 0)}
          cnames=""
        />
      )}
      {row?.results?.totals?.todayDoubles > 0 && (
        <IconText
          icon={{ name: 'chevrons-up', type: 'fal', cnames: 'ish' }}
          text={formatRawNumber(row?.results?.totals?.todayDoubles || 0)}
          cnames=""
        />
      )}
      <span className="flex--break"> </span>
      {row?.results?.totals?.singles > 0 && (
        <IconText
          icon={{ name: 'chevron-up', type: 'fal', cnames: 'ish' }}
          text={formatRawNumber(row?.results?.totals?.singles || 0)}
          cnames=""
        />
      )}
      {row?.results?.totals?.doubles > 0 && (
        <IconText
          icon={{ name: 'chevrons-up', type: 'fal', cnames: 'ish' }}
          text={formatRawNumber(row?.results?.totals?.doubles || 0)}
          cnames=""
        />
      )}
    </>
  )

  const inviteToTeamPanel = {
    header: 'Invite Members',
    opensFrom: 'right',
    cnames: 'team-panel team-panel--invites',
    content: <TeamInvites dismissPanel={dismissPanel} teamId={myTeam?.id} />,
  }

  const joinTeamPanel = {
    header: 'Join A Team',
    opensFrom: 'right',
    cnames: 'team-panel team-panel--join',
    content: <JoinTeam dismissPanel={dismissPanel} />,
  }

  const translatedTeam = myTeam?.id && translateTeam(myTeam)
  const isLikeUser = myTeam?.isLikeUser

  if (debug) console.log('team jumps from *date*', todaysJumps)

  return (
    <>
      <section className="team-stats">
        {!myTeam?.id && !myTeamIsLoading && (
          <section className="section-block">
            <div className="rpm-button-block is-shaded">
              <Button
                kind="primary"
                label="Build Your Team"
                onClick={openCreateTeam}
              />
              <Button
                kind="primary"
                label="Join a Team!"
                onClick={openJoinTeam}
              />
            </div>
          </section>
        )}

        {myTeam?.id && (
          <>
            <section className="section-block">
              <div className="rpm-header">
                <h5 className="flex--auto-spread text--caps">
                  <span>My Team</span>
                  {!myTeam.isTeamAdmin && (
                    <span className="user--meta--kcl ish">
                      <Button kind="text" onClick={openLeaveTeam}>
                        <IconText
                          icon={{
                            type: 'far',
                            name: 'arrow-up',
                            size: '1x',
                            cnames: 'icon-rotate--45',
                            fw: false,
                          }}
                          text="Leave Team"
                          flipped
                        />
                      </Button>
                    </span>
                  )}
                  {myTeam.isTeamAdmin && (
                    <span className="user--meta--kcl ish">
                      <Button kind="text" onClick={openEditTeam}>
                        <IconText
                          icon={{
                            type: 'far',
                            name: 'edit',
                            size: '1x',
                          }}
                          text="Edit"
                          cnames="text--700"
                          flipped
                        />
                      </Button>
                    </span>
                  )}
                </h5>
              </div>
            </section>

            <UserProfileRow
              user={translatedTeam}
              avatarSize={3}
              openProfile={() => openGroupProfile({ id: myTeam.id })}
            >
              <div
                className={printClasses([
                  'flex--auto-spread',
                  'text--small',
                  'text--caps',
                ])}
              >
                <p
                  className={printClasses([
                    'user--meta--kcl',
                    'flex--auto-gap',
                  ])}
                >
                  <span>
                    Today <b>{formatRawNumber(todaysJumps) || '---'}</b>
                  </span>

                  <ButtonComments
                    action={() =>
                      open10kTeamComment({
                        ...translatedTeam,
                        id: myTeam.id,
                        onCommentSuccess: () => refetch(),
                      })
                    }
                    count={myTeam?.commentCount}
                  />

                  <ButtonLikes
                    action={() =>
                      toggle10kTeamLike(myTeam.id, { onSuccess: refetch })
                    }
                    count={myTeam?.likeCount}
                    isLikeUser={isLikeUser}
                  />

                  <ButtonMembers
                    action={() => openGroupProfile({ id: myTeam.id })}
                    count={myTeam?.memberCount}
                  />
                </p>
              </div>
            </UserProfileRow>

            <section className="section-block jump-stats">
              <div className="rpm-description">
                <h5 className="text--caps">Team Jump Stats</h5>
                <JumpStats data={jumpStats} />
              </div>

              <div className="rpm-description">
                <DailyJumps data={jumpData(myTeam?.daily)} />
              </div>

              <div className="rpm-description">
                <Link
                  to={{
                    pathname: '/events/10k/leaderboard',
                    state: {
                      returnTo: location,
                      division: {
                        id: 'team',
                        label: 'Teams',
                      },
                    },
                  }}
                  className="rpm-button rpm-button--primary"
                >
                  View Teams Leaderboard
                </Link>
              </div>
            </section>

            {myTeam.raised > 0 && (
              <section className="section-block">
                <div className="rpm-header flex--auto-gap">
                  <div>
                    <Icon type="svg" name="iron-compass" size={3} />
                  </div>
                  <div>
                    <b className="text--caps">
                      ${formatRawNumber(myTeam.raised) || 0} Raised for Iron
                      Compass
                    </b>
                    <br />
                    <small className="ish">By @{myTeam?.name}</small>
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </section>

      <section className="team-members">
        {myTeam?.id && (
          <>
            <section className="section-block">
              <div className="rpm-header">
                <h5 className="flex--auto-spread text--caps">
                  <span>Team Members ({teamMembers.length})</span>
                  {myTeam.isTeamAdmin && (
                    <Button kind="link" onClick={openInviteMembers}>
                      <IconText
                        icon={{
                          type: 'far',
                          name: 'plus-circle',
                          size: 'lg',
                        }}
                        text="Invite"
                        cnames="text--700"
                        flipped
                      />
                    </Button>
                  )}
                </h5>
              </div>
            </section>

            <ListUsers
              users={teamMembers}
              openUserProfile={openProfile}
              openUserComments={open10kUserComment}
              detailsFunc={getRowDetails}
              toggleUserLike={userId =>
                toggle10kUserLike(userId, { onSuccess: refetch })
              }
              refetchData={refetch}
              isAdmin={myTeam.isTeamAdmin}
              onRemove={(userId, options) => {
                leaveTeam.mutate({ teamId: myTeam.id, userId }, options)
              }}
            />
          </>
        )}

        {myTeam?.isTeamAdmin && pendingInvites.length > 0 && (
          <>
            <section className="section-block">
              <div className="rpm-header">
                <h5 className="flex--auto-spread text--caps">
                  <span>Pending Invites ({pendingInvites.length})</span>
                </h5>
              </div>
            </section>

            <ListUsers
              users={pendingInvites}
              openUserProfile={openProfile}
              isAdmin={myTeam.isTeamAdmin}
              disableComments
              disableLikes
              onRemove={(userId, options) => {
                leaveTeam.mutate({ teamId: myTeam.id, userId }, options)
              }}
            />
          </>
        )}
      </section>

      <Panel
        isOpen={teamPanel}
        handleDismiss={dismissPanel}
        header={displayedPanel()?.header}
        opensFrom={displayedPanel()?.opensFrom}
        cnames={displayedPanel()?.cnames}
      >
        {displayedPanel()?.content}
      </Panel>

      <ConfirmationDialog
        isOpen={showLeaveTeam}
        header="Leave Team"
        primaryText="Leave"
        primaryAction={() => {
          leaveTeam.mutate(
            {
              teamId: myTeam.id,
              userId: status?.user?.id,
            },
            {
              onSuccess: () => {
                dismissLeaveTeam()
              },
              onError: anError => {
                console.log(anError)
                dismissLeaveTeam()
              },
            },
          )
        }}
        secondaryText="Cancel"
        secondaryAction={dismissLeaveTeam}
        cnames="app-comments--delete-dialog"
      >
        <p>
          <b>
            You are leaving this team. If you continue, you will no longer be
            able to contribute to the team jump count.
          </b>
        </p>
        <p>This cannot be undone.</p>
      </ConfirmationDialog>
    </>
  )
}

export default TeamChallenge
