import React from 'react'

import { printClasses } from '../../Helpers'

type Props = {
  name: string,
  alt: string,
  color?: string,
}

Logo.defaultProps = {
  color: 'white',
}

function Logo(props: Props) {
  const prefix = 'rpm'
  const { name, alt, color } = props

  const classes = [
    `${prefix}-logo`,
    `${prefix}-logo--${name}`,
    `${prefix}-logo--${color}`,
  ]

  return (
    <svg className={printClasses(classes)} alt={alt}>
      <use href={`#svg-logo-${name}`} />
    </svg>
  )
}

export default Logo
