import React from 'react'
import dayjs from 'dayjs'

import { printClasses } from '../../Helpers'

import InputGroup from './InputGroup'
import TextField from './TextField'
import Dropdown from './Dropdown'

type Props = {
  id: string | number,
  name: string,
  value: string,
  label?: string,
  delimiter?: string,
  cnames?: string,
  required?: boolean,
  error?: boolean,
  changed?: any,
}

DateInput.defaultProps = {
  label: null,
  delimiter: '/',
  cnames: null,
  required: false,
  error: false,
  changed: null,
}

function DateInput(props: Props) {
  const {
    id,
    name,
    value,
    label,
    delimiter,
    cnames,
    required,
    error,
    changed,
  } = props

  const classes = ['rpm-input--group--date', cnames]

  const date = (value && value.split(delimiter)) || []
  const year = date[0]
  const month = date[1]
  const day = date[2]

  const handleDateAtom = (event, atom) => {
    date[atom] = event.target.value && event.target.value.padStart(1, '0')
    const newDate = date.join(delimiter)

    return changed(newDate)
  }

  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ]

  const maxYear = dayjs().year()
  const minYear = dayjs().subtract(100, 'years')

  return (
    <InputGroup
      label={label}
      cnames={printClasses(classes)}
      columns="2fr 1fr 1fr"
      styles={{ '--cols': 3 }}
    >
      <Dropdown
        id={`${id}-month`}
        name={name && `${name}-month`}
        required={required}
        value={month}
        options={months}
        placeholder="Month"
        error={error}
        changed={event => handleDateAtom(event, 1)}
      />

      <TextField
        id={`${id}-day`}
        name={name && `${name}-day`}
        type="tel"
        min="1"
        max="31"
        maxlength={2}
        value={day}
        placeholder="Day"
        required={required}
        error={error}
        changed={event => handleDateAtom(event, 2)}
      />

      <TextField
        id={`${id}-year`}
        name={name && `${name}-year`}
        type="tel"
        min={minYear}
        max={maxYear}
        maxlength={4}
        value={year}
        placeholder="Year"
        required={required}
        error={error}
        changed={event => handleDateAtom(event, 0)}
      />

      {error && <span className="rpm-input--error">{error}</span>}
    </InputGroup>
  )
}

export default DateInput
