import React, { useEffect, useState } from 'react'
import Icon from '../elements/Icon'

import { animateCSS } from '../../Helpers'

import { useProfile } from '../../context/atomContext'

type SubmitScoreSplashProps = {
  visible: boolean,
}

SubmitScoreSplash.defaultProps = {}

function SubmitScoreSplash(props: SubmitScoreSplashProps) {
  const { visible } = props
  const { data: profileData } = useProfile()
  //   const [prevWorkoutTotal, setPrevWorkoutTotal] = useState(undefined)
  //   const [prevStreak, setPrevStreak] = useState(undefined)
  const [animationStep, setAnimationStep] = useState(0)

  //   useEffect(() => {
  //     if (
  //       prevWorkoutTotal !== undefined &&
  //       prevWorkoutTotal !== profileData?.value?.userTraining?.totalTrainingCount
  //     ) {
  //       animateCSS('.splash-total-training-block', 'fadeInDown')
  //     }
  //     setPrevWorkoutTotal(profileData?.value?.userTraining?.totalTrainingCount)
  //   }, [profileData?.value?.userTraining?.totalTrainingCount, prevWorkoutTotal])

  //   useEffect(() => {
  //     if (
  //       prevStreak !== undefined &&
  //       prevStreak !== profileData?.value?.userTraining?.streaks?.training
  //     ) {
  //       animateCSS('.splash-streak-training-block', 'fadeInDown')
  //     }
  //     setPrevStreak(profileData?.value?.userTraining?.streaks?.training)
  //   }, [profileData?.value?.userTraining?.streaks?.training, prevStreak])

  const username = profileData?.value?.firstName || profileData?.value?.username

  useEffect(() => {
    if (!visible) setAnimationStep(0)
    if (animationStep === 0 && visible) {
      setTimeout(() => {
        setAnimationStep(1)
        animateCSS('.splash-total-icon', 'rotateIn').then(() => {
          setTimeout(() => {
            setAnimationStep(2)
            setTimeout(() => setAnimationStep(3), 750)
          }, 750)
        })
        // animateCSS('.splash-total-training-block', 'fadeIn').then(() => {
        //   setTimeout(() => {
        //     animateCSS('.splash-total-training-block', 'fadeOut').then(() => {
        //       setAnimationStep(1)
        //     })
        //   }, 1000)
        // })
      }, 750)
    } else if (animationStep === 3) {
      setAnimationStep(4)
      animateCSS('.splash-streak-icon', 'rotateIn')
      //   animateCSS('.splash-streak-training-block', 'fadeIn').then(() => {
      //     setAnimationStep(2)
      //   })
    }
  }, [animationStep, visible])

  return (
    <div
      style={{
        '--animate-duration': '.75s',
        position: 'fixed',
        width: '100%',
        height: '100vh',
        top: 0,
        left: 0,
        background: 'var(--brand-base)',
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',

        opacity: visible ? 1 : 0,
        transition: 'opacity .25s ease-in-out',
      }}
      className="submit-splash"
    >
      <div className="is-flex--column">
        <h1
          style={{
            textAlign: 'center',
          }}
        >
          Nice Job
          <br />
          {username}!
        </h1>
        <div
          className="is-flex--column splash-total-training-block"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            opacity: animationStep === 1 && visible ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            display: animationStep > 3 ? 'none' : 'flex',
          }}
        >
          <Icon
            name="video-segment-main"
            type="svg"
            size="6"
            cnames="splash-total-icon"
          />
          <span
            style={{
              transform: 'translateZ(0)',
              fontSize: 'var(--text-size-xlarge)',
            }}
          >
            {profileData?.value?.userTraining?.totalTrainingCount}
          </span>
          <span
            style={{
              fontSize: 'var(--text-size-medium)',
            }}
          >
            Workouts Logged
          </span>
        </div>
        <div
          className="is-flex--column splash-streak-training-block"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            opacity: animationStep > 3 ? 1 : 0,
            transition: 'opacity .25s ease-in-out',
            display: animationStep < 4 ? 'none' : 'flex',
          }}
        >
          <Icon name="streak" type="svg" size="6" cnames="splash-streak-icon" />
          <span
            className="splash-streak-training-block"
            style={{
              transform: 'translateZ(0)',
              fontSize: 'var(--text-size-xlarge)',
            }}
          >
            {profileData?.value?.userTraining?.streaks?.training}
          </span>
          <span
            style={{
              fontSize: 'var(--text-size-medium)',
            }}
          >
            Current Streak
          </span>
        </div>
      </div>
    </div>
  )
}

export default SubmitScoreSplash
