import React, { useState, useEffect, useContext, useCallback } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  useLeaderboard,
  useStatus,
  useTrainingCalendar,
} from '../context/atomContext'
import { SocialContext } from '../context/socialContext'

import {
  checkIsMe,
  dateMe,
  formattedScore,
  kitForID,
  divisionForID,
  levelForID,
  typeForID,
  getMessageFromError,
  defaultKitByTrack,
  getTrack,
} from '../Helpers'

import AppLayout from '../components/layout/Layout'

import Calendar from '../components/elements/Calendar'
import Button from '../components/elements/Button'
import Zipper from '../components/elements/Zipper'
import Spinner from '../components/elements/Spinner'

import SelectKit from '../components/molecules/SelectKit'
import FilterByDivision from '../components/molecules/FilterByDivision'
import FilterByLevel from '../components/molecules/FilterByLevel'
import FilterByType from '../components/molecules/FilterByType'

import LeaderboardRow from '../components/structures/LeaderboardRow'

type Props = {
  history: Object,
  match: Object,
}

function Leaderboard(props: Props) {
  const { history, match } = props
  const track = match?.params?.track || 'atomic'
  const trackData = getTrack(track)
  const lastFiltersData = localStorage.getItem(`filtersForTrack-${track}`)

  let lastFilters

  try {
    lastFilters = JSON.parse(lastFiltersData)
  } catch {
    console.log('Failed to parse previous filters')
  }

  const lastKitFilter = lastFilters?.kit || undefined

  const lastLevelFilter =
    lastFilters?.level && lastFilters?.level !== '0'
      ? levelForID(lastFilters.level)
      : {}

  const lastDivisionFilter =
    lastFilters?.division && lastFilters?.division !== '0'
      ? divisionForID(lastFilters.division)
      : {}

  const lastTypeFilter =
    lastFilters?.type && lastFilters?.type !== '0'
      ? typeForID(lastFilters.type)
      : 'myAffiliate'

  const queryParams = new URLSearchParams(window.location.search)
  const [date, setDate] = useState(queryParams.get('date') || dateMe(dayjs()))
  const [activeDivision, setActiveDivision] = useState(lastDivisionFilter)
  const [activeLevel, setActiveLevel] = useState(lastLevelFilter)
  const [activeType, setActiveType] = useState(lastTypeFilter)
  const [popComment, setPopComment] = useState(null)

  const { openComments, toggleLike, openProfile } = useContext(SocialContext)

  const [calendarDate, setCalendarDate] = useState(
    queryParams.get('date') || dateMe(dayjs()),
  )

  const [activeKitFilter, setActiveKitFilter] = useState(
    kitForID(
      queryParams.get('kit') || lastKitFilter || defaultKitByTrack(track),
    ),
  )

  const saveFilters = useCallback(
    val => {
      localStorage.setItem(`filtersForTrack-${track}`, JSON.stringify(val))
    },
    [track],
  )

  const doSelectKitFilter = useCallback(
    item => {
      const currentUrlParams = new URLSearchParams(window.location.search)

      currentUrlParams.set('kit', item.id)
      history.replace(
        `${history.location.pathname}?${currentUrlParams.toString()}`,
      )

      setActiveKitFilter(item)
      saveFilters({
        kit: item.id,
        division: activeDivision?.id,
        level: activeLevel?.id,
        type: activeType?.id,
      })
    },
    [activeDivision, activeLevel, activeType, history, saveFilters],
  )

  const doSelectDivisionFilter = item => {
    setActiveDivision(item)
    saveFilters({
      kit: activeKitFilter?.id,
      division: item?.id,
      level: activeLevel?.id,
      type: activeType?.id,
    })
  }

  const doSelectLevelFilter = item => {
    setActiveLevel(item)
    saveFilters({
      kit: activeKitFilter?.id,
      division: activeDivision?.id,
      level: item?.id,
      type: activeType?.id,
    })
  }

  const doSelectTypeFilter = item => {
    setActiveType(item)
    saveFilters({
      kit: activeKitFilter?.id,
      division: activeDivision?.id,
      level: activeLevel?.id,
      type: item?.id,
    })
  }

  const filterParams = activeType?.id ? [activeType.id] : []

  const {
    isLoading,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    error,
    isError: isLeaderboardError,
    data,
    refetch,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useLeaderboard(
    date,
    {
      kit: activeKitFilter.id,
      division: activeDivision.id,
      level: activeLevel.id,
      filters: filterParams,
    },
    false,
    track,
  )

  const { data: calendarData, isError: isTrainingCalendarError } =
    useTrainingCalendar(dateMe(calendarDate, 'yearMonth'))

  const trainingDates = () => {
    if (
      calendarData?.value?.elementUserTraining.length > 0 ||
      calendarData?.value?.persistUserTraining?.length > 0 ||
      calendarData?.value?.strengthUserTraining.length > 0 ||
      calendarData?.value?.atomicUserTraining.length > 0 ||
      calendarData?.value?.affiliateUserTraining.length > 0
    ) {
      const combinedDates = calendarData?.value?.elementUserTraining || []
      return combinedDates.concat(
        calendarData?.value?.persistUserTraining || [],
        calendarData?.value?.atomicUserTraining || [],
        calendarData?.value?.affiliateUserTraining || [],
        calendarData?.value?.strengthUserTraining || [],
      )
    }
    return []
  }

  const allowedDates = () => {
    if (track === 'atomic') {
      return calendarData?.value?.atomicTraining
    }
    if (track === 'persist') {
      return calendarData?.value?.persistTraining
    }
    if (track === 'element') {
      return calendarData?.value?.elementTraining
    }
    if (track === 'affiliate') {
      return calendarData?.value?.affiliateTraining
    }
    if (track === 'strength') {
      return calendarData?.value?.strengthTraining
    }
    return []
  }

  const maxTrainingDate = () =>
    calendarData?.value?.maxTrainingDates?.[track] || []

  const hasData =
    data?.pages?.length > 0 && data.pages[0].value?.rows?.length > 0

  const workoutData = data?.pages?.length && data.pages[0].value?.workout

  const workoutKits = data?.pages?.length
    ? Object.keys(workoutData).filter(k => k !== 'format')
    : []

  const status = useStatus()
  const location = useLocation()
  const returnTo = location?.state?.returnTo
  const commentId = location?.state?.commentId

  useEffect(() => {
    const kits = data?.pages?.length
      ? Object.keys(workoutData).filter(k => k !== 'format')
      : []

    if (
      activeKitFilter &&
      (kits.length === 0 || kits.includes(activeKitFilter.id))
    ) {
      return
    }
    if (kits.length === 0 || kits.includes(defaultKitByTrack(track))) {
      doSelectKitFilter(kitForID(defaultKitByTrack(track)))
    }
    doSelectKitFilter(kitForID(kits[0]))
  }, [activeKitFilter, data, doSelectKitFilter, track, workoutData])

  useEffect(() => {
    if (commentId && popComment !== commentId) {
      openComments({ id: commentId })
      setPopComment(commentId)
    }
  }, [commentId, popComment, openComments])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const aDate = params.get('date') || dateMe(dayjs())

    if (date !== aDate) {
      setDate(aDate)
    }
  }, [date, location])

  const updateDate = aDate => {
    const currentUrlParams = new URLSearchParams(window.location.search)

    currentUrlParams.set('date', aDate)
    history.replace(
      `${history.location.pathname}?${currentUrlParams.toString()}`,
    )

    setDate(aDate)
  }

  const maxDate = dateMe(maxTrainingDate() || dayjs())

  const getPageNumber = page => {
    if (!page?.value?.pagination?.self) return undefined
    const myurl = new URL(page.value.pagination.self)
    return myurl.searchParams.get('page')
  }

  const getMyRow = () => data?.pages?.length > 0 && data?.pages[0]?.value?.user

  const myRow = getMyRow()

  const showUserRow = () => {
    if (!myRow || myRow.length === 0 || !myRow.row) return false
    const myPageUrl = new URL(myRow.page)
    const myPageNum = myPageUrl.searchParams.get('page')
    let returnVal = true
    data.pageParams.forEach((page, index) => {
      if (Number(myPageNum) === Number(page)) returnVal = false
      if (
        page === undefined &&
        Number(myPageNum) === Number(data.pages[index].value.filters.page)
      )
        returnVal = false
    })
    return returnVal
  }

  const isAltActionActive =
    !Object.is(activeKitFilter.id, defaultKitByTrack(track)) ||
    Object.keys(activeLevel).length > 0 ||
    Object.keys(activeDivision).length > 0

  const headerConfig = {
    backAction: () => {
      if (returnTo) history.push(returnTo)
      else history.push('/')
    },
    label: trackData?.strings?.LEADERBOARD_TITLE || 'Leaderboard',
    altAction: {
      onClick: () => {
        doSelectKitFilter(defaultKitByTrack(track))
        setActiveLevel({})
        setActiveDivision({})
      },
      icon: { name: 'reset-filters', type: 'svg', size: 'lg' },
      label: 'Filters',
      isActive: isAltActionActive,
    },
  }

  const filters = (
    <nav
      className="rpm-filters leaderboard--filters"
      id="leaderboardFilters"
      key="filters"
    >
      <Calendar
        mode="day"
        date={date}
        eventDates={trainingDates()}
        allowedDates={allowedDates()}
        updateEventDatesForDate={d => setCalendarDate(d)}
        handleDate={updateDate}
        config={{
          tools: ['previous', 'next'],
          maxDate,
          allowWeekends: false,
        }}
      />

      <SelectKit
        item={activeKitFilter}
        allowedKits={workoutKits}
        doSelect={doSelectKitFilter}
        track={track}
      />

      {Object.is(track, 'affiliate') && (
        <>
          <div className="filter-column-spacer" />
          <FilterByType item={activeType} doSelect={doSelectTypeFilter} />
        </>
      )}

      <FilterByLevel item={activeLevel} doSelect={doSelectLevelFilter} />

      <FilterByDivision
        item={activeDivision}
        doSelect={doSelectDivisionFilter}
      />

      <Zipper isActive={isFetching} />
    </nav>
  )

  const subheader = hasData ? (
    <div
      id="leaderboardSubheader"
      className="rpm-subheader leaderboard--subheader"
      key="subheader"
    >
      <span>
        Rank{' '}
        {data?.pages?.length > 0 && data?.pages[0].value?.totalCount && (
          <b>
            <em>/{data.pages[0].value.totalCount}</em>
          </b>
        )}
      </span>
      <span>Result</span>
    </div>
  ) : null

  return (
    <AppLayout
      name="leaderboard"
      headerConfig={headerConfig}
      filters={filters}
      subheader={subheader}
      isLoading={
        isLoading ||
        (isFetching && !isFetchingNextPage && !isFetchingPreviousPage)
      }
      isError={isTrainingCalendarError || isLeaderboardError}
      errorMessage={getMessageFromError(error)}
      errorAction={() => window.location.reload()}
      cnames={`one-column-content leaderboard--${track}`}
    >
      <section className="app-content leaderboard--content">
        {((!isLoading && !hasData) || error) && (
          <div className="app-content--inner rpm-description rpm-description--fallback">
            <p>
              {trackData?.strings?.LEADERBOARD_MISSING ||
                'No Leaderboard for this day.'}
            </p>
          </div>
        )}

        {hasData && (
          <>
            {hasPreviousPage && !isFetchingPreviousPage && (
              <Button
                label="Load Previous"
                onClick={() => fetchPreviousPage()}
              />
            )}

            {isFetchingPreviousPage && (
              <div className="leaderboard--infinite-spinner">
                <Spinner size={3} />
              </div>
            )}

            <ul className="app-content--inner rpm-data-list leaderboard--results">
              {data?.pages &&
                data.pages.map(
                  page =>
                    page.value.rows &&
                    page.value.rows.map(row => (
                      <LeaderboardRow
                        isMe={checkIsMe(status, row)}
                        showRank={checkIsMe(status, row)}
                        row={row}
                        results={page.value.totalCount}
                        comments={row.commentCount.toString()}
                        likes={row.likeCount.toString()}
                        toggleLike={() =>
                          toggleLike(row.id, track, {
                            onSuccess: () =>
                              refetch({
                                refetchPage: p =>
                                  getPageNumber(page) === getPageNumber(p),
                              }),
                          })
                        }
                        score={
                          row.beatCap
                            ? formattedScore(row.score, page.value.scoring)
                            : row.tiebreak
                        }
                        key={`${row.name}-${row.rank}`}
                        openComments={() =>
                          openComments({
                            ...row,
                            track,
                            training: { workout: workoutData },
                            onCommentSuccess: () => {
                              refetch({
                                refetchPage: p =>
                                  getPageNumber(page) === getPageNumber(p),
                              })
                            },
                          })
                        }
                        openProfile={() => openProfile(row.userId)}
                      />
                    )),
                )}

              {((hasNextPage && !isFetchingNextPage) || isFetchingNextPage) && (
                <li>
                  {hasNextPage && !isFetchingNextPage && (
                    <Button label="Load More" onClick={() => fetchNextPage()} />
                  )}

                  {isFetchingNextPage && (
                    <div className="leaderboard--infinite-spinner">
                      <Spinner size={3} />
                    </div>
                  )}
                </li>
              )}

              {showUserRow() && (
                <LeaderboardRow
                  isMe
                  showRank
                  row={myRow.row}
                  results={data.pages[0].value.totalCount}
                  comments={myRow.row.commentCount.toString()}
                  likes={myRow.row.likeCount.toString()}
                  toggleLike={() =>
                    toggleLike(myRow.row.id, track, {
                      onSuccess: () =>
                        refetch({
                          refetchPage: p => getPageNumber(p) === '1',
                        }),
                    })
                  }
                  score={formattedScore(
                    myRow.row.score,
                    data.pages[0].value.scoring,
                  )}
                  key={`${myRow.row.name}-${myRow.row.rank}`}
                  openComments={() =>
                    openComments({
                      ...myRow.row,
                      track,
                      training: { workout: workoutData },
                      onCommentSuccess: () => {
                        refetch({
                          refetchPage: p => getPageNumber(p) === '1',
                        })
                      },
                    })
                  }
                  openProfile={() => openProfile(myRow.row.userId)}
                />
              )}
            </ul>
          </>
        )}
      </section>
    </AppLayout>
  )
}

export default withRouter(Leaderboard)
