import React, { useState, useEffect } from 'react'
import { useSwiperSlide } from 'swiper/react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Button from '../../components/elements/Button'
import { PlayIcon } from '../../components/elements/Icon'
import Spinner from '../../components/elements/Spinner'
import VideoPlayer from '../../components/structures/BrightcoveVideoPlayer'
import VideoJS from '../../components/structures/VideojsPlayer'

// export function useOnScreen(ref: RefObject<HTMLElement>) {
//   const [isIntersecting, setIntersecting] = useState(false)

//   const observer = useMemo(
//     () =>
//       new IntersectionObserver(
//         ([entry]) => setIntersecting(entry.isIntersecting),
//         { threshold: 1.0 },
//       ),
//     [],
//   )

//   useEffect(() => {
//     observer.observe(ref.current)
//     return () => {
//       observer.disconnect()
//       setIntersecting(false)
//     }
//   }, [observer, ref])

//   return isIntersecting
// }

type MovementDemoSlideProps = {
  videoId: String,
  id?: String,
  play: Boolean,
  setPlay: () => void,
  videoData?: { id: string, title: string, poster: string, thumb: string },
}

MovementDemoSlide.defaultProps = {
  id: undefined,
  videoData: undefined,
}

function MovementDemoSlide(props: MovementDemoSlideProps) {
  const { videoId, id, play, setPlay, videoData } = props
  const [player, setPlayer] = useState(undefined)
  const [error, setError] = useState(false)
  const [name, setName] = useState('')

  const theme = useTheme()
  const bigScreen = useMediaQuery(theme.breakpoints.up('md'))

  const swiperSlide = useSwiperSlide()

  const isBCVideo = videoId.toString().slice(0, 5) !== 'atom.'

  useEffect(() => {
    if (!player || player.isDisposed()) {
      return
    }
    if (player && swiperSlide.isActive && player.paused() && play) {
      player.currentTime(0)
      player.play()
    }
    if (player && !swiperSlide.isActive && !player.paused()) {
      player.currentTime(0)
      player.pause()
      player.hasStarted(false)
    }
    if (player?.mediainfo?.name && player?.mediainfo?.name !== '')
      setName(player?.mediainfo?.name)
  }, [swiperSlide, player, play])

  useEffect(() => {
    if (!error) return
    const retry = setTimeout(() => setError(false), 3000)

    return () => clearTimeout(retry)
  }, [error])

  return (
    <div
      style={{
        width: 'var(--dashboard-slider--slide-width)',
      }}
    >
      {!error && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            padding: '5px',
            color: 'white',
            backgroundColor: 'var(--lounge-lizard)',
            zIndex: 5,
            transform: 'translateZ(0)',
          }}
          className="text--small"
        >
          {name || videoData?.title}
        </div>
      )}
      {isBCVideo && !error && videoId && (
        <VideoPlayer
          videoId={videoId}
          showWorkoutOverlay={false}
          resume={false}
          muted
          onMetaDataLoad={p => {
            setPlayer(p)
          }}
          onEnded={p => {
            setPlay(false)
            p.currentTime(0)
            p.pause()
          }}
          controls={false}
          id={id}
          preload="metadata"
          onError={() => {
            if (!error) {
              setError(true)
            }
          }}
        />
      )}
      {!isBCVideo && !error && videoId && (!swiperSlide.isActive || !play) && (
        <img
          src={bigScreen ? videoData?.poster : videoData?.thumb}
          alt="movement video poster"
          style={{ width: '100%' }}
        />
      )}
      {!isBCVideo && !error && videoId && play && swiperSlide.isActive && (
        <VideoJS
          videoId={videoId}
          onMetaDataLoad={p => {
            setPlayer(p)
          }}
          onEnded={() => {
            setPlay(false)
          }}
          muted
          controls={false}
          resume={false}
          quality="low"
        />
      )}
      {error && (
        <Button
          onClick={() => setError(false)}
          kind="text"
          styles={{
            width: '100%',
            height: '100%',
            minHeight: 'calc(var(--dashboard-slider--slide-width)/16*9)',
            backgroundColor: 'var(--text-ish)',
            background: player?.mediainfo?.poster
              ? `url("${player.mediainfo.poster}")`
              : 'unset',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            justifyContent: 'center',
          }}
        >
          <Spinner size={2} />
        </Button>
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          justifyContent: 'center',
        }}
      />
      {!play && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            justifyContent: 'center',
            alignItems: 'center',
            transform: 'translateZ(0)',
          }}
        >
          <PlayIcon />
        </div>
      )}
    </div>
  )
}

export default MovementDemoSlide
