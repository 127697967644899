import React from 'react'

import Avatar from '../elements/Avatar'
import Button from '../elements/Button'

import { printClasses, userData } from '../../Helpers'

type Props = {
  user: Object,
  action: Object,
  avatarSize?: number | string,
  isSelected?: Boolean,
  cnames?: string,
}

MentionRow.defaultProps = {
  avatarSize: 2,
  isSelected: false,
  cnames: null,
}

function MentionRow(props: Props) {
  const { user, action, avatarSize, isSelected, cnames } = props

  const { name, username } = userData(user)
  const classes = ['mention--result', isSelected && 'is-selected', cnames]

  return (
    <li className={printClasses(classes)}>
      <Button kind="text" onClick={action}>
        <div className="mention--result--avatar">
          <Avatar
            image={user.profileImage}
            label={name}
            size={avatarSize}
            flag={{ name: user.country }}
          />
        </div>
        <div className="mention--result--details">
          <p className="mention--result--name">
            <b>{name}</b>
          </p>
          <p className="mention--result--username">
            <small>{username}</small>
          </p>
        </div>
      </Button>
    </li>
  )
}

export default MentionRow
