import React from 'react'

import { printClasses } from '../../Helpers'

type Props = {
  id: string | number,
  name: string,
  value: any,
  label: string,
  children?: any,
  checked?: boolean,
  disabled?: boolean,
  changed?: any,
  cnames?: string,
}

CheckboxInput.defaultProps = {
  children: null,
  checked: false,
  disabled: false,
  changed: null,
  cnames: null,
}

function CheckboxInput(props: Props) {
  const {
    id,
    name,
    value,
    label,
    children,
    checked,
    disabled,
    changed,
    cnames,
  } = props

  const classes = [
    'rpm-input',
    'rpm-input--control',
    'rpm-input--checkbox',
    disabled && 'is-disabled',
    cnames,
  ]

  return (
    <label htmlFor={id} className={printClasses(classes)}>
      <input
        type="checkbox"
        id={id}
        className="rpm-input--element"
        name={name}
        value={value}
        defaultChecked={checked}
        onChange={changed}
        disabled={disabled}
      />
      <span>{label || children}</span>
    </label>
  )
}

export default CheckboxInput
