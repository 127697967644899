import React, { useState, useEffect } from 'react'

import AssessmentListItem from './AssessmentListItem'

import Button from '../../components/elements/Button'
import TextField from '../../components/inputs/TextField'
import RadioGroup from '../../components/inputs/RadioGroup'
import { printClasses } from '../../Helpers'
import Markdown from '../../components/elements/Markdown'

import VideoPlayer from '../../components/structures/VideoPlayer'

type Props = {
  onNext: Object,
  onBack: Object,
  question: Object,
  currentResponse?: any,
  setBackgroundImage?: Object,
  hideOnboardingSkip?: Boolean,
}

AssessmentQuestion.defaultProps = {
  currentResponse: [],
  setBackgroundImage: {},
  hideOnboardingSkip: false,
}

function AssessmentQuestion(props: Props) {
  const {
    onNext,
    onBack,
    question,
    currentResponse,
    setBackgroundImage,
    hideOnboardingSkip,
  } = props

  const [selected, setSelected] = useState([])
  const [slide, setSlide] = useState(false)
  const [inputs, setInputs] = useState({})
  const [inputOptions, setInputOptions] = useState({})
  const [errors, setErrors] = useState({})
  const [selectedTooltip, setSelectedTooltip] = useState(-1)

  const fadeIn = () => {
    setSlide(true)
  }

  useEffect(() => {
    if (currentResponse !== undefined) {
      if (question.type !== undefined && question.type === 'userInput') {
        const currentInputs = JSON.parse(JSON.stringify(currentResponse))
        const newInputs = {}
        const newInputOptions = {}
        question.inputs.forEach(input => {
          input.options.forEach(option => {
            if (currentInputs[option.id]) {
              newInputs[input.id] = currentInputs[option.id]
              newInputOptions[input.id] = option.id
            }
          })
        })
        setInputs(newInputs)
        setInputOptions(newInputOptions)
      } else {
        setSelected(JSON.parse(JSON.stringify(currentResponse)))
      }
    }

    const timer = setTimeout(() => {
      fadeIn()
    }, 100)

    const bgImage = question.backgroundImage || 'bg-assessment-01.jpg'

    if (question.backgroundImage) {
      setBackgroundImage(`/app/images/onboarding/${bgImage}`)
    } else {
      setBackgroundImage('')
    }

    // On cleanup clear the timer
    return () => clearTimeout(timer)
  }, [
    currentResponse,
    question.inputs,
    question.backgroundImage,
    question.type,
    setBackgroundImage,
  ])

  const onTooltipSelected = (event, toolTipId) => {
    event.preventDefault()
    event.stopPropagation()
    const tid = Object.is(toolTipId, selectedTooltip) ? -1 : toolTipId
    setSelectedTooltip(tid)
  }

  const handleInputChange = event => {
    const myInputs = { ...inputs }

    myInputs[event.target.id] = event.target.value
    setInputs(myInputs)

    const input = question.inputs.find(
      element => element.id === event.target.id,
    )

    if (input && input.type !== undefined) {
      // we do some validation
      const number = Number(event.target.value)

      if (
        input.type === 'tel' &&
        (typeof number !== 'number' ||
          !Number.isSafeInteger(number) ||
          number < 0)
      ) {
        setErrors({
          ...errors,
          [event.target.id]: 'Must be a number greater than or equal to 0.',
        })
      } else {
        const newErrors = { ...errors }
        newErrors[event.target.id] = undefined
        setErrors(newErrors)
      }
    }
  }

  const handleSelect = (event, index) => {
    event.preventDefault()

    if (selected.includes(index)) {
      // unselect item
      const newSelected = selected.filter(item => item !== index)
      setSelected(newSelected)
    } else {
      // select item if possible
      const { maxItems } = question

      if (selected.length < maxItems) {
        const newSelected = [...selected]
        newSelected.push(index)
        setSelected(newSelected)
      } else if (maxItems === 1) {
        const newSelected = [index]
        setSelected(newSelected)
      }
    }
  }

  const handleNext = skip => {
    if (!skip) {
      let skipToQuestion

      if (question.answers) {
        const answers = question.answers.filter(answer =>
          selected.includes(answer.id),
        )

        const reducer = (accumulator, currentValue) =>
          currentValue.skipTo || accumulator

        skipToQuestion = answers.reduce(reducer, undefined)
      }

      if (question.type && Object.is(question.type, 'userInput')) {
        const finalInputs = {}

        question.inputs.forEach(inputObj => {
          finalInputs[inputOptions[inputObj.id] || inputObj.options[0].id] =
            inputs[inputObj.id]
        })

        onNext(question.id, finalInputs, skipToQuestion)
      } else {
        onNext(question.id, selected, skipToQuestion)
      }
    } else {
      onNext()
    }
  }

  const handleBack = () => {
    onBack()
  }

  const isDisabled = () => {
    if (question.type === 'userInput') {
      return question.inputs.reduce((accumulator, currentValue) => {
        if (!inputs[currentValue.id] || inputs[currentValue.id] === undefined) {
          return true
        }

        if (errors[currentValue.id] !== undefined) {
          return true
        }

        return accumulator
      }, false)
    }

    return question.minItems ? selected.length < question.minItems : false
  }

  return (
    <>
      <div
        className={printClasses(['assessment--container', slide && 'active'])}
      >
        {question.headerText && (
          <header
            className="assessment--header"
            key={`question-header-${question.id}`}
          >
            <small>
              <Markdown>{question.headerText}</Markdown>
            </small>
            {question.headerTooltip && (
              <Button
                kind="secret"
                onClick={event => {
                  onTooltipSelected(event, `${question.id}-header`)
                }}
                cnames="assessment--tooltip--cue fg--dark"
                icon={{
                  name: Object.is(selectedTooltip, `${question.id}-header`)
                    ? 'times-circle'
                    : 'question-circle',
                  type: 'fal',
                  size: 'lg',
                }}
                canRipple={false}
              />
            )}
            {Object.is(selectedTooltip, `${question.id}-header`) && (
              <small>
                <Markdown className="assessment--header--tooltip">
                  {question.headerTooltip}
                </Markdown>
              </small>
            )}
          </header>
        )}

        {(question.answers ||
          question.questionText ||
          question.questionVideo ||
          question.questionImage) && (
          <ul className="assessment--list">
            {question.questionText && (
              <AssessmentListItem
                key="question-question"
                itemKey="question-question"
                primaryText={question.questionText}
                tooltipText={question.questionTooltipText || ''}
                displayTooltip={Object.is(selectedTooltip, question.id)}
                onTooltipSelected={event =>
                  onTooltipSelected(event, question.id)
                }
              />
            )}

            {question.questionVideo && (
              <li key="question-video">
                <VideoPlayer
                  videoId={question.questionVideo}
                  showWorkoutOverlay={false}
                  aspectRatio={question.questionVideoAspectRatio || undefined}
                  onReady={player => {
                    const sourceId =
                      question.questionVideo.split('.')[1] ||
                      question.questionVideo
                    player.poster(
                      `/streaming/${sourceId}/${sourceId}_poster.0000000.jpg`,
                    )
                  }}
                />
              </li>
            )}

            {question.questionImage && (
              <li key="question-image">
                <img src={question.questionImage} alt="question" />
              </li>
            )}

            {(question.type === undefined || question.type === 'select') &&
              question.answers &&
              question.answers.map(answer => (
                <AssessmentListItem
                  key={`question-answer-${answer.id}`}
                  itemKey={`question-answer-${answer.id}`}
                  onClick={event => handleSelect(event, answer.id)}
                  selected={selected.includes(answer.id)}
                  primaryText={answer.answerText}
                  tooltipText={answer.tooltipText || ''}
                  displayTooltip={selectedTooltip === answer.id}
                  onTooltipSelected={event =>
                    onTooltipSelected(event, answer.id)
                  }
                  showSelectedCheck
                />
              ))}
          </ul>
        )}

        {(question.type !== undefined || question.type === 'userInput') && (
          <div className="assessment-inputs">
            {question.inputs.map(input => (
              <div key={input.id} className="assessment-inputs--cluster">
                <RadioGroup
                  name={input.id}
                  label={input.labelText}
                  options={input.options}
                  mode="list"
                  handleChange={event => {
                    setInputOptions({
                      ...inputOptions,
                      [input.id]: event.target.value,
                    })
                  }}
                  selectedValue={inputOptions[input.id] || input.options[0].id}
                  cnames="assessment-inputs--options"
                />
                <div className="assessment-inputs--input">
                  <TextField
                    id={input.id}
                    key={input.id}
                    size={input.size}
                    maxlength={input.size}
                    type={input.type || 'text'}
                    label={input.labelText}
                    placeholder={input.placeholderText}
                    value={inputs[input.id] || ''}
                    error={errors[input.id]}
                    changed={handleInputChange}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        <div
          className={printClasses([
            'rpm-container--button-group',
            question.backgroundImage ? '' : 'has-background',
          ])}
        >
          <p>
            <Button
              cnames="rpm-onboarding--next"
              onClick={() => handleNext(false)}
              gtm={`Atom-Onboarding-${question.id}`}
              disabled={isDisabled()}
              label={
                question.customNextLabel ? question.customNextLabel : 'Next'
              }
            />
          </p>

          <p>
            <Button
              onClick={handleBack}
              kind="link"
              label={
                question.customBackLabel ? question.customBackLabel : 'Back'
              }
              cnames="rpm-onboarding--back"
            />
          </p>

          {question.allowSkip && (
            <p>
              <Button
                onClick={() => handleNext(true)}
                kind="link"
                label="Skip"
                cnames="rpm-onboarding--skip"
              />
            </p>
          )}

          {!hideOnboardingSkip && (
            <p>
              <Button
                onClick={() => window.open('/', '_self')}
                kind="link"
                cnames="rpm-onboarding--skip"
                gtm={`Atom-Onboarding-Skip-${question.id}`}
              >
                Skip Onboarding,
                <br /> take me to my Dashboard
              </Button>
            </p>
          )}
        </div>
      </div>
    </>
  )
}
export default AssessmentQuestion
