import React from 'react'

import { printClasses } from '../../Helpers'

import Button from '../elements/Button'
import Icon from '../elements/Icon'
import Spinner from '../elements/Spinner'

import TextField from './TextField'

type Props = {
  id: string | number,
  name: string,
  value: string,
  debouncedValue: string,
  isSearching?: boolean,
  disabled?: boolean,
  placeholder?: string,
  icon?: string,
  noResults?: string,
  helpMessage?: string,
  hasResults?: boolean,
  maxlength?: number | string,
  cnames?: string,
  isThin?: boolean,
  required?: boolean,
  changed?: any,
  clear?: any,
  error?: boolean,
  alternates?: React.Node,
  prefix?: String,
}

Search.defaultProps = {
  isSearching: false,
  disabled: false,
  placeholder: 'Search...',
  icon: 'search',
  noResults: 'No results, try again.',
  helpMessage: '',
  hasResults: false,
  maxlength: 20,
  cnames: null,
  isThin: false,
  required: false,
  changed: null,
  clear: null,
  error: false,
  alternates: null,
  prefix: '',
}

function Search(props: Props) {
  // State
  const {
    id,
    name,
    value,
    debouncedValue,
    isSearching,
    disabled,
    placeholder,
    icon,
    noResults,
    helpMessage,
    hasResults,
    maxlength,
    cnames,
    isThin,
    required,
    changed,
    clear,
    error,
    alternates,
    prefix,
  } = props

  // Context
  // API
  // test data

  // Variables
  const classes = [
    'rpm-input--control--search',
    (isSearching || icon) && 'has-icon',
    cnames,
  ]

  const helper =
    !isSearching &&
    !hasResults &&
    debouncedValue &&
    ((noResults || helpMessage) && debouncedValue?.length > 1
      ? noResults
      : helpMessage)

  // functions

  return (
    <TextField
      id={id}
      name={name}
      type="text"
      disabled={disabled}
      value={value}
      maxlength={maxlength}
      placeholder={placeholder}
      required={required}
      changed={event => changed(event.target.value)}
      error={error}
      helpMessage={helper}
      cnames={printClasses(classes)}
      isThin={isThin}
    >
      {(isSearching || icon || prefix) && (
        <span className="rpm-input--search--icon">
          {prefix}

          {isSearching ? (
            <Spinner size={1.5} />
          ) : (
            icon && (
              <Icon name={icon} type="far" size="lg" fw={false} cnames="ish" />
            )
          )}
        </span>
      )}

      <div className="rpm-input--search--clear flex--auto-gap">
        {value && clear && (
          <Button
            kind="icon"
            icon={{
              name: 'times-circle',
              type: 'fal',
              size: 'lg',
              fw: false,
            }}
            cnames=" fg--link"
            onClick={clear}
          />
        )}
        {alternates}
      </div>
    </TextField>
  )
}

export default Search
