import React from 'react'

import DetailsBlock from '../../../components/structures/DetailsBlock'
import Markdown from '../../../components/elements/Markdown'

export default function WorkoutNotesSection({
  children,
}: {
  children: ReactNode,
}) {
  return (
    <div>
      <DetailsBlock collapsible cnames="training--format">
        <Markdown>{children}</Markdown>
      </DetailsBlock>
    </div>
  )
}
