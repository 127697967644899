import React from 'react'

import Avatar from '../elements/Avatar'
import Button from '../elements/Button'
import Icon from '../elements/Icon'

import { printClasses, userData } from '../../Helpers'

type Props = {
  user: Object,
  openProfile: Object,
  action?: Object,
  altAction?: Object,
  avatarSize?: number | string,
  expanded?: boolean,
  children?: mixed,
  isMe?: boolean,
  cnames?: string,
}

SearchResultRow.defaultProps = {
  action: null,
  altAction: null,
  avatarSize: 3,
  expanded: true,
  children: null,
  isMe: false,
  cnames: null,
}

function SearchResultRow(props: Props) {
  const {
    user,
    openProfile,
    action,
    altAction,
    avatarSize,
    expanded,
    children,
    isMe,
    cnames,
  } = props

  const { name, username, locale } = userData(user)
  const classes = ['search--result', isMe && 'is-me', cnames]

  return (
    <li className={printClasses(classes)}>
      <div className="search--result--avatar">
        {Object.is(user.type, 'email') ? (
          <Button kind="secret" onClick={altAction} disabled={!altAction}>
            <Avatar
              icon={{ name: 'envelope', type: 'fal', size: 'lg' }}
              size={avatarSize}
              isIcon
            />
          </Button>
        ) : (
          <Button kind="secret" onClick={openProfile}>
            <Avatar
              image={user.profileImage}
              label={name}
              size={avatarSize}
              flag={expanded && { name: user.country }}
            />
          </Button>
        )}
      </div>

      {expanded && (
        <>
          <div className="search--result--details">
            <p className="search--result--username">
              {Object.is(user.type, 'email') ? (
                <span>{user.email}</span>
              ) : (
                <Button kind="text" onClick={openProfile}>
                  <b>{name}</b>
                </Button>
              )}
            </p>

            <p className="search--result--meta">
              {name && username && <small>{username}</small>}

              {locale && (
                <>
                  <br />
                  <small>{locale}</small>
                </>
              )}
            </p>

            {children}
          </div>

          <div className="search--result--action">
            {user.isOnMyTeam ? (
              <span className="text--caps text--small">
                <small>On my team </small>
                <Icon name="star" type="fas" size="xlg" />
              </span>
            ) : (
              action
            )}
          </div>
        </>
      )}
    </li>
  )
}

export default SearchResultRow
