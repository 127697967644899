import { useProfile } from '../context/atomContext'
import { isPermissionType } from '../Helpers'

export default function useFeature(feature: String) {
  const json = localStorage.getItem('features')
  // if we don't have any local data all features turned off
  if (!json) return false
  const features = JSON.parse(json)
  if (features && features[feature]) return true
  return false
}

export function useIsPermission(permission: String | Array) {
  const { data: profileData } = useProfile()

  return isPermissionType(profileData, permission)
}
