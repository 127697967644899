import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useSetProfile, useProfile } from '../context/atomContext'
import { getTrackString } from '../Helpers'

import AppLayout from '../components/layout/Layout'
import Profile from './parts-for-onboarding/Profile'

function SimpleEditProfile() {
  const history = useHistory()
  const showPassword = history?.location?.state?.showPassword
  const { data: myProfile } = useProfile()

  const subscriptions = myProfile?.value?.subscriptions
  const saveButtonLabel = getTrackString(
    subscriptions,
    'SIMPLE_PROFILE_SAVE_BUTTON_LABEL',
    'Start Training',
  )

  const saveButtonTarget = getTrackString(
    subscriptions,
    'SIMPLE_PROFILE_SAVE_BUTTON_TARGET',
    '/dashboard',
  )

  const postProfile = useSetProfile()
  const [error, setError] = useState(undefined)

  const submitProfile = (newProfile, onSuccess, onError) => {
    setError(false)
    postProfile.mutate(newProfile, {
      onSuccess: () => {
        onSuccess()
        if (!window.location.pathname.match(/\/app\/simpleprofile\/?/i)) {
          window.location.reload()
        } else {
          history.push(saveButtonTarget)
        }
      },
      onError: anError => {
        console.log(anError)
        setError(anError)
        onError()
      },
    })
  }

  return (
    <AppLayout
      name="edit-profile"
      hasHeader={false}
      headerConfig={{ label: 'Profile' }}
      cnames="one-column-content edit-profile--simple"
      requireTerms={false}
    >
      <Profile
        onSubmit={(newProfile, onSuccess, onError) => {
          submitProfile(newProfile, onSuccess, onError)
        }}
        sections={[
          'profileImage',
          'firstName',
          'lastName',
          'username',
          'password',
          'easyLevel',
          'easyTrainingGoal',
          'terms',
          'showLogout',
        ]}
        saveButtonLabel={saveButtonLabel}
        formError={error || false}
        showPassword={showPassword}
        noticeText="Let's finish setting up your profile..."
      />
    </AppLayout>
  )
}

export default SimpleEditProfile
