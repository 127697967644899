import React from 'react'

function TeamProfile() {
  // State
  // Context
  // API
  // test data
  // Variables
  // functions
  // validators

  return (
    <section className="app-public-profile--row">
      <h3>boo</h3>
    </section>
  )
}

export default TeamProfile
