import React from 'react'
import Button from '../../elements/Button'

type DetailsProps = {
  onClick: () => void,
}

function AffiliateDetails(props: DetailsProps) {
  const { onClick } = props

  return (
    <>
      <div className="dashboard--details--description">
        <h4 className="text--caps text--700">Description</h4>
        <p>
          Congrats! Your gym is now part of the Atom family! Going forward, you
          can find all of your affiliate’s programming tracks right here,
          including the Gym, Comp, Oly, Teens, and Kids programs.
        </p>
        <p>
          Our Gym track is a classic, constantly varied, GPP-based program
          designed to make you the best all-around athlete you can be. We’re
          optimizing for sustainable long-term results over cheap short-term
          wins. The best program is one you should be able to do for the rest of
          your life, and that requires a thoughtful approach that’s not only
          effective but also FUN!
        </p>
        <p>
          As a member of an Atom gym, you also get access to the entire Atom
          platform. So when life gets in the way and you can’t make it to the
          gym, just hit the Atom GPP workout at home. Need some gear? As an Atom
          Affiliate, your gym gets discounts on RPM equipment. See your coach or
          owner for details!
        </p>
        <p>
          Equipment needed:{' '}
          <a
            href="https://rpmtraining.com/collections/atom-equipment-2"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Everything
          </a>{' '}
          found at your typical functional fitness commercial gym.
        </p>
        <p>
          <Button label="Start Training" onClick={onClick} />
        </p>
      </div>
    </>
  )
}

export default AffiliateDetails
